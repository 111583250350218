/*
  Handles linking user account to Payout service (Connect with PayPal)
*/

import fetch from 'isomorphic-unfetch'

import * as Config from './config'
import * as Utility from './utility'

const globals = Config.globals
const generalConfig = Config.pages.general
const serverConfig = Config.serverConfig

// called by the frontend UI to handle return from external checkout session to link payout (paypal) account, to request the server to receive users info (payout account email address):
export async function payout_requestUserPayoutLinking(callerPage) {
    let data = {}
    let urlParams = globals.urlParams
    if (urlParams.error) {
        data.error = new Error(decodeURIComponent(urlParams['error_description']))
    } else {
        let authCode = urlParams.code
        if (authCode) {
            try {
                const res = await fetch(serverConfig.apiUrl, { // Backend API url
                    method: 'POST',
                    body: JSON.stringify({
                        type: 'payout-link',
                        params: {
                            isDev: Config.isDev,
                            scope: urlParams.scope,
                            authCode: authCode,
                        }
                    }),
                })
                data = await res.json()
            } catch (error) {
                data.error = error
            }
        } else
            data.error = new Error('Linking payout account failed.')
    }
    if (data.error) {
        Utility.showTextDialog(0, 'Failed to link your payout account:\n"' + data.error.toString() + '"', generalConfig.dialogBoxErrorIconClassName)
    } else {        
        data = data.data        
        callerPage.payoutLinkingSuccessful(data['user-id'], data['payer-id'], data.name, data.email)
    }    
    return data
}

