/*
    Creator site - Creator Lesson Plan View Page
    Opens the lesson plan belonging to a given previously created curriculum by the creator teacher
*/

import React from 'react'
import * as Config from './config'
import LessonPlanViewPage from './lesson'
import * as CreatorUtility from './creator-utility'
import * as Database from './database-api'
import * as Utility from './utility'

const globals = Config.globals

class CreatorLessonPlanViewPage extends LessonPlanViewPage {

    constructor(props) {
        super(props)
        // flag true for user site pages and false for creator site pages:
        this.isUserSite = false
        this.isCreatorSite = true
        this.fetchMissingSubmittedCurriculumsDataTried = false
        this.fetchMissingDraftedCurriculumsDataTried = false
        this.pageId = 'creator-lesson'
        this.pageDir = 'creator/'
    }

    // override the superclass's userDataFetched which checks if user has bought the curriculum (not relevant here):
    userDataFetched() {
        this.updateTopMenuUserIconImage()
    }

    // Database API calls this when curriculum info for the given id is fetched. Here first we need to fetch the teacher's data:
    curriculumsDataFetched() { CreatorUtility.lessonPlanViewPageCurriculumsDataFetched(this) }

    // if this returns true the page proceeds to rendering
    // for this page we just need to make sure the user is the teacher for the curriculum being viewed (subclasses may want to redefine this...):
    curriculumTeacherCheckForPage(teacher) { return CreatorUtility.curriculumTeacherCheckForPage(teacher) }

    // which page to navigate to if curriculum with id on url params not found:
    accessNotAllowedNavigationPage() { return '/creator/library' }
      
    renderCTAs(curriculumData, lessonData) {
        return (
            <div id="top-lesson-listing-ctas" className="top-listing-image-and-ctas">
                {Utility.renderLink(<span><i className="fas fa-edit"></i>&nbsp;&nbsp;&nbsp;Edit lesson plan</span>, 'creator-lesson-edit-lesson-plan-button', 'wide-cta-text-button cta-text-button shadowed-text-button bordered-text-button text-button button', this.pageDir + 'edit-lesson?subject=' + this.subject + '&id=' + this.curriculum + '&random-key=' + curriculumData['random-key'] + '&section=' + this.sectionNumber + '&week=' + this.weekNumber + '&day=' + this.dayNumber + '&lesson=' + this.lesson + '&number=' + this.lessonNumber)}
                {Utility.renderLink(<span><i className="fas fa-print"></i>&nbsp;&nbsp;&nbsp;Print lesson plan</span>, 'creator-lesson-print-lesson-plan-button', 'wide-cta-text-button cta-text-button clear-shadowed-text-button bordered-text-button text-button button', this.pageDir + 'lesson-print?subject=' + this.subject + '&id=' + this.curriculum + '&random-key=' + curriculumData['random-key'] + '&section=' + this.sectionNumber + '&week=' + this.weekNumber + '&day=' + this.dayNumber + '&lesson=' + this.lesson + '&number=' + this.lessonNumber)}           
            </div>
        )
    }

    renderLessonTopInfo() {
        let curriculumData = this.curriculumData            
        let lessonData = this.lessonData    
        let lessonNumber = this.lessonNumber
        let gradeTitle = Database.getCurriculumGradeTitle(curriculumData)
        let lessonPrevNextLessonLinksInfo = curriculumData['lessons-prev-next-info'][lessonNumber - 1]
        let lessonPrevLinkInfo = lessonPrevNextLessonLinksInfo.prev
        let lessonNextLinkInfo = lessonPrevNextLessonLinksInfo.next
        return (
            <div id="lesson-body-top-listing" className="page-body-top-listing section">
                <div id="top-lesson-listing-info">
                    <div id="top-lesson-listing-classification" className="row-div">                        
                        <i className="fas fa-angle-left row-padding-10"></i>
                        {Utility.renderLink(curriculumData.title + ' (' + gradeTitle + ')' , 'top-lesson-listing-curriculum-title', 'h5size', this.pageDir + 'view?subject=' + curriculumData.subject + '&id=' + curriculumData.id + '&random-key=' + curriculumData['random-key'])}
                    </div><br/><br/>                    
                    <div id="lesson-number-and-prev-next-buttons" className="row-div bottom-margin25">
                        {this.lessonNumber === 1 ? null : Utility.renderReloadingLink(<span><i className="fas fa-angle-left"></i></span>, 'lesson-prev', 'size22 brown-color toggle-button toggle-link round-border image-button', this.pageDir + 'lesson?subject=' + curriculumData.subject + '&id=' + curriculumData.id + '&random-key=' + curriculumData['random-key'] + '&section=' + lessonPrevLinkInfo.section + '&week=' + lessonPrevLinkInfo.week+ '&day=' + lessonPrevLinkInfo.day + '&lesson=' + lessonPrevLinkInfo.lesson + '&number=' + (this.lessonNumber - 1))}
                        <div className='col-div'>
                            <span  className="size20">Week {this.weekNumber}{curriculumData['num-lessons-per-week'] > 1 ? (' - Day ' + this.dayNumber) : ''}</span><br/><br/>
                            <span className="size25">Lesson {lessonNumber}</span>
                        </div>
                        {this.lessonNumber === curriculumData['num-lessons'] ? null : Utility.renderReloadingLink(<span><i className="fas fa-angle-right"></i></span>, 'lesson-next', 'size22 brown-color toggle-button toggle-link round-border image-button', this.pageDir + 'lesson?subject=' + curriculumData.subject + '&id=' + curriculumData.id + '&random-key=' + curriculumData['random-key'] + '&section=' + lessonNextLinkInfo.section + '&week=' + lessonNextLinkInfo.week + '&day=' + lessonNextLinkInfo.day + '&lesson=' + lessonNextLinkInfo.lesson + '&number=' + (this.lessonNumber + 1))}
                    </div>

                    
                    
                    <h3 id="top-lesson-listing-section-title" className="size22">{curriculumData['section-titles'][this.sectionNumber - 1]}</h3>
                    <h2 id="top-lesson-listing-title" className="size31">{curriculumData['lesson-titles'][this.lessonNumber - 1]}</h2>
                </div>
                {this.renderCTAs(curriculumData, lessonData)}
            </div>
        )
    }
    
    // default render of a landing page without search bar in header...:
    render() {
        return  Utility.renderNoSearchBarPage(this)
    }
}

export default CreatorLessonPlanViewPage
