/*
    Curriculum Print View Page
    Printer-friendly view of the listing for a given already-purchased curriculum
*/

import React from 'react'

import * as Utility from './utility'
import CurriculumViewPage from './view'

class CurriculumPrintViewPage extends CurriculumViewPage {
    constructor(props) {
      super(props)      
      this.pageId = 'curriculum-print'
      this.isPrintPage = true // flag for print pages
      this.bodyClass = 'no-margin-body'
    }
    
    reviewsDataFetched() {
    }

    renderBody() {
      return (
        <Utility.CurriculumPrintViewPageBodyComponent page={this}/>
      )
    }

    render() {
        return this.noHeadersNoFooterRender()
    }   
}

export default CurriculumPrintViewPage
