/*
    Admin site - Admin Lesson Plan View Page
    Allows the admin viewer to view the lesson listing for a given previously created curriculum by any teacher, whether its productions (live), in submissions, or in drafts curriculums table
*/

import React from 'react'

import CreatorLessonPlanViewPage from './creator-lesson'
import * as Database from './database-api'
import * as Config from './config'

const globals = Config.globals

class AdminLessonPlanViewPage extends CreatorLessonPlanViewPage {
    constructor(props) {
      super(props)
      this.pageId = 'admin-lesson'
      this.pageDir = 'admin/'
      // flag true for user site pages and false for creator site pages:      
      this.isCreatorSite = false
      this.isAdminSite = true      
      if (Database.isAdminUserDataFetched())
            this.adminUserDataFetched()
    }

    // override the superclass's userDataFetched which checks if user has bought the curriculum (not relevant here):
    userDataFetched() {
        this.updateTopMenuUserIconImage()
        // Fetch the data for the admin user in session (if any) from the database:
        Database.database_fetchAdminUserData()
    }    

    // database API calls this when data for admin user in session's info is fetched
    // if the user is indeed admin, Database.cachedAdminUserInfo will be defined...
    adminUserDataFetched() {
        // if user is not admin, boot the user out of this page:        
        if (!Database.isAdminUserDataFetched())
            this.setState({navigateTo: this.accessNotAllowedNavigationPage()})
        else if (Database.isCurriculumsDataFetched())
            this.curriculumsDataFetched()
        else        
            Database.fetchCurriculumDataForIds([this.id])
    }

    // Database API calls this when curriculum info for the given id is fetched. Here first we need to fetch the teacher's data:
    curriculumsDataFetched() {    
        // if user is not admin, boot the user out of this page:
        if (!Database.isAdminUserDataFetched())
            return undefined
        super.curriculumsDataFetched()        
    }

    // for admins we don't need to require the user is the teacher for the curriculum being viewed...
    curriculumTeacherCheckForPage(teacher) {
        return true
    }

    // which page to navigate to if curriculum with id on url params not found:
    accessNotAllowedNavigationPage() {
        return '/admin/library'
    }

    renderCTAs(curriculumData, lessonData) {
        return null
    }
}

export default AdminLessonPlanViewPage
