/*
    Creator site - Creator Lesson Plan Print View Page
    Printer-fiendly view of a lesson plan for a given previously created curriculum by the creator teacher
*/

import LessonPlanPrintViewPage from './lesson-print'
import * as CreatorUtility from './creator-utility'

class CreatorLessonPlanPrintViewPage extends LessonPlanPrintViewPage {
    constructor(props) {
      super(props)
      this.pageId = 'creator-lesson-print'      
    }
  
    // override the superclass's userDataFetched which checks if user has bought the curriculum (not relevant here):
    userDataFetched() {
      this.updateTopMenuUserIconImage()
    }

    // Database API calls this when curriculum info for the given id is fetched. Here first we need to fetch the teacher's data:
    curriculumsDataFetched() { CreatorUtility.lessonPlanViewPageCurriculumsDataFetched(this) }

    // if this returns true the page proceeds to rendering
    // for this page we just need to make sure the user is the teacher for the curriculum being viewed (subclasses may want to redefine this...):
    curriculumTeacherCheckForPage(teacher) { return CreatorUtility.curriculumTeacherCheckForPage(teacher) }    

    // which page to navigate to if curriculum with id on url params not found:
    accessNotAllowedNavigationPage() { return '/creator/library' }

}

export default CreatorLessonPlanPrintViewPage
