/*
    Signin page:

    Redirecting the external cognito hosted sign-in page...
*/

import React from 'react'

import * as Config from './config'
import * as Utility from './utility'

let authConfig = Config.authConfig

// ------------------------------------------------------------------------
// Signin Page element
class SignInPage extends React.Component {
    constructor(props) {
        super(props)
        // store refering page before signin/register redirect to external cognito auth page, so that upon return we can auto redirect back to that page, instead of home page...:
        Utility.storeReferingPageBeforeAuthRedirect()        
        // now redirecting to the external cognito hosted sign-in page:
        window.location.replace(authConfig.loginDomain + '/login?response_type=token&client_id=' + authConfig.clientId + '&redirect_uri=' + authConfig.signInRedirectUrl)
    }
    
    render() {
        // Instead use an iframe to the external cognito hosted sign-in page:
        return (                                                           
            <div id="signin-page" className="page logo-top-page"></div> 
        )
    }
}

export default SignInPage