/*
    Landing Page is a superclass for all pages that can be the landing page (user first visits in the address bar), including the home page, search page, ...
*/

import React from 'react'
import { Navigate } from 'react-router-dom'
import DocumentMeta from 'react-document-meta'
import ReactGA from 'react-ga4' // google analytics

import * as Config from './config'
import * as Auth from './auth-api'
import * as Locale from './locale-api'
import * as Database from './database-api'
import * as Fileserver from './fileserver-api'
import * as Utility from './utility'

const reactAppEnv = Config.reactAppEnv

const envConfig = Config.envConfig
const globals = Config.globals
const analyticsConfig = Config.analyticsConfig

class LandingPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = { 
            // use this flag to set when want the component to re-render:
            rerender: false,
            // will be set when we want to navigate to a new page: 
            navigateTo: undefined
        }
        // flag to know this page could be used as a landing page (entering url in address bar)
        this.isLandingPage = true
        // flag true for user site pages:
        this.isUserSite = true
        // flag true for creator site pages:
        this.isCreatorSite = false
        // flag true for admin site pages:
        this.isAdminSite = false
        // for user site pages dir is empty:
        this.pageDir = ''
        // a layer behind all select/menu components so we can toggle them off by clicking outside them:
        this.selectComponentsBackgroundLayerRef = React.createRef()
        // save a ref to this page so database api can trigger render for it once data fetched
        globals.currentPage = this        
        // read URL params into globals
        globals.urlParams = Utility.parseUrlParams()
        // Initializer for everything having to do with frontend client-side:        
        this.initFrontend()
        // Run initializers related to backend (authentication, database, etc.) if not done so yet:
        this.initBackend()
    }

    // things to do after first render:
    componentDidMount() {        
        // set a flag so others know this is ready:
        this.didMount = true    
        // if site is flagged as down, navigate to down page:
        if (envConfig.siteDown && !globals.debug)
            this.setState({navigateTo: '/down'})        
        // make sure if we're just visiting page it is scrolled to top:
        window.scrollTo(0, 0)
        // if the flag is set, listen to window size change to trigger re-render for adaptive components (currently: home page: number of curriculum cards shown, all pages: search bar width):
        // a flag that say whether this page needs a resize event handler:
        if (this.hasResizeHandler)
            window.addEventListener('resize', (e) => this.handleResize())  
        this.addSearchBarEvent()
        // Analytics - record page visit:
        if (analyticsConfig.analyticsOn) {
            //ReactGA.pageview(this.pageDir + this.pageId, undefined, this.pageId)
            ReactGA.send({ hitType: 'pageview', page: this.pageDir + this.pageId, title: 'Page Visit - ' + this.pageId})
        }
    }
    
    // things to do after each new render
    // returns true if the page is supposed to go on rendering or not:
    componentDidUpdate(prevProps, prevState, snapshot) {
        // if we're already navigating out of this page, then skip:
        if (this.state.navigateTo) {
            return false
        } else {
            this.addSearchBarEvent()
            return true
        }
    }

    // remove any window resize listener when done:
    componentWillUnmount() {
        // set a flag so others know this is done:
        this.didMount = false        
        if (this.hasResizeHandler)
            window.removeEventListener('resize', (e) => this.handleResize())
    }

    // use this to safely set state knowing component is mounted and not yet unmounted:
    safeSetState(state) {        
        if (this.didMount)
            this.setState(state)
    }

    // re-render for adaptive components (currently: home page: number of curriculum cards shown):
    handleResize() {        
    }

    // Handle "search" event in the search bars (to navigate to search page):
    addSearchBarEvent() {                
        if (this.headerSearchBarRef && this.headerSearchBarRef.current) {
            let searchFn = (query, isMobileHeader) => {                
                if (query) {                    
                    this.searchBarClicked(isMobileHeader)
                }
            }
            this.headerSearchBarRef.current.addEventListener('search', (e) => searchFn(e.target.value, false))
            if (this.mobileHeaderSearchBarRef && this.mobileHeaderSearchBarRef.current)
                this.mobileHeaderSearchBarRef.current.addEventListener('search', (e) => searchFn(e.target.value, true))
            // if search query was in the url, search bar should render with the value set to the query:
            if (globals.urlParams.q)
                this.headerSearchBarRef.current.value = globals.urlParams.q
        }
    }
        
    // Initializer for everything having to do with frontend client-side. Currently:
    // 1. initiliaze Analytics for monitoring user sessions:
    // 2. initate async fetch of locale information (user ip, country, currency, exchange rate to USD, etc.)
    initFrontend() {
        if (globals.FrontEndInit) { return }
        globals.FrontEndInit = true
        // 1. initiliaze Analytics for monitoring user sessions:
        if (analyticsConfig.analyticsOn) {
            ReactGA.initialize(analyticsConfig.analyticsTrackingId) //, {debug: true})
            // trying to see if this fixes no data being picked up:
            //ReactGA.ga('set', 'checkProtocolTask', null)
        }
        // 2. initate async fetch of locale information (user ip, country, currency, exchange rate to USD, etc.):
        Locale.locale_fetchUserInfo()
    }
    
    // Initializer for everything having to do with backend: authentication initializing, database connection init, etc. Currently:
    // 1. Initiliaze connection to database abd fetch curriculum data
    // 2. Check if user session active:
    initBackend() {
        // let database process anything based on URL params:
        Database.processUrlParams()
        // Inititalize connection to backend AWS (auth/database/etc.):
        Auth.initAndGetSessionInfoThenInitDatabase(this)
    }

    // Default (including for home page) initial search query to run is to get all popular (rating >= 4) curriculums in any subject
    getOnLoadCurriculumResultsQueryForThisPage() {
        return Database.getOnLoadCurriculumResultsQueryForLandingPage()
    }

    searchBarClicked(isMobileHeader = false) {             
        const currSubject = globals.urlParams.subject
        let refName = isMobileHeader ? 'mobileHeaderSearchBarRef' : 'headerSearchBarRef'   
        let headerSearchBarElement = this[refName].current  
        const searchQuery = headerSearchBarElement ? headerSearchBarElement.value.trim().toLowerCase().replace(/\s+/g, ' ') : undefined        
        // If we're already in search page, then just trigger a reload to search the new query. Otherwise, redirect to search page:                
        if (searchQuery || currSubject) {
            let stateChange
            if (this.isSearchPage) {
                // hide the mobile search 
                Utility.mobileHeaderSearchBarToggleFn(this, false)
                globals.urlParams.q = searchQuery
                // run search based on current subject and query word:
                this.runSearch(true)
            } else {
                stateChange = {navigateTo: '/search?' + (currSubject ? 'subject=' + globals.urlParams.subject + (searchQuery ? '&' : '') : '') + (searchQuery ? ('q=' + searchQuery) : '')}                
                this.safeSetState(stateChange)
            }                    
        }
    }

    // if user has created a teacher image, once the user data is fetched change top menu user button icon image from placeholder:
    updateTopMenuUserIconImage() {        
        let userProfileImageUrl = Database.getUserImageUrl()
        if (userProfileImageUrl) {
            ['userIconImageRef', 'mobileUserIconImageRef'].forEach(f => {
                let userIconImageRef = this[f]
                if (userIconImageRef && userIconImageRef.current) {
                    let imageElement = userIconImageRef.current
                    imageElement.setAttribute('src', userProfileImageUrl)
                    imageElement.className = 'user-button-icon user-button-icon-user-image'                    
                }
            })
        }
    }

    // database API calls this when data for user in session's info is fetched. It'll trigger components that need that info to render now... 
    userDataFetched() {
        this.updateTopMenuUserIconImage()
    }

    // database API calls this when data for admin user in session's info is fetched. It'll trigger components that need that info to render now... For a typical page it's a no-op:
    adminUserDataFetched() {
    }
    
    // database API calls this when subjects info is fetched. It'll trigger components that need that info to render now...
    subjectsDataFetched() {
        if (this.didMount && this.searchBarPulldownRef && this.mobileSearchBarPulldownRef) {            
            [this.searchBarPulldownRef, this.mobileSearchBarPulldownRef].forEach(s => { if (s.current) s.current.setState({dataFetched: true}) })
        }
    }
    
    // database API calls this when subject topics info is fetched. It'll trigger components that need that info to render now...
    topicsDataFetched() {
    }
    
    // database API calls this when initial fetch of popular/latest curriculums for all subjects shown in home page are fetched. It'll trigger components that need that info to render now... For a typical page it's a no-op:
    popularAndLatestCurriculumsDataFetched() {
    }

    // database API calls this when curriculums info for queiried curriculums are fetched. It'll trigger components that need that info to render now... pages will write their own function:
    curriculumsDataFetched() {
    }

    // database API calls this when teacher's info is fetched for the given id. It'll trigger components that need that info to render now... For a typical page it's a no-op:
    teacherDataFetched() {
    }
  
    // Database API calls this when teacher info for the given id is fetched. We'll trigger components that needed review info to render now... For a typical page it's a no-op:
    reviewsDataFetched() {
    }

    // database API calls this when current users's a given curriculum review is fetched. It'll trigger components that need that info to render now... For a typical page it's a no-op:
    userReviewForCurriculumFetched() {        
    }

    // Database API calls this when lesson info for the given id is fetched... For a typical page it's a no-op:
    lessonDataFetched() {
    }

    // Database API calls this when lessons info for the given ids is fetched... For a typical page it's a no-op:
    lessonsDataFetched() {
    }    
    
    // Locale API calls this if it's just updated locale pay config to non-default (non USD currency) and it noticed that the current page has already rendered price of an item (so that page can force update of its price component so that it shows in the right local currency). For a normal page, this is no-op:
    localePayConfigUpdated() {
    }
    
    // if we need to redirect to another page, render redirect element:
    renderRedirect() {
        this.state.rerender = false        
        if (this.state.navigateTo !== undefined) {
            //console.log('nav=', this.state.navigateTo)
            return <Navigate to={this.state.navigateTo} />
        } else
            return undefined
    }

    // defualt render of a landing page...:
    render() {
        // if someone has set a rerouting navigating to another page, then navigate to it instead of rendering this page:
        let anyRedirects = this.renderRedirect()      
        if (anyRedirects)
          return anyRedirects
        // no reroutes. render the page:
        let metaTags = this.getPageMetaDiffs()
        return (
          <div id={this.pageId + '-page'} className="page">              
            {metaTags ? <DocumentMeta {...metaTags} /> : null}
            {Utility.renderHeaders(this)}
            {this.renderBodyAndFooter()}
          </div>
        )
    }

    // body of the landing page -- to be implemented by the subclass:
    renderBody() {
        return null
    }

    // renders an invisible bh behind the select/menu divs so clicking outside those elements can toggle them off:
    renderInvisibleBackgroundLayerFromOptionsSelect() {
        return (
            <div className="full-page-bg-layer" onClick={Utility.turnOffAnySelectComponentCurrentlyActive} ref={this.selectComponentsBackgroundLayerRef}></div>
        )
    }

    // default render of the body and footer of a landing page:
    renderBodyAndFooter() {
        return (
          <div id={this.pageId + '-body'} className={'body' + (this.bodyClass ? (' ' + this.bodyClass) : '')}>
            {this.renderBody()}
            {Utility.renderFooter()}
            {this.renderInvisibleBackgroundLayerFromOptionsSelect()}
          </div>
        )
    }

    // pages that don't want headers/footer use this instead:
    noHeadersNoFooterRender() {
        // if someone has set a rerouting navigating to another page, then navigate to it instead of rendering this page:
        let anyRedirects = this.renderRedirect()      
        if (anyRedirects)
          return anyRedirects
        // no reroutes. render the page:
        let metaTags = this.getPageMetaDiffs()                  
        return (
          <div id={this.pageId + '-page'} className="page">
            {metaTags ? <DocumentMeta {...metaTags} /> : null}
            <div id={this.pageId + '-body'} className={'body' + (this.bodyClass ? (' ' + this.bodyClass) : '')}>
                {this.renderBody()}
            </div>
          </div>
        )
    }
        
    // get url for curriculum's public (SEO-friendly) page:
    getCurriculumShareUrl() {
        //return window.location.protocol + '//' + window.location.host + '/preview?subject=' + globals.urlParams.subject + '&id=' + globals.urlParams.id
        return Fileserver.getCurriculumPublicPageUrl(this.id)
    }

    // document header title for page:
    getPageTitle() {
        return reactAppEnv.REACT_APP_SITE_TITLE
    }    

    // document header title for a page that lists a curriculum (preview, view, lesson, etc.):
    getPageTitleForCurriculumListingPage() {
        let curriculumData = this.curriculumData
        return curriculumData ? ('Curriculum' + (curriculumData['single-unit'] ? ' Unit' : '') + ' — ' + curriculumData.title + (curriculumData.subtitle ? (' | ' + curriculumData.subtitle) : '')) : reactAppEnv.REACT_APP_SITE_TITLE
    }

    // page header/meta tags different from the default set inside public/index.html:
    getPageMetaDiffs() {
        let title = this.getPageTitle()        
        return {
            // <title>...</title>
            title: title
        }
    }
}

export default LandingPage
