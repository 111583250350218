/*
    Curriculum Preview Page
    Shows the listing for a given curriculum (option to purchase/download)
*/

import React from 'react'

import * as Config from './config'
import * as Database from './database-api'
import * as Utility from './utility'

const globals = Config.globals

// ------------------------------------------------------------------------
/*
    Curriculum Preview Page
*/
class CurriculumPreviewPage extends Utility.LandingPageWithDialogBox {
  constructor(props) {
    super(props)  
    this.state = {
      dataFetched: false
    }
    this.subject = globals.urlParams.subject
    this.id = globals.urlParams.id    
    this.pageId = 'preview' 
    this.wishlisted = false
    this.bought = false
    this.pageBody = React.createRef()
    this.inCart = Database.isInCart(this.id)
    if (globals.haveLocalStorage)
      this.lastTabOpen = localStorage.getItem('termeric-page-' + this.pageId + '-' + this.id + '-last-tab-open')
  }
    
  componentDidMount() {    
    super.componentDidMount()
    // if this page was loaded as a landing page, the superclass already initiates calls to the database to get the curriculum and teacher and user data. However, if we're here just by navigating from a previous page, these callbacks need to be called manually here:
    // if user has already bought the curr id we're currently viewing, then redirect to view page instead where they have full access to it:
    if (Database.isUserDataFetched())
      this.userDataFetched()        
    // manually run the callback function to trigger getting teacher's data and render the page once the data is in:
    if (Database.isCurriculumDataFetchedForId(this.id))
      this.curriculumsDataFetched()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // make sure we need to proceed with rendering this page (not navigating already):
    if (!super.componentDidUpdate(prevProps, prevState, snapshot))
      return    
    // update how the wishlist button renders on loading the page:
    if ((this.wishlisted) && !this.wishlistButtonInit) {
      this.wishlistButtonInit = true
      this.body.rerenderWishlistButton()
    }      
  }

  // Database API calls this when curriculum info for the given id is fetched. Here first we need to fetch the teacher's data:
  curriculumsDataFetched() {      
    this.curriculumData = Database.getCurriculumData(this.id)         
    if (this.curriculumData) {
      // if user has already bought the curr id we're currently viewing, then redirect to view page instead where they have full access to it:    
      if (Utility.isUserSignedIn() && Database.getUserBoughtCurriculums().indexOf(this.id) !== -1)        
        this.setState({navigateTo: '/view?subject=' + this.subject + '&id=' + this.id + '&random-key=' + this.curriculumData['random-key']})
      else {        
        // check if user had previously marked this curriculum as wishlisted. Update wishlist button if so:        
        Database.database_fetchTeacherData(this.curriculumData.teacher)
        // check if user had previously marked this curriculum as wishlisted. Update wishlist button if so:
        this.wishlisted = Database.isCurriculumInUserWishlist(this.id)
        this.bought = Database.isCurriculumInUserBoughtList(this.id)      
        if (this.wishlisted)
          this.body.rerenderWishlistButton()
      }
    } else { // If there's no curriculum with the given id, it's an error. Redirect back to search page:      
      this.setState({navigateTo: '/search'})
    }    
  }

  // Database API calls this when teacher info for the given id is fetched. We'll trigger components that needed curriculum and teacher info to render now...
  teacherDataFetched() {
    this.teacherData = Database.getTeacherData(this.curriculumData.teacher)    
    this.setState({dataFetched: true})
  }

  // Database API calls this when teacher info for the given id is fetched. We'll trigger components that needed review info to render now...
  reviewsDataFetched() {
    this.reviewsComponent.setState({dataFetched: true})
  }  
  
  // Locale API calls this if it's just updated locale pay config to non-default (non USD currency) and it noticed that the current page has already rendered price of an item (so that page can force update of its price component so that it shows in the right local currency). 
  localePayConfigUpdated() {
    this.reRenderBody()
  }
  
  // forces update of the page body:
  reRenderBody() {
    let pageBodyComponent = this.pageBody.current
    if (pageBodyComponent != null)
      pageBodyComponent.forceUpdate()
  }

  renderBody() {    
    return (     
      <CurriculumPreviewPageBodyComponent page={this} ref={this.pageBody} />     
    )
  }

  // document header title for page:
  getPageTitle() { return this.getPageTitleForCurriculumListingPage() }    

}

export default CurriculumPreviewPage

class CurriculumPreviewPageBodyComponent extends Utility.CurriculumPreviewAndViewPageBodyComponent {
  
  constructor(props) {
    super(props)
    this.wishlistButtonRef = React.createRef()    
  }

  // if existing user session, will toggle the curriculum id from/to wishlist
  // otherwise, redirects to register page
  // if already purchased in will open it instead
  wishlistToggle() {
    if (Config.globals.sessionInfo) {
      if (this.page.bought) {
        this.page.setState({navigateTo: '/view?subject=' + this.page.subject + '&id=' + this.page.id + '&random-key=' + this.page.curriculumData['random-key']})
      } else {
        let dbUpdateCallbackFn = (e) => {
          this.page.wishlisted = !this.page.wishlisted
          this.rerenderWishlistButton()
        }
        Database.updateWishlist(this.page.id, this.page.subject, !this.page.wishlisted, dbUpdateCallbackFn)
      }
    } else {
      this.page.setState({navigateTo: '/register' + Utility.getSigninRelatedPageNavigationLink()})
    }
  }

  // clicking on wishlist button toggles the text shown inside it:
  rerenderWishlistButton() {    
    let wishlistButton = this.wishlistButtonRef.current
    if (wishlistButton)
      Utility.rerenderElementWithIcon(wishlistButton, this.page.bought ? 'In library' : 'Wishlist', 'fas fa-heart', this.page.wishlisted)
  }

  // renders wishlist button:
  renderWishlistButton() {
    return (
      Utility.renderButtonWithRef('Wishlist', 'top-curriculum-listing-wishlist-button', 'wide-text-button colored-bordered-text-button  bordered-text-button text-button button', (e) => {this.wishlistToggle()}, undefined, this.wishlistButtonRef)
    )
  }

  renderListingImageAndCTAs(curriculumData) {
    let bought = this.page.bought
    return (
      <div id="top-curriculum-listing-image-and-ctas" className="top-listing-image-and-ctas">
        <img id="top-curriculum-listing-image" className="curriculum-listing-image boxed-div" src={curriculumData.imageUrl} alt="" />
        {Utility.renderCurriculumPrice(this.page, curriculumData, 'preview-curriculum-price flex-start-item bottom-margin20 size25 weight500', true, true)}
        {Utility.renderMoneyBackGuaranteeLabel(this.page, 'money-back-label flex-start-item bottom-margin25')}
        {Utility.renderButtonFromButton(<span><i className="fas fa-eye"></i>&nbsp;&nbsp;&nbsp;Preview samples</span>, 'preview-preview-content-button', 'wide-cta-text-button cta-text-button clear-shadowed-text-button bordered-text-button text-button button', (e) => this.previewContent(curriculumData.previewsUrl))}
        {Utility.renderButtonFromButton(<span><i className={this.page.inCart ? 'fas fa-shopping-cart' : (bought ? 'fas fa-readme' : 'fas fa-cart-plus')}></i>&nbsp;&nbsp;&nbsp;{this.page.inCart ? 'Go to cart' : (bought ? 'Open curriculum' : 'Add to cart')}</span>, 'preview-add-to-cart-button', 'wide-cta-text-button cta-text-button shadowed-text-button bordered-text-button text-button button', (e) => this.addToCartOrOpenIfBought(e.target, curriculumData.teacher))}
        {bought ? null : Utility.renderApplyPromo('preview-apply-promo-button', this)}
      </div>
    )
  }

  // opens curriculum if already bought, or else adds curriculum to cart, or if it's already there it navigates to cart:
  addToCartOrOpenIfBought(button, teacher) {
    if (this.page.bought)
      this.page.setState({navigateTo: '/view?subject=' + this.page.subject + '&id=' + this.page.id})
    else if (this.page.inCart) {
      let promo = globals.urlParams.promo
      this.page.setState({navigateTo: '/cart' + (promo ? ('?promo=' + promo) : '')})
    } else {      
      this.page.inCart = true
      Utility.rerenderElementWithIcon(button, 'Go to cart', 'fas fa-cart-plus', true)        
      Database.addToCart(this.page.id, this.page.subject)      
    }   
  }

  // applies a promo code entered:
  applyPromoButtonClicked() {

  }
}

