import React from 'react'

import uuid from 'react-uuid'
import { Editor } from 'react-draft-wysiwyg'
import { EditorState, convertToRaw, ContentState } from 'draft-js'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import UploadDropZone from 'react-dropzone'

import * as Config from './config'
import * as Database from './database-api'
import * as Fileserver from './fileserver-api'
import * as CloudStorage from './cloud-storage-api'
import * as Utility from './utility'

const globals = Config.globals
const generalConfig = Config.pages.general
const creatorConfig = Config.pages.creator
const curriculumConfig = Config.curriculumConfig
const fsConfig = Config.fsConfig
const payConfig = Config.payConfig

export const monthsReverse = [...Utility.months].reverse()

// Creator landing page is a session landing page without search bar in header:
export class CreatorLandingPage extends Utility.SessionLandingPageWithDialogBox {
  constructor(props) {
    super(props)  
    // flag true for user site pages and false for creator site pages:
    this.isUserSite = false
    this.isCreatorSite = true
    this.pageDir = 'creator/'
    if (!globals.fileserverInit)
        Fileserver.fileserver_initConnection()
  }

  componentDidMount() {
      super.componentDidMount()
      // if database has already finished its fetching curriculum data for default query at this time, call the callback manually (otherwise, it will call it for us itself once fetched):
      if (Database.isUserDataFetched())
          this.userDataFetched()
  }

  // database API calls this when db info are fetched. It'll trigger components that need that info to render now...   
  userDataFetched() {
      super.userDataFetched()    
      this.teacher = globals.sessionInfo.username
      if (Database.isTeacherDataFetched(this.teacher)) 
          this.userAndTeacherDataFetched()
      else 
          Database.database_fetchTeacherData(this.teacher, true)
  }

  teacherDataFetched() {
      if (Database.isUserDataFetched()) 
          this.userAndTeacherDataFetched()    
  }

  userAndTeacherDataFetched() {
      this.teacher = globals.sessionInfo.username
      this.teacherData = Database.getTeacherData(this.teacher)
  }
  
  // default render of a landing page without search bar in header...:
  render() {
      return Utility.renderNoSearchBarPage(this)
  }
}

export class CreatorRestrictedAccessLandingPage extends CreatorLandingPage {
  constructor(props) {
      super(props)
      this.subject = globals.urlParams.subject  
      this.id = globals.urlParams.id
      this.isNew = this.id === undefined
      this.checkedForMissingCurriculumsData = false
      this.fetchMissingLiveCurriculumsDataTried = false
      this.fetchMissingSubmittedCurriculumsDataTried = false   
      this.fetchMissingDraftedCurriculumsDataTried = false        
  }

  // database API calls this when curriculums info for all curriculum data are fetched. It'll trigger components that need that info to render now...
  // handle the case when both curriculums and user data are fetched, otherwise wait for both to arrive before calling the handler function to:
  // if at this point there are curriculum ids in the url whose data have not yet been fetched from the database, call the database to do so
  // otherwise if we have all data we need go ahead and trigger to render:
  curriculumsDataFetched() {
    this.checkedForMissingCurriculumsData = true
    // first do check to see if random key is missing or wrong boot the user out of this page:
    this.checkValidAccessForPage()
    this.checkForMissingCurriculumsData()    
  }

  checkValidAccessForPage() {
    Utility.checkValidAccessForRestrictedAccessPage(this)
  }

  // what curriculum ids we need to fetch data for for this page:
  getCurriculumIdsToCheck(isSubmissionsTable, isDraftsTable) {    
    return this.isNew ? [] : [this.id]  
  } 

  checkForMissingCurriculumsDataIfNotDone() {      
    if (!this.checkedForMissingCurriculumsData) {
        this.checkedForMissingCurriculumsData = true        
        this.checkForMissingCurriculumsData()
    }
  }

  // which page to navigate to if curriculum with id on url params not found:
  accessNotAllowedNavigationPage() {
    return '/creator/library'
  }
  
  checkForMissingCurriculumsData() {
      // if database has already finished its fetching curriculum data for default query at this time, call the callback manually (otherwise, it will call it for us itself once fetched):          
      var curriculumsWithoutFetchedData = Database.getCurriculumIdsWithoutFetchedData(this.getCurriculumIdsToCheck(false, false))      
      // if there are curriculums to show for which we have not fetched data from db, fetch here (cap number of tries to 2):
      if (curriculumsWithoutFetchedData.length > 0 && !this.fetchMissingLiveCurriculumsDataTried) {
        // try the live curriculums table first:
        this.fetchMissingLiveCurriculumsDataTried = true          
        Database.fetchCurriculumDataForIds(curriculumsWithoutFetchedData)
      } else {
        // try the submissions curriculums if not found:
        curriculumsWithoutFetchedData = Database.getCurriculumIdsWithoutFetchedData(this.getCurriculumIdsToCheck(true, false))
        if (curriculumsWithoutFetchedData.length > 0 && !this.fetchMissingSubmittedCurriculumsDataTried) {
          this.fetchMissingSubmittedCurriculumsDataTried = true            
          Database.fetchCurriculumDataForIds(curriculumsWithoutFetchedData, true, false)
        } else {
          // try the drafts curriculums if not found:
          curriculumsWithoutFetchedData = Database.getCurriculumIdsWithoutFetchedData(this.getCurriculumIdsToCheck(false, true))
          if (curriculumsWithoutFetchedData.length > 0 && !this.fetchMissingDraftedCurriculumsDataTried) {
            this.fetchMissingDraftedCurriculumsDataTried = true              
            Database.fetchCurriculumDataForIds(curriculumsWithoutFetchedData, false, true)
          } else {            
            this.allDataAndCurriculumDataFetched()
          }
        }
      }
  }  

  // what to do once all data is fetched... to be defined by the subclass:
  allDataAndCurriculumDataFetched() {
  }
}

export class CreatorCurriculumViewPageBodyComponent extends Utility.CurriculumViewPageBodyComponent {
  
  renderListingImageAndCTAs(curriculumData) {
    let page = this.page
    return (
      <div id="top-curriculum-listing-image-and-ctas" className="top-listing-image-and-ctas">
        <img id="top-curriculum-listing-image" className="curriculum-listing-image boxed-div" src={curriculumData.imageUrl} alt="" />
        {Utility.renderLink(<span><i className="fas fa-edit"></i>&nbsp;&nbsp;&nbsp;Manage curriculum</span>, 'creator-view-edit-curriculum-button', 'wide-cta-text-button cta-text-button shadowed-text-button bordered-text-button text-button button', 'creator/edit?subject=' + page.subject + '&id=' + page.id + '&random-key=' + curriculumData['random-key'])}
        {Utility.renderButtonFromButton(<span><i className="fas fa-eye"></i>&nbsp;&nbsp;&nbsp;Preview samples</span>, 'creator-view-preview-content-button', 'wide-cta-text-button cta-text-button clear-shadowed-text-button bordered-text-button text-button button', (e) => this.previewContent(curriculumData.previewsUrl))}
        {Utility.renderLink(<span><i className="fas fa-print"></i>&nbsp;&nbsp;&nbsp;Print course plan</span>, 'creator-view-print-course-plan-button', 'wide-cta-text-button cta-text-button clear-shadowed-text-button bordered-text-button text-button button', 'creator/curriculum-print?subject=' + page.subject + '&id=' + page.id + '&random-key=' + curriculumData['random-key'])}
        <span className="no-box-shadow wide-cta-text-button cta-text-button bordered-text-button text-button">{curriculumData.active ? '🟢' : (curriculumData['in-submissions'] ? '🟡': (curriculumData['in-drafts'] ? '🔴' : '🟡'))}&nbsp;&nbsp;&nbsp;{curriculumData.active ? ('  Live' + (curriculumData.private ? '(Private)' : '')) : (curriculumData['in-submissions'] ? 'Submitted for review' : (curriculumData['in-drafts'] ? 'Working draft' : 'Deactived'))}</span>        
      </div>
    )
  }
  
  // share only live/public curriculum urls:
  renderShareButton() {
    let curriculumData = this.page.curriculumData
    return (curriculumData['in-productions'] && !curriculumData.private) ? super.renderShareButton() : null
  }

}


// UI for creating a list of texts:
export class TextInputList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      items: props.items || [],
      focus: this.props.autoFocus
    }
    this.itemElements = this.state.items.map(i => React.createRef())
    this.keyGenCtr = 0
    this.page = props.page
    this.sortable = props.sortable === true //FIXME: turning off dragging because it's
    this.growable = props.growable !== false
    this.addMessage = props.addMessage || 'Add new item...'
    this.extraClasses = props.extraClasses
    this.addNewButtonExtraClasses = props.addNewButtonExtraClasses
  }
 
  // returns the current list of items (texts)
  getList() {
    return this.itemElements.map(e => e.current.getValue())
  }

  // called when there's a change to list (add or delete), before setting the state and rendering:
  // subclass implementable:
  willChangeCount(numItemsToBe) {}

  handleDelete(index) {    
    const { items } = this.state
    let count = items.length
    // decrement index of items that are after the item being deleted:
    let idxsToDecrement = Utility.arrayFromTo(index + 1, count - 1, false)
    idxsToDecrement.forEach(decIdx => this.itemElements[decIdx].current.index--)
    // now remove the item at index:
    items.splice(index, 1)
    this.itemElements.splice(index, 1)
    this.willChangeCount(count - 1)
    this.setState({ items: items })
  }

  generateNewItem(defaultInputValue) {
    return defaultInputValue || ''
  }

  handleNewItem() {
    const { items } = this.state
    let count = items.length
    this.itemElements.push(React.createRef())    
    this.willChangeCount(count + 1)
    this.setState({items: [...items, this.generateNewItem()], focus: true})
  }

  setItems(items) {
    let count = items.length
    this.itemElements = items.map(i => React.createRef())
    this.willChangeCount(count)
    this.setState({ items: items.map(i => this.generateNewItem(i)) })
  }

  onDragEnd(result) {
    // dropped outside the list
    if (!result.destination)
      return
    let from = result.source.index, to = result.destination.index
    if (from === to)
      return
    let { items } = this.state
    let indexList = [...Array(this.itemElements.length).keys()]
    // move index list so we know the new indices for the elements after drag:
    Utility.arrayMoveIndexFromTo(indexList, from, to)
    // move item elements components index fields:    
    indexList.forEach((item, idx) => { this.itemElements[item].current.index = idx } )
    // move item elements components:
    Utility.arrayMoveIndexFromTo(this.itemElements, from, to)
    // move state items:
    Utility.arrayMoveIndexFromTo(items, from, to)    
    this.setState({items})
  }

  renderOneItem(index, key, value) {
    return (
      <TextInputListItem
        key={key}
        getKey={key}
        index={index}
        value={value}
        onDelete={(index) => this.handleDelete(index)}
        autoFocus={this.state.focus && index == this.state.items.length - 1}
        parent={this}
        ref={this.itemElements[index]}
      />)
  }

  render() {
    let count = this.state.items.length    
    let itemComponents = this.state.items.map((entry, index) => {
      let itemElement = this.itemElements[index].current      
      var key, value;        
      if (itemElement) { // retrive info for a previously rendered item:
          key = itemElement.props.getKey
          value = itemElement.getValue()                
      } else { // creating a new item:
          key = this.keyGenCtr++
          value = entry
      }
      let oneItem = this.renderOneItem(index, key, value)        
      return this.sortable ? 
        {id: '' + key, content: oneItem} 
        : oneItem
      }
    )
    return (
      <div className="text-input-list boxed-div flex-col">        
        <div>
          <div className={'form-item flex-col' + this.extraClasses ? (' ' + this.extraClasses) : ''}>
            {this.sortable ? 
              (
                <DragDropContext onDragEnd={this.onDragEnd.bind(this)}>
                  <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                      <div
                        {...provided.droppableProps}                        
                        ref={provided.innerRef}>
                        {itemComponents.map((item, index) => (
                          <Draggable key={item.id} draggableId={item.id} index={index}>
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}                                
                                className="text-input-list-item-wrapper flex-row flex-center">
                                  {item.content}                                  
                                  <div {...provided.dragHandleProps} tabIndex={-1} className="list-item-drag-button list-item-action-button"><i className="fas fa-bars" /></div>
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              ) 
              : itemComponents}
          </div>
          {this.growable ?
          <TextInputListAddNewItem
              key={this.keyGenCtr++}                
              index={count}
              addMessage={this.addMessage}
              extraClasses={this.addNewButtonExtraClasses}
              onFocus={() => this.handleNewItem()}
            /> : null}
        </div>
      </div>
    )
  }
}

// One item for UI for creating a list of texts:
export class TextInputListItem extends React.Component {
  
  constructor(props) {
    super(props)
    this.parent = props.parent
    this.page = this.parent.page 
    this.inputElement = React.createRef()
    this.index = props.index
  }

  getValue() {
    return this.inputElement.current ? this.inputElement.current.value : undefined
  }

  itemDefaultClassNames() {
    return 'text-input-list-item text-input-list-added-item flex-row flex-center'
  }

  itemExtraClassNames() {
    return ' simple-text-input-list-item'
  }

  handleDelete() {    
    this.props.onDelete(this.index) // delegate to parent
  }
   
  renderInputComponent() {
    return this.props.autoFocus ?
      (<input defaultValue={this.props.value} ref={this.inputElement} autoFocus />) :
      (<input defaultValue={this.props.value} ref={this.inputElement} />)
  }

  renderActionButtons() {
    return (
      <div className="flex-row flex-center">
        {this.parent.growable ? (<div className="list-item-action-button button"><i className="fas fa-times" onClick={(e) => {this.handleDelete()}} /></div>) : null}        
      </div>
    )
  }
  
  render() {
    return (
      <div className={this.itemDefaultClassNames() + this.itemExtraClassNames()}>
        {this.renderInputComponent()}
        <div className="list-item-action-buttons-container">
          {this.renderActionButtons()}
        </div>
      </div>
    )
  }
}

// Add new button item for UI for creating a list of texts:
export class TextInputListAddNewItem extends React.Component {
  
  constructor(props) {
    super(props)
    this.extraClasses = props.extraClasses
  }

  handleFocus() {    
      this.props.onFocus() // delegate to parent
  }
  
  render() {
    return (
      <div className={'text-input-list-item text-input-list-add-new-item' + (this.extraClasses ? (' ' + this.extraClasses) : '')}>
          <input
            placeholder={this.props.addMessage}
            defaultValue={''}
            onFocus={(e) => {this.handleFocus()}}
          />
      </div>
    )
  }
}

// editor for a teacher/student requirement list as part of a curriculum or lesson data
export class RequirementInputList extends TextInputList {
  constructor(props) {
      super(props)      
      this.addMessage = 'Add a new requirement...'  
      this.addNewButtonExtraClasses = 'requirement-input-list-add-new-item'
  }
  
  generateNewItem(defaultInputValue) {
      return defaultInputValue || {description: '', isOptional: false}
  }

  renderOneItem(index, key, value) {    
    return (<RequirementInputListItem
        key={key}
        getKey={key}
        index={index}
        value={value}
        isTeacherRequirement={this.props.isTeacherRequirement}
        onDelete={(index) => this.handleDelete(index)}
        autoFocus={this.state.focus && index == this.state.items.length - 1}
        sortable={true}
        parent={this}
        ref={this.itemElements[index]} />)
  }
}

// editor for a teacher/student requirement item as part of a curriculum or lesson data
class RequirementInputListItem extends TextInputListItem {

  constructor(props) {
      super(props)
      this.value = this.props.value
      this.isTeacherRequirement = this.props.isTeacherRequirement
      this.optionalCheckboxRef = React.createRef()
  }

  getValue() {
      return {description: super.getValue(), isOptional: this.isOptional()}
  }
  
  isOptional() { 
    let checkbox = this.optionalCheckboxRef.current
    return checkbox ? Utility.isCheckboxChecked(this.optionalCheckboxRef.current) : this.value.isOptional
  }

  itemExtraClassNames() { return '' }

  renderInputComponent() {
    return (
      <div className="full-width flex-row flex-center">
        {this.props.autoFocus ?
          (<input defaultValue={this.props.value.description} ref={this.inputElement} autoFocus />) :
          (<input defaultValue={this.props.value.description} ref={this.inputElement} />)}
        {Utility.renderCheckbox((this.isTeacherRequirement ? 'teacher' : 'student') + '-requirement-' + this.index, this.isOptional(), 'Optional', this.optionalCheckboxRef, 'text-input-list-item-element')}
      </div>
    )
  }  
}

// editor for a resource list where each resource has a title and description:
export class ResourceInputList extends TextInputList {
  constructor(props) {
      super(props)
      this.isSlides = props.isSlides === true
      this.isResource = props.isResource !== false
      this.hasCloudStorageLinkOption = props.hasCloudStorageLinkOption === true
      this.addMessage = props.addMessage || 'Add a new resource such as a web link, PDFs, slides, etc. ...'
      this.defaultResourceTypeIsExternal = props.defaultResourceTypeIsExternal === false ? false : true
      this.includeMessageAboutExtenralResource = props.includeMessageAboutExtenralResource !== false
      this.isLessonResource = props.isLessonResource !== false
  }
  
  generateNewItem(defaultInputValue) {
      return defaultInputValue || {description: '', url: '', isExternal: this.defaultResourceTypeIsExternal, isCloudStorage: this.defaultResourceTypeIsExternal && this.hasCloudStorageLinkOption, isTest: false, isAssignment: false, isNew: true}
  } 

  renderOneItem(index, key, value) {
    return (
      <ResourceInputListItem
        key={key}
        getKey={key}
        index={index}
        value={value}
        onDelete={(index) => this.handleDelete(index)}
        autoFocus={this.state.focus && index == this.state.items.length - 1}
        sortable={true}
        parent={this}
        ref={this.itemElements[index]}        
      />)
  }

}

// One item for UI for creating a list of 2 input texts:
export class ResourceInputListItem extends TextInputListItem  {

  constructor(props) {
      super(props)
      this.value = this.props.value
      this.isNew = this.value.isNew
      this.isExternal = this.value.isExternal      
      this.hasCloudStorageLinkOption = props.parent.hasCloudStorageLinkOption
      this.isCloudStorage = this.hasCloudStorageLinkOption && this.value.isCloudStorage
      this.cloudStorageLastKnownCopyURL = this.isCloudStorage ? this.value.url : undefined
      this.externalResourceTypeKeys = ['cloud-storage-google', 'other-external']
      this.resourceTypeKey = this.isExternal ? (this.isCloudStorage ? 'cloud-storage-google' : 'other-external') : 'uploaded'
      this.cloudStoragePlatform = this.isCloudStorage ? this.resourceTypeKey.replace('cloud-storage-', '') : undefined
      this.resourceTypeOptionLabels = {'uploaded': 'Uploaded Resource',
        'other-external': (this.hasCloudStorageLinkOption ? 'Other ' : '') +  'External Link (URL)'
      }
      this.resourceTypeOptionIcons = {'uploaded': 'fas fa-upload', 'other-external': 'fas fa-link'}
      if (this.hasCloudStorageLinkOption) {
        this.resourceTypeOptionLabels['cloud-storage-google'] = 'Cloud Storage Link (URL): Google Drive'
        this.resourceTypeOptionIcons['cloud-storage-google'] = 'fab fa-google-drive'
      }
      this.resourceTypeOptions = [...(this.hasCloudStorageLinkOption ? ['cloud-storage-google'] : []), 'other-external', 'uploaded']
      this.defaultResourceTypeIsExternal = props.parent.defaultResourceTypeIsExternal
      this.defaultResourceTypeKey = this.defaultResourceTypeIsExternal ? (this.hasCloudStorageLinkOption ? 'cloud-storage-google' : 'other-external') : 'uploaded'
      this.includeMessageAboutExtenralResource = props.parent.includeMessageAboutExtenralResource
      this.resourceTypeOptionSelectElement = React.createRef()  
      this.resourceInfoInputContainerRef = React.createRef()
      this.isTestCheckboxRef = React.createRef()
      this.isAssignmentCheckboxRef = React.createRef()
  }

  getValue() {
      return this.inputElement.current ?
      {description: this.inputElement.current.value, url: this.resourceInfoInputContainerRef.current.urlInputElementRef.current.value, isExternal: this.isExternal, isCloudStorage: this.isCloudStorage, cloudStorageNotYetCopied: this.isCloudStorage && this.cloudStorageURLChanged, isTest: this.isTest(), isAssignment: this.isAssignment()} : undefined
  }

  isTest() {
    let checkbox = this.isTestCheckboxRef.current
    return checkbox ? Utility.isCheckboxChecked(checkbox) : this.value.isTest
  }

  isAssignment() {
    let checkbox = this.isAssignmentCheckboxRef.current
    return checkbox ? Utility.isCheckboxChecked(checkbox) : this.value.isAssignment
  }

  resourceTypeOnChangeFn(e, val) {      
    this.resourceTypeKey = val
    this.isExternal = this.externalResourceTypeKeys.indexOf(this.resourceTypeKey) >= 0
    this.isCloudStorage = this.resourceTypeKey.startsWith('cloud-storage')
    this.cloudStoragePlatform = this.isCloudStorage ? this.resourceTypeKey.replace('cloud-storage-', '') : undefined
    let infoInputElement = this.resourceInfoInputContainerRef.current
    let urlInputElement = infoInputElement.urlInputElementRef.current     
    // whenever Resource type is changed back to what it was, reset url input value to what it was originally. if it's switched to other type, reset to empty:        
    let inputValue = ''
    if (this.isExternal === this.props.value.isExternal) {
        let origUrl = this.props.value.url
        inputValue = this.isExternal ? origUrl : origUrl.replace(/^\d+\-/, '') // remove internal filename prefix for uploaded files....
    }
    urlInputElement.value = inputValue
    let uploadZoneElement = infoInputElement.uploadZoneRef.current
    uploadZoneElement.filePreviewRef.current.innerText = this.isExternal ? '' : inputValue
    uploadZoneElement.messageRef.current.innerText = this.page.uploadDropZoneNewMessage
    // refresh the input container element based on the new selection:
    this.resourceInfoInputContainerRef.current.forceUpdate()
  }

  // called when user changes a cloud-storage type resource item's URL is changed. if we detect the url is changed from its previous value (and it's not an already copied resource), we set a flag to show the "Make a copy" button to ask the user to make a copy of the resource for use:
  onCloudStorageURLChanged(newVal) {     
    let urlChanged = newVal !== this.cloudStorageLastKnownCopyURL && !CloudStorage.isCloudStorageCopyURL(newVal)
    let stateChanged = false
    if (urlChanged) {
      if (!this.cloudStorageURLChanged) {
        this.cloudStorageURLChanged = true
        stateChanged = true        
      }
    } else {
      if (this.cloudStorageURLChanged) {
        this.cloudStorageURLChanged = false
        stateChanged = true
      }
    }
    if (stateChanged) {
      // refresh the input container element based on the new selection:
      this.resourceInfoInputContainerRef.current.forceUpdate()
    }
  }  

  // make a copy of external cloud storage resource and return the share link of the copy:
  async makeCloudStorageResourceCopy(curriculum, lesson, platform, optionalTitle, lock, url) {
    let copyUrl = await CloudStorage.makeCloudStorageResourceCopy(curriculum, lesson, platform, optionalTitle, lock, url)
    if (copyUrl) {
      this.cloudStorageURLChanged = false
      this.cloudStorageLastKnownCopyURL = copyUrl
      // refresh the input container element based on the new selection:
      let infoInputElement = this.resourceInfoInputContainerRef.current
      infoInputElement.urlInputElementRef.current.value = copyUrl
      infoInputElement.forceUpdate()
    }
  }

  itemExtraClassNames() { return '' }

  renderInputComponent() {
    let index = this.index
    let isSlides = this.parent.isSlides
    let isNotOtherLessonResource = isSlides || !this.parent.isLessonResource 
    return (
      <div className="lesson-input-list text-input-list complex-level-1-input-list-item boxed-div flex-col">
        <label>Description</label>
        {this.props.autoFocus ?
        (<input placeholder="Enter description of the resource" defaultValue={this.props.value.description} ref={this.inputElement} disabled={isSlides} autoFocus />) :
        (<input placeholder="Enter description of the resource" defaultValue={this.props.value.description} ref={this.inputElement} disabled={isSlides} />)}
        <label>Resource Type</label>
        <div className="flex-row flex-center">
            <Utility.OptionsSelect key={this.resourceTypeKey} label={this.resourceTypeOptionLabels[this.resourceTypeKey]} id={'lesson-form-resource-type-' + index} containerExtraClasses={'form-select-div'} options={this.resourceTypeOptions} optionKeyToTitleFn={(idx, p) => this.resourceTypeOptionLabels[p]} optionKeyToTitleIconFn={(idx, p) => this.resourceTypeOptionIcons[p]} onChangeFn={this.resourceTypeOnChangeFn.bind(this)} defaultValue={this.resourceTypeKey} addDefault={false} />
        </div>
        <ResourceInputListItemInfoInputContainer parent={this} ref={this.resourceInfoInputContainerRef} />
        {isNotOtherLessonResource ? null : Utility.renderCheckbox('lesson-form-resource-is-assignment-checkbox-' + this.index, this.isAssignment(), 'This is an assignment', this.isAssignmentCheckboxRef, 'top-margin15', undefined, undefined, 
          (checked) => { 
            if (checked && Utility.isCheckboxChecked(this.isTestCheckboxRef.current))
              Utility.toggleCheckbox(this.isTestCheckboxRef.current)
            })}
        {isNotOtherLessonResource ? null : <span className="form-input-description"><em className="opac">To keep the counting of assignments accurate, do not mark as an assignment if this resource is already marked as an assignment in a previous lesson.</em></span>}
        {isNotOtherLessonResource ? null : Utility.renderCheckbox('lesson-form-resource-is-test-checkbox-' + this.index, this.isTest(), 'This is a midterm/final test', this.isTestCheckboxRef, undefined, undefined, undefined, 
          (checked) => { 
            if (checked && Utility.isCheckboxChecked(this.isAssignmentCheckboxRef.current))
              Utility.toggleCheckbox(this.isAssignmentCheckboxRef.current)
            })}
        {isNotOtherLessonResource ? null : <span className="form-input-description"><em className="opac">Do not mark as a test unless this is a major test such as midterm or final.</em></span>}
    </div>
    )
  }
  
}

class ResourceInputListItemInfoInputContainer extends React.Component {
  constructor(props) {
    super(props)
    this.parent = props.parent
    this.urlInputContainerElement = React.createRef()
    this.uploadZoneContainerElement = React.createRef()
    this.urlInputElementRef = React.createRef()
    this.uploadZoneRef = React.createRef()      
  } 

  render() {
    let parent = this.parent
    let page = parent.page
    let url = parent.props.value.url
    let index = parent.index
    let isNew = parent.isNew
    let isCloudStorage = parent.isCloudStorage
    let isSlides = parent.parent.isSlides
    let isResource = parent.parent.isResource
    let makeCopyFn = async (lock) => {
      await parent.makeCloudStorageResourceCopy(page.curriculum, page.lesson, parent.cloudStoragePlatform, isSlides ? 'Slides' : undefined, lock, this.urlInputElementRef.current.value)
    }
    let makeCopyDialogFn = (e) => {
      Utility.showTextDialog(0, 'The copy will be view-only and not copiable. You can also "lock" it to prevent printing and downloading. Do you want to lock it?', generalConfig.dialogBoxQuestionIconClassName, undefined, ["Don't lock", 'Lock'], [async (e) => { await makeCopyFn(false) }, async (e) => { await makeCopyFn(true) }
      ], true)
    }
    return (
      <div>
        <div className={'flex-col' + (parent.isExternal ? '' : ' hidden')} ref={this.urlInputContainerElement}>
          <label>{'Resource ' + (isCloudStorage ? 'Share ' : '') + 'Link'}</label>
          {isCloudStorage && parent.includeMessageAboutExtenralResource ? (<span className="form-input-description"><em className="opac">For cloud storage external documents such as Google Docs, Slides, etc., a copy rather than the original document will be posted, in order to ensure quality persistence and to prevent copying and sharing.</em></span>) : null}
          <div className="flex-row flex-center">
            <input placeholder={'Enter ' + (isCloudStorage ? 'share' : 'external') + ' link (URL)'} defaultValue={url} ref={this.urlInputElementRef} onChange={isCloudStorage ? (e) => {parent.onCloudStorageURLChanged(this.urlInputElementRef.current.value)} : undefined} />
            {(isCloudStorage && parent.cloudStorageURLChanged) ? Utility.renderButton('Make a copy', 'creator-lesson-edit-resource-copy-button-' + index, 'clear-shadowed-text-button bordered-text-button superwide-text-button text-button button', makeCopyDialogFn, {tabIndex: '-1'}) : null}
          </div>
        </div>
        <div className={'flex-col' + (parent.isExternal ? ' hidden' : '')} ref={this.uploadZoneContainerElement}>
            <label>Upload Resource File</label>
            <FileUploadDropZone accept={fsConfig.allowedResourceUploadFileTypes} hasImagePreview={false} isNew={isNew} previewFile={url} newMessage={page.uploadDropZoneNewMessage.replace('@', '')} editMessage={page.uploadDropZoneEditMessage.replace('@','')} onSuccessfulDropFn={page.generateResourceOnDropFn(isSlides, isResource, index, this.uploadZoneRef, this.urlInputElementRef)} ref={this.uploadZoneRef} />
        </div>
      </div>
    )
  }
}

// a stylized text editor capable of importing/exporting text from/into html
export class EditorWithHtmlImportExport extends React.Component {
  constructor(props) {
    super(props)
    this.id = props.id
    this.placeholder = props.placeholder

    this.state = {
      editorState: this.getContentStatefromHtml(props.defaultContentHtml)
    }
  }

  // given a html text set the current state of the text editor to it:
  setContentStatefromHtml(html) {
    this.setState({editorState: this.getContentStatefromHtml(html)})
  }

  // given a html text gets the current state of the text editor to set to:
  getContentStatefromHtml(html) {
    if (html) {
      const contentBlock = htmlToDraft(html)
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks)
        const editorState = EditorState.createWithContent(contentState)
        return editorState
      }
    }
    return EditorState.createEmpty() 
  }  

  // given the current state of the text editor it returns it in html:
  getStylizedTextAsHtml() {
    return draftToHtml(convertToRaw(this.state.editorState.getCurrentContent())).replaceAll(/<p><\/p>|\n/g, '').replaceAll('<a href', '<a class="resource-link" href').trim()
  }

  onEditorStateChange(editorState) {
    this.setState({editorState: editorState})
  }

  render() {
    const { editorState } = this.state
    return (
      <div>
        <Editor
          editorState={editorState}
          id={this.id}
          placeholder={this.placeholder}
          //toolbarClassName="toolbarClassName"
          //editorClassName="demo-editor"
          wrapperClassName="stylized-textarea boxed-div white-bg"
          //editorClassName="editorClassName"
          onEditorStateChange={this.onEditorStateChange.bind(this)}
          toolbar={{
            options: ['inline', 'blockType', 
                //'fontSize', 'fontFamily', 
                'list', 'textAlign', 
                //'colorPicker', 
                'link', 
                //'embedded', 'emoji', 
                'image', 'remove', 
                'history'
              ],
              blockType: {
                options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote']
              }
              
          }}
          stripPastedStyles={true}
          spellCheck={true}
        />    
      </div>
    )
  }
}

// Wrapper around UploadDropZone library to have a drop zone component for uploading files to our FS:
export class FileUploadDropZone extends React.Component {
  
  constructor(props) {
    super(props)
    this.hasImagePreview = props.hasImagePreview === true
    this.isNew = props.isNew
    this.newMessage = props.newMessage
    this.editMessage = props.editMessage
    this.onSuccessfulDropFn = props.onSuccessfulDropFn
    this.ref = React.createRef()
    this.messageRef = React.createRef()
    this.filePreviewRef = React.createRef()
    this.uploadingIconRef = React.createRef()
  }
  
  // function called once file is dropped in the upload zone:
  onDrop(acceptedFiles) {
    if (acceptedFiles && acceptedFiles.length == 1) {
      let file = acceptedFiles[0]
      let fileSize = file.size      
      if (fileSize > fsConfig.maxUploadFileSizeBytes) {
        Utility.showTextDialog(0, 'Max upload file size is ' + (Math.floor(fsConfig.maxUploadFileSizeBytes / 1000000)) + 'MB.' + (this.hasImagePreview ? '' : '\nIf applicable, you can split your resources into smaller files.'), generalConfig.dialogBoxErrorIconClassName)
        return
      }      
      let dropZoneElement = this.ref.current
      let dropZoneMsgElement = this.messageRef.current
      let dropZoneUploadingIcon = this.uploadingIconRef.current
      dropZoneMsgElement.innerText = this.newMessage
      let origFileName = file.name
      let fileName = Utility.getEpochTime() + '-' + origFileName
      this.origMessage = dropZoneMsgElement.innerText
      dropZoneMsgElement.innerText = 'uploading...'
      dropZoneElement.className = dropZoneElement.className + ' loading'
      dropZoneUploadingIcon.className = dropZoneUploadingIcon.className.replace('hidden', '')
      if (this.onSuccessfulDropFn !== undefined)
        this.onSuccessfulDropFn(file, fileName, origFileName)
    }
  }

  // function called (by the caller) once successful upload is done:
  // param 'filePreviewInfo' is either a url for an uploaded image or the original final name uploaded if not an image....
  onUploadSuccessFn(filePreviewInfo) {
    let dropZoneElement = this.ref.current
    let dropZoneMsgElement = this.messageRef.current
    let dropZoneUploadingIcon = this.uploadingIconRef.current
    let dropZoneMsgFilePreviewElement = this.filePreviewRef.current
    dropZoneElement.className = dropZoneElement.className.replace(' loading', '')
    dropZoneMsgElement.innerText = this.editMessage
    dropZoneUploadingIcon.className = dropZoneUploadingIcon.className + ' hidden'
    if (this.hasImagePreview)
      dropZoneMsgFilePreviewElement.src = filePreviewInfo
    else
      dropZoneMsgFilePreviewElement.innerText = filePreviewInfo
  }

  // function called (by the caller) once upload fails:
  onUploadFailureFn() {
    let dropZoneElement = this.ref.current
    let dropZoneMsgElement = this.messageRef.current   
    let dropZoneUploadingIcon = this.uploadingIconRef.current
    dropZoneElement.className = dropZoneElement.className.replace(' loading', '')
    dropZoneMsgElement.innerText = this.origMessage
    dropZoneUploadingIcon.className = dropZoneUploadingIcon.className + ' hidden'
    Utility.showTextDialog(0, 'There was a problem uploading your file!', generalConfig.dialogBoxErrorIconClassName)
  }

  render() {
    let isNew = this.isNew || !this.props.previewFile
    return (
      <UploadDropZone 
        multiple={false} accept={this.props.accept} onDrop={this.onDrop.bind(this)}>
          {({getRootProps, getInputProps}) => (
            <div className={(this.props.extraClasses ? (this.props.extraClasses + ' ') : '') + 'dropzone'} ref={this.ref}>
                <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <div className="flex-col">
                        {this.hasImagePreview ? 
                          (<img className="monospace boxed top-margin20" src={isNew ? this.props.previewImagePlaceHolder : this.props.previewFile} ref={this.filePreviewRef} />)
                          : (<p className="monospace boxed" ref={this.filePreviewRef}>{isNew ? '' : this.props.previewFile.replace(/^\d+\-/, '') /* remove internal filename prefix for uploaded files.... */}</p>)}
                        <div className="height20 top-margin10">
                          <div ref={this.uploadingIconRef} className="flex hidden"><i className="fas fa-paper-plane"></i></div>
                        </div>
                        <p ref={this.messageRef} >{isNew ? this.newMessage : this.editMessage}</p>
                    </div>
                </div>
            </div>
          )}
      </UploadDropZone>
    )
  }
}

// CurriculumsDataFetched function for curriculum view pages: Database API calls this when curriculum info for the given id is fetched. Here first we need to fetch the teacher's data:
export function curriculumViewPageCurriculumsDataFetched(page) {     
  var curriculumNotFound = false
  page.curriculumData = Database.getCurriculumData(page.id)
  if (page.curriculumData) {
    // first do check to see if random key is missing or wrong boot the user out of this page:
    page.checkValidAccessForPage()
    let teacher = page.curriculumData.teacher    
    // only proceed to view the page if the user is indeed the teacher for the curriculum:
    if (page.curriculumTeacherCheckForPage(teacher))
      Database.database_fetchTeacherData(teacher, true)
    else 
      curriculumNotFound = true 
  } else if (!page.fetchMissingSubmittedCurriculumsDataTried) {
    // if curriculum data was not found, also check the submissions curriculum table. it might be there...:
    page.fetchMissingSubmittedCurriculumsDataTried = true
    Database.fetchCurriculumDataForIds([page.id], true, false)
  } else if (!page.fetchMissingDraftedCurriculumsDataTried) {
    // if curriculum data was not found, also check the drafts curriculum table. it might be there...:
    page.fetchMissingDraftedCurriculumsDataTried = true
    Database.fetchCurriculumDataForIds([page.id], false, true)
  } else
    curriculumNotFound = true
  // If there's no curriculum with the given id, or the user isn't the creator of the given curriculum, it's an error. Redirect back to creator home page:  
  if (curriculumNotFound)
    page.setState({navigateTo: page.accessNotAllowedNavigationPage()})
}

// CurriculumsDataFetched function for lesson plan view pages: Database API calls this when curriculum info for the given id is fetched. Here first we need to fetch the teacher's data:
export function lessonPlanViewPageCurriculumsDataFetched(page) {
  var curriculumNotFound = false
  page.curriculumData = Database.getCurriculumData(page.curriculum)  
  if (page.curriculumData) {
      // first do check to see if random key is missing or wrong boot the user out of this page:
      page.checkValidAccessForPage()
      let teacher = page.curriculumData.teacher      
      // only proceed to view the page if the user is indeed the teacher for the curriculum:
      if (page.curriculumTeacherCheckForPage(teacher)) {
          // make sure number is not out of range: 
          page.lessonNumber = Math.min(page.lessonNumber, page.curriculumData['num-lessons'])
          // check if user had previously marked this curriculum as wishlisted. Update wishlist button if so:        
          Database.database_fetchLessonData(page.curriculum, page.lesson)            
      } else 
        curriculumNotFound = true 
  } else if (!page.fetchMissingSubmittedCurriculumsDataTried) {
      // if curriculum data was not found, also check the submissions curriculum table. it might be there...:
      page.fetchMissingSubmittedCurriculumsDataTried = true
      Database.fetchCurriculumDataForIds([page.id], true, false)
  } else if (!page.fetchMissingDraftedCurriculumsDataTried) {
      // if curriculum data was not found, also check the drafts curriculum table. it might be there...:
      page.fetchMissingDraftedCurriculumsDataTried = true
      Database.fetchCurriculumDataForIds([page.id], false, true)
  } else
      curriculumNotFound = true
  // If there's no curriculum with the given id, or the user isn't the creator of the given curriculum, it's an error. Redirect back to creator home page:  
  if (curriculumNotFound)
      page.setState({navigateTo: page.accessNotAllowedNavigationPage()})
}


// if this returns true the page proceeds to rendering
// for this page we just need to make sure the user is the teacher 
// for the curriculum being viewed (subclasses may want to redefine this...):
export function curriculumTeacherCheckForPage(teacher) { 
  const username = globals.sessionInfo.username 
  return teacher === username
}

// deletes a given curriculum from the drafts  
// NOTE: No need to remove fileserver because lambdas handle deleting the files
export function deleteCurriculum(curriculum, curriculumData, teacher, teacherData, onSuccessFn) {
    let isDraft = curriculumData['in-drafts']
    if (!isDraft) {
      Utility.showTextDialog(0, 'Curriculum is not in your drafts, so cannot be deleted.', generalConfig.dialogBoxErrorIconClassName)
      return true
    }    
    let preOnSuccessFn = () => {
      let onCloseFn = () => {
        if (onSuccessFn !== undefined)
          onSuccessFn()
        return true
      }
      Utility.showTextDialog(0, 'Your curriculum is deleted.', generalConfig.dialogBoxOKIconClassName, onCloseFn)      
    }
    Database.database_deleteCurriculum(curriculum, curriculumData, teacherData, true, false, false, preOnSuccessFn)
    return true
}

// if it's a live curriculum, it will deactivate it so existing purchasers can continue to access it but no longer visible on the store for anybody new to purchase (keeping it in productions table) if it's live, or activate it if it's currently deactivated:
export function toggleCurriculumActive(curriculum, curriculumData, teacher, teacherData, onSuccessFn) {
    let isActive = curriculumData['in-productions'] && curriculumData.active       
    let preOnSuccessFn = () => {      
      let onCloseFn = () => {
        if (onSuccessFn !== undefined)
          onSuccessFn()
        return true
      }
      Utility.showTextDialog(0, 'The curriculum is ' + (isActive ? 'removed from the store.' : 'now available in the store.'), generalConfig.dialogBoxOKIconClassName, onCloseFn)
    }
    Database.database_toggleCurriculumActive(!isActive, curriculum, curriculumData, preOnSuccessFn)
    return true
}

// if it's a live curriculum, we do not allow removing the curriculum unless admin (Lifetime Access Policy - so that existing content purchasers can continue accessing it while new users cannot see or purchase it)
// if it's a submitted curriculum, it will remove from store and move it into drafts just in case the teacher wants to submit again:
// also if it was a live curriculum, it will place a copy of the curriculum and lessons and their fileserver files into the archive deleted tables/fileserver areas...:
export function removeCurriculumFromStore(curriculum, curriculumData, teacher, teacherData, onSuccessFn) {
  let inDrafts = curriculumData['in-drafts']
  let inProductions = curriculumData['in-productions']
  let randomKey = curriculumData['random-key']
  if (inDrafts || (inProductions && !Database.isAdminUserDataFetched())) {
    Utility.showTextDialog(0, inDrafts ? 'Curriculum is not submitted yet.' : 'Curriculum cannot be removed because of Lifetime Access Policy.', generalConfig.dialogBoxErrorIconClassName)
    return true
  }  
  let preOnSuccessFn = () => {  
    let onCloseFn = () => {
      if (onSuccessFn !== undefined)
        onSuccessFn()
      return true
    }   
    Utility.showTextDialog(0, 'The curriculum is removed and moved into your drafts.', generalConfig.dialogBoxOKIconClassName, onCloseFn)    
  }
  let finalOnSuccessFn = () => {
      // for live curriiculums copy the fileserver files from live bucket area if a live curriculum (deleting the live area is done by the lambda fns...):
      if (inProductions)
        Fileserver.copyCurriculumFilesOutOfProductions(randomKey, curriculum, teacher, preOnSuccessFn)
      else 
        preOnSuccessFn()            
  }
  Database.database_removeCurriculumFromStore(curriculum, curriculumData, teacherData, finalOnSuccessFn)  
  return true
}

// checks if a given curriculum data in the edit form is complete and correct, ready to save or submit
export function validateCurriculumForm(isForSubmission, caller, formData, skipLessonsValidation = false) {
  let numLessonsPerWeek = formData['num-lessons-per-week']
  let numWeeks = formData['num-weeks']  
  let termYear = formData.termYear
  let parsedTermYear = termYear ? parseInt(termYear, 10) : 0
  let err = false    
  if (!formData.subject) {
    err = 'Choose a subject.'
  } else if (!formData.topic) {
    err = 'Choose a topic.'
  } else if (!Utility.validateTitle(formData.title)) {
    err = 'Enter a valid curriculum title.'
  } else if (formData.subtitle && !Utility.validateTitle(formData.subtitle)) {
    err = 'Enter a valid curriculum subtitle.'
  } else if (formData['search-terms'].filter(i => !i).length > 0) {
    err = 'There are empty items in search terms.'
  } else if (isForSubmission && (formData['search-terms'].length > curriculumConfig.maxNumberOfSearchTerms)) {
    err = 'Enter at most ' + curriculumConfig.maxNumberOfSearchTerms + ' search terms.'
  } else if (isForSubmission && formData.grade === undefined) {
    err = 'Choose a grade.'
  } else if (formData.grade != null && formData.grade.length > 1 && formData.grade[0] > formData.grade[1]) {    
    err = 'Maximum grade must be greater or equal to minimum grade.'
  } else if (isForSubmission && !formData['the-language']) {
    err = 'Choose a language.'
  } else if (isForSubmission && (caller.isInDraftStage && !(caller.imageUploaded || (!caller.isNew && caller.curriculumData.image)))) {
    err = 'Please upload a course image.'
  } else if (formData['learn-what'].filter(i => !i).length > 0) {
    err = 'There are empty items in learning outcomes.'
  } else if (isForSubmission && (formData['learn-what'].length < curriculumConfig.minNumberOfLearningOutcomes)) {
    err = 'Enter at least ' + curriculumConfig.minNumberOfLearningOutcomes + ' learning outcomes.'
  } else if (isForSubmission && !numLessonsPerWeek) {
    err = 'Choose number of lessons per week.'
  } else if (isForSubmission && !formData['num-minutes-per-lesson']) {
    err = 'Choose number of minutes per lesson.'
  } else if (isForSubmission && !numWeeks) {
    err = 'Choose number of weeks.'
  } else if (!formData['single-unit'] && numWeeks < curriculumConfig.minNumberOfWeeks) {
    err = 'The minimum number of weeks for full curriculums is ' + curriculumConfig.minNumberOfWeeks + '.\nChange the number of weeks or change the type of the curriculum to "Curriculum Unit".'
  } else if (isForSubmission && formData['price-tier'] === undefined) {
    err = 'Choose a price tier.'
  } else if (isForSubmission && !Utility.validateTitle(formData['school-taught-title'])) {
    err = 'Enter a valid school name.'
  } else if (isForSubmission && !Utility.validateURL(formData['school-taught-website'])) {
    err = 'Enter a valid school website.'
  } else if (isForSubmission && !formData.termName) {
    err = 'Choose a term you taught the course.'
  } else if (isForSubmission && !termYear) {
    err = 'Choose a term year you taught the course.'
  } else if (termYear && ((('' + termYear) !== ('' + parsedTermYear)) || !yearIsMoreRecentThanNYears(parsedTermYear, 50))) {
    err = 'Enter a valid term year for when you taught the course.'
  } else if ((formData['teacher-requirements'].filter(i => !i).length > 0) || (formData['teacher-requirements-optionals'].filter(i => !i).length > 0)) {
    err = 'There are empty items in teacher requirements.'
  } else if ((formData['student-requirements'].filter(i => !i).length > 0) || (formData['student-requirements-optionals'].filter(i => !i).length > 0)) {
    err = 'There are empty items in student requirements.'
  } else if (isForSubmission && !formData.descriptionText) {
    err = 'Write a complete description of your curriculum.'
  } else if (isForSubmission && formData['resource-descriptions'].filter(i => !i).length > 0) {
    err = 'Write a description for each resource.'
  } else if (formData['resource-is-externals'] && (isForSubmission || (formData['resource-is-externals'].filter(is => is).length > 0)) && formData['resource-is-externals'].filter((is, index) => is && !Utility.validateURL(formData['resources'][index])).length > 0) {
    err = 'Enter a valid URL share link for each external resource, or upload them instead.'
  } else if (formData['resource-is-cloud-storages'] && (isForSubmission || (formData['resource-is-cloud-storages'].filter(is => is).length > 0)) && formData['resource-is-cloud-storages'].filter((is, index) => is && !CloudStorage.isCloudStorageURL(formData['resources'][index])).length > 0) {
    err = 'The URLs for some of the resources do not seem to be a link to a cloud storage platform.\nUse the "Cloud Storage" resource link type option only for Google Drive documents. For other external links use the "Other External" link type option.'
  } else if (formData['resource-is-cloud-storages'] && (isForSubmission || (formData['resource-is-cloud-storages'].filter(is => !is).length > 0)) && formData['resource-is-cloud-storages'].filter((is, index) => !is && CloudStorage.isCloudStorageURL(formData['resources'][index])).length > 0) {
    err = 'The URLs for some of the resources are links to a cloud storage platform.\nUse the "Cloud Storage" resource link type option for Google Drive documents. For other external links use the "Other External" link type option.'
  } else if (formData['resource-is-cloud-storages'] && (isForSubmission || (formData['resource-is-cloud-storages'].filter(is => is).length > 0)) && formData['resource-is-cloud-storages'].filter((is, index) => is && formData['resource-cloud-storage-not-copieds'][index]).length > 0) {
    err = 'The URLs for some of the resources were changed.\nClick on each "Make a copy" button to make a copy of the document to be used as a course resource.'
  } else if (formData['resource-is-externals'] && (isForSubmission || (formData['resource-is-externals'].filter(is => !is).length > 0)) && formData['resource-is-externals'].filter((is, index) => !is && !formData['resources'][index]).length > 0) {
      err = 'Upload each resource or specify an external URL instead.'
  } else if (isForSubmission && formData['section-titles'].length < 1) {
    err = 'Organize your lessons into at least one unit and title each unit.'
  } else if (isForSubmission && formData['section-titles'].indexOf('') > -1) {
    err = 'Title each unit.'
  } else if (isForSubmission && formData['lesson-titles'].indexOf('') > -1) {
    err = 'Title each lesson.'
  } else if (isForSubmission && (formData['lesson-titles'].length != numWeeks * numLessonsPerWeek)) {
    err = 'Your curriculum lasts ' + Utility.plural('week', numWeeks) + ' and each week has ' + Utility.plural('lesson', numLessonsPerWeek) + ', so there needs to be exactly ' + Utility.plural('lesson', numWeeks * numLessonsPerWeek) + ' in the course plan.'
  } else if (isForSubmission && formData.sectionLessonCounts.indexOf(0) > -1) {
    err = 'Every unit must contain lessons.'
  } else if (isForSubmission && (formData['section-lesson-indices'].length != formData['section-titles'].length)) {
    err = 'There is a problem with number of units and unit titles.'
  }  
  if (!(err || skipLessonsValidation)) {
      // validate lessons data if so far so good:
    err = validateLessonsInForm(isForSubmission, formData, caller.curriculumData, formData.lessons, caller.lessonsData)
  }  
  if (!err) {
    if (isForSubmission && (caller.isInDraftStage && !(caller.previewsUploaded || (!caller.isNew && caller.curriculumData.previews)))) {
      err = "Please upload a PDF or ZIP file containing your curriculum's content preview screenshots."
    } else if (isForSubmission && (caller.isInDraftStage && !(caller.curriculumProofUploaded.school || (!caller.isNew && caller.curriculumData['school-proof'])))) {
      err = 'Please upload a document as proof of your teaching position at the school:\n' + formData['school-taught-title'] + '.'
    } else if (isForSubmission && (caller.isInDraftStage && !(caller.curriculumProofUploaded.teaching || (!caller.isNew && caller.curriculumData['teaching-proof'])))) {
      err = 'Please upload a document as proof of your teaching this curriculum at the school:\n' + formData['school-taught-title'] + '.'
    } else if (isForSubmission && formData['other-proof-descriptions'].filter(i => !i).length > 0) {
      err = 'Write a description for each proof.'
    } else if (formData['other-proof-is-externals'] && (isForSubmission || (formData['other-proof-is-externals'].filter(is => is).length > 0)) && formData['other-proof-is-externals'].filter((is, index) => is && !Utility.validateURL(formData['other-proofs'][index])).length > 0) {
      err = 'Enter a valid URL share link for each external proof, or upload them instead.'
    } else if (formData['other-proof-is-externals'] && (isForSubmission || (formData['other-proof-is-externals'].filter(is => !is).length > 0)) && formData['other-proof-is-externals'].filter((is, index) => !is && !formData['other-proofs'][index]).length > 0) {
      err = 'Upload each proof document or specify an external URL instead.'
    }
  }
  if (!err) {
    const promoLen = payConfig.promoCodeLength 
    if (formData['promo-codes'].filter(i => (i.length === promoLen) && Utility.validatePromoCode(i)).length !== formData['promo-codes'].length)
      err = 'Promo codes should be ' + promoLen + ' alphanumeric characters.'
    else if (new Set(formData['promo-codes']).size !== formData['promo-codes'].length)
      err = 'Promo codes need to be unique.'
    else if (formData['promo-tiers'].indexOf('0') >= 0)
      err = 'Choose a discount % for each promo code.'
    else if (new Set(formData['promo-tiers']).size !== formData['promo-tiers'].length)
      err = 'Promo codes need to have unique discounts %s.'
  }  
  return err
}

// checks if all lessons in curriculum have been created/saved and have complete data
function validateLessonsInForm(isForSubmission, formData, curriculumData, lessons, lessonsData) {
  let err = false
  let lessonNumber = 1
  for (let lesson of lessons) {
    let lessonData = lessonsData[lesson]
    if (lessonData === undefined) {
        if (isForSubmission) {
          err = 'Create lesson ' + lessonNumber + ' first.'
          break
        } else
          continue
    }
    let lessonError = validateLessonForm(isForSubmission, curriculumData, lessonData, false)
    if (lessonError) {
        err = (isForSubmission ? ('Lesson ' + lessonNumber + ' is incomplete') : ('Check lesson ' + lessonNumber)) + ': ' + lessonError
        break
    }    
    lessonNumber++
  }
  if (!err) {
    let numTests = formData['num-tests']    
    if (isForSubmission && formData.numTestsCounted != numTests) {
      err = 'Your curriculum specifies that it has a total of ' + Utility.plural('test', numTests) + ', but the sum total of tests inside all lessons is ' + formData.numTestsCounted + ' and does not add up to that.\nUpdate the number of tests for the curriculum, or update the resources inside lessons marked as tests.'
    } 
  }
  return err
}

// checks if a given lesson data in the edit form is complete and correct, ready to save or submit
export function validateLessonForm(isForSubmission, curriculumData, formData, isDuratingLessonEdit = true) {
  let err = false  
  if (formData['key-concepts'].filter(i => !i).length > 0) {
    err = 'There are empty items in key concepts.'
  } else if (isForSubmission && formData['key-concepts'].length < 1) {
    err = 'Add key concepts.'
  } else if (formData.objectives.filter(i => !i).length > 0) {
    err = 'There are empty items in objectives.'
  } else if (isForSubmission && formData.objectives.length < curriculumConfig.minLessonNumberOfObjectives) {
      err = 'Add at least ' + curriculumConfig.minLessonNumberOfObjectives + ' objectives.'
  } else if (isForSubmission && formData.objectives.length > curriculumConfig.maxLessonNumberOfObjectives) {
    err = 'Add at most ' + curriculumConfig.maxLessonNumberOfObjectives + ' objectives.'
  } else if ((formData['teacher-requirements'].filter(i => !i).length > 0) || (formData['teacher-requirements-optionals'].filter(i => !i).length > 0)) {
    err = 'There are empty items in teacher requirements.'
  } else if ((formData['student-requirements'].filter(i => !i).length > 0) || (formData['student-requirements-optionals'].filter(i => !i).length > 0)) {
    err = 'There are empty items in student requirements.'
  } else if (isForSubmission && !formData.descriptionText) {
      err = 'Write a description of this lesson.'
  } else if (isForSubmission && !formData.slides) {
      err = 'Add lesson slides share link or upload them.'
  } else if (formData['slides-is-external'] && (isForSubmission || formData.slides) && !Utility.validateURL(formData.slides)) {
      err = 'Enter a valid URL for the slides external share link, or upload slides instead.'
  } else if (isDuratingLessonEdit && formData['slides-is-cloud-storage'] && (isForSubmission || formData.slides) && !CloudStorage.isCloudStorageURL(formData.slides)) {
    err = 'Slides URL does not seem to be a link to a cloud storage platform.\nUse the "Cloud Storage" resource link type option only for Google Drive documents. For other external links use the "Other External" link type option.'
  } else if (isDuratingLessonEdit && !formData['slides-is-cloud-storage'] && (isForSubmission || formData.slides) && CloudStorage.isCloudStorageURL(formData.slides)) {
  err = 'Slides URL is a link to a cloud storage platform.\nUse the "Cloud Storage" resource link type option for Google Drive documents. For other external links use the "Other External" link type option.'
  } else if (isDuratingLessonEdit && formData['slides-is-cloud-storage'] && (isForSubmission || formData.slides) && formData['slides-cloud-storage-not-copied']) {
  err = 'Slides cloud storage URL was changed.\nClick on the "Make a copy" button to make a copy of your document to be used as a lesson resource.'
  } else if (isForSubmission && formData['other-resource-descriptions'].filter(i => !i).length > 0) {
    err = 'Write a description for each resource.'
  } else if (formData['other-resource-is-externals'] && (isForSubmission || (formData['other-resource-is-externals'].filter(is => is).length > 0)) && formData['other-resource-is-externals'].filter((is, index) => is && !Utility.validateURL(formData['other-resources'][index])).length > 0) {
    err = 'Enter a valid URL share link for each external resource, or upload them instead.'
  } else if (isDuratingLessonEdit && formData['other-resource-is-cloud-storages'] && (isForSubmission || (formData['other-resource-is-cloud-storages'].filter(is => is).length > 0)) && formData['other-resource-is-cloud-storages'].filter((is, index) => is && !CloudStorage.isCloudStorageURL(formData['other-resources'][index])).length > 0) {
    err = 'The URLs for some of the other resources do not seem to be a link to a cloud storage platform.\nUse the "Cloud Storage" resource link type option only for Google Drive documents. For other external links use the "Other External" link type option.'
  } else if (isDuratingLessonEdit && formData['other-resource-is-cloud-storages'] && (isForSubmission || (formData['other-resource-is-cloud-storages'].filter(is => !is).length > 0)) && formData['other-resource-is-cloud-storages'].filter((is, index) => !is && CloudStorage.isCloudStorageURL(formData['other-resources'][index])).length > 0) {
    err = 'The URLs for some of the other resources are links to a cloud storage platform.\nUse the "Cloud Storage" resource link type option for Google Drive documents. For other external links use the "Other External" link type option.'
  } else if (isDuratingLessonEdit && formData['other-resource-is-cloud-storages'] && (isForSubmission || (formData['other-resource-is-cloud-storages'].filter(is => is).length > 0)) && formData['other-resource-is-cloud-storages'].filter((is, index) => is && formData['other-resource-cloud-storage-not-copieds'][index]).length > 0) {
    err = 'The URLs for some of the other resources were changed.\nClick on each "Make a copy" button to make a copy of the document to be used as a lesson resource.'
  } else if (formData['other-resource-is-externals'] && (isForSubmission || (formData['other-resource-is-externals'].filter(is => !is).length > 0)) && formData['other-resource-is-externals'].filter((is, index) => !is && !formData['other-resources'][index]).length > 0) {
      err = 'Upload each resource or specify an external URL instead.'
  } else if (isForSubmission && formData['other-resource-is-tests'] && formData['other-resource-is-tests'].filter(i => i).length > curriculumData['num-tests']) {
      err = 'Your curriculum specifies that it has a total of ' + Utility.plural('test', curriculumData['num-tests']) + ', so this lesson cannot contain more than that.\nUpdate the number of tests for the curriculum, or uncheck resources in this lesson marked as tests.'
  } else if (isForSubmission && formData['activity-titles'].filter(i => !i).length > 0) {
      err = 'Title each activity'
  } else if (isForSubmission && formData['activity-titles'].length < Math.max(curriculumConfig.minLessonNumberOfActivities, formData['activity-durations'].length)) {
    err = 'List at least ' + curriculumConfig.minLessonNumberOfActivities + ' activities and title each one.'
  } else if (isForSubmission && formData['activity-descriptions'].filter(i => !i).length > 0) {
    err = 'Write a description for each activity.'
  } else if (isForSubmission && formData['activity-descriptions'].length < Math.max(2, formData['activity-durations'].length)) {
    err = 'Write a description for each activity.'
  } else if (isForSubmission && formData['activity-durations'].reduce((acc, curr) => acc + curr, 0) !== curriculumData['num-minutes-per-lesson']) {
    let numMinutesPerLesson = curriculumData['num-minutes-per-lesson']
    let activityTotalMinutes = formData['activity-durations'].reduce((acc, curr) => acc + curr, 0)
    let isOver = activityTotalMinutes > numMinutesPerLesson    
    err = 'Activity durations do not add up to ' + numMinutesPerLesson + ' minutes (' + (Utility.plural('minute', Math.abs(activityTotalMinutes - numMinutesPerLesson))) + ' ' + (isOver ? 'over' : 'under') + ').'
  } else if (formData.assessments.filter(i => !i).length > 0) {
    err = 'There are empty items in assessment strategies.'
  } else if (isForSubmission && formData.assessments.length < 1) {
    err = 'Add assessment strategies.'
  }
  return err
}

// returns true if at least one of lesson's resources (slides or other resources) is uploaded on fileserver (rather than external link):
export function doesLessonHaveUploadedResources(lessonData) {
  return !lessonData['slides-is-external'] || lessonData['other-resource-is-externals'].filter(is => !is).length > 0
}

// dialog to run an operation on the curriculum, such as deactivate, remove from store, etc.
export function showButtonDialog(curriculum, curriculumData, message, operationLabel, handlerFn, showWaitingDialogAfterClick = false) {  
  Utility.showTextDialog(0, message, generalConfig.dialogBoxQuestionIconClassName , undefined, ['Cancel', operationLabel], [undefined, (e) => { 
    if (showWaitingDialogAfterClick)
      Utility.showWaitingDialog()
    handlerFn(curriculum, curriculumData) 
  }])
}

// generates the content of an html file with description of a curriculum or lesson, to be uploaded and placed on the fileservers. iframes in the code source this file to retrieve the descriptions
// the id is used so that the retriever knows which description it's getting in the POST message, is it the curriculum's description, or one of its lessons, and if so which one...:
export function generateDescriptionHtmlFileFromHtmlText(id, descriptionText) {    
    let paragraphs =  descriptionText.trim()
    return `<html><head><meta charset="UTF-8"></head><body onload="window.top.postMessage(JSON.stringify(document.body.children[0].innerHTML), '*')"><description><div id="termeric-description" item="${id}">${paragraphs}</div></description></body></html>`
}

export function generateNewCurriculumId(subject) {
    return Database.getSubjectPrefix(subject) + '-' + new Date().getTime() + '-' + generateUniqueId(true, 12)
}

// generates a unique id using uuid module or using current epoch time
export function generateNewCurriculumRandomKey() {
  let full = uuid()
  return full.substring(0, creatorConfig.randomKeyLength)
}

// generates a unique id using uuid module or using current epoch time
export function generateUniqueId(useUuid, length) { 
  let full = useUuid ? uuid().replaceAll('-', '') : 
    // add value of counter to ensure unique ids generated from current epoch time are indeed unique:
    '' + (Utility.getEpochTime(false) + creatorConfig.uniqueIdCtr++)
  let len = full.length
  return useUuid ? full.substring(0, length) : // take the first N
    full.substring(len, len - length) // take the last N
}

export function getPastNYears(n) {
  let thisYear = new Date().getFullYear()
  return Utility.arrayFromTo(thisYear, thisYear - n + 1)
}

export function yearIsMoreRecentThanNYears(year, n) {
  let thisYear = new Date().getFullYear()
  return (year <= thisYear) && (thisYear - year <= n)
}
// returns list of months backwards from current month on:
export function getMonthsReverseFromCurrentMonth() {  
  let currentMonth = new Date().getMonth()
  let currentMonthIndex = 12 - currentMonth - 1
  return [...monthsReverse.slice(currentMonthIndex)].concat(monthsReverse.slice(0, currentMonthIndex))
}
