import * as Config from './config'
import * as Database from './database-api'
import * as Fileserver from './fileserver-api'
import * as Utility from './utility'


const globals = Config.globals
const adminConfig = Config.pages.admin

// Landing page for admin site (to be subclassed by individual admin pages...):
export class AdminLandingPage extends Utility.SessionLandingPageWithDialogBox {
    constructor(props) {
        super(props)
        if (!globals.fileserverInit)
            Fileserver.fileserver_initConnection()
    }

    // override the superclass's userDataFetched which checks if user has bought the curriculum (not relevant here):
    userDataFetched() {
        this.updateTopMenuUserIconImage()
        // Fetch the data for the admin user in session (if any) from the database:
        Database.database_fetchAdminUserData()
    }    

    // database API calls this when data for admin user in session's info is fetched
    // if the user is indeed admin, Database.cachedAdminUserInfo will be defined...
    adminUserDataFetched() {        
        // if user is not admin, boot the user out of this page:        
        if (!Database.isAdminUserDataFetched())
            this.setState({navigateTo: this.accessNotAllowedNavigationPage()})        
    }

    // which page to navigate to if curriculum with id on url params not found:
    accessNotAllowedNavigationPage() {
        return '/'
    }
  
    // default render of a landing page without search bar in header...:
    render() {
        return Utility.renderNoSearchBarPage(this)
    }
}

// validate key for a subject being added:
export function validateSubjectKey(key) {
    let pattern = /^([a-z]+-?)*[a-z]+$/
    return key.length > 1 && pattern.test(key)
}

// validate language key conforms to ISO 639-1: two-letter codes:
export function validateLanguageCode(code) {
    let pattern = /^[a-z][a-z]$/
    return pattern.test(code)
}
