/*
    Creator site - Creator Curriculum Print View Page
    Printer-fiendly view of the listing for a given previously created curriculum by the creator teacher
*/

import React from 'react'

import CreatorCurriculumViewPage from './creator-view'
import * as Utility from './utility'

class CreatorCurriculumPrintViewPage extends CreatorCurriculumViewPage {
    constructor(props) {
      super(props)
      this.pageId = 'creator-curriculum-print'
      this.isPrintPage = true // flag for print pages
      this.bodyClass = 'no-margin-body'      
    }
  
    renderBody() {   
        return (
          <Utility.CurriculumPrintViewPageBodyComponent page={this}/>
        )
    }

    render() {
        return this.noHeadersNoFooterRender()
    }

}

export default CreatorCurriculumPrintViewPage
