/*
    Creator site - Lesson Plan Edit Page
    Allows the creator of a previously created curriculum to edit a specific lesson plan inside the curriculum
*/

import React from 'react'
import { Tabs, Tab, TabPanel, TabList } from 'react-web-tabs'
import 'react-web-tabs/dist/react-web-tabs.css'

import * as Config from './config'
import * as Database from './database-api'
import * as Fileserver from './fileserver-api'
import * as CloudStorage from './cloud-storage-api'
import * as Utility from './utility'
import * as CreatorUtility from './creator-utility'

const globals = Config.globals
const generalConfig = Config.pages.general
const fsConfig = Config.fsConfig
const curriculumConfig = Config.curriculumConfig

class CreatorEditLessonPlanPage extends CreatorUtility.CreatorRestrictedAccessLandingPage {
    constructor(props) {
        super(props)
        this.hasAwayNavigationPrompt = true
        this.pageId = 'creator-edit-lesson'
        this.uploadDropZoneNewMessage = 'Drop resource file here, or click to select...'
        this.uploadDropZoneEditMessage = '✓ Resource file uploaded. To change it, drop a resource file here or click to select...'
        this.descriptionText = ''
        this.formTabsComponent = React.createRef()
        this.formDescriptionTextEditor = React.createRef()
        this.lessonFormKeyConcepts = React.createRef()
        this.lessonFormObjectives = React.createRef()
        this.lessonFormTeacherRequirements = React.createRef()
        this.lessonFormStudentRequirements = React.createRef()
        this.lessonFormSlides = React.createRef()
        this.lessonFormOtherResources = React.createRef()
        this.lessonFormActivities = React.createRef()
        this.lessonFormAssessments = React.createRef()
        this.lessonFormTeacherNotes = React.createRef()
        this.curriculum = globals.urlParams.id
        this.lesson = globals.urlParams.lesson        
        this.weekNumber = parseInt(globals.urlParams.week, 10)
        this.dayNumber = parseInt(globals.urlParams.day, 10)
        this.sectionNumber = parseInt(globals.urlParams.section, 10)
        this.lessonNumber = Math.max(1, parseInt(globals.urlParams.number, 10))        
        this.lessonResourceFilesUploadedNames = {'slides': {}, 'other-resources': {}}
        if (globals.haveLocalStorage)
            this.lastTabOpen = localStorage.getItem('termeric-page-' + this.pageId + '-' + this.lesson + '-last-tab-open')
        this.state = {
            selectedTab: this.lastTabOpen || 'about'
        }
    }

    userAndTeacherDataFetched() {
        super.userAndTeacherDataFetched()
        // if there's no teacher with the given id, redirect to creator-edit-teacher page for new teacher profile to be created:
        if (!this.teacherData)
            this.setState({navigateTo: '/creator/edit-teacher'})        
        // if we're navigating from a previously page and the curr id data was already fetched, we need to manually trigger the process of page rendering:
        else {
            this.curriculumData = Database.getCurriculumData(this.id)       
            if (this.curriculumData) {                
                this.allDataAndCurriculumDataFetched()
            }      
        }
    }

    // what curriculum ids we need to fetch data for for this page:
    getCurriculumIdsToCheck(isSubmissionsTable, isDraftsTable) {  
        return [this.id]
    } 

    // database API calls this when curriculums info for all curriculum data are fetched. It'll trigger components that need that info to render now...
    // handle the case when both curriculums and user data are fetched, otherwise wait for both to arrive before calling the handler function to:
    // if at this point there are curriculum ids in the shopping Library whose data have not yet been fetched from the database, call the database to do so
    // otherwise if we have all data we need go ahead and trigger the Library panel to render:
    allDataAndCurriculumDataFetched() {
        this.curriculumData = Database.getCurriculumData(this.id)        
        // this is a previously saved curriculum. get its data and validate it
        // if id supplied in the url params and it's not found or the curriculum doesn't belong to the current teacher user, it's an error. navigate to creator home page:                        
        if (!this.curriculumData || (this.curriculumData.teacher !== globals.sessionInfo.username))
            this.setState({navigateTo: '/creator/library'})        
        this.isInSubmissions = this.curriculumData['in-submissions']
        this.isInDrafts = this.curriculumData['in-drafts']
        // now fetch the lessons data (if any) before rendering page:        
        Database.database_fetchLessonData(this.id, this.lesson)
    }

    // database api will call this once lessons data for curriculum is fetched:
    lessonDataFetched() {
        this.lessonData = Database.getLessonData(this.id, this.lesson)
        this.isNew = this.lessonData === undefined        
        // we got all data. ready to render page:
        this.setState({dataFetched: true})        
    }

    // collects the filled out form into a new object used for saveForm function:
    collectFormData() {
        let activitiesData = this.lessonFormActivities.current.getList()
        let otherResourcesData = this.lessonFormOtherResources.current.getList()
        let slidesData = this.lessonFormSlides.current.getList()[0]
        let teacherRequirements = this.lessonFormTeacherRequirements.current.getList()
        let studentRequirements = this.lessonFormStudentRequirements.current.getList()
        let otherResourcesIsTests = otherResourcesData.map(i => i.isTest)
        let otherResourcesIsAssignments = otherResourcesData.map(i => i.isAssignment)
        let formData = {
            slides: slidesData.url.trim(),
            'slides-is-external': slidesData.isExternal,
            'slides-is-cloud-storage': slidesData.isCloudStorage,
            'slides-cloud-storage-not-copied': slidesData.cloudStorageNotYetCopied,
            'activity-titles': activitiesData.map(i => i.title),
            'activity-descriptions': activitiesData.map(i => i.description),
            'activity-durations': activitiesData.map(i => i.duration),
            'key-concepts': this.lessonFormKeyConcepts.current.getList(),
            objectives: this.lessonFormObjectives.current.getList(),
            'other-resource-descriptions': otherResourcesData.map(i => i.description),
            'other-resources': otherResourcesData.map(i => i.url.trim()),
            'other-resource-is-externals': otherResourcesData.map(i => i.isExternal),
            'other-resource-is-cloud-storages': otherResourcesData.map(i => i.isCloudStorage),
            'other-resource-cloud-storage-not-copieds': otherResourcesData.map(i => i.cloudStorageNotYetCopied),
            'teacher-requirements': teacherRequirements.filter(i => !i.isOptional).map(i => i.description),
            'teacher-requirements-optionals': teacherRequirements.filter(i => i.isOptional).map(i => i.description),
            'student-requirements': studentRequirements.filter(i => !i.isOptional).map(i => i.description),
            'student-requirements-optionals': studentRequirements.filter(i => i.isOptional).map(i => i.description),
            assessments: this.lessonFormAssessments.current.getList(),
            'teacher-notes': this.lessonFormTeacherNotes.current.getList(),
            descriptionText: this.formDescriptionTextEditor.current.getStylizedTextAsHtml()
        }
        // only add optional fields if there's a true in the list:
        let otherResourcesOptionalFields = {'other-resource-is-tests': otherResourcesIsTests, 'other-resource-is-assignments': otherResourcesIsAssignments}
        Object.keys(otherResourcesOptionalFields).forEach(f => {
            let list = otherResourcesOptionalFields[f]
            if ((list.indexOf(true) >= 0) || (this.lessonData && this.lessonData[f] && (JSON.stringify(this.lessonData[f]) !== JSON.stringify(list))))
                formData[f] = list
        })        
        //console.log(formData)
        return formData
    }

    // checks if lesson draft is complete and ready for curriculum submission
    checkForm() {
        let formData = this.collectFormData()
        var err = CreatorUtility.validateLessonForm(true, this.curriculumData, formData, true)
        Utility.showTextDialog(0, err || 'Your lesson plan is complete.', err ? generalConfig.dialogBoxErrorIconClassName : generalConfig.dialogBoxOKIconClassName)
    }

    // saves the lesson plan draft on the database for later retrival
    saveForm(afterSaveFn) {
        let error = false
        let formData = this.collectFormData()
        // check if it is at a savable state:
        var err = CreatorUtility.validateLessonForm(!this.isInDrafts, this.curriculumData, formData, true)
        if (err) {
            Utility.showTextDialog(0, err, generalConfig.dialogBoxErrorIconClassName)
            error = err
        } else {
            let onFailureFn = () => {
                let onCloseFn = () => {                    
                    if (afterSaveFn !== undefined)
                        afterSaveFn()
                }
                if (afterSaveFn === undefined)
                    Utility.showTextDialog(0, 'There was a problem saving the lesson plan!', generalConfig.dialogBoxErrorIconClassName, onCloseFn)
                else
                    onCloseFn()
            }
            let onSuccessFn = () => {
                let onCloseFn = () => {
                    this.isNew = false
                    this.isInDraftStage = true
                    this.lessonData = Database.getLessonData(this.id, this.lesson)
                    this.lastSavedFormData = formData
                    if (afterSaveFn !== undefined)
                        afterSaveFn()
                }
                if (afterSaveFn === undefined)
                    Utility.showTextDialog(0, 'Your lesson plan is saved.', generalConfig.dialogBoxOKIconClassName, onCloseFn)
                else
                    onCloseFn()
            }
            let lessonData = {}
            let fields = ['slides', 'slides-is-external', 'objectives', 'activity-titles', 'activity-descriptions', 'activity-durations', 'key-concepts', 'other-resource-descriptions', 'other-resources', 'other-resource-is-externals', 'teacher-requirements', 'teacher-requirements-optionals', 'student-requirements', 'student-requirements-optionals', 'assessments', 'teacher-notes']
            let optionalFields = ['other-resource-is-tests', 'other-resource-is-assignments']
            optionalFields.forEach(f => { if (formData[f] !== undefined) fields.push(f) })
            fields.forEach(f => { lessonData[f] = formData[f] })            
            let fileserverStatus = this.processUploadedFiles(lessonData, formData)
            // Save the new lesson plan draft or update draft:
            Utility.showWaitingDialog()       
            if (this.isNew)
                Database.database_addNewLessonDraft(this.id, this.lesson, this.curriculumData, lessonData, onSuccessFn, onFailureFn)
            else
                Database.database_updateLesson(this.id, this.lesson, this.curriculumData, lessonData, fileserverStatus.fileserverUpdated, fileserverStatus.fileserverCleanupNeeded, onSuccessFn, onFailureFn)
        }
        return error
    }

    // if files have been uploaded using upload zones (for lesson slides/resources), or description has been updated using text editor field, this updates the cached db fields for the lesson and sets a flag (return value) that db entry for the lesson needs to be forced to update
    // fileserverStatus = true --> anything on fs was updated
    // fileserverCleanupNeeded = true --> new files were uploaded, thus old files need to be removed on fs
    processUploadedFiles(lessonData, formData) {
        let fileserverUpdated = false, fileserverCleanupNeeded = false        
        let fields = ['slides', 'other-resources']
        fields.forEach( (field, idx) => {
            let updates = this.lessonResourceFilesUploadedNames[field]            
            Object.keys(updates).forEach(index => {                                
                // if files have been uploaded, db also needs to be updated because the file on filesever has changed even if no other db fields have changed (so that an update entry will be made with last-update-date and the lambda triggered to handle the update):
                fileserverUpdated = true
                fileserverCleanupNeeded = true
                if (idx === 0) // slides
                    lessonData[field] = updates[index]
                else // other-resources
                    lessonData[field][index] = updates[index]                
                // remove it so we don't force update unnecessarily next time curr is saved:
                delete this.lessonResourceFilesUploadedNames[field][index]
            })
        })
        // handle uploading the description file on the fs in case this is a new curriculum or an old one whose description was updated:            
        let isDescriptionUpdated = this.isNew || (formData.descriptionText !== this.descriptionText)
        if (isDescriptionUpdated) {
            // also db needs to be updated because the file on filesever has changed even if no other db fields have changed (so that an update entry will be made with last-update-date and the lambda triggered to handle the update):            
            fileserverUpdated = true
            let descriptionUploadFileHtmlText = CreatorUtility.generateDescriptionHtmlFileFromHtmlText(this.lesson, formData.descriptionText)
            Fileserver.uploadLessonDescriptionFile(this.id, this.lesson, this.teacher, !this.isNew && this.curriculumData['in-productions'], fsConfig.curriculumDescriptionFile, descriptionUploadFileHtmlText)
            this.descriptionText = formData.descriptionText
        }
        return {fileserverUpdated: fileserverUpdated, fileserverCleanupNeeded: fileserverCleanupNeeded}
    }

    // creates function that will be called once a resource file is dropped in the other resource drop zone:
    generateResourceOnDropFn(isSlides, isResource, index, uploadZoneRef, urlInputElementRef) {
        let fn = (file, fileName, origFileName) => {
            let onUploadSuccessFn = () => {                
                this.lessonResourceFilesUploadedNames[isSlides ? 'slides' : 'other-resources'][index] = fileName
                urlInputElementRef.current.value = fileName              
                uploadZoneRef.current.onUploadSuccessFn(origFileName)
            }
            let onUploadFailureFn = () => {
                uploadZoneRef.current.onUploadFailureFn()
            }
            Fileserver.uploadLessonResourceFile(isSlides, this.curriculumData['random-key'], this.id, this.lesson, this.teacher, !this.isNew && this.curriculumData['in-productions'], fileName, file, onUploadSuccessFn, onUploadFailureFn)
        }
        return fn.bind(this)
    }

    // copies requirements list from curriculum's data:
    copyRequirementsFromCurriculumButtonClicked(isTeacherRquirement) {
        let type, requirementListRef
        if (isTeacherRquirement) {
            type = 'teacher'
            requirementListRef = this.lessonFormTeacherRequirements
        } else {
            type =  'student'
            requirementListRef = this.lessonFormStudentRequirements
        }
        requirementListRef.current.setItems([...this.curriculumData[type + '-requirements'].map(i => { return {description: i, isOptional: false} }), ...this.curriculumData[type + '-requirements-optionals'].map(i => { return {description: i, isOptional: true} })])
    }

    // auto save or warn user about saving form before leaving:
    promptForSaveBeforeNavigatingAway(link) {
        let navigateToPage = () => {
            this.setState({navigateTo: link})
        }
        // if form was never saved or the current form has changes over the last saved form warn the user about leaving first:
        if (this.lastSavedFormData === undefined || (JSON.stringify(this.collectFormData()) !== JSON.stringify(this.lastSavedFormData))) {
            let error = this.saveForm(navigateToPage)
            // if there was an error saving the form:
            if (error)
                Utility.showTextDialog(0, 'Failed to save lesson plan due to errors in the form:\n"' + error + '"\nIf you think you might have unsaved changes, click "Cancel", correct the errors in the lesson plan form, and save before leaving this page.', generalConfig.dialogBoxErrorIconClassName, undefined, ['Leave anyway', 'Cancel'], [navigateToPage, undefined])
        } else
            navigateToPage()
    }
    
    renderForm() {
        return (
          <Tabs ref={this.formTabsComponent} id="creator-edit-lesson-body-content" className="page-body-listing-content section"
            defaultTab={this.state.selectedTab}
            onChange={(tabId) => { if (globals.haveLocalStorage) localStorage.setItem('termeric-page-' + this.pageId + '-' + this.id + '-last-tab-open', tabId) }} >
            <TabList className="many-tabs-list">
                <Tab tabFor='about'><span className="many-tabs-header-lesson-edit tab-header">About</span></Tab>
                <Tab tabFor='description'><span className="many-tabs-header-lesson-edit tab-header">Description</span></Tab>
                <Tab tabFor='resources'><span className="many-tabs-header-lesson-edit tab-header">Resources</span></Tab>
                <Tab tabFor='activities'><span className="many-tabs-header-lesson-edit tab-header">Activities</span></Tab>
                <Tab tabFor='assessments'><span className="many-tabs-header-lesson-edit tab-header">Assessment Strategies</span></Tab>
                <Tab tabFor='teacher-notes'><span className="many-tabs-header-lesson-edit tab-header">Teacher Notes</span></Tab>
            </TabList>
            <TabPanel tabId='about'>
                {this.renderFormAbout()}
            </TabPanel>            
            <TabPanel tabId='description'>
              {this.renderFormDescription()}
            </TabPanel>
            <TabPanel tabId='resources'>
                {this.renderFormResources()}
            </TabPanel>
            <TabPanel tabId='activities'>
                {this.renderFormActivities()}
            </TabPanel>
            <TabPanel tabId='assessments'>                
                {this.renderFormAssessments()}
            </TabPanel>
            <TabPanel tabId='teacher-notes'>                
                {this.renderFormTeacherNotes()}
            </TabPanel>
          </Tabs>
        )
    }

    renderFormAbout() {
        let isNew = this.isNew
        let lessonData = this.lessonData
        let curriculumData = this.curriculumData
        return (
            <div id="creator-edit-lesson-form-info" className="form flex-col">                        
                <div className="form-item flex-col">                            
                    <label>Key Concepts &amp; Keywords</label>
                    <h6>Key concepts and keywords covered...</h6>
                    <CreatorUtility.TextInputList ref={this.lessonFormKeyConcepts} items={isNew ? undefined : lessonData['key-concepts'].slice()} sortable={true} page={this} />
                </div>
                <div className="form-item flex-col">                        
                    <label>Objectives</label>
                    <span className="form-input-description"><em className="opac">Add between {curriculumConfig.minLessonNumberOfObjectives} to {curriculumConfig.maxLessonNumberOfObjectives} objectives.</em></span>
                    <h6>After this lesson, the students will be able to...</h6>          
                    <CreatorUtility.TextInputList ref={this.lessonFormObjectives} items={isNew ? undefined : lessonData.objectives.slice()} sortable={true} page={this} />
                </div>
                <div className="form-item flex-col">
                    <div className="flex-row">
                        <div>
                            <label>Requirements for Teacher</label>
                            <h6>Software/Hardware items the teacher needs...</h6>
                            <span className="form-input-description"><em className="opac">If you want to include a link (URL), use the format:&nbsp;<span className="monospace"><small>[[Title||URL]]</small></span></em></span>
                        </div>                        
                        {Utility.renderButton('Copy from curriculum', 'creator-edit-lesson-teacher-requirements-copy-button', 'flex-end-item clear-shadowed-text-button bordered-text-button super2wide-text-button text-button button bottom-margin10', (e) => this.copyRequirementsFromCurriculumButtonClicked(true), {tabIndex: '-1'})}
                    </div>
                    <CreatorUtility.RequirementInputList ref={this.lessonFormTeacherRequirements} items={[...(isNew ? curriculumData : lessonData)['teacher-requirements'].map(i => { return {description: i, isOptional: false }}), ...(isNew ? curriculumData : lessonData)['teacher-requirements-optionals'].map(i => { return {description: i, isOptional: true }})]} isTeacherRquirement={true} sortable={true} page={this} />
                </div>
                <div className="form-item flex-col">
                    <div className="flex-row">
                        <div>
                            <label>Requirements for Students</label>
                            <h6>Software/Hardware items the students need...</h6>
                            <span className="form-input-description"><em className="opac">If you want to include a link (URL), use the format:&nbsp;<span className="monospace"><small>[[Title||URL]]</small></span></em></span>
                        </div>
                        {Utility.renderButton('Copy from curriculum', 'creator-edit-lesson-student-requirements-copy-button', 'flex-end-item clear-shadowed-text-button bordered-text-button super2wide-text-button text-button button bottom-margin10', (e) => this.copyRequirementsFromCurriculumButtonClicked(false), {tabIndex: '-1'})}
                    </div>
                    <CreatorUtility.RequirementInputList ref={this.lessonFormStudentRequirements} items={[...(isNew ? curriculumData : lessonData)['student-requirements'].map(i => { return {description: i, isOptional: false }}), ...(isNew ? curriculumData : lessonData)['student-requirements-optionals'].map(i => { return {description: i, isOptional: true }})]} isTeacherRquirement={false} sortable={true} page={this} />
                </div>
            </div>
        )
    }

    renderFormResources() {
        let isNew = this.isNew
        let lessonData = this.lessonData
        let slidesIsExternal = isNew || lessonData['slides-is-external']
        let resources = isNew || lessonData['other-resources']
        let resourceIsExternals = isNew || lessonData['other-resource-is-externals']
        let resourceIsTests = isNew || lessonData['other-resource-is-tests']
        let resourceIsAssignments = isNew || lessonData['other-resource-is-assignments']
        let slides = isNew || lessonData.slides 
        return (
            <div id="creator-edit-lesson-form-resources" className="form flex-col">
                <div className="form-item flex-col"> 
                    <label>Slides</label>
                    <h6>Lesson Slides</h6>
                    <span className="form-input-description"><em className="opac">Each lesson slide must include detailed <strong>teacher notes</strong> written inside speaker notes.</em></span>
                    <CreatorUtility.ResourceInputList ref={this.lessonFormSlides} items={(isNew ? [''] : [slides]).map((url) => {
                        return {description: 'Slides', url: url, isExternal: slidesIsExternal, isCloudStorage: (isNew || (slidesIsExternal && (!slides || CloudStorage.isCloudStorageURL(slides)))), isTest: false, isAssignment: false, isNew: isNew}
                     })} extraClasses={'text-input-list-unsortable'} sortable={false} growable={false} page={this} isSlides={true} hasCloudStorageLinkOption={true} />
                </div>
                <div className="form-item flex-col"> 
                    <label>Other Resources</label>
                    <h6>List resources (other than lesson slides) used by the activities in this lesson, such as <strong>tests</strong>, PDFs, links to <strong>instruction video</strong> of this lesson (on Udemy, YouTube, Vimeo, and so on), web links, other videos, etc.</h6>
                    <CreatorUtility.ResourceInputList ref={this.lessonFormOtherResources} items={isNew ? undefined : lessonData['other-resource-descriptions'].map((description, index) => {
                        let aUrl = resources[index]
                        let aResourceIsExternal = resourceIsExternals[index]                        
                        return {description: description, url: aUrl, isExternal: aResourceIsExternal, isCloudStorage: aResourceIsExternal && (!aUrl || CloudStorage.isCloudStorageURL(aUrl)), isTest: (resourceIsTests !== undefined && (resourceIsTests[index] === true)), isAssignment: (resourceIsAssignments !== undefined && (resourceIsAssignments[index] === true)), isNew: false}
                     })} sortable={true} page={this} hasCloudStorageLinkOption={true} />
                </div>
            </div>
        )
    }

    renderFormAssessments() {
        let isNew = this.isNew
        let lessonData = this.lessonData
        return (
            <div id="creator-edit-lesson-form-assessments" className="form flex-col">
                <div className="form-item flex-col"> 
                    <label>Assessment Strategy</label>
                    <h6>List your plans or items in the activities that are used for assessing student learning, such as Q/A discussions, quizzes, ...</h6>
                    <span className="form-input-description"><em className="opac">If you want to include a link (URL), use the format:&nbsp;<span className="monospace"><small>[[Title||URL]]</small></span></em></span>
                    <CreatorUtility.TextInputList ref={this.lessonFormAssessments} items={isNew ? undefined : lessonData.assessments.slice()} sortable={true} page={this} />
                </div>
            </div>
        )
    }

    renderFormTeacherNotes() {
        let isNew = this.isNew
        let lessonData = this.lessonData
        return (
            <div id="creator-edit-lesson-form-teacher-notes" className="form flex-col">
                <div className="form-item flex-col"> 
                    <label>Teacher Notes</label>
                    <h6>List any additional notes and tips you would like to leave for the teacher on how to deliver this lesson.</h6>
                    <span className="form-input-description"><em className="opac">Another great place to leave notes for the teacher is <strong>speaker notes</strong> in your lesson <strong>slides</strong></em>.</span>
                    <CreatorUtility.TextInputList ref={this.lessonFormTeacherNotes} items={isNew ? undefined : lessonData['teacher-notes'].slice()} sortable={true} page={this} />
                </div>
            </div>
        )
    }

    renderFormDescription() {
        return (
            <div id="creator-edit-lesson-form-description" className="form flex-col">
                <div className="form-item flex-col">
                    <label>Description</label>
                    <span className="form-input-description"><em className="opac">Explain the purpose of this lesson. Keep it short. Avoid repeating what's already stated elsewhere such as in Objectives and Key Concepts.</em></span>
                    <CreatorUtility.EditorWithHtmlImportExport id="lesson-form-description" ref={this.formDescriptionTextEditor} placeholder="Write a description of this lesson..." defaultContentHtml={undefined} />
                </div>
            </div>
        )
    }

    renderFormActivities() {
        let isNew = this.isNew
        let lessonData = this.lessonData
        let descriptions = isNew || lessonData['activity-descriptions']
        let durations = isNew || lessonData['activity-durations']
        return (
            <div id="creator-edit-lesson-form-objectives" className="form flex-col">                        
                <div className="form-item flex-col">
                    <label>Activities </label>
                    <h6>List a warmup activity and other activities in this lesson.</h6>
                    <span className="form-input-description"><em className="opac">These should correspond with pages in your lesson <strong>Slides</strong>.</em></span>
                    <span className="form-input-description"><em className="opac">If you want to include a link (URL) in the description, use the format:&nbsp;<span className="monospace"><small>[[Title||URL]]</small></span></em></span>
                    <ActivityInputList ref={this.lessonFormActivities} items={isNew ? undefined : lessonData['activity-titles'].map((title, index) => {
                        return {title: title, description: descriptions[index], duration: durations[index], isNew: false}
                     })} sortable={true} page={this} />
                </div>
            </div>
        )
    }
    
    renderBody() {
        if (this.state.dataFetched) {
            let curriculumData = this.curriculumData
            return (
                <div>
                    {this.isNew ? null : this.renderExternalIframes()}
                    <div id="creator-edit-lesson-body" className="flex-col section page-body">
                        <div id="creator-edit-lesson-top-section" className="page-body-top-listing section">
                            <div>                                
                                <div id="top-lesson-listing-info">
                                    <div>
                                        <i className="fas fa-angle-left row-padding-10"></i>
                                        {Utility.renderButton(this.curriculumData.title, 'top-lesson-listing-curriculum-link', 'text-button button', (e) => this.promptForSaveBeforeNavigatingAway('/creator/edit?subject=' + this.subject + '&id=' + this.id + '&random-key=' + curriculumData['random-key']))}
                                    </div>
                                    <br/><br/>
                                    <span  className="size18">Week {this.weekNumber}{curriculumData['num-lessons-per-week'] > 1 ? (' - Day ' + this.dayNumber) : ''}</span><br/><br/>
                                    <span  className="size18">Lesson {this.lessonNumber}</span>
                                    <h3 id="top-lesson-listing-section-title" className="size18">{curriculumData['section-titles'][this.sectionNumber - 1]}</h3>
                                    {Utility.renderButton(curriculumData['lesson-titles'][this.lessonNumber - 1], 'top-lesson-listing-view-link', 'button text-button h3size left-margin0', (e) => this.promptForSaveBeforeNavigatingAway('/creator/lesson?subject=' + curriculumData.subject + '&id=' + curriculumData.id + '&random-key=' + curriculumData['random-key'] + '&section=' + this.sectionNumber + '&week=' + this.weekNumber + '&day=' + this.dayNumber + '&lesson=' + this.lesson + '&number=' + this.lessonNumber))}
                                </div>
                                <h2 className="top-margin25 size31">{this.isNew ? 'Create' : 'Edit'} your lesson plan</h2>
                            </div>                            
                            <div id="creator-edit-lesson-submit" className="flex-col form-side-panel curriculum-listings-side-panel opac-background">
                                {Utility.renderButtonFromButton(<span><i className="fas fa-save"></i>&nbsp;&nbsp;&nbsp;Save lesson plan</span>, 'creator-edit-lesson-save-draft-button', 'wide-cta-text-button cta-text-button shadowed-text-button bordered-text-button text-button button', (e) => this.saveForm())}
                                {Utility.renderButtonFromButton(<span><i className="fas fa-glasses"></i>&nbsp;&nbsp;&nbsp;Check lesson for submission</span>, 'creator-edit-lesson-check-draft-button', 'wide-cta-text-button cta-text-button clear-shadowed-text-button bordered-text-button text-button button', this.checkForm.bind(this))}
                            </div>
                        </div>
                        {this.renderForm()}
                    </div>
                </div>
            )
        } else return Utility.renderPageLoading()
    }

    // *** A *** Some of curriculum info (course and each lesson descriptions) comes from external HTML files sitting in the fileserver. We get those content by having invisible iframes to those html files, whose scripts will asyncronously send (postMessage) to this page their content DOM as a string. 
    // Here we create those iframes to initiate this process, and in another place we will receive the sent messages and convert the string into DOM elements to place in the right positions in this page...:
    renderExternalIframes() {
        return Utility.renderExternalIframes(this, this, this.lessonData, false)
    }

    // *** B *** here we will handle/receive the sent messages and convert the string into DOM elements to place in the right positions in this page...:    
    handleReceivingContentFromExternalIframes() {
        Utility.handleReceivingContentFromExternalIframesForTextEditor(this, undefined)        
    }
}


// editor for an activity list where each activity has a title, description, and duration
export class ActivityInputList extends CreatorUtility.TextInputList {
    constructor(props) {
        super(props)        
        this.addMessage = 'Add a ' + (this.state.items.length === 0 ? 'warmup' : 'new') + ' activity...'
    }

    generateNewItem(defaultInputValue) {        
        return defaultInputValue || {title: '', description: '', duration: 0, isNew: true}
    }

    willChangeCount(numItemsToBe) {
        this.addMessage = 'Add a ' + (numItemsToBe === 0 ? 'warmup' : 'new') + ' activity...'
    }

    renderOneItem(index, key, value) {
      return (
        <ActivityInputListItem
          key={key}
          getKey={key}
          index={index}
          value={value}
          onDelete={(index) => this.handleDelete(index)}
          autoFocus={this.state.focus && index == this.state.items.length - 1}
          sortable={true}
          parent={this}
          ref={this.itemElements[index]}        
        />)
    }
}

// One item for UI for creating a list of 2 input texts:
export class ActivityInputListItem extends CreatorUtility.TextInputListItem  {

    constructor(props) {
        super(props)
        this.value = this.props.value
        this.isNew = this.value.isNew
        let lessonData = this.page.lessonData
        this.duration = this.isNew ? curriculumConfig.defaultLessonActivityDurationMinutes : lessonData['activity-durations'][this.index]
        this.descriptionInputElementRef = React.createRef()
        this.activityDurationOptionSelectElement = React.createRef()        
    }

    getValue() {
        return this.inputElement.current ? 
        {title: this.inputElement.current.value, description: this.descriptionInputElementRef.current.value, duration: this.getDuration(), isNew: this.isNew} : undefined
    }
    
    getDuration() { 
        let durationSelect = this.activityDurationOptionSelectElement.current
        return durationSelect ? parseInt(this.activityDurationOptionSelectElement.current.getValue(), 10) : this.duration
    }

    itemExtraClassNames() { return '' }

    renderInputComponent() {
        let index = this.index
        let activityPrefix = this.index === 0 ? 'warmup ' : ''
        let titlePlaceholder = 'Enter a title for the ' + activityPrefix + 'activity'
        let descriptionPlaceholder = 'Enter description of the ' + activityPrefix + 'activity'
        let duration = this.getDuration()        
        return (
            <div className="lesson-input-list complex-level-1-input-list-item text-input-list boxed-div flex-col">
                <label>Title</label>
                {this.props.autoFocus ?
                (<input placeholder={titlePlaceholder} defaultValue={this.props.value.title} ref={this.inputElement} autoFocus />) :
                (<input placeholder={titlePlaceholder} defaultValue={this.props.value.title} ref={this.inputElement} />)}
                <label>Description</label>
                <input placeholder={descriptionPlaceholder} defaultValue={this.props.value.description} ref={this.descriptionInputElementRef} />
                <label>Duration (in Minutes)</label>
                <div className="flex-row flex-center">
                    <Utility.OptionsSelect label={'' + duration} id={'lesson-form-activity-duration-' + index} key={'' + duration} containerExtraClasses={'form-select-div'} options={curriculumConfig.allowedLessonActivityDurationsMinutes} optionKeyToTitleFn={(idx, p) => { return p }} onChangeFn={undefined} defaultValue={duration} addDefault={false} ref={this.activityDurationOptionSelectElement} />
                </div>
            </div>
        )
    }   
}

export default CreatorEditLessonPlanPage
