/*
 API functions for talking to the backend database AWS DynamoDB:
*/

import * as Config from './config'
import * as Fileserver from './fileserver-api'
import * as Utility from './utility'

let AWS = require('aws-sdk')

const dbConfig = Config.dbConfig
const globals = Config.globals
const generalConfig = Config.pages.general
const homeConfig = Config.pages.home
const libraryConfig = Config.pages.library
const payConfig = Config.payConfig

// languge id to name map:
const languages = {'en': 'English'}
// flag is subject data fetched from database:
var subjectsDataFetched = false
// flag is each subject's topics data fetched from database:
var topicsDataFetched = false
// flag is curriculum data for all subjects fetched from database:
var popularAndLatestCurriculumsDataFetched = false
// flag is curriculum data for all queried curriculums are fetched from database:
var curriculumsDataFetched = false
// flag is each curriculum's lesson data for all queried lessons are fetched from database:
var curriculumLessonsDataFetched = {}
// flag to know (if there's a user session) have we gotten user data from database:
var userDataFetchRequested = false
// flag to know (if there's a creator user session) have we gotten creator user data from database:
var creatorUserDataFetchRequested = false
// flag to know (if there's a admin user session) have we gotten admin user data from database:
var adminUserDataFetchRequested = false
// dictionary of each curriculums data
var cachedAllCurriculums = {}
// list of popular curriculums regardless of subject:
var cachedAllPopularCurriculums = []
// list of popular curriculum units regardless of subject:
var cachedAllPopularCurriculumUnits = []
// list of latest curriculums regardless of subject:
var cachedAllLatestCurriculums = []
// list of latest curriculum units regardless of subject:
var cachedAllLatestCurriculumUnits = []
// list of curriculums returned by search
var cachedSearchResultCurriculums = []
// list of curriculum ids returned by search
var cachedSearchResultCurriculumIds = []
// list of curriculums returned by search after applying any filters that are turned on currently:
var cachedFilteredSearchResultCurriculums
// list of curriculum ids returned by search after applying any filters that are turned on currently:
var cachedFilteredSearchResultCurriculumIds
// fetched data of subjects:
var cachedSubjectsInfo = {}
// fetched data of proposed subjects:
var cachedProposedSubjectsInfo = undefined
// fetched data of proposed topics:
var cachedProposedTopicsInfo = undefined
// fetched data of proposed topics:
var cachedProposedLanguagesInfo = undefined
// fetched data of subjects:
var cachedCurriculumIdToSubjectMap = {}
// fetched data of subject topics:
var cachedTopicsInfo = {}
// fetched data of teachers:
var cachedTeachersInfo = {}
// fetched data of curriculum reviews:
var cachedReviewsInfo = {}
// fetched data of this users curriculum reviews:
var cachedUserReviewsInfo = {}
// fetched data of user:
var cachedUserInfo = undefined
// fetched data of admin user:
var cachedAdminUserInfo = undefined
// fetched data of creator user:
var cachedCreatorUserInfo = undefined
// fetched list of subjects:
var cachedSubjectsList = undefined
// fetched list of popular subjects:
var cachedPopularSubjectsList = undefined
// maps subject title to subject key:
var cachedSubjectTitleToKeyMap = {}
// maps topics title to topic key:
var cachedTopicTitleToKeyMap = {}
// cache of price info for curriculums:
var cachedCurriculumsPriceInfo = {}
// search results filters currently on:
export var searchResultsCurrentFilters = undefined
// Database reference:
let DynamoDB
// Database DynamoDB reserved attribute names:
const DynamoDB_reservedAttributeNames = ['name']
// Counters and prefixes for creating attribute variable names for DB operations:
var attributeNameValueVarCtr = 0

// initialize connection to database and retrieve subjects/curriculums data:
// databaseCurriculumsQuery specifies what query to use to get curriculums data for the current page
export function init() {  
  // skip init if done already:
  if (globals.databaseInitStarted) { return }
    globals.databaseInitStarted = true    
  console.log('database init started...')  
  // Init connection to database
  database_initConnection()  
  // Fetch the data for the user in session (if any) from the database:
  database_fetchUserData()
  globals.databaseInitEnded = true
  // Get all subjects info:
  database_fetchSubjectsData()
  // Get data curriculums as required by the landing page (for home page its popular curriculums, for others might be  curriculum whose id is in the url params, etc.:
  if (globals.currentPage) {    
    let searchQuery = globals.currentPage.getOnLoadCurriculumResultsQueryForThisPage()
    if (searchQuery !== undefined)
      database_fetchCurriculumsData(searchQuery)    
  }
}

// anything Database needs to do after parsing URL params. currently:
// - cache the curriculum id to subject map if url params are set:
export function processUrlParams() {
  // cache the curriculum id to subject map if url params are set:
  let id = globals.urlParams.id
  let subject = globals.urlParams.subject        
  if (id && subject)
      cachedCurriculumIdToSubjectMap[id] = subject
}

export function getLanguages() { return Object.keys(languages) }
// Get name of language from language id:
export function getLanguageTitle(language) { return languages[language] || language }
// if have fetched all subjects data:
export function isSubjectsDataFetched() { return subjectsDataFetched }
// if have fetched all subjects topics data:
export function isTopicsDataFetched() { return topicsDataFetched }
// if have fetched all curriculums data for all popular curriculums (shown in home page) or for searched results:
export function isPopularAndLatestCurriculumsDataFetched() { return popularAndLatestCurriculumsDataFetched }
// if have fetched all curriculums data for all queried curriculums queried by a given page e.g. searched results:
export function isCurriculumsDataFetched() { return curriculumsDataFetched }
// if have fetched curriculum data for curriculum with id:
export function isCurriculumDataFetchedForId(id) { return cachedAllCurriculums[id] !== undefined }
// if have fetched curriculum data for curriculum with id:
export function isLessonsDataFetchedForId(id) { return curriculumLessonsDataFetched[id] !== undefined }
// if have fetched teacher data for teacher with id:
export function isTeacherDataFetched(id) { return cachedTeachersInfo[id] !== undefined }
// if have fetched teacher data for teacher with id:
export function isReviewsDataFetched(id) { return cachedReviewsInfo[id] !== undefined }
// gets the list of ids within the given list whose data we have not fetched yet
export function getCurriculumIdsWithoutFetchedData(ids) { return ids.filter((id) => { return cachedAllCurriculums[id] === undefined }) }
// gets user wishlisted curriculums
export function getUserWishlistCurriculums() { return cachedUserInfo !== undefined ? cachedUserInfo.wishlist : [] }
// gets user profile image if any
export function getUserImageUrl() { return cachedUserInfo !== undefined ? cachedUserInfo.imageUrl : undefined }
// gets user bought curriculums
export function getUserBoughtCurriculums() { return cachedUserInfo !== undefined ? cachedUserInfo.bought : [] }
// gets user's wishlisted and bought curriculums
export function getUserLibraryCurriculums() { return cachedUserInfo !== undefined ? getUserWishlistCurriculums().concat(getUserBoughtCurriculums()) : [] }
// gets user bought curriculum purchase date:
export function getUserCurriculumPurchaseDate(id) { return getUserPurchasedCurriculumsData(id, 'bought-date') }
// gets user bought curriculum purchase date:
export function getUserCurriculumPurchaseIsDownloaded(id) { return getUserPurchasedCurriculumsData(id, 'bought-downloaded') }
// if have fetched  data for user in session:
export function isUserDataFetched() { return cachedUserInfo !== undefined }
// if have successfully fetched data for creator user in session:
export function isCreatorUserDataFetched() { return cachedCreatorUserInfo !== undefined }
// returns fetched data for creator user in session:
export function getCreatorUserData() { return cachedCreatorUserInfo }
// if have successfully fetched data for admin user in session:
export function isAdminUserDataFetched() { return cachedAdminUserInfo !== undefined }
// if have already requested to fetch data for user in session:
export function isUserDataFetchRequested() { return userDataFetchRequested }
// gets a list of all subjects with keys as values:
export function getSubjects() { return cachedSubjectsList }
// gets list of all popular subjects (download count > X) with keys as values:
export function getPopularSubjects() { return cachedPopularSubjectsList }
// gets default subject key:
export function getDefaultSubject() { return cachedPopularSubjectsList[0] }

// if have fetched curriculum data for all curriculum with ids:
export function isCurriculumDataFetchedForIds(ids) {
  for (var i = 0; i < ids.length; i++)
    if (cachedAllCurriculums[ids[i]] === undefined)
      return false  
  return true
}

export function getSubjectIcon(subject) {
    let res = cachedSubjectsInfo[subject]
    return res ? (res.iconUrl || '') : ''
}

export function getSubjectTitle(subject) {
  let res = cachedSubjectsInfo[subject]
  return res ? (res.title || '') : subject
}

export function getSubjectPrefix(subject) {
  let res = cachedSubjectsInfo[subject]
  return res ? (res['prefix'] || 'zz') : 'zz'
}

export function getSubjectTopics(subject) {
  let res = cachedSubjectsInfo[subject]
  return res ? (res.topics || []) : []
}

// is the given subject already in the subject table or is this a proposed new one:
export function isSubjectInDatabase(subject) {return cachedSubjectsInfo[subject] !== undefined }
// is the given topic already in the subject table or is this a proposed new one:
export function isTopicInDatabase(topic) { return cachedTopicsInfo[topic] !== undefined }
// is the given language already in the language table (currently here in code) or is this a proposed new one:
export function isLanguageInDatabase(language) { return languages[language] !== undefined }

// is the given topic in the given subjects already in the topics table, or is this a proposed new one:
export function isSubjectTopicInDatabase(subject, topic) {
  let res = cachedSubjectsInfo[subject]  
  return res !== undefined && res.topics !== undefined && res.topics.indexOf(topic) !== -1
}

export function getTopicTitle(topic) {
  let res = cachedTopicsInfo[topic]
  return res ? (res.title || topic) : topic
}

export function getSubjectKeyFromTitle(title) { return cachedSubjectTitleToKeyMap[title] }
export function getTopicKeyFromTitle(title) { return cachedTopicTitleToKeyMap[title] }

// Returns curriculum with id's data:
export function getTeacherData(id) { return cachedTeachersInfo[id] }

export function getTeacherCurriculums(teacher) {
  let res = cachedTeachersInfo[teacher] || {}
  return res['curriculums'] || []
}

export function getTeacherCurriculumsSubmitted(teacher) {
  let res = cachedTeachersInfo[teacher] || {}
  return res['curriculums-submitted'] || []
}

export function getTeacherCurriculumsDrafted(teacher) {
  let res = cachedTeachersInfo[teacher] || {}
  return res['curriculums-drafted'] || []
}

export function getTeacherName(teacher) {
  let res = cachedTeachersInfo[teacher] || {}
  return res['name'] || 'teacher name'
}

// returns reviews for curriculum with id:
export function getReviewsData(id) { return cachedReviewsInfo[id] }

// returns any review data for curriculum review previously submitted by the user:
export function getUserReviewForCurriculum(id) { return cachedUserReviewsInfo[id] }

// returns a list of info of cirriculums returned by search results (any current filters also applied to it):
export function getSearchResultCurriculums() {    
  return cachedFilteredSearchResultCurriculums || cachedSearchResultCurriculums
}

// Returns a list of info of cirriculums returned by search results (any current filters also applied to it):
export function getSearchResultCurriculumIds() {  
  return cachedFilteredSearchResultCurriculumIds || cachedSearchResultCurriculumIds
}

// initializes the searchResultsFilters to empty {} ready to add some filters:
export function resetSearchResultsCurrentFilters() { searchResultsCurrentFilters = {} } 

// unsets searchResultsFilters to meaning there are no current filters:
export function unsetSearchResultsCurrentFilters() {
  searchResultsCurrentFilters = undefined
  cachedFilteredSearchResultCurriculums = undefined
  cachedFilteredSearchResultCurriculumIds = undefined
} 

// Returns a list of info of each popular/latest cirriculum under a subject (or all subjects if subject is not passed)
export function getPopularOrLatestCurriculums(isPopularCurriculums, isUnitCurriculums) { return isPopularCurriculums ? getPopularCurriculums(isUnitCurriculums) : getLatestCurriculums(isUnitCurriculums) }

// Returns a list of info of each popular cirriculum under a subject (or all subjects if subject is not passed)
export function getPopularCurriculums(isUnitCurriculums) { return isUnitCurriculums ? cachedAllPopularCurriculumUnits :  cachedAllPopularCurriculums}

// Returns a list of info of each latest cirriculum under a subject (or all subjects if subject is not passed)
export function getLatestCurriculums(isUnitCurriculums) { return isUnitCurriculums ? cachedAllLatestCurriculumUnits :  cachedAllLatestCurriculums}

// Returns curriculum with id's data:
export function getCurriculumData(id) { return cachedAllCurriculums[id] }

// calculate curriculum current price and whether or not there's a promo:
export function getCurriculumPriceInfo(curriculumData) {
  let id = curriculumData.id
  let explicitPromoAdded = globals.urlParams.promo
  let cachingAllowed = !explicitPromoAdded && globals.localeDataFetched
  let cachedPriceInfo = cachedCurriculumsPriceInfo[id]  
  if ((cachedPriceInfo !== undefined) && cachingAllowed)
    return cachedPriceInfo
  let priceTier = curriculumData['price-tier']
  let promoCode = explicitPromoAdded || curriculumData['promo-code-current'] || false
  let promoCodes = curriculumData['promo-codes']
  let promoTiers = curriculumData['promo-tiers']
  let promoIndex = promoCode ? promoCodes.indexOf(promoCode) : -1
  let promoTier = promoIndex >= 0 ? promoTiers[promoIndex] : 0
  let hasPromo = promoCode && promoTier > 0    
  let origPrice = payConfig.priceTierPrices.local[priceTier]  
  let percentOff = payConfig.promoTierDiscountPercents[promoTier]
  let origPriceUSD = payConfig.priceTierPrices.USD[priceTier]
  let result = {hasPromo: hasPromo, promoCode: promoCode, price: hasPromo ? Math.ceil(origPrice * (100 - percentOff) / 100) : origPrice, origPrice: origPrice, percentOff: percentOff, priceUSD: hasPromo ? Math.ceil(origPriceUSD * (100 - percentOff) / 100) : origPriceUSD}
  if (cachingAllowed)
    cachedCurriculumsPriceInfo[id] = result
  return result
}

// Depending on a curriculum's grade or a grade range, generates the text showing the grade:
export function getCurriculumGradeTitle(curriculumData) {
  let minGrade = curriculumData['min-grade'], maxGrade = curriculumData['max-grade'], isGradeRange = curriculumData['is-grade-range'], isPreK = minGrade === 0
  let gradeTitle = isGradeRange ? 'Grades' : (isPreK ? '' : 'Grade')
  let gradeShown = '' + (isPreK ? 'Pre/K' : minGrade) + (isGradeRange ? ('-' + maxGrade) : '')
  return gradeTitle + ' ' + gradeShown
}

// Filters out from a curriculum list those which have been deactivated (removed from store) or set to private by the creator:
export function filterOutNonPublicCurriculums(curriculums) {
  return curriculums.filter(id => { 
    let curriculumData = cachedAllCurriculums[id]
    return (curriculumData !== undefined) && (curriculumData.active && !curriculumData.private)
  })
}

// Returns curriculum with id's data:
export function getLessonData(curriculum, lesson) {
  var result = undefined
  let curriculumData = cachedAllCurriculums[curriculum]
  if (curriculumData && curriculumData.lessonsData)
    result = curriculumData.lessonsData[lesson]
  return result
}

// Init connection to database:
export function database_initConnection() {
  DynamoDB = new AWS.DynamoDB()
}

// fetches curriculum data from database for specific list of ids given:
// if isSubmissionsTable flag is true, instead of live curriculums table, data from curriculums-updates table (submissions) will be fetched:
// if isDraftsTable flag is true, instead of live curriculums table, data from curriculums-drafts table (not submitted yet) will be fetched:
export function fetchCurriculumDataForIds(curriculumsWithoutFetchedData, isSubmissionsTable = false, isDraftsTable = false) {  
  let searchQuery = getCurriculumResultsQueryToFetchIds(curriculumsWithoutFetchedData)  
  if (searchQuery)
    database_fetchCurriculumsData(searchQuery, false, isSubmissionsTable, isDraftsTable)
}

// Home page search query to get all popular (download count >= X and rating >= 3) or latest (date created < 1 yr old) curriculums in any subject:
export function getOnLoadCurriculumResultsQueryForHomePage() {
  let attrNameParams = {'#nd': 'num-downloads', '#dc': 'date-created'}
  let attrValParams = {
      ':r': {N: '' + homeConfig.curriculumsPopularRatingThreshold},
      ':d': {N: '' + homeConfig.curriculumsPopularDownloadCountThreshold},
      ':c': {N: '' + (Utility.getEpochTime() - (homeConfig.curriculumsLatestCreationDateDaysThreshold * 24 * 60 * 60))}
    }
  let filterExpression = '(rating >= :r and #nd >= :d) or #dc >= :c'
  return {
      ExpressionAttributeNames: attrNameParams,
      ExpressionAttributeValues: attrValParams, 
      FilterExpression: filterExpression,
  }
}

// Default (including for home page) initial search query to run for a landing page (curr with id specified in the url params):
export function getOnLoadCurriculumResultsQueryForLandingPage() {
  let filter = undefined
  // if there's an id url parameter, also add that to the query as an 'or':
  let id = globals.urlParams.id
  let subject = globals.urlParams.subject  
  if (id) {
      // if both id and subject are set, we don't need a query, just a getItem:
      if (subject) {
        filter = {
          Key: {id: {S: id}, subject: {S: subject}}
        }
      } else {
        let attrValParams = {':i': {S: id}}
        let keyConditionExpression = 'id = :i'
        filter = {
          ExpressionAttributeValues: attrValParams, 
          KeyConditionExpression: keyConditionExpression,
      }
    }
  }
  return filter
}

// what initial search query to run to get curriculum search results list for this page.
// asking for all curriculums whose ids match one of the ids given 
// the result db operation is going to be a getItem, batchGetItem, query, or scan (given in order of preferance...):
export function getCurriculumResultsQueryToFetchIds(curriculumsWithoutFetchedData) {
  let count = curriculumsWithoutFetchedData.length
  if (count === 0)
    return undefined
  let filter = {}
  // check if we have cached the subject for each id asked. If so, we can do a batchGetItem (subject is a sort index attribute for live curriculums table). If not do a query or scan...:  
  let knowSubjectsForAllCurriculums = curriculumsWithoutFetchedData.filter(id =>  cachedCurriculumIdToSubjectMap[id] !== undefined).length === count  
  if (knowSubjectsForAllCurriculums) {
    let keys = curriculumsWithoutFetchedData.map(id => {
      return {id: {S: id}, subject: {S: cachedCurriculumIdToSubjectMap[id]}}
    })
    if (count === 1)
      filter.Key = keys[0]
    else
      filter.Keys = keys
  } else {    
    let attrValParams = {}, keyConditionExpression = ''
    var idCtr = 0
    curriculumsWithoutFetchedData.forEach((id) => {
      let currVar = ':id' + (idCtr++) 
      attrValParams[currVar] = {S: id}      
      keyConditionExpression += (idCtr === 1 ? '' : ' or ') + 'id = ' + currVar  
    })
    filter = {ExpressionAttributeValues: attrValParams}
    // if it's only one id we can query, otherwise need to scan!
    if (count === 1)
      filter.KeyConditionExpression = keyConditionExpression
    else 
      filter.FilterExpression = keyConditionExpression
  }  
  return filter
}

// What initial search query to run to get curriculum search results list for this page:
export function getCurriculumResultsQueryForSearch() {
  let attrValParams = {}, attrNameParams = undefined, filterExpression = undefined 
  let urlParams = globals.urlParams
  let s = urlParams.subject, t = urlParams.topic, q = urlParams.q
  if (!(s || t || q))
    return undefined
  if (s) {
    attrValParams[':s'] = {S: s.toLowerCase()}
    filterExpression = 'subject = :s'    
  }
  if (t) {
    attrValParams[':t'] = {S: t.toLowerCase()}
    filterExpression = (filterExpression ? (filterExpression + ' and ') : '') + 'topic = :t'
  }
  if (q) {
    attrNameParams = {'#t': 'title-lc','#st': 'subtitle-lc', '#tt': 'topic-title-lc', '#ks': 'search-terms'}
    attrValParams[':q'] = {S: q}    
    // currently checking: if query word is mentioned in title or subtitle or search terms (not description yet)    
    filterExpression = (filterExpression ? (filterExpression + ' and (') : '') + 'contains(#t, :q) or contains(#st, :q) or contains(#tt, :q) or contains(:q, #tt) or contains(subject, :q) or contains(:q, subject) or contains(:q, #ks[0]) or contains(#ks[0], :q) or contains(:q, #ks[1]) or contains(#ks[1], :q) or contains(:q, #ks[2]) or contains(#ks[2], :q) or contains(:q, #ks[3]) or contains(#ks[3], :q) or contains(:q, #ks[4]) or contains(#ks[4], :q)' + (filterExpression ? ')' : '')
  }
  return {
    ExpressionAttributeNames: attrNameParams,
    ExpressionAttributeValues: attrValParams,
    FilterExpression: filterExpression, 
  }
}

// fetches subjects table from the database
export function database_fetchSubjectsData() {
  database_scanTable(dbConfig.subjectsTable, undefined, undefined, undefined, undefined, undefined, 'fetching subjects info', function(data) {    
    cachedSubjectsList = []  
    data.Items.forEach(subjectInfo => {
      var subject = subjectInfo.subject.S      
      let subjectData = cacheFetchedData(dbConfig.subjectsTable, subjectInfo)
      subjectData.iconUrl = Fileserver.getSubjectIconUrl(subject, subjectData.icon)
      cachedSubjectsInfo[subject] = subjectData
      cachedSubjectTitleToKeyMap[subjectData.title] = subject
      cachedSubjectsList.push({subject: subject, 'sort-index': subjectData['sort-index'], 'num-downloads': subjectData['num-downloads']})
      
    })
    // sort the list of subjects by sort-index field:
    cachedSubjectsList.sort((s1, s2) => s1['sort-index'] - s2['sort-index'])
    // copy the subjects list to popular subjects list. sort the list of popular subjects based on download count:    
    cachedPopularSubjectsList = [...cachedSubjectsList]
    cachedPopularSubjectsList.sort((s1, s2) => s2['num-downloads'] - s1['num-downloads'])
    let popularSubjectsListCount = cachedPopularSubjectsList.length
    // calculate how many popular subjects to show:
    let numberOfPopularSubjectsToShow = Math.min(homeConfig.maxNumberOfPopularSubjectsShown, Math.max(homeConfig.minNumberOfPopularSubjectsShown, popularSubjectsListCount))
    cachedPopularSubjectsList.splice(numberOfPopularSubjectsToShow)
    cachedPopularSubjectsList = cachedPopularSubjectsList.map(i => i.subject)    
    cachedSubjectsList = cachedSubjectsList.map(i => i.subject)
    subjectsDataFetched = true
    // tell home page to render components who needed the database data:    
    globals.currentPage.subjectsDataFetched()    
  })
}

// fetches subjects table from the database
export function database_fetchTopicsData() {
  database_scanTable(dbConfig.topicsTable, undefined, undefined, undefined, undefined, undefined, 'fetching topics info', function(data) {        
    data.Items.forEach(topicInfo => {
      let topic = topicInfo.topic.S            
      cachedTopicsInfo[topic] = cacheFetchedData(dbConfig.topicsTable, topicInfo)
      let subject = topicInfo.subject.S
      let subjectInfo = cachedSubjectsInfo[subject]
      if (subjectInfo.topics === undefined)
        subjectInfo.topics = []
      subjectInfo.topics.push(topic) 
      let title = topicInfo.title.S
      cachedTopicTitleToKeyMap[title] = topic       
    })
    topicsDataFetched = true
    // tell home page to render components who needed the database data:    
    globals.currentPage.topicsDataFetched()
  })
}

// if there are any filters from search page filter panel turned on, apply them to cached search results:
export function applyFiltersUpdateToSearchResults(searchResultsComponent) {  
  cachedFilteredSearchResultCurriculums = cachedSearchResultCurriculums  
  let filteredList = []
  for (let filterName in searchResultsCurrentFilters) {                
    let onFilters = searchResultsCurrentFilters[filterName]    
    let filterFn = getFilterFunction(filterName, onFilters)        
    cachedFilteredSearchResultCurriculums.forEach((curriculumData) => {          
      if (filterFn(curriculumData, onFilters))
        filteredList.push(curriculumData)
    })
    cachedFilteredSearchResultCurriculums = filteredList
    filteredList = []
  }
  cachedFilteredSearchResultCurriculumIds = cachedFilteredSearchResultCurriculums.map(c => { return c.id })
  // trigger results section to rerender with new filter state and go back to first page of results:  
  if (searchResultsComponent)
    searchResultsComponent.reRender(0)
}

// returns the function that passes/rejects a give curriculum based on filter name:
export function getFilterFunction(filterName) {
  switch (filterName) {
    case 'type':
      return ((curriculumData, onFilters) => {        
          return onFilters[!curriculumData['single-unit']]
        }
      )
    case 'subject':
      return ((curriculumData, onFilters) => {        
          return onFilters[curriculumData.subject]
        }
      )
    case 'grade':
      return (
        (curriculumData, onFilters) => {
          var match = false
          for (let g of Object.keys(onFilters))
            if (g >= curriculumData['min-grade'] && g <= curriculumData['max-grade']) {
              match = true
              break
            }
          return match
        }
      )
    case 'rating':
      return (
        (curriculumData, onFilters) => {
          let lowestPassingRating = Math.min(...Object.keys(onFilters))
          return curriculumData.rating >= lowestPassingRating            
        }
      )
    case 'lesson-duration':
      return (
        (curriculumData, onFilters) => {
          let highestAllowedMinutes = Math.max(...Object.keys(onFilters))
          return curriculumData['num-minutes-per-lesson'] <= highestAllowedMinutes            
        }
      )
    case 'num-lessons':
      return (
        (curriculumData, onFilters) => {
          let lowestAllowedNumLessons = Math.min(...Object.keys(onFilters))
          return curriculumData['num-weeks'] * curriculumData['num-lessons-per-week'] >= lowestAllowedNumLessons            
        }
      )
    case 'language':
      return (
        (curriculumData, onFilters) => {          
          return onFilters[curriculumData['the-language']]
        }
      )
    default:
      return (
        (curriculumData, onFilters) => {
          return true
        }
      )
  }
}

// Applies a new sorting type to cached search results:
export function applySortTypeToSearchResults(sortType, searchResultsComponent) {  
  let sortingFn = getSortingFunction(sortType)
  cachedSearchResultCurriculums.sort(sortingFn)
  cachedSearchResultCurriculumIds = cachedSearchResultCurriculums.map(c => { return c.id })
  if (cachedFilteredSearchResultCurriculums) {
    cachedFilteredSearchResultCurriculums.sort(sortingFn)
    cachedFilteredSearchResultCurriculumIds = cachedFilteredSearchResultCurriculums.map(c => { return c.id })
  }
  // if this function is called because of user changing sort type UI, trigger results section to rerender with new filter state and go back to first page of results:  
  if (searchResultsComponent !== undefined)
    searchResultsComponent.reRender(0)
}

// returns the function that sorts a pair of curriculums based on sort type
export function getSortingFunction(sortType) {
  let getSortingFnByFieldNameDescending = field => {
    return (curriculum1Data, curriculum2Data) => (curriculum2Data[field] - curriculum1Data[field])
  }  
  switch (sortType) {
    // FIXME: relevant not implemented yet...
    case 'relevant': 
      return (curriculum1Data, curriculum2Data) => {          
          return false
        }
    case 'rating':
      return getSortingFnByFieldNameDescending('rating')
    case 'recent':
      return getSortingFnByFieldNameDescending('date-created')
    case 'price-lowest':
        return (curriculum1Data, curriculum2Data) => (getCurriculumPriceInfo(curriculum1Data).price - getCurriculumPriceInfo(curriculum2Data).price)
    case 'price-highest':
        return (curriculum1Data, curriculum2Data) => (getCurriculumPriceInfo(curriculum2Data).price - getCurriculumPriceInfo(curriculum1Data).price)
    case 'popular':
    default: // = 'popular':
      return getSortingFnByFieldNameDescending('num-downloads')
  }
}

// is the user in session has the given cirriculum in wishlist:
export function isCurriculumInUserWishlist(curriculum) {
  return cachedUserInfo !== undefined && cachedUserInfo.wishlist.includes(curriculum)
}

// is the user in session has the given cirriculum in wishlist:
export function isCurriculumInUserBoughtList(curriculum) {
  return cachedUserInfo !== undefined && cachedUserInfo.bought.includes(curriculum)
}

// assuming existing user session, will toggle on/off the curriculum id to/from wishlist (updating database):
export function updateWishlist(curriculum, subject, isAdd, dbUpdateCallbackFn) {
  if (cachedUserInfo !== undefined) {
    if (isAdd) {
      if (!cachedUserInfo.wishlist.includes(curriculum)) {        
        // update the user info cache as well as the database:
        cachedUserInfo.wishlist.unshift(curriculum)
        updateWishlistHelper([curriculum], [subject], true, undefined, dbUpdateCallbackFn)
      }
    } else {
      let idx = cachedUserInfo.wishlist.indexOf(curriculum)
      if (idx !== -1) {        
        // update the user info cache as well as the database:
        cachedUserInfo.wishlist.splice(idx, 1)
        updateWishlistHelper([curriculum], [subject], false, [idx], dbUpdateCallbackFn)
      }
    }
  }
}

function updateWishlistHelper(curriculums, subjects, isAdd, removeIndices, dbUpdateCallbackFn) {
  let wishlistSubjectsField = dbConfig.tableCurriculumToSubjectMappings[dbConfig.usersTable].wishlist
  var reservedAttrVarNameSubjects = getAttributeNameVarForDatabaseOperation(wishlistSubjectsField)    
  var expressionAttributeNames = {}
  expressionAttributeNames[reservedAttrVarNameSubjects] = wishlistSubjectsField
  let operations = isAdd ?
    [
      'wishlist = list_append(:valuesToTake0, wishlist)', 
      reservedAttrVarNameSubjects + ' = list_append(:valuesToTake1, ' + reservedAttrVarNameSubjects + ')'
    ] :
    [
      removeIndices.map(removeIndex => { return 'wishlist[' + removeIndex + ']' }).join(','),
      removeIndices.map(removeIndex => { return reservedAttrVarNameSubjects + '[' + removeIndex + ']' }).join(',')
    ]
  database_updateList(isAdd, dbConfig.usersTable, 'username', globals.sessionInfo.username, ['S', 'S'], operations, expressionAttributeNames, [curriculums, subjects], dbUpdateCallbackFn)
}

// adds curriculum id to shopping cart list (persistent on local storage):
export function addToCart(curriculum, subject) { updateCart(true, curriculum, subject) }
// remove curriculum id from shopping cart list (persistent on local storage):
export function removeFromCart(curriculum, subject) { updateCart(false, curriculum, subject) }

// add or remove to/from shopping cart list (persistent on local storage):
export function updateCart(isAdd, curriculum, subject) {
  let cartAndSubjects = getCart()
  let cart = cartAndSubjects.cart
  let hasIt = cart.includes(curriculum)
  let doIt = isAdd ? !hasIt : hasIt
  if (doIt) {
    let cartSubjects = cartAndSubjects.subjects
    if (isAdd) {
      cart.push(curriculum)
      cartSubjects.push(subject)
    } else {
      let removeIdx = cart.indexOf(curriculum)
      cart.splice(removeIdx, 1)
      cartSubjects.splice(removeIdx, 1)
    }
    updateCartFinish()
  } 
}

// empty shopping cart list (persistent on local storage):
export function emptyCart() {
  globals.cart = []
  globals['cart-subjects'] = []
  updateCartFinish()
}

// does last steps in updating the cart:
function updateCartFinish() {
  // update persistent cart list:
  if (globals.haveLocalStorage) {
    let cartValueForLocalStorage = JSON.stringify(globals.cart)
    let cartSubjectsValueForLocalStorage = JSON.stringify(globals['cart-subjects'])
    localStorage.setItem('termeric-cart', cartValueForLocalStorage)
    localStorage.setItem('termeric-cart-subjects', cartSubjectsValueForLocalStorage)
  }
  // update cart icons' number badge:
  [globals.currentPage.cartButton, globals.currentPage.cartButtonMobile].forEach(e => e.setState({count: globals.cart.length}))
}

// gets shopping cart (list of curriculum ids/subjects) (persistent on local storage):
export function getCart() {
  let cart, cartSubjects
  if (globals.cart) {
    cart = globals.cart
    cartSubjects = globals['cart-subjects']
  } else {
    if (globals.haveLocalStorage) {
      cart = localStorage.getItem('termeric-cart')
      cart = cart ? JSON.parse(cart) : []
      cartSubjects = localStorage.getItem('termeric-cart-subjects')
      cartSubjects = cartSubjects ? JSON.parse(cartSubjects) : []
      // also cache mapping of curriculum id -> subject to help us fetch curriculum data using batchGetItem instead of scan...:
      cart.forEach((curriculum, index) => {
        let subject = cartSubjects[index]
        if (subject && cachedCurriculumIdToSubjectMap[curriculum] === undefined)
            cachedCurriculumIdToSubjectMap[curriculum] = subject
      })
    } else {
      cart = []
      cartSubjects = []
    }    
    globals.cart = cart
    globals['cart-subjects'] = cartSubjects
  }
  return {cart: cart, subjects: cartSubjects}
}

// gets shopping cart (list of curriculum ids) count:
export function getCartCount() {
  return getCart().cart.length
}

// gets shopping cart (list of curriculum ids) count:
export function isInCart(curriculum) {
  let cart = getCart().cart
  return cart.includes(curriculum)
}

// Filters out from the cart those which have been deactivated (removed from store) by the creator:
export function filterOutDeactivatedCurriculumsFromCart() {
  let removeIdxs = []
  globals.cart = globals.cart.filter((id, idx) => { 
    let curriculumData = cachedAllCurriculums[id]
    let isActive = curriculumData !== undefined && curriculumData.active
    if (!isActive)
      removeIdxs.push(idx)
    return isActive
  })
  Utility.removeIndicesFromList(globals['cart-subjects'], removeIdxs)  
}

// if user is signed-in any curriculums already bought need to be removed from cart:
export function removeFromCartBoughtItems() {
  // if user is signed-in any curriculums already bought don't need to be in cart:
  if (cachedUserInfo !== undefined) {      
    cachedUserInfo.bought.forEach(c => {
      if (isInCart(c))
        updateCart(false, c)
    })      
  }
}

// assuming existing user session, will checkout/purchase items in shopping cart, moving curriculums into users bought list in the db users table, then empty cart, remove any that were previously part of user's wishlist:
// it will also put an entry in the checkout queue table for lambdas to pick it up and process this sale: updating download counts of curriculums, teachers, and anything else...
// called once payment is successful...
// checkoutData = {transactionId: , curriculums: , curriculumSubjects: , promoCodoes: , pricesLocal: pricesUSD: , total: {USD: , local: } }
export function database_checkoutCart(checkoutData, onSuccessFn, onFailureFn) {  
  if (cachedUserInfo === undefined)
    return
  let subjectFields = dbConfig.tableCurriculumToSubjectMappings[dbConfig.usersTable]
  let boughtSubjectsField = subjectFields.bought
  let wishlistSubjectsField = subjectFields.wishlist
  let cart = checkoutData.curriculums
  let cartSubjects = checkoutData.curriculumSubjects
  let promoCodes = checkoutData.promoCodes
  let boughtDate = Utility.getEpochTime()
  let boughtDateStr = '' + boughtDate
  let finalOnSuccessFn = (e) => {    
    let wishlist = cachedUserInfo.wishlist
    // 3. remove curriculums from wishlist if there were there:
    let removeIdxs = []
    cart.forEach(c => { 
        let i = wishlist.indexOf(c)
        if (i !== -1)
          removeIdxs.push(i)
    })
    if (removeIdxs.length > 0) {
      // remove them from db:
      updateWishlistHelper(cart, cartSubjects, false, removeIdxs)
      // remove them from local var:
      Utility.removeIndicesFromList(cachedUserInfo.wishlist, removeIdxs)
      Utility.removeIndicesFromList(cachedUserInfo[wishlistSubjectsField], removeIdxs)
    } 
    // 4. add curriculums to cached user bought curriculums list
    cart.forEach((c, index) => { 
      cachedUserInfo.bought.unshift(c)
      cachedUserInfo[boughtSubjectsField].unshift(cartSubjects[index])
      cachedUserInfo['bought-date'].unshift(boughtDate)
      cachedUserInfo['bought-downloaded'].unshift(false)
    })
    // 5. empty cart:
    emptyCart()
    // 6. run any other callback fn supplied by the caller:  
    if (onSuccessFn !== undefined)
      onSuccessFn()
  }
  let putEntryOnCheckoutQueueTableFn = () => {
    // 2. put an entry on the checkout queue table so that lambda functions update download counts for the curriculums and teachers in the cart:    
    database_putItem(dbConfig.checkoutQueueTable, {username: {S: globals.sessionInfo.username}, 'date-purchased': {N: boughtDateStr}, 'transaction-id': {S: checkoutData.transactionId}, 'curriculums': {L: cart.map(c => {return {S: c}})}, subjects: {L: cartSubjects.map(s => {return {S: s}})}, 'promo-codes': {L: promoCodes.map(c => {return {S: c}})}, 'prices-local': {L: checkoutData.pricesLocal.map(p => {return {N: '' + p}})}, 'prices-usd': {L: checkoutData.pricesUSD.map(p => {return {N: '' + p}})}, 'total-price-usd': {N: '' + checkoutData.total.USD}, 'total-price-local': {N: '' + checkoutData.total.local}, country: {S: payConfig.country}, 'currency-symbol': {S: payConfig.currencySymbol}}, 'marking checkout completion', finalOnSuccessFn, onFailureFn)
  }
  let reservedAttrVarNameSubjects = getAttributeNameVarForDatabaseOperation(boughtSubjectsField)
  let reservedAttrVarNameBoughtDate = getAttributeNameVarForDatabaseOperation('bought-date')
  let reservedAttrVarNameBoughtDownloaded = getAttributeNameVarForDatabaseOperation('bought-downloaded')
  let expressionAttributeNames = {}    
  expressionAttributeNames[reservedAttrVarNameSubjects] = boughtSubjectsField
  expressionAttributeNames[reservedAttrVarNameBoughtDate] = 'bought-date'
  expressionAttributeNames[reservedAttrVarNameBoughtDownloaded] = 'bought-downloaded'
  let expressionAttributeListItemTypes = [
    'S',    // bought
    'S',    // bought-subjects
    'N',    // bought-date
    'BOOL'  // bought-downloaded
  ]
  let operations = [
    'bought = list_append(:valuesToTake0, bought)', 
    reservedAttrVarNameSubjects + ' = list_append(:valuesToTake1, ' + reservedAttrVarNameSubjects + ')',
    reservedAttrVarNameBoughtDate + ' = list_append(:valuesToTake2, ' + reservedAttrVarNameBoughtDate + ')',
    reservedAttrVarNameBoughtDownloaded + ' = list_append(:valuesToTake3, ' + reservedAttrVarNameBoughtDownloaded + ')',
  ]
  // 1. update list of purchased curriculums by for the user:
  let count = globals.cart.length  
  database_updateList(true, dbConfig.usersTable, 'username', globals.sessionInfo.username, expressionAttributeListItemTypes, operations, expressionAttributeNames, [globals.cart, globals['cart-subjects'], Array(count).fill().map(i => boughtDateStr), Array(count).fill().map(i => false)], putEntryOnCheckoutQueueTableFn, onFailureFn)
}
  
// is the purchased curriculum's money back guarantee still valid:
export function isPurchasedCurriculumMoneyBackGuaranteeValid(id) {  
    let purchaseDate = getUserCurriculumPurchaseDate(id)
    return purchaseDate && (Utility.numberOfDaysBetweenEpochTimes(Utility.getEpochTime(), purchaseDate) <= libraryConfig.numberOfDaysBeforeMoneyBackGuaranteeExpiresDays)
}

// gets user bought curriculums specific data:
function getUserPurchasedCurriculumsData(id, field) { 
  let item = undefined
  if (cachedUserInfo !== undefined) {
    let idx = cachedUserInfo.bought.indexOf(id)
    if (idx >= 0) {
      let data = cachedUserInfo[field]
      if (data)
        item = data[idx]
    }
  }
  return item
}

// In the absense of local storage, we call this to get checkout session token so that upon return from external payment session we can verify the return param matches the stored token...:
export function getUserCheckoutToken() { return cachedUserInfo !== undefined ? cachedUserInfo['checkout-token'] : undefined }

// marks the bought-downloaded field for a given purchased curriculum as true:
export function database_markUserBoughtCurriculumAsDownloaded(id) {
  if (cachedUserInfo !== undefined) {
    let idx = cachedUserInfo.bought.indexOf(id)
    if (idx >= 0) {
      if (!cachedUserInfo['bought-downloaded'][idx]) {
        database_updateItem(dbConfig.usersTable, {username: {S: globals.sessionInfo.username}}, 'set #bd[' + idx + '] = :true', {'#bd': 'bought-downloaded'}, {':true': {BOOL: true}}, 'marking curriculum as downloaded', () => { cachedUserInfo['bought-downloaded'][idx] = true })
      } 
    }
  }
}

// FIXME: use Stripe webhook to receive checkout.session.completed event to verify payment was successful, instead of manual token verification below:
// In the absense of local storage, we call this to store checkout session token so that upon return from external payment session we can verify the return param matches the stored token...:
export async function database_storeUserCheckoutToken(token) {
  // FIXME: add error handling...
  try {
    return await database_sync_updateItem(dbConfig.usersTable, {username: {S: globals.sessionInfo.username}}, 'set #f = :t', {'#f': 'checkout-token'}, {':t': {S: token}})
  } catch (error) {
    return {error: error}
  }
}

// is this a curriculum in which (published/submissions/drafts) table?
export function getCurriculumDatabaseTable(curriculumData) {
  return curriculumData['in-drafts'] ? dbConfig.curriculumsDraftsTable : 
    (curriculumData['in-submissions'] ? dbConfig.curriculumsSubmissionsTable :
    dbConfig.curriculumsTable)
}

// is this a curriculum in which (published/submissions/drafts) table?
export function getCurriculumDatabaseUpdateTable(curriculumData, fileserverCleanupNeeded) {
  return (curriculumData['in-drafts'] && !fileserverCleanupNeeded) ? dbConfig.curriculumsDraftsTable : 
    ((curriculumData['in-submissions'] && !fileserverCleanupNeeded) ? dbConfig.curriculumsSubmissionsTable :
    (isAdminUserDataFetched() ? dbConfig.curriculumsTable :  dbConfig.curriculumsUpdatesTable))
}

// is this a lesson in which (published/submissions) table?
export function getLessonDatabaseUpdateTable(curriculumData, fileserverCleanupNeeded) {
  return (curriculumData['in-productions'] || fileserverCleanupNeeded) ? dbConfig.lessonsUpdatesTable : dbConfig.lessonsSubmissionsTable
}

// in which list (field name) in teachers table is the curriculum:
export function getCurriculumTeacherCurriculumsListName(curriculumData) {
  return 'curriculums' + (curriculumData['in-drafts'] ? '-drafted' : 
      (curriculumData['in-submissions'] ? '-submitted' : ''))
}

// get which table the curriculu's lessons are in?
export function getLessonsDatabaseTable(curriculumData) {
  return (curriculumData['in-drafts'] || curriculumData['in-submissions']) ?
      dbConfig.lessonsSubmissionsTable : dbConfig.lessonsTable  
}

// fetches curriculums table from the database (based on any optional scan/query filter for key and other attributes):
// (home page sets isPopularsAndLatestQuery = true meaning it's fetching popular/latest curriculums to list on home page...)
export function database_fetchCurriculumsData(filter, isPopularsAndLatestQuery, isSubmissionsTable = false, isDraftsTable = false) {
  curriculumsDataFetched = false  
  cachedSearchResultCurriculums = []
  cachedSearchResultCurriculumIds = []
  var isGetItem = false, isBatchGetItem = false, expressionAttributeNames = undefined, expressionAttributeValues = undefined, keyConditionExpression = undefined, filterExpression = undefined, otherParams = undefined, keyParams = undefined
  //console.log(filter)
  if (filter !== undefined) {
    // teacher of the curriculum must be the user him/herself or else the user must be an admin:
    let teacher = Utility.isUserSignedIn() ? (isAdminUserDataFetched() && globals.urlParams.teacher !== undefined ? globals.urlParams.teacher : globals.sessionInfo.username) : undefined    
    // check if it's a simple getItem, or a batch getItem, or a query. or a scan:
    keyParams = filter.Keys
    isBatchGetItem = keyParams !== undefined
    if (!isBatchGetItem) {
      keyParams = filter.Key
      isGetItem = keyParams !== undefined
    }
    if (isGetItem || isBatchGetItem) {      
      // table keys for submission/drafts tables are different. change the filter.Key here:
      if (isSubmissionsTable || isDraftsTable) {
        let keys = isGetItem ? [keyParams] : keyParams
        keys.forEach(k => {
          delete k.subject
          k.teacher = {S: teacher}
        })
      }
    } else {
      expressionAttributeNames = filter.ExpressionAttributeNames
      expressionAttributeValues = filter.ExpressionAttributeValues
      filterExpression = filter.FilterExpression
      keyConditionExpression = filter.KeyConditionExpression      
      // add filter to only get submissions by current teacher and also only get new-submission entries
      if (isSubmissionsTable || isDraftsTable) {        
        expressionAttributeValues[':t'] = {S: teacher}
        keyConditionExpression = 'teacher = :t' + (keyConditionExpression ? (' and ' + keyConditionExpression) : '')
      }
    }
  }
  let curriculumsTable = isSubmissionsTable ? dbConfig.curriculumsSubmissionsTable : (isDraftsTable ? dbConfig.curriculumsDraftsTable : dbConfig.curriculumsTable)  
  if (isPopularsAndLatestQuery)
    otherParams = {Limit: homeConfig.maxNumberOfPopularCurriculumsToShow}
  let databaseOperationOnSuccessFn = (data) => { 
    processFetchedCurriculumsData(data, isGetItem, isPopularsAndLatestQuery, isDraftsTable, isSubmissionsTable)
  }
  // run a getItem, query, or scan depending on the case we have:
  if (isGetItem || isBatchGetItem) {
    let databaseOperationFn = isGetItem ? database_getItem : database_batchGetItem
    databaseOperationFn(curriculumsTable, keyParams, 'fetching curriculums info', databaseOperationOnSuccessFn)    
  } else {
    let databaseOperationFn = keyConditionExpression === undefined ? database_scanTable : database_queryTable
    databaseOperationFn(curriculumsTable, expressionAttributeNames, expressionAttributeValues,
    keyConditionExpression, filterExpression, otherParams, 'fetching curriculums info', databaseOperationOnSuccessFn)
  }
}

// helper function for fetchCurriculumsData above: the callback for data returned from the db:
function processFetchedCurriculumsData(data, isGetItem, isPopularsAndLatestQuery, isDraftsTable = false, isSubmissionsTable = false) {
  let items = isGetItem ? (data.Item ? [data.Item] : []) : data.Items
  //console.log('fetched curriculums', items)
  items.forEach(curriculumInfo => {    
    let curriculumData = cacheFetchedData(dbConfig.curriculumsTable, curriculumInfo) 
    let id = curriculumData.id
    curriculumData['in-productions'] = !(isDraftsTable || isSubmissionsTable)
    curriculumData['in-submissions'] = isSubmissionsTable
    curriculumData['in-drafts'] = isDraftsTable
    curriculumData['the-language'] = curriculumData['the-language'] || generalConfig.defaultLanguage
    // delay setting the price in case locale currency info hasn't come yet:
    curriculumData.rating = parseFloat(curriculumInfo.rating.N)
    curriculumData['is-grade-range'] = curriculumData.grade.length > 1
    curriculumData['min-grade'] = curriculumData.grade[0]
    curriculumData['max-grade'] = curriculumData.grade[curriculumData.grade.length > 1 ? 1 : 0]
    curriculumData['num-lessons'] = curriculumData.lessons.length
    curriculumData.lessonsData = {}
    Fileserver.setCurriculumDataFullUrls(id, curriculumData)    
    cachedAllCurriculums[id] = curriculumData
    if (isPopularsAndLatestQuery) {
      // Cache popular curriculums (rating & num-downloads above a threshold)
      if (curriculumData.rating >= homeConfig.curriculumsPopularRatingThreshold
          && curriculumData['num-downloads'] >= homeConfig.curriculumsPopularDownloadCountThreshold) {
        (curriculumData['single-unit'] ? cachedAllPopularCurriculumUnits : cachedAllPopularCurriculums).push(curriculumData);
        (curriculumData['single-unit'] ? cachedAllLatestCurriculumUnits : cachedAllLatestCurriculums).push(curriculumData)
      }
    } else {
      // don't add inactive curr results (e.g. curriculums in review):
      if (curriculumData.active && !curriculumData.private) {        
        cachedSearchResultCurriculums.push(curriculumData)
        cachedSearchResultCurriculumIds.push(curriculumData.id)
      }
    }
  })
  // set flags and notify to the caller the fetch is done:
  if (isPopularsAndLatestQuery)
    popularAndLatestCurriculumsDataFetched = true
  else
    curriculumsDataFetched = true
  // notify landing page to render components who needed the database data: 
  if (isPopularsAndLatestQuery) {
    // sort them in order of download count:    
    cachedAllPopularCurriculums.sort((s1, s2) => s2['num-downloads'] - s1['num-downloads'])
    cachedAllPopularCurriculumUnits.sort((s1, s2) => s2['num-downloads'] - s1['num-downloads'])
    // sort them in order of latest date:
    cachedAllLatestCurriculums.sort((s1, s2) => s2['date-created'] - s1['date-created'])
    cachedAllLatestCurriculumUnits.sort((s1, s2) => s2['date-created'] - s1['date-created'])
    // notify current page popular curriculums data fetched:
    globals.currentPage.popularAndLatestCurriculumsDataFetched()
  } else {
    // special work for search page:
    if (globals.currentPage.isSearchPage) {
      // cache searched topic title (to avoid having to get from topics table):      
      if (items.length > 0) {
        let firstCurriculumData = items[0]
        let firstCurriculumTopic = firstCurriculumData.topic.S
        if (cachedTopicsInfo[firstCurriculumTopic] === undefined)
          cachedTopicsInfo[firstCurriculumTopic] = {title: firstCurriculumData['topic-title'].S}
      }
      // apply any on filters to new search results if this is search page:
      applyFiltersUpdateToSearchResults()      
    }
    // notify current page curriculums data fetched:
    globals.currentPage.curriculumsDataFetched()
  }  
}

// fetches a teacher's info from teachers table from the database
// optionally also tried to check teacher submissions table if entry not found in teachers table
export function database_fetchTeacherData(id, alsoCheckSubmissionsTable, table = dbConfig.teachersTable, onSuccessFn = undefined) {
  let finalOnSuccessFn = () => {
    if (onSuccessFn !== undefined)
      onSuccessFn()
    else
      globals.currentPage.teacherDataFetched()
  }
  // don't query database if have already fetched it:
  if (cachedTeachersInfo[id] !== undefined) {
    finalOnSuccessFn()
    return
  }
  database_getItem(table, {id: {S: id}}, 'fetching teacher info', function(data) {
      if (data.Item) {
        let items = data.Item
        let teacherData = cacheFetchedData(dbConfig.teachersTable, items)        
        var inSubmissions = table === dbConfig.teachersSubmissionsTable
        var inProductions = !inSubmissions
        teacherData['in-submissions'] = inSubmissions
        teacherData['in-productions'] = inProductions        
        teacherData.imageUrl = Fileserver.getTeacherImageUrl(id, inProductions, false, teacherData.image)
        cachedTeachersInfo[id] = teacherData
      } else {
        if (alsoCheckSubmissionsTable) {
          return database_fetchTeacherData(id, false, dbConfig.teachersSubmissionsTable)
        } else {
          //console.log('No teacher found with id', id)
        }
      }
      finalOnSuccessFn()
  })
}

// fetches all reviews for a given curriculum id from reviews table from the database
export function database_fetchReviewsDataForCurriculum(id) {
  // don't query database if have already fetched it:
  if (cachedReviewsInfo[id] !== undefined) {
    globals.currentPage.reviewsDataFetched()
    return
  }
  let expressionAttributeValues = {
    ':i': {
      S: id
    }, ':empty': {
      S: ''
    }
  }  
  let keyConditionExpression = 'curriculum = :i'
  let filterExpression = 'NOT (review = :empty)'
  return database_fetchReviewsData({ExpressionAttributeValues: expressionAttributeValues, KeyConditionExpression: keyConditionExpression, FilterExpression: filterExpression})
}

// fetches all reviews based on the given filter, from reviews table from the database:
// (params filter for optional filters on key attributes and other attributes...)
export function database_fetchReviewsData(filter = undefined) {    
  var expressionAttributeNames = undefined, expressionAttributeValues = undefined, keyConditionExpression = undefined, filterExpression = undefined
  if (filter !== undefined) {
    expressionAttributeNames = filter.ExpressionAttributeNames
    expressionAttributeValues = filter.ExpressionAttributeValues
    keyConditionExpression = filter.KeyConditionExpression
    filterExpression = filter.FilterExpression
  }
  let reviewsTable = dbConfig.reviewsTable
  let userInSession = globals.sessionInfo && globals.sessionInfo.username !== undefined
  let user = userInSession ? globals.sessionInfo.username : undefined
  database_queryTable(reviewsTable, expressionAttributeNames, expressionAttributeValues, keyConditionExpression, filterExpression, undefined, 'fetching reviews info', function(data) {
    data.Items.forEach(reviewInfo => {
      let reviewData = cacheFetchedData(reviewsTable, reviewInfo)      
      reviewData.rating = parseFloat(reviewInfo.rating.N)
      let curriculum = reviewInfo.curriculum.S
      if (cachedReviewsInfo[curriculum] === undefined)
        cachedReviewsInfo[curriculum] = []
      cachedReviewsInfo[curriculum].push(reviewData)
      // also cache here if happens to be a review by the current user (if in session):      
      if (userInSession && reviewData.reviewer === user)
        cachedUserReviewsInfo[curriculum] = reviewData
    })
    globals.currentPage.reviewsDataFetched()  
  })
}

// fetches the user's review info for a given curriculum from reviews table from the database
// optionally also tried to check reviews submissions table if entry not found in reviews table
export function database_fetchUserReviewDataForCurriculum(curriculum, alsoCheckSubmissionsTable, table) {
  let user = globals.sessionInfo.username
  // don't query database if have already fetched it:
  if (cachedUserReviewsInfo[curriculum] !== undefined) {
    globals.currentPage.userReviewForCurriculumFetched()
    return
  }
  database_getItem(table || dbConfig.reviewsTable, {reviewer: {S: user}, curriculum: {S: curriculum}}, 'fetching review info', function(data) {
      if (data.Item) {
        let items = data.Item      
        let reviewData = cacheFetchedData(dbConfig.reviewsTable, items)        
        if (cachedReviewsInfo[curriculum] === undefined)
          cachedReviewsInfo[curriculum] = []
        cachedReviewsInfo[curriculum].push(reviewData)
        cachedUserReviewsInfo[curriculum] = reviewData
      } else {
        if (alsoCheckSubmissionsTable) {
          return database_fetchUserReviewDataForCurriculum(curriculum, false, dbConfig.reviewsSubmissionsTable)
        }
      }
      globals.currentPage.userReviewForCurriculumFetched()
  })
}

// Fetch the data for the user in session (if any) from the database:
export function database_fetchUserData() {
  let sessionInfo = globals.sessionInfo
  if (!sessionInfo) return  
  // don't query database if have already fetched it:
  if (cachedUserInfo !== undefined) {
    globals.currentPage.userDataFetched()
    return
  }
  if (userDataFetchRequested)
    return
  userDataFetchRequested = true
  let username = sessionInfo.username    
  database_getItem(dbConfig.usersTable, {username: {S: username}}, 'fetching user info', function(data) {
    if (data.Item === undefined) {
      // this is a new user. add a new entry in the database:      
      console.log('Adding new user info in the db...')//, username)
      data.Item = {
        username: {S: username},
        name: {S: sessionInfo.userName},
        email: {S: sessionInfo.userEmail},
        bought: {L: []},
        'bought-subjects': {L: []},
        'bought-date': {L: []},
        'bought-downloaded': {L: []},
        wishlist: {L: []},
        'wishlist-subjects': {L: []},
        'checkout-token': {S: ''}
      }
      database_putItem(dbConfig.usersTable, data.Item, 'adding new user info')
    }
    cachedUserInfo = cacheFetchedData(dbConfig.usersTable, data.Item)
    // set up full url path for any teacher profile image:
    if (cachedUserInfo.image)
      cachedUserInfo.imageUrl = Fileserver.getTeacherImageUrl(username, true, false, cachedUserInfo.image)    
    removeFromCartBoughtItems()
    globals.currentPage.userDataFetched()    
  })
}

// Fetch the data for the creator user in session (if any) from the database:
export function database_fetchCreatorUserData() {
  let sessionInfo = globals.sessionInfo
  if (!sessionInfo) return  
  // don't query database if have already fetched it:
  if (cachedCreatorUserInfo !== undefined) {
    globals.currentPage.creatorUserDataFetched()
    return
  }
  if (creatorUserDataFetchRequested)
    return
  creatorUserDataFetchRequested = true
  let username = sessionInfo.username
  let operationEndedFn = () => {
    globals.currentPage.creatorUserDataFetched()
  }
  database_getItem(dbConfig.creatorsTable, {username: {S: username}}, 'fetching creator user info', function(data) {
    if (data.Item !== undefined) {
      cachedCreatorUserInfo = cacheFetchedData(dbConfig.creatorsTable, data.Item)        
    }
    operationEndedFn()
  }, operationEndedFn)
}

// parses the (typed) data item received from the database and caches it for the given table's cach variable:
function cacheFetchedData(table, data) {
  let cachedData = {}
  let fieldTypes = dbConfig.tableFields[table]  
  Object.keys(fieldTypes).forEach(field => {
    let fieldType = fieldTypes[field]
    let isListType = Array.isArray(fieldType)
    let dataType
    if (isListType) {
      dataType = fieldType[1]
      fieldType = 'L'
    }
    //console.log('looking', table, field, fieldType, data[field])
    let typedFieldVal = data[field]
    // default value for primitive types:
    let fieldVal = typedFieldVal === undefined ? '' : typedFieldVal[fieldType]
    if (fieldType === 'N')
      fieldVal = parseInt(fieldVal, 10)
    cachedData[field] = fieldVal
    if (isListType) {
      // default value for lists:
      if (typedFieldVal === undefined)
        cachedData[field] = []
      cachedData[field] = cachedData[field].map(typedVal => { 
        let val = typedVal[dataType] 
        if (dataType === 'N')
          val = parseInt(val, 10)
        return val
      })
    }
  })
  // if table has fields that correspond between curriculum id & subject: cache the curriculum -> subject mapping to help us fetchCurriculumData using getItem later on... (since subject is part of keys for the live curriculum table...):
  let fieldsWithCurriculumToSubjectMapping = dbConfig.tableCurriculumToSubjectMappings[table]  
  if (fieldsWithCurriculumToSubjectMapping !== undefined) {
    Object.keys(fieldsWithCurriculumToSubjectMapping).forEach(field => {
      let fieldType = fieldTypes[field]
      let isListType = Array.isArray(fieldType)
      let subjectField = fieldsWithCurriculumToSubjectMapping[field]      
      let fieldVal = cachedData[field]
      if (isListType) {
        let subjects = cachedData[subjectField]
        fieldVal.forEach((curriculum, index) => {
          let subject = subjects[index]
          if (subject && cachedCurriculumIdToSubjectMap[curriculum] === undefined)
            cachedCurriculumIdToSubjectMap[curriculum] = subject
        })
      } else {
        let curriculum = fieldVal
        let subject = cachedData[subjectField]
        if (subject && cachedCurriculumIdToSubjectMap[curriculum] === undefined)
          cachedCurriculumIdToSubjectMap[curriculum] = subject
      }
    })    
  }
  return cachedData
}

// helper for database_fetchLessonsData function below (prepares the parameters and determines if nothing to do return early situations...)
// optionalLessons: optionally give lesson id list so we can do batchGetItem instead of a query...
function prepareFetchLessonsData(curriculum, optionalLessons, filter, skipIfAlreadyFetched = true) {
  let curriculumData = checkCurriculumDataFetchedBeforeFetchingLessonData(curriculum)
  // no such curriculum:
  if (curriculumData === undefined)
    return {found: false, params: undefined}
  let lessonsTable = getLessonsDatabaseTable(curriculumData)
  // don't fetch again if already done:  
  if (skipIfAlreadyFetched && (curriculumLessonsDataFetched[curriculum] !== undefined))
    return {found: true, params: undefined, lessonsTable: lessonsTable}
  // teacher of the curriculum must be the user him/herself or else the user must be an admin:
  let teacher = Utility.isUserSignedIn() ? (isAdminUserDataFetched() && globals.urlParams.teacher !== undefined ? globals.urlParams.teacher : globals.sessionInfo.username) : undefined
  let inProductions = curriculumData['in-productions']
  
  var params = {curriculumData: curriculumData, lessonsTable: lessonsTable}
  if (optionalLessons !== undefined) {
    let keyParamList = (inProductions || globals.currentPage.isUserSite) ? 
      optionalLessons.map(lesson => { return {curriculum: {S: curriculum}, id: {S: lesson}} }) :
      optionalLessons.map(lesson => { return {teacher: {S: teacher}, id: {S: lesson}} })
    params.Keys = keyParamList
  } else {
    var expressionAttributeNames = undefined, expressionAttributeValues = undefined, keyConditionExpression = undefined, filterExpression = undefined
    if (filter === undefined) {
      filter = {ExpressionAttributeValues: {}, KeyConditionExpression: undefined, FilterExpression: undefined} 
    }
    expressionAttributeValues = filter.ExpressionAttributeValues
    keyConditionExpression = filter.KeyConditionExpression
    filterExpression = filter.FilterExpression
    expressionAttributeValues[':c'] = {S: curriculum}
    // FIXME: curriculum is a key attribute for live table, but not in submissions lesson table!:
    if (inProductions)
      keyConditionExpression = 'curriculum = :c' + (keyConditionExpression ? (' and ' + keyConditionExpression) : '')    
    else
      filterExpression = 'curriculum = :c' + (filterExpression ? (' and ' + filterExpression) : '')
    // for creator/admin sites add filter to only get submissions by current teacher:
    if (!globals.currentPage.isUserSite) {
      expressionAttributeValues[':t'] = {S: teacher}
      if (inProductions)  
        filterExpression = 'teacher = :t' + (filterExpression ? (' and ' + filterExpression) : '')
      else
        keyConditionExpression = 'teacher = :t' + (keyConditionExpression ? (' and ' + keyConditionExpression) : '')
    }  
    params.ExpressionAttributeNames = expressionAttributeNames
    params.ExpressionAttributeValues = expressionAttributeValues
    params.KeyConditionExpression = keyConditionExpression
    params.FilterExpression = filterExpression
  }
  return {found: true, params: params, lessonsTable: lessonsTable}
}

// fetches lessons for a given curriculum (and maybe some extra filter) from lessons table from the database
// optionalLessons: optionally give lesson id list so we can do batchGetItem instead of a query...
export function database_fetchLessonsData(curriculum, optionalLessons, filter) {
  let prepareData = prepareFetchLessonsData(curriculum, optionalLessons, filter, true)  
  let curriculumDataFound = prepareData.found
  // no such curriculum:
  if (!curriculumDataFound)
    return  
  let currentPage = globals.currentPage
  let fetchParams = prepareData.params  
  // don't fetch again if already done:
  if (fetchParams === undefined) {
    // notify current page curriculums data fetched:
    currentPage.lessonsDataFetched()
    return
  }
  let curriculumData = fetchParams.curriculumData
  let inProductions = curriculumData['in-productions']
  let onLessonsDataFetchSuccessFn = (data) => {    
    data.Items.forEach((lessonInfo) => {
      let lessonData = cacheFetchedData(dbConfig.lessonsTable, lessonInfo)
      let lesson = lessonData.id
      Fileserver.setLessonDataFullUrls(curriculumData['random-key'], curriculum, lesson, curriculumData.teacher, lessonData, inProductions)
      curriculumData.lessonsData[lesson] = lessonData      
    })    
    curriculumLessonsDataFetched[curriculum] = true
    // notify current page curriculums data fetched:
    currentPage.lessonsDataFetched()
  }
  let table = prepareData.lessonsTable
  // can we do a batchGetItem or have to do a query...:
  if (fetchParams.Keys !== undefined)
    database_batchGetItem(table, fetchParams.Keys, 'fetching lessons info', onLessonsDataFetchSuccessFn)
  else
    database_queryTable(table, fetchParams.ExpressionAttributeNames, fetchParams.ExpressionAttributeValues, fetchParams.KeyConditionExpression, fetchParams.FilterExpression, undefined, 'fetching lessons info', onLessonsDataFetchSuccessFn)
}

// fetches a new lesson items for a given curriculum (and maybe some extra filter) from lessons table from the database, and runs the onSuccessFn on the returned items list:
// optionalLessons: optionally give lesson id list so we can do batchGetItem instead of a query...
export function database_fetchLessonsDataForBatchOperation(curriculum, optionalLessons, filter, onSuccessFn, onFailureFn) {
  let prepareData = prepareFetchLessonsData(curriculum, optionalLessons, filter, false)  
  let curriculumDataFound = prepareData.found
  // no such curriculum:
  if (!curriculumDataFound) {
    if (onFailureFn !== undefined)
      onFailureFn()
    return   
  }
  let fetchParams = prepareData.params
  let table = prepareData.lessonsTable
  // can we do a batchGetItem or have to do a query...:
  if (fetchParams.Keys !== undefined)
    database_batchGetItem(table, fetchParams.Keys, 'refetching lessons info', onSuccessFn, onFailureFn)
  else
    database_queryTable(table, fetchParams.ExpressionAttributeNames, fetchParams.ExpressionAttributeValues, fetchParams.KeyConditionExpression, fetchParams.FilterExpression, undefined, 'refetching lessons info', onSuccessFn, onFailureFn)
}

// fetches a lesson's info for a curriculum from lessons table from the database
export function database_fetchLessonData(curriculum, lesson) {
  let curriculumData = checkCurriculumDataFetchedBeforeFetchingLessonData(curriculum)
  if (curriculumData === undefined)
    return
  // don't query database if have already fetched it:
  if (curriculumData.lessonsData !== undefined && curriculumData.lessonsData[lesson] !== undefined) {
    globals.currentPage.lessonDataFetched()
    return
  }
  let inProductions = curriculumData['in-productions']
  let keyParams = {
      id: {S: lesson}
  }
  if (inProductions)
    keyParams.curriculum = {S: curriculum}
  else
    keyParams.teacher = {S: curriculumData.teacher}
  let lessonsTable = getLessonsDatabaseTable(curriculumData)
  database_getItem(lessonsTable, keyParams, 'fetching lesson info', function(data) {    
    if (data.Item) {      
      let lessonData = cacheFetchedData(dbConfig.lessonsTable, data.Item)
      Fileserver.setLessonDataFullUrls(curriculumData['random-key'], curriculum, lesson, curriculumData.teacher, lessonData, curriculumData['in-productions'])
      curriculumData.lessonsData[lesson] = lessonData
    } else {
      console.log('No lesson found with curriculum and id', curriculum, lesson)        
    }
    globals.currentPage.lessonDataFetched()
  })
}

// don't query for lessons if the curriculum data itself hasen't been fetched:
function checkCurriculumDataFetchedBeforeFetchingLessonData(curriculum) {  
  let curriculumData = cachedAllCurriculums[curriculum]
  if (curriculumData === undefined) {
    console.log('Cannot fetch lesson info without fetching curriculum info first!')
    return undefined
  }
  return curriculumData
}

// fetches sales items for athe creator teacher user via a query...
export function database_fetchSalesData(teacher, month, year) {  
    const [epochRangeStart, epochRangeEnd] = Utility.getEpochRangeforMonth(month, year)       
    let table = dbConfig.salesPerTeacherTable
    let onSalesDataFetchSuccessFn = (data) => {      
      // notify current page curriculums data fetched:
      globals.currentPage.saleDataFetched(data.Items)
    }    
    database_queryTable(table, {'#dp': 'date-purchased'}, {':t': {S: teacher}, ':ds': {N: '' + epochRangeStart}, ':de': {N: '' + epochRangeEnd}}, 'teacher = :t and (#dp between :ds and :de)', undefined, undefined, 'fetching sales info', onSalesDataFetchSuccessFn)    
}

// submits a new message (e.g. sent by the user through the 'Contact Us' form) by adding an entry to the email outbox queue table. a lambda function will pick that up and send us the email:
export function database_sendEmailMessageToTeam(messageData, onSuccessFn, onFailureFn) {
  delete messageData['recipient-teacher']
  database_sendEmailMessage(messageData, onSuccessFn, onFailureFn)
}

// submits a new email message (e.g. sent to either termeric team or a creator teacher, by the user through the 'Contact' form) by adding an entry to the email outbox queue table. a lambda function will pick that up and send us the email:
export function database_sendEmailMessage(messageData, onSuccessFn, onFailureFn) {
  let username = Utility.getSessionUsername()
  let item = {username: {S: username}, 'date-sent': {N: '' + Utility.getEpochTime()}, email: {S: messageData.email}, name: {S: messageData.name}, title: {S: messageData.title}, message: {S: messageData.message}}
  let anyRecipientTeacher = messageData['recipient-teacher']
  if (anyRecipientTeacher) {
    item['recipient-teacher'] = {S: anyRecipientTeacher}
    item['recipient-teacher-name'] = {S: messageData['recipient-teacher-name']}
  }
  database_putItem(dbConfig.emailsOutboxQueueTable, item, 'submitting user message', onSuccessFn, onFailureFn)
}

/*

  Creator Site
  
*/

// adds a new creator entry for a teacher in the creators table:
export function database_addNewCreator(creatorData, onSuccessFn, onFailureFn) {
  let teacher = globals.sessionInfo.username
  let submissionDate = '' + Utility.getEpochTime()  
  creatorData.username = teacher    
  creatorData['date-created'] = submissionDate
  creatorData['date-last-updated'] = submissionDate
  let finalOnSuccessFn = (data) => {    
    // cache the new creator data
    cachedCreatorUserInfo = creatorData
    if (onSuccessFn !== undefined)
      onSuccessFn(data)
  }  
  let creatorItem = convertKeyValuesToTypedKeyValuesForDatabase(dbConfig.creatorsTable, creatorData)
  database_putItem(dbConfig.creatorsTable, creatorItem, 'adding creator data', finalOnSuccessFn, onFailureFn)
}

// updates an existing creator entry in creators table
export function database_updateCreator(creatorData, onSuccessFn, onFailureFn) {
  let teacher = globals.sessionInfo.username
  // update cached creators info on success (and run users any onSuccessFn too):  
  let finalOnSuccessFn = (data) => {    
    Object.keys(creatorData).forEach(f => cachedCreatorUserInfo[f] = creatorData[f])    
    if (onSuccessFn !== undefined)
      onSuccessFn(data)
  }  
  database_updateItemIfChanged(dbConfig.creatorsTable, dbConfig.creatorsTable, true, {username: {S: teacher}}, 'updating creator info', creatorData, cachedCreatorUserInfo, 'date-last-updated', false, undefined, finalOnSuccessFn, onFailureFn)
}

// adds a new teacher entry to teachers and creators tables in the database
export function database_addNewTeacher(teacherData, payoutIsLinked, onSuccessFn, onFailureFn) {    
  let teacher = globals.sessionInfo.username
  let submissionDate = '' + Utility.getEpochTime()  
  teacherData.id = teacher  
  teacherData['num-downloads'] = 0
  teacherData['num-ratings'] = 0
  teacherData['sum-ratings'] = 0
  teacherData.rating = 0
  teacherData.curriculums = []
  teacherData['curriculums-subjects'] = []  
  teacherData['curriculums-drafted'] = []
  teacherData['curriculums-drafted-subjects'] = []
  teacherData['curriculums-submitted'] = []
  teacherData['curriculums-submitted-subjects'] = []
  teacherData['date-created'] = submissionDate
  teacherData['date-last-updated'] = submissionDate
  let creatorPutSuccessFn = () => {
    // 2. next, add teacher entry:
    let teacherItem = convertKeyValuesToTypedKeyValuesForDatabase(dbConfig.teachersTable, teacherData)
    teacherItem.active = {BOOL: false}  
    let finalOnSuccessFn = (data) => {
      // lambda triggers should have set the active to true and moved it to live table:
      teacherData.active = true
      teacherData['in-submissions'] = false    
      teacherData['in-productions'] = true
      if (teacherData.image) {
        // set up full url path for teacher profile image:
        teacherData.imageUrl = Fileserver.getTeacherImageUrl(teacher, false, false, teacherData.image)        
      }
      // cache the new teacher data
      cachedTeachersInfo[teacher] = teacherData
      if (onSuccessFn !== undefined)
        onSuccessFn(data)
    }
    database_putItem(dbConfig.teachersSubmissionsTable, teacherItem, 'adding teacher data', finalOnSuccessFn, onFailureFn)
  }
  // 1. add creator entry if it hasn't been done before:
  if (!payoutIsLinked) {
    // if teacher is new, creator entry also doesnt exist. create an entry now:
    database_addNewCreator({'payout-user-id': '', 'payout-payer-id': '', 'payout-name': '', 'payout-email': ''}, creatorPutSuccessFn, onFailureFn)    
  } else
    creatorPutSuccessFn()
}

// updates an existing teacher entry in teachers table
// if fileserverUpdated flag is true (set when fileserver files were updated as a result of teachers edits), the caller wants to force an update to the db entry (e.g. last-updated-date) even if no other fields are being updated
// if teacher is live teacher or fileserverCleanupNeeded flag is true it means some files were uploaded, which means the old replaced files on fileserver need to be removed, so an entry to update-queue needs to be put so that lambdas take care of the house cleaning...
export function database_updateTeacher(teacherData, fileserverUpdated, fileserverCleanupNeeded, onSuccessFn, onFailureFn) {
  let teacher = globals.sessionInfo.username  
  let cachedTeacherData = cachedTeachersInfo[teacher]    
  // update cached teachers info on success (and run users any onSuccessFn too):  
  let finalOnSuccessFn = (data) => {    
    Object.keys(teacherData).forEach(f => cachedTeacherData[f] = teacherData[f])    
    // if image was updated, be sure that updated cached data also has the full url path:
    if (teacherData.image) {
      cachedTeacherData.imageUrl = Fileserver.getTeacherImageUrl(teacher, cachedTeacherData['in-productions'], false, teacherData.image)      
      cachedUserInfo.imageUrl = '' // reset this for now until next refresh to make sure new image is already uploaded already...
    }
    if (onSuccessFn !== undefined)
      onSuccessFn(data)
  }
  let inProductions = cachedTeacherData['in-productions']
  let isDirectUpdate = !(inProductions || fileserverCleanupNeeded)
  let indirectUpdateTypedExtraValues = isDirectUpdate ? undefined : {'in-productions': {BOOL: inProductions}, 'fileserver-updated': {BOOL: fileserverUpdated}, 'fileserver-cleanup-needed': {BOOL: fileserverCleanupNeeded}}
  database_updateItemIfChanged(dbConfig.teachersTable, isDirectUpdate ? dbConfig.teachersSubmissionsTable : dbConfig.teachersUpdatesTable, isDirectUpdate, {id: {S: teacher}}, 'updating teacher info', teacherData, cachedTeacherData, 'date-last-updated', fileserverUpdated, indirectUpdateTypedExtraValues, finalOnSuccessFn, onFailureFn)
}

// adds the curriculum id to the teacher's curriculum list under the classification fields: 'curriculums', 'curriculums-submitted', or 'curriculums-drafted':
export function database_addCurriculumToTeachersList(id, subject, teacherTableField, teacher, teacherData, onSuccessFn, onFailureFn) {
  // only add it if it isn't already there:
  if (teacherData[teacherTableField].indexOf(id) == -1) {  
    let subjectsField = dbConfig.tableCurriculumToSubjectMappings[dbConfig.teachersTable][teacherTableField]
    var expressionAttributeNames = {}
    var reservedAttrVarName = getAttributeNameVarForDatabaseOperation(teacherTableField)
    var reservedAttrVarNameSubjects = getAttributeNameVarForDatabaseOperation(subjectsField)
    expressionAttributeNames[reservedAttrVarName] = teacherTableField    
    expressionAttributeNames[reservedAttrVarNameSubjects] = subjectsField
    var operations = [
      reservedAttrVarName + ' = list_append(:valuesToTake0, ' + reservedAttrVarName + ')', 
      reservedAttrVarNameSubjects + ' = list_append(:valuesToTake1, ' + reservedAttrVarNameSubjects + ')'
    ]
    let finalOnSuccessFn = () => {
      teacherData[teacherTableField].unshift(id)
      teacherData[subjectsField].unshift(subject)
      if (onSuccessFn !== undefined)
        onSuccessFn()
      return true
    }
    database_updateList(true, teacherData['in-productions'] ? dbConfig.teachersTable : dbConfig.teachersSubmissionsTable, 'id', teacher, ['S', 'S'], operations, expressionAttributeNames, [[id], [subject]], finalOnSuccessFn, onFailureFn)
  }
}

// removes the curriculum id from teacher's curriculum list under the classification fields: 'curriculums', 'curriculums-submitted', or 'curriculums-drafted':
export function database_removeCurriculumFromTeachersList(id, subject, teacherTableField, teacher, teacherData, onSuccessFn, onFailureFn) {
  let removeIdx = teacherData[teacherTableField].indexOf(id)
  if (removeIdx !== -1) {
    let subjectsField = dbConfig.tableCurriculumToSubjectMappings[dbConfig.teachersTable][teacherTableField]
    let expressionAttributeNames = {}
    let reservedAttrVarName = getAttributeNameVarForDatabaseOperation(teacherTableField)
    var reservedAttrVarNameSubjects = getAttributeNameVarForDatabaseOperation(subjectsField)    
    expressionAttributeNames[reservedAttrVarName] = teacherTableField    
    expressionAttributeNames[reservedAttrVarNameSubjects] = subjectsField
    let operations = [
      reservedAttrVarName + '[' + removeIdx + ']', 
      reservedAttrVarNameSubjects + '[' + removeIdx + ']'
    ]
    let finalOnSuccessFn = () => {
      teacherData[teacherTableField].splice(removeIdx, 1)
      teacherData[subjectsField].splice(removeIdx, 1)
      if (onSuccessFn !== undefined)
        onSuccessFn()
      return true
    }
    database_updateList(false, teacherData['in-productions'] ? dbConfig.teachersTable : dbConfig.teachersSubmissionsTable, 'id', teacher, ['S', 'S'], operations, expressionAttributeNames, [[id], [subject]], finalOnSuccessFn, onFailureFn)    
  }
}

// adds a new curriculum entry in the database's curriculum-drafts table for user's later retrieval:
export function database_addNewCurriculumDraft(id, curriculumData, teacherData, onSuccessFn, onFailureFn) {
  database_addNewCurriculumDraftOrSubmission(false, id, curriculumData, teacherData, onSuccessFn, onFailureFn)
}

// adds a new curriculum entry in the database's curriculum-submissions table (which will flow into actual curriculums table upon approval using a lambda trigger)
// since this is a submission, it will also remove the curriculum from drafts table and remove its id from the teacher's entry's curriculums-drafted field (if any) in the teachers table
export function database_addNewCurriculumSubmission(id, curriculumData, teacherData, onSuccessFn, onFailureFn) {  
  let finalOnSuccessFn = (data) => {    
    database_deleteCurriculum(id, curriculumData, teacherData, false, false, true, onSuccessFn, onFailureFn, dbConfig.curriculumsDraftsTable, 'curriculums-drafted')
  }
  database_addNewCurriculumDraftOrSubmission(true, id, curriculumData, teacherData, finalOnSuccessFn, onFailureFn)
}

// helper for 2 functions above:
export function database_addNewCurriculumDraftOrSubmission(isSubmission, id, curriculumData, teacherData, onSuccessFn, onFailureFn) {
  let teacher = globals.sessionInfo.username
  let subject = curriculumData.subject
  let submissionDate = '' + Utility.getEpochTime()
  curriculumData.id = id
  curriculumData.teacher = teacher
  curriculumData.active = false
  curriculumData.private = false
  curriculumData.rating = 0
  curriculumData['teacher-name'] = getTeacherName(teacher)  
  curriculumData['date-created'] = submissionDate
  curriculumData['date-last-updated'] = submissionDate
  curriculumData['num-ratings'] = 0
  curriculumData['sum-ratings'] = 0
  curriculumData['num-downloads'] = 0
  curriculumData['bundle'] = Utility.generateFilenameFromTitle(curriculumData.title, 'zip')
  let item = convertKeyValuesToTypedKeyValuesForDatabase(dbConfig.curriculumsTable, curriculumData)
  item.active = {BOOL: false}
  let finalOnSuccessFn = () => {
    // cache the new curriculum data    
    curriculumData['in-submissions'] = isSubmission === true
    curriculumData['in-drafts'] = isSubmission !== true
    curriculumData['in-productions'] = false
    curriculumData.active = false
    curriculumData['the-language'] = curriculumData['the-language'] || generalConfig.defaultLanguage
    // delay setting the price in case locale currency info hasn't come yet:    
    curriculumData['is-grade-range'] = curriculumData.grade.length > 1
    curriculumData['min-grade'] = curriculumData.grade[0]
    curriculumData['max-grade'] = curriculumData.grade[curriculumData.grade.length > 1 ? 1 : 0]
    curriculumData['num-lessons'] = curriculumData.lessons.length
    if (curriculumData.lessonsData === undefined) {
      let cachedCurriculumData = cachedAllCurriculums[id]      
      curriculumData.lessonsData = cachedCurriculumData ? (cachedCurriculumData.lessonsData || {}) : {}
    }    
    // be sure that cached data also has the full url paths for all url fields:  
    Fileserver.setCurriculumDataFullUrls(id, curriculumData)
    cachedAllCurriculums[id] = curriculumData
    if (onSuccessFn !== undefined)
      onSuccessFn()
  }
  let afterAddCurriculumFn = () => {
    database_addCurriculumToTeachersList(id, subject, isSubmission ? 'curriculums-submitted' : 'curriculums-drafted', teacher, teacherData, finalOnSuccessFn, onFailureFn)    
  }
  database_putItem(isSubmission ? dbConfig.curriculumsSubmissionsTable : dbConfig.curriculumsDraftsTable, item, 'adding curriculum ' + (isSubmission ? 'submission' : 'draft') + ' data', afterAddCurriculumFn, onFailureFn)
}

// if it's a live curriculum or there was a new fileserver upload: submits a new entry in the curriculums updates table which a lamda fns will pick up upon approval to updates an existing curriculum entry in database:
// otherwise: it makes the update directly to the submission table instead...
// if fileserverUpdated flag is true (set when fileserver files were updated as a result of teachers edits), the caller wants to force an update to the db entry (e.g. last-updated-date) even if no other fields are being updated
// if fileserverCleanupNeeded flag is true it means some files were uploaded, which means the old replaced files on fileserver need to be removed, so an entry to update-queue needs to be put so that lambdas take care of the house cleaning...
export function database_updateCurriculum(id, curriculumDataChanges, fileserverUpdated, fileserverCleanupNeeded, onSuccessFn, onFailureFn) {
  let cachedCurriculumData = cachedAllCurriculums[id]  
  // update cached curriculum info on success (and run users any onSuccessFn too):
  let finalOnSuccessFn = (data) => {
    Object.keys(curriculumDataChanges).forEach(f => cachedCurriculumData[f] = curriculumDataChanges[f])
    cachedCurriculumData['is-grade-range'] = cachedCurriculumData.grade.length > 1
    cachedCurriculumData['min-grade'] = cachedCurriculumData.grade[0]
    cachedCurriculumData['max-grade'] = cachedCurriculumData.grade[cachedCurriculumData.grade.length > 1 ? 1 : 0]
    cachedCurriculumData['num-lessons'] = cachedCurriculumData.lessons.length
    // be sure that cached data also has the full url paths for all url fields updated:
    Fileserver.setCurriculumDataFullUrlsForUpdatedFields(id, cachedCurriculumData, curriculumDataChanges)
    if (onSuccessFn !== undefined)
      onSuccessFn(data)
  }
  let inProductions = cachedCurriculumData['in-productions']
  let inSubmissions = cachedCurriculumData['in-submissions']
  let isAdmin = isAdminUserDataFetched()
  let isDirectUpdate = !(inProductions || fileserverCleanupNeeded) || isAdmin  
  let keyParams = (isAdmin && inProductions) ? {id: {S: id}, subject: {S: cachedCurriculumData.subject}} : {id: {S: id}, teacher: {S: globals.sessionInfo.username}}
  let indirectUpdateTypedExtraValues = (isDirectUpdate && !fileserverCleanupNeeded) ? undefined : {'random-key': {S: cachedCurriculumData['random-key']}, 'in-productions': {BOOL: inProductions}, 'in-submissions': {BOOL: inSubmissions}, 'fileserver-updated': {BOOL: fileserverUpdated}, 'fileserver-cleanup-needed': {BOOL: fileserverCleanupNeeded}, subject: {S: cachedCurriculumData.subject}}
  // if live curriculum's title has been updated, the bundle file name have to chage too:
  if (inProductions && curriculumDataChanges.title != null)
    curriculumDataChanges['bundle'] = Utility.generateFilenameFromTitle(curriculumDataChanges.title, 'zip')
  database_updateItemIfChanged(dbConfig.curriculumsTable, getCurriculumDatabaseUpdateTable(cachedCurriculumData, fileserverCleanupNeeded), isDirectUpdate, keyParams, 'updating curriculum info', curriculumDataChanges, cachedCurriculumData, 'date-last-updated', fileserverUpdated, indirectUpdateTypedExtraValues, finalOnSuccessFn, onFailureFn)
}

// deactivates a published curriculum (mark active field to false): it remains in productions table but wont be available in the store:
export function database_deactivateCurriculum(id, curriculumData, onSuccessFn, onFailureFn) {
  database_toggleCurriculumActive(false, id, curriculumData, onSuccessFn, onFailureFn)
}

// reactivates a deactivated published curriculum (mark active field to true): it will be available in the store:
export function database_activateCurriculum(id, curriculumData, onSuccessFn, onFailureFn) {
  database_toggleCurriculumActive(true, id, curriculumData, onSuccessFn, onFailureFn)
}

// activates or deactivates a live curriculum (in productions table):
export function database_toggleCurriculumActive(activate, id, curriculumData, onSuccessFn, onFailureFn) {
  if ((activate && curriculumData.active) || (!activate && !curriculumData.active)) {
    // nothing to do!
    if (onSuccessFn !== undefined)
      return onSuccessFn()
  }
  let finalOnSuccessFn = (data) => {
    curriculumData.active = activate
    // send team an email about this curriculum active status changing:
    let messageData = {name: generalConfig.teamName, email: generalConfig.teamEmail, title: 'Curriculum ' + (activate ? 'Put Back in the Store' : 'Removed from the Store') + ' by the Creator', message: JSON.stringify({id: id, teacher: curriculumData.teacher, title: curriculumData.title, subtitle: curriculumData.subtitle})}
    database_sendEmailMessageToTeam(messageData)
    // run any success callback by the caller:
    if (onSuccessFn !== undefined)
      return onSuccessFn()
  }
  database_updateCurriculum(id, {active: activate}, false, false, finalOnSuccessFn, onFailureFn)
}

// if it's a live curriculum, we do not allow removing the curriculum (Lifetime Access Policy - so that existing content purchasers can continue accessing it while new users cannot see or purchase it)
// instead we call toggleCurriculumActive to deactivate it... (only admins can do)
// if it's a submitted curriculum, it will remove from store and move it into drafts just in case the teacher wants to submit again: this function will update the teachers curriculum list to reflect the move of a currriculum currently in submissions  to drafts, removes a curriculum from the store, by moving the curriculum and its lesson entries from submissions to drafts.
// NOT ANYMORE: if it's a live curriculum, it will also place an entry to the deleted queue table. A lambda function will pick it up to do the following: - a copy of the curriculum/lesson entries and fileseverver files will be made in the archive deleted tables/fileserver areas - fileserver files in live area will be moved from live to submissions area
export function database_removeCurriculumFromStore(id, curriculumData, teacherData, onSuccessFn, onFailureFn) {
  let inDrafts = curriculumData['in-drafts']
  let inSubmissions = curriculumData['in-submissions']
  let inProductions = curriculumData['in-productions']
  let isDueToLiveCurriculumRemovalFromStore = inProductions
  let isDueToMoveBetweenDraftsAndSubmissions = inSubmissions
  if (inDrafts) {
    // nothing to do if it's alredy in drafts:
    if (onSuccessFn !== undefined)
      return onSuccessFn()
  }
  let teacher = curriculumData.teacher
  let subject = curriculumData.subject
  let keyParams = {      
    id: {S: id}
  }
  if (inSubmissions) 
    keyParams.teacher = {S: teacher}
  else
    keyParams.subject = {S: curriculumData.subject}
  
  // get item where it is now (submissions or productions) table. handler function:
  let getItemFn = (data) => {    
    if (data.Item) { 
      data.Item.active = {BOOL: false}
      curriculumData.active = false
      let finalOnSuccessFn = () => {
        curriculumData['in-productions'] = false
        curriculumData['in-submissions'] = false
        curriculumData['in-drafts'] = true
        if (onSuccessFn !== undefined)
          onSuccessFn()
        return true
      }      
      let afterDeleteFn = () => {
        // now that we de deleted the curr from the table, add the entry to teacher's curriculums-drafted list:
        database_addCurriculumToTeachersList(id, subject, 'curriculums-drafted', teacher, teacherData, finalOnSuccessFn, onFailureFn)
      }
      let afterAddCurriculumAndLessonsToDraftsFn = () => {              
        // step 4: now that curriculum/lessons entries copied to drafts tables, delete it from submissions/productions table. Just copy the entry to the drafts table instead and remove the entry from the submissions table (or if its live add it to the deletion queue for lambdas to handle it -- the removing of fileserver files and making a copy in the deleted archive areas will still be handled by lambdas...):
        database_deleteCurriculum(id, curriculumData, teacherData, false, isDueToLiveCurriculumRemovalFromStore, isDueToMoveBetweenDraftsAndSubmissions, afterDeleteFn, onFailureFn) //, dbConfig.curriculumsSubmissionsTable, 'curriculums-submitted')
      }
      let afterAddCurriculumToDraftsFn = () => {
        // if curriculum is being moved from submissions to drafts then nothing to do with lessons (for both cases they are in lessons-submissions table)
        if (isDueToMoveBetweenDraftsAndSubmissions) {
          afterAddCurriculumAndLessonsToDraftsFn()
        } else {                                
          // otherwise, copy them from productions to drafts:
          let copyCurriculumLessonsIntoDraftsFn = (data) => {
            // step 3: copy lessons from productions to drafts 
            database_batchWriteItem(true, dbConfig.lessonsSubmissionsTable, data.Items, 'adding lessons to submissions table', afterAddCurriculumAndLessonsToDraftsFn, onFailureFn)
          }
          // now that curriculum entry copied to drafts table, also add its lesson entires to lessons drafts table:
          database_fetchLessonsDataForBatchOperation(id, curriculumData.lessons, undefined, copyCurriculumLessonsIntoDraftsFn, onFailureFn)
        }
      }
      // step 2: now that we fetched the entry, put it in the drafts table, then also fetch its lessons and put  them in the lesson submissions table...           
      database_putItem(dbConfig.curriculumsDraftsTable, data.Item, 'adding curriculum to drafts table', afterAddCurriculumToDraftsFn, onFailureFn)
    } else {
      if (onFailureFn !== undefined)
        return onFailureFn()
    }
  }
  // step 1: get the item from live/submissions table:
  database_getItem(getCurriculumDatabaseTable(curriculumData), keyParams, 'fetching curriculum', getItemFn, onFailureFn)
}

// delete the curriculum given from the (published/submissions/drafts) table and its lessons from the corresponding lessons table
// isTotalDelete = true if this is being totally removed from all curriculum tables
// isTotalDelete = false if this is just being removed from one table after making a copy of the entry in anther curriculum table: drafts/submissions/live
// isDueToLiveCurriculumRemovalFromStore = true: we're deleting a live curriculum because it's being removed from the live store and being moved as a draft curriculum, and lambdas will need to also make a copy of it in the deleted archive areas
// isDueToMoveBetweenDraftsAndSubmissions = true: we're deleting curriculum from drafts or submissions because a copy was made in the other table (submissions or drafts - it's a curriculum move): in this case nothing to do with lessons entries because for both drafts/submissions the lessons are in lessons-submissions table
// if this is a live table, we don't delete it. let the lambdaa triggers move it to the archive deleted table/fileserver area
export function database_deleteCurriculum(id, curriculumData, teacherData, isTotalDelete, isDueToLiveCurriculumRemovalFromStore, isDueToMoveBetweenDraftsAndSubmissions, onSuccessFn, onFailureFn, forceTableChoice = undefined, forceTeacherTableFieldChoice = undefined) {
  let inDrafts = curriculumData['in-drafts']
  let inSubmissions = curriculumData['in-submissions']
  let inProductions = curriculumData['in-productions']
  let randomKey = curriculumData['random-key']  
  let teacher = curriculumData.teacher
  let subject = curriculumData.subject
  let table = forceTableChoice || getCurriculumDatabaseTable(curriculumData)
  let teacherTableField = forceTeacherTableFieldChoice || getCurriculumTeacherCurriculumsListName(curriculumData)
  // remove the curriculum id from the teacher's entry's list field (if any) in the teachers table
  let removeIdx = teacherData[teacherTableField].indexOf(id)
  if (removeIdx !== -1) {
    let afterRemoveFromTeachersListFn = () => {
      // step 2: also remove the curriculum entry from the corresponding curriculum table:
      let keyParams = {teacher: {S: teacher}, id: {S: id}}  
      let finalOnSuccessFn = (isTotalDelete || isDueToLiveCurriculumRemovalFromStore) ? 
        () => {
          // step 4: if this is a direct delete (not a side-effect of another operation like moving a curr from submissions to drafts) or a live curriculum being removed from the store, also add an entry to the curriculums-deleted-queue table for the lambdas to pick it up and handle removing the fileserver files for this deleted curriculum and making a copy of curriculum db/fs stuff in the deleted archive tables/areas:
          database_putItem(dbConfig.curriculumsDeletedQueueTable, {teacher: {S: teacher}, id: {S: id}, 'date-last-updated': {N: '' + Utility.getEpochTime()}, 'random-key': {S: randomKey}, subject: {S: subject}, 'in-drafts': {BOOL: inDrafts}, 'in-submissions': {BOOL: inSubmissions}, 'in-productions': {BOOL: inProductions}, 'is-removal-from-store': {BOOL: isDueToLiveCurriculumRemovalFromStore}}, 'marking curriculum deletion', onSuccessFn, onFailureFn)
        } :
        onSuccessFn
      // if this is a live table, don't delete it or its lesson entries. let the lambda handle deleting:
      if (inProductions)
        finalOnSuccessFn()
      else {
        let afterDeleteLessonsFn = () => {
          // step 3: then delete the curriculum itself:
          database_deleteItem(table, keyParams, 'deleting curriculum from table ' + table, finalOnSuccessFn, onFailureFn)
        }
        // step 2: if curriculum from drafts being deleted, while curriculum is now in submissions, there's nothing to do with the lesson entries (for both drafts/submissions the lessons are in lessons-submissions table). Otherswise, delete the lesson entries first and then move onto deleting the curriculum entry...:
        if (isDueToMoveBetweenDraftsAndSubmissions)
          afterDeleteLessonsFn()
        else
          database_deleteLessons(id, curriculumData.lessons, curriculumData, false, afterDeleteLessonsFn, onFailureFn)      
      }
    }
    // step 1: remove from teachers curriculum list:
    database_removeCurriculumFromTeachersList(id, subject, teacherTableField, teacher, teacherData, afterRemoveFromTeachersListFn, onFailureFn)
  } else {
    // nothing to do!
    if (onSuccessFn !== undefined)
      onSuccessFn()
  }
}

// saves a new lesson plan entry for a given curriculum
export function database_addNewLessonDraft(curriculum, lesson, curriculumData, lessonData, onSuccessFn, onFailureFn) {
  let inProductions = curriculumData['in-productions']
  let teacher = curriculumData.teacher
  let submissionDate = '' + Utility.getEpochTime()  
  lessonData.id = lesson
  lessonData['date-created'] = submissionDate
  curriculumData['date-last-updated'] = submissionDate  
  let item = convertKeyValuesToTypedKeyValuesForDatabase(dbConfig.lessonsTable, lessonData)  
  // lessons-submissions table has 'teacher' as primary key and 'curriculum' as a normal attribute instead of a sort key:
  item.curriculum = {S: curriculum}
  item.teacher = {S: teacher}
  let finalOnSuccessFn = (data) => {
    // be sure that cached data also has the full url paths for all url fields:    
    Fileserver.setLessonDataFullUrls(curriculumData['random-key'], curriculum, lesson, teacher, lessonData, inProductions)
    curriculumData.lessonsData[lesson] = lessonData    
    if (onSuccessFn !== undefined)
      onSuccessFn(data)
  }
  database_putItem(inProductions ? dbConfig.lessonsAddedQueueTable : dbConfig.lessonsSubmissionsTable, item, 'saving lesson data', finalOnSuccessFn, onFailureFn)
}

// if it's a live lesson or there was a new fileserver upload: submits a new entry in the tables updates table which a lamda fns will pick up upon approval to updates an existing curriculum's lesson entry in database:
// otherwise: it makes the update directly to the lesson submission table instead...
// if fileserverUpdated flag is true (set when fileserver files were updated as a result of teachers edits), the caller wants to force an update to the db entry (e.g. last-updated-date) even if no other fields are being updated
// if fileserverCleanupNeeded flag is true it means some files were uploaded, which means the old replaced files on fileserver need to be removed, so an entry to update-queue needs to be put so that lambdas take care of the house cleaning...
export function database_updateLesson(curriculum, lesson, curriculumData, lessonDataChanges, fileserverUpdated, fileserverCleanupNeeded, onSuccessFn, onFailureFn) {
  let teacher = curriculumData.teacher  
  let cachedLessonData = curriculumData.lessonsData[lesson]
  // update cached teachers info on success (and run users any onSuccessFn too):
  let finalOnSuccessFn = (data) => {
    Object.keys(lessonDataChanges).forEach(f => cachedLessonData[f] = lessonDataChanges[f])    
    // be sure that cached data also has the full url paths for any url fields updated:
    Fileserver.setLessonDataFullUrlsForUpdatedFields(curriculumData['random-key'], curriculum, lesson, teacher, cachedLessonData, lessonDataChanges, curriculumData['in-productions'])
    if (onSuccessFn !== undefined)
      onSuccessFn(data)
  }
  let inProductions = curriculumData['in-productions']
  let inSubmissions = curriculumData['in-submissions']
  let isDirectUpdate = !(inProductions || fileserverCleanupNeeded)
  let keyParams = {id: {S: lesson}, teacher: {S: teacher}}
  if (!isDirectUpdate) {
    keyParams.curriculum = {S: curriculum}
    keyParams.subject = {S: curriculumData.subject}    
  }
  let indirectUpdateTypedExtraValues = (isDirectUpdate && !fileserverCleanupNeeded) ? undefined : {'random-key': {S: curriculumData['random-key']}, 'in-productions': {BOOL: inProductions}, 'in-submissions': {BOOL: inSubmissions},  'fileserver-updated': {BOOL: fileserverUpdated}, 'fileserver-cleanup-needed': {BOOL: fileserverCleanupNeeded}}  
  database_updateItemIfChanged(dbConfig.lessonsTable, getLessonDatabaseUpdateTable(curriculumData, fileserverCleanupNeeded), isDirectUpdate, keyParams, 'updating lesson info', lessonDataChanges, cachedLessonData, 'date-last-updated', fileserverUpdated, indirectUpdateTypedExtraValues, finalOnSuccessFn, onFailureFn)
}

// delete a list of lesson entries from the (published/submissions) lessons table
// normally set doAddToDeletedQueue = true so that lambda triggers can pickup cleaning the fileserver as well, but can set to false if that's already done since curriculum's whole directory there was cleaned up...
export function database_deleteLessons(curriculum, lessons, curriculumData, doAddToDeletedQueue, onSuccessFn, onFailureFn) {
  if (lessons.length === 0) {
    // nothing to do
    if (onSuccessFn !== undefined)
      onSuccessFn()
    return
  }
  let inDrafts = curriculumData['in-drafts']
  let inSubmissions = curriculumData['in-submissions']
  let inProductions = curriculumData['in-productions']
  let randomKey = curriculumData['random-key']
  let teacher = curriculumData.teacher
  let table = getLessonsDatabaseTable(curriculumData)
  let finalOnSuccessFn = doAddToDeletedQueue ?
    () => {
      // also add an entry to the lessons-deleted-queue table for the lambdas to pick it up and handle cleaning up the fileserver files for the lesson...:
      let allItems = lessons.map(lesson => { return {teacher: {S: teacher}, id: {S: lesson}, curriculum: {S: curriculum}, 'date-last-updated': {N: '' + Utility.getEpochTime()}, 'random-key': {S: randomKey}, 'in-drafts': {BOOL: inDrafts}, 'in-submissions': {BOOL: inSubmissions}, 'in-productions': {BOOL: inProductions}} })
      database_batchWriteItem(true, dbConfig.lessonsDeletedQueueTable, allItems, 'marking lessons deletions', onSuccessFn, onFailureFn)
    } : onSuccessFn
  // if this is a live table, don't delete it. let the lambdaa handle deleting:
  if (inProductions)
    finalOnSuccessFn()
  else {
    let allKeyParams = lessons.map(lesson => { return {teacher: {S: teacher}, id: {S: lesson}} })
    database_batchWriteItem(false, table, allKeyParams, 'deleting lessons from submissions table', finalOnSuccessFn, onFailureFn)
  }
}

// delete a lesson entry's from the (published/submissions) lessons table
// normally set doAddToDeletedQueue = true so that lambda triggers can pickup cleaning the fileserver as well, but can set to false if that's already done since curriculum's whole directory there was cleaned up...
export function database_deleteLesson(curriculum, lesson, curriculumData, doAddToDeletedQueue, onSuccessFn, onFailureFn) {
  let inDrafts = curriculumData['in-drafts']
  let inSubmissions = curriculumData['in-submissions']
  let inProductions = curriculumData['in-productions']
  let randomKey = curriculumData['random-key']
  let teacher = curriculumData.teacher
  let table = getLessonsDatabaseTable(curriculumData)
  let keyParams = {teacher: {S: teacher}, id: {S: lesson}}
  let finalOnSuccessFn = doAddToDeletedQueue ?
    (data) => {
      // also add an entry to the lessons-deleted-queue table for the lambdas to pick it up and handle cleaning up the fileserver files for the lesson...:
      database_putItem(dbConfig.lessonsDeletedQueueTable, {teacher: {S: teacher}, id: {S: lesson},  curriculum: {S: curriculum}, 'date-last-updated': {N: '' + Utility.getEpochTime()}, 'random-key': {S: randomKey}, 'in-drafts': {BOOL: inDrafts}, 'in-submissions': {BOOL: inSubmissions}, 'in-productions': {BOOL: inProductions}}, 'marking lesson deletion', onSuccessFn, onFailureFn)        
    } : onSuccessFn
  // if this is a live table, don't delete it. let the lambdaa handle deleting:
  if (inProductions)
    finalOnSuccessFn()
  else
    database_deleteItem(table, keyParams, 'deleting lesson from table ' + table, finalOnSuccessFn, onFailureFn)
}

// makes a duplicate lesson entry (updating key params and creation/update date fields):
export function database_addLessonDuplicate(curriculum, sourceLesson, targetLesson, curriculumData, onSuccessFn, onFailureFn) {
  let teacher = curriculumData.teacher
  let inProductions = curriculumData['in-productions']
  // it's not a direct table update if it's a live curriculum. rather lambda function will pick it up from the lesson added queue table:
  let sourceTable = inProductions ? dbConfig.lessonsTable : dbConfig.lessonsSubmissionsTable
  let targetTable = inProductions ? dbConfig.lessonsAddedQueueTable : dbConfig.lessonsSubmissionsTable
  let sourceKeyParams = inProductions ? {curriculum: {S: curriculum}, id: {S: sourceLesson}} : {teacher: {S: teacher}, id: {S: sourceLesson}}
  let targetKeyParams = inProductions ? {curriculum: {S: curriculum}, id: {S: targetLesson}} : {teacher: {S: teacher}, id: {S: targetLesson}}
  database_duplicateItem(sourceTable, targetTable, sourceKeyParams, targetKeyParams, true, inProductions, inProductions ? {'random-key': {S: curriculumData['random-key']}} : undefined, 'duplicating lesson data', onSuccessFn, onFailureFn)
}

// adds a new review entry in the database:
export function database_addNewReview(reviewData, curriculum, teacher, curriculumData, onSuccessFn, onFailureFn) {
  let submissionDate = '' + Utility.getEpochTime()  
  reviewData.curriculum = curriculum
  reviewData.teacher = teacher
  reviewData.reviewer = globals.sessionInfo.username
  // add subject attribute so to be able to do getItem to get the belonging curriculum entry:
  reviewData.subject = curriculumData.subject
  reviewData['date-last-updated'] = submissionDate
  let item = convertKeyValuesToTypedKeyValuesForDatabase(dbConfig.reviewsTable, reviewData)  
  // set active to false:
  item.active = {BOOL: false}
  let finalOnSuccessFn = (data) => {
    // lambda triggers should have set the active to true and moved it to live table:
    reviewData.active = true    
    // cache the new review data    
    cachedUserReviewsInfo[curriculum] = reviewData
    if (onSuccessFn !== undefined)
      onSuccessFn(data)
  }
  // check if a review for this curriculum by this user already exist or not first:
  database_putItem(dbConfig.reviewsSubmissionsTable, item, 'adding review data', finalOnSuccessFn, onFailureFn)
}

// updates an existing user review entry for curriculum in database:
export function database_updateReview(reviewData, curriculum, teacher, curriculumData, onSuccessFn, onFailureFn) {
  let user = globals.sessionInfo.username
  let cachedReviewData = cachedUserReviewsInfo[curriculum]  
  // update cached teachers info on success (and run users any onSuccessFn too):  
  let finalOnSuccessFn = (data) => {    
    Object.keys(reviewData).forEach(f => cachedReviewData[f] = reviewData[f])    
    if (onSuccessFn !== undefined)
      onSuccessFn(data)
  }
  let isDirectUpdate = !cachedReviewData.active
  // if it's not a direct update (review is live):
  let indirectUpdateTypedExtraValues = undefined
  if (!isDirectUpdate) {
    // add subject/teacher attributes so to be able to do getItem to get the belonging curriculum / teacher entries
    let subject = curriculumData.subject
    reviewData.subject = subject
    reviewData.teacher = teacher
    indirectUpdateTypedExtraValues = {teacher: {S: teacher}, subject: {S: subject}}
    // if the 'rating' attribute was updated, add an extra 'old-rating' attribute to the put item db operation to help the lambdas in calculating updated rating for the curriculum/teacher:
    if (reviewData.rating !== undefined && reviewData.rating !== cachedReviewData.rating)
      reviewData['old-rating'] = cachedReviewData.rating
  }
  database_updateItemIfChanged(dbConfig.reviewsTable, cachedReviewData.active ? dbConfig.reviewsUpdatesTable : dbConfig.reviewsSubmissionsTable, isDirectUpdate, {reviewer: {S: user}, curriculum: {S: curriculum}}, 'updating review info', reviewData, cachedReviewData, 'date-last-updated', false, indirectUpdateTypedExtraValues, finalOnSuccessFn, onFailureFn)  
}

// adds a new proposed subject in the subjects proposals table in the db:
export function database_addNewSubjectProposal(curriculum, subject, curriculumData) {
  database_putItem(dbConfig.subjectsProposalsTable, {teacher: {S: globals.sessionInfo.username}, 'date-last-updated': {N: '' + Utility.getEpochTime()}, subject: {S: subject}, curriculum: {S: curriculum}, title: {S: curriculumData.title}, 'in-productions': {BOOL: curriculumData['in-productions']}, 'is-subject-proposal': {BOOL: true}}, 'submitting new subject proposal')
}

// adds a new proposed topic under a given subject in the topics proposals table in the db:
export function database_addNewTopicProposal(curriculum, subject, topic, curriculumData) {  
  database_putItem(dbConfig.topicsProposalsTable, {teacher: {S: globals.sessionInfo.username}, 'date-last-updated': {N: '' + Utility.getEpochTime()}, subject: {S: subject}, topic: {S: topic}, curriculum: {S: curriculum}, title: {S: curriculumData.title}, 'in-productions': {BOOL: curriculumData['in-productions']}, 'is-subject-proposal': {BOOL: false}}, 'submitting new topic proposal')
}

// adds a new proposed language in the languages proposals table in the db:
export function database_addNewLanguageProposal(curriculum, subject, language, curriculumData) {
  database_putItem(dbConfig.languagesProposalsTable, {teacher: {S: globals.sessionInfo.username}, 'date-last-updated': {N: '' + Utility.getEpochTime()}, subject: {S: subject}, 'the-language': {S: language}, curriculum: {S: curriculum}, title: {S: curriculumData.title}, 'in-productions': {BOOL: curriculumData['in-productions']}}, 'submitting new language proposal')
}


/*
  Admin Database functions
*/

// Fetch the data for the admin user in session (if any) from the database:
export function database_fetchAdminUserData() {
  let sessionInfo = globals.sessionInfo
  if (!sessionInfo) return  
  // don't query database if have already fetched it:
  if (cachedAdminUserInfo !== undefined) {
    globals.currentPage.adminUserDataFetched()
    return
  }
  if (adminUserDataFetchRequested)
    return
  adminUserDataFetchRequested = true
  let username = sessionInfo.username
  let operationEndedFn = () => {
    globals.currentPage.adminUserDataFetched()
  }
  database_getItem(dbConfig.adminsTable, {username: {S: username}}, 'fetching admin user info', function(data) {
    if (data.Item !== undefined) {
      console.log('Successfully signed into admin role...')      
      cachedAdminUserInfo = cacheFetchedData(dbConfig.adminsTable, data.Item)        
    }
    operationEndedFn()
  }, operationEndedFn)
}

// run admin only database functions with a check first if admin role is granted:
export function admin_runFunction(fnName, fnArgs) {
  let err = false
  if (!isAdminUserDataFetched())
    err = 'Error: Only admins can run function ' + fnName  
  let fn = admin_functions[fnName]
  if (fn === undefined)
    err = 'Error: No such admin function ' + fnName    
  if (err) {
    console.log(err)
    return err
  }
  return fn(...fnArgs)  
}

// list of admin only functions:
const admin_functions = {

  // when admin approves a new subject proposal, we assign the new subject a sort-index (highest + 1000):
  generateNewSubjectSortIndex: () => {   
    let highestIndex = 0
    for (let subject in cachedSubjectsInfo) {
      let sortIndex = cachedSubjectsInfo[subject]['sort-index']
      if (sortIndex > highestIndex)
        highestIndex = sortIndex
    }
    return highestIndex + 1000
  },

  // check if primary key for subjects table "sort-index" already exists:
  subjectSortIndexExists:  (sortIndex) => {    
    for (let subject in cachedSubjectsInfo) {
      if (('' + sortIndex) === ('' + cachedSubjectsInfo[subject]['sort-index']))
        return true
    }
    return false
  },

  topicSortIndexExists: (sortIndex) => {
    for (let subject in cachedSubjectsInfo) {
      if (('' + sortIndex) === ('' + cachedSubjectsInfo[subject]['sort-index']))
        return true
    }
    return false
  },

  // get list of ids of submitted curriculums (if fetched already):
  getSubmittedCurriculums: () => {    
    return Object.keys(cachedAllCurriculums).filter(id => cachedAllCurriculums[id]['in-submissions'])
  },

  // get list of proposed subjects:
  getProposedSubjects: () => {  
    return cachedProposedSubjectsInfo !== undefined ? Object.keys(cachedProposedSubjectsInfo) : []
  },

  // get list of proposed topics:
  getProposedTopics: () => {  
    return cachedProposedTopicsInfo !== undefined ? Object.keys(cachedProposedTopicsInfo) : []
  },

  // get list of proposed languages:
  getProposedLanguages: () => {  
    return cachedProposedLanguagesInfo !== undefined ? Object.keys(cachedProposedLanguagesInfo) : []
  },

  getProposedSubjectData: (subjectTitleKey) => {
    return cachedProposedSubjectsInfo[subjectTitleKey]    
  },

  getProposedTopicData: (topicTitleKey) => {    
    return cachedProposedTopicsInfo[topicTitleKey]  
  },

  getProposedLanguageData: (languageTitleKey) => {    
    return cachedProposedLanguagesInfo[languageTitleKey]  
  },

  getProposedSubjectTitle: (subjectTitleKey) => {        
    let res = cachedProposedSubjectsInfo[subjectTitleKey]
    return res ? (res.title || subjectTitleKey) : subjectTitleKey
  },

  getProposedTopicTitle: (topicTitleKey) => {
    let res = cachedProposedTopicsInfo[topicTitleKey]
    return res ? (res.title || topicTitleKey) : topicTitleKey
  },

  // fetches proposed subjects table from the database
  database_fetchProposedSubjectsData: () => {  
    database_scanTable(dbConfig.subjectsProposalsTable, undefined, undefined, undefined, undefined, undefined, 'fetching proposed subjects info', function(data) {    
      if (cachedProposedSubjectsInfo === undefined)
        cachedProposedSubjectsInfo = {}
      data.Items.forEach(subjectInfo => {
        let subject = subjectInfo.subject.S
        let keyAddr = 2
        // make sure we have unique entries (just in case multiple people have proposed same subject string):
        if (cachedProposedSubjectsInfo[subject] !== undefined) {
          while (cachedProposedSubjectsInfo[subject + '#' + keyAddr] !== undefined)
            keyAddr++
          subject += '#' + keyAddr
        }
        cachedProposedSubjectsInfo[subject] = cacheFetchedData(dbConfig.subjectsProposalsTable, subjectInfo)
      })    
      // tell home page to render components who needed the database data:    
      globals.currentPage.proposedSubjectsDataFetched()    
    })
  },

  // fetches proposed topics table from the database
  database_fetchProposedTopicsData: () => {  
    database_scanTable(dbConfig.topicsProposalsTable, undefined, undefined, undefined, undefined, undefined, 'fetching proposed topics info', function(data) {    
      if (cachedProposedTopicsInfo === undefined)
        cachedProposedTopicsInfo = {}
      data.Items.forEach(topicInfo => {
        let topic = topicInfo.topic.S
        let keyAddr = 2
        // make sure we have unique entries (just in case multiple people have proposed same subject string):
        if (cachedProposedTopicsInfo[topic] !== undefined) {
          while (cachedProposedTopicsInfo[topic + '#' + keyAddr] !== undefined)
            keyAddr++
          topic += '#' + keyAddr
        }      
        cachedProposedTopicsInfo[topic] = cacheFetchedData(dbConfig.topicsProposalsTable, topicInfo)
      })
      // tell home page to render components who needed the database data:    
      globals.currentPage.proposedTopicsDataFetched()    
    })
  },

  // fetches proposed languages table from the database
  database_fetchProposedLanguagesData: () => {  
    database_scanTable(dbConfig.languagesProposalsTable, undefined, undefined, undefined, undefined, undefined, 'fetching proposed languages info', function(data) {    
      if (cachedProposedLanguagesInfo === undefined)
        cachedProposedLanguagesInfo = {}
      data.Items.forEach(languageInfo => {
        let language = languageInfo['the-language'].S
        let keyAddr = 2
        // make sure we have unique entries (just in case multiple people have proposed same subject string):
        if (cachedProposedLanguagesInfo[language] !== undefined) {
          while (cachedProposedLanguagesInfo[language + '#' + keyAddr] !== undefined)
            keyAddr++
          language += '#' + keyAddr
        }      
        cachedProposedLanguagesInfo[language] = cacheFetchedData(dbConfig.languagesProposalsTable, languageInfo)
      })
      // tell home page to render components who needed the database data:    
      globals.currentPage.proposedLanguagesDataFetched()    
    })
  }, 

  // adds a new subject to the subjects table, and then removes the proposal entry from the subjects proposal table
  // isProposalFromCurriculumSubmission = true --> admin is submitting subject proposed by another teaching who submitted a new curriculum
  // isProposalFromCurriculumSubmission = false --> admin is submitting his/her own new subject and not because of a curriculum submission
  database_addNewSubject: (subjectData, proposedSubjectData, isProposalFromCurriculumSubmission, onSuccessFn, onFailureFn) => {
    let subject = subjectData.subject
    let title = subjectData.title
    let item = {subject: {S: subject}, 'sort-index': {N: '' + subjectData['sort-index']}, prefix: {S: subjectData.prefix}, icon: {S: subjectData.icon}, title: {S: title}, 'num-downloads': {N: '0'}, active: {BOOL: true}}
    let finalOnSuccessFn = (data) => {
      // update cache
      delete cachedProposedSubjectsInfo[title]      
      if (onSuccessFn !== undefined)
        onSuccessFn(data)
      return true
    }
    let onAddSubjectFn = () => {
      // update cache:
      subjectData.topics = []
      cachedSubjectsInfo[subject] = subjectData
      // step 2: if this came from a curriculum submission, remove subject proposal from subject proposal table:
      if (isProposalFromCurriculumSubmission)
        database_deleteItem(dbConfig.subjectsProposalsTable, {teacher: {S: proposedSubjectData.teacher}, 'date-last-updated': {N: '' + proposedSubjectData['date-last-updated']}}, 'deleting subject proposal entry', finalOnSuccessFn, onFailureFn)
      else
        finalOnSuccessFn()
    }
    // step 1: add new subject to subjects table
    database_putItem(dbConfig.subjectsTable, item, 'adding new subject', onAddSubjectFn, onFailureFn)
  },

  // adds a new topic to the subjects table, and then removes the proposal entry from the topics proposal table
  // isProposalFromCurriculumSubmission = true --> admin is submitting subject proposed by another teaching who submitted a new curriculum
  // isProposalFromCurriculumSubmission = false --> admin is submitting his/her own new subject and not because of a curriculum submission
  database_addNewTopic: (topicData, proposedTopicData, isProposalFromCurriculumSubmission, onSuccessFn, onFailureFn) => {
    let subject = topicData.subject
    let topic = topicData.topic
    let title = topicData.title
    let item = {topic: {S: topic}, subject: {S: subject}, title: {S: title}, 'num-downloads': {N: '0'}, active: {BOOL: true}}    
    let finalOnSuccessFn = (data) => {
      // update cache
      delete cachedProposedTopicsInfo[title]
      if (onSuccessFn !== undefined)
        onSuccessFn(data)
      return true
    }
    let onAddTopicFn = () => {
      // update cache:
      cachedTopicsInfo[topic] = topicData
      let subjectData = cachedSubjectsInfo[subject]
      if (subjectData.topics === undefined)
        subjectData.topics = []
      subjectData.topics.push(topic)
      // step 2: if this came from a curriculum submission, remove topic proposal from topic proposal table:
      if (isProposalFromCurriculumSubmission)
        database_deleteItem(dbConfig.topicsProposalsTable, {teacher: {S: proposedTopicData.teacher}, 'date-last-updated': {N: '' + proposedTopicData['date-last-updated']}}, 'deleting topic proposal entry', finalOnSuccessFn, onFailureFn)
      else
        finalOnSuccessFn()
    }
    // step 1: add new subject to subjects table
    database_putItem(dbConfig.topicsTable, item, 'adding new topic', onAddTopicFn, onFailureFn)
  },

  // submits the outcome of curriculum approval process by adding an entry to the approved-queue curriculums table (with field: approved = true or false):
  database_submitCurriculumApprovalOutcome: (id, curriculumData, doApprove, rejectionMessage, onSuccessFn, onFailureFn) => {
    doApprove = doApprove === true
    var err = false
    if (!curriculumData['in-submissions'])
      err = 'Curriculum ' + id + ' is not in submissions'
    else if (!doApprove && !rejectionMessage) 
      err = 'A rejection outcome requires a message for the teacher'
    if (err) {
      console.log('Error: ' + err)
      if (onFailureFn !== undefined)
        onFailureFn()
      return
    }
    let item = {teacher: {S: curriculumData.teacher}, id: {S: id}, 'date-last-updated': {N: '' + Utility.getEpochTime()}, 'random-key': {S: curriculumData['random-key']}, subject: {S: curriculumData.subject}, approved: {BOOL: doApprove}}
    if (!doApprove)
      item['rejection-message'] = {S: rejectionMessage}
    database_putItem(dbConfig.curriculumsApprovedQueueTable, item, 'submitting curriculum approval outcome', onSuccessFn, onFailureFn)
  },

  // submits to the database a curriculum purchase manually entered by the admin
  // (used when purchases are made on external sites such as TpT and we want to 
  // transfer the purchase to our site too so that user can access their curriculum)
  database_adminCurriculumPurchase: (checkoutData, onSuccessFn, onFailureFn) => {    
    let subjectFields = dbConfig.tableCurriculumToSubjectMappings[dbConfig.usersTable]
    let boughtSubjectsField = subjectFields.bought    
    let cart = checkoutData.curriculums
    let cartSubjects = checkoutData.curriculumSubjects
    let promoCodes = checkoutData.promoCodes
    let boughtDate = Utility.getEpochTime()
    let boughtDateStr = '' + boughtDate
    checkoutData.transactionId = 'admin_purchase_transaction_' + boughtDateStr    
    let putEntryOnCheckoutQueueTableFn = () => {
      // 2. put an entry on the checkout queue table so that lambda functions update download counts for the curriculums and teachers in the cart:       
      database_putItem(dbConfig.checkoutQueueTable, {username: {S: checkoutData.username}, 'date-purchased': {N: boughtDateStr}, 'transaction-id': {S: checkoutData.transactionId}, 'curriculums': {L: cart.map(c => {return {S: c}})}, subjects: {L: cartSubjects.map(s => {return {S: s}})}, 'promo-codes': {L: promoCodes.map(c => {return {S: c}})}, 'prices-local': {L: checkoutData.pricesLocal.map(p => {return {N: '' + p}})}, 'prices-usd': {L: checkoutData.pricesUSD.map(p => {return {N: '' + p}})}, 'total-price-usd': {N: '' + checkoutData.total.USD}, 'total-price-local': {N: '' + checkoutData.total.local}, country: {S: payConfig.country}, 'currency-symbol': {S: payConfig.currencySymbol}}, 'marking checkout completion', onSuccessFn, onFailureFn)
    }
    let reservedAttrVarNameSubjects = getAttributeNameVarForDatabaseOperation(boughtSubjectsField)
    let reservedAttrVarNameBoughtDate = getAttributeNameVarForDatabaseOperation('bought-date')
    let reservedAttrVarNameBoughtDownloaded = getAttributeNameVarForDatabaseOperation('bought-downloaded')
    let expressionAttributeNames = {}    
    expressionAttributeNames[reservedAttrVarNameSubjects] = boughtSubjectsField
    expressionAttributeNames[reservedAttrVarNameBoughtDate] = 'bought-date'
    expressionAttributeNames[reservedAttrVarNameBoughtDownloaded] = 'bought-downloaded'
    let expressionAttributeListItemTypes = [
      'S',    // bought
      'S',    // bought-subjects
      'N',    // bought-date
      'BOOL'  // bought-downloaded
    ]
    let operations = [
      'bought = list_append(:valuesToTake0, bought)', 
      reservedAttrVarNameSubjects + ' = list_append(:valuesToTake1, ' + reservedAttrVarNameSubjects + ')',
      reservedAttrVarNameBoughtDate + ' = list_append(:valuesToTake2, ' + reservedAttrVarNameBoughtDate + ')',
      reservedAttrVarNameBoughtDownloaded + ' = list_append(:valuesToTake3, ' + reservedAttrVarNameBoughtDownloaded + ')',
    ]
    // 1. update list of purchased curriculums by for the user:
    let count = cart.length    
    database_updateList(true, dbConfig.usersTable, 'username', checkoutData.username, expressionAttributeListItemTypes, operations, expressionAttributeNames, [cart, cartSubjects, Array(count).fill().map(i => boughtDateStr), Array(count).fill().map(i => false)], putEntryOnCheckoutQueueTableFn, onFailureFn)    
  },

  // DDB updateItem:
  database_updateItem: (table, keyParams, updateExpression, expressionAttributeNames, expressionAttributeValues, operationDescription, onSuccessFn, onFailureFn) => {
    database_updateItem(table, keyParams, updateExpression, expressionAttributeNames, expressionAttributeValues, operationDescription, onSuccessFn, onFailureFn)
  },

  // DDB putItem:
  database_putItem: (table, item, operationDescription, onSuccessFn, onFailureFn) => {
    database_putItem(table, item, operationDescription, onSuccessFn, onFailureFn)
  }

}

/*
  DynamoDB Database Primitive Operations & any helpers:
*/

// returns a handler function for all database operations (get, put, update) to handler success/failure
// used as a helper function for operation functions below...:
function getDatabaseOperationResultHandler(operationDescription, onSuccessFn, onFailureFn) {
  operationDescription = operationDescription || 'performing database opertion'
  return (err, data) => {
    if (err) {
      let errorMessage = 'There was a problem ' + operationDescription + '!' + '\n"' + err.toString() + '"'
      console.log('Error', errorMessage, err.stack)
      if (onFailureFn !== undefined)
        onFailureFn(errorMessage)
    } else {
      //console.log('Success', operationDescription + '...')
      if (onSuccessFn !== undefined)
        onSuccessFn(data)
    }
  }
}

// converts table key/value pairs to key/typed value for dynamodb database:
function convertKeyValuesToTypedKeyValuesForDatabase(table, values) {
  let typedValues = {}
  let fieldTypes = dbConfig.tableFields[table]
  Object.keys(values).forEach(field => {
    let fieldType = fieldTypes[field]        
    let value = values[field]  
    typedValues[field] = getTypedValueForDatabase(fieldType, value)  
  })
  return typedValues
}

// converts value to typed value for dynamodb database:
function getTypedValueForDatabase(fieldType, value) {
  let isListType = Array.isArray(fieldType)
  let attrVal = {}
  if (isListType) {
    let dataType = fieldType[1]
    fieldType = 'L'
    let isBoolDataType = value.length > 0 && (typeof value[0] === 'boolean')
    let elementFn = isBoolDataType ?
      v => { 
        let typedVal = {} 
        typedVal[dataType] = v
        return typedVal
      } :
      v => { 
        let typedVal = {} 
        typedVal[dataType] = '' + v
        return typedVal
      }
    attrVal[fieldType] = value.map(elementFn)
  } else
    attrVal[fieldType] = (typeof value === 'boolean') ? value : ('' + value)
  return attrVal
}

// tests whether the name of a field can show up directly in a DynamoDB opertion or need to create an attribute expression name for it (being reserved or containing dashes):
function isReservedDatabaseAttributeName(field) {
  return !(DynamoDB_reservedAttributeNames.indexOf(field) === -1 && field.indexOf('-') === -1)
}

// In the above case, need to create a expression attribute name variable to be used in a DynamoDB operation:
function getAttributeNameVarForDatabaseOperation(field) {  
  return '#' + field.charAt(0) + (attributeNameValueVarCtr++)
}

// Generates an expression attribute value variable for updating a field to be used in a DynamoDB operation:
function getAttributeValueVarForDatabaseOpeartion(field) {
  return ':' + field.charAt(0) + (attributeNameValueVarCtr++)
}

// given a field and its new update value (typed for DynamoDB), add the corresponding update expression, expression attribute name, and expression attribute values to the inputs:
function addUpdateExpressionAndExpressionAttributeNameVarsForFieldUpdate(field, typedUpdateValue, updateExpressions, expressionAttributeNames, expressionAttributeValues) {
  var updateExprFieldName = field
  if (isReservedDatabaseAttributeName(field)) {
    let reservedAttrVarName = getAttributeNameVarForDatabaseOperation(field)
    expressionAttributeNames[reservedAttrVarName] = field
    updateExprFieldName = reservedAttrVarName
  }
  let attributeValuesVar = getAttributeValueVarForDatabaseOpeartion(field)
  expressionAttributeValues[attributeValuesVar] = typedUpdateValue
  updateExpressions.push(updateExprFieldName + ' = ' + attributeValuesVar)
}

// add or remove a set of items to/from a set in the database:
function database_updateSet(table, key, keyValue, updateExpression, expressionAttributeNames, valuesToAddOrRemove, onSuccessFn, onFailureFn) {
  let keyParams = {}
  keyParams[key] = {S: keyValue}
  let expressionAttributeValues = {
    ':valuesToTake': {SS: valuesToAddOrRemove}
  }
  database_updateItem(table, keyParams, updateExpression, expressionAttributeNames, expressionAttributeValues, 'updating info in table ' + table, onSuccessFn, onFailureFn)
}

// add or remove a list of list attributes to/from a row in the database:
function database_updateList(isAdd, table, key, keyValue, expressionAttributeListItemTypes, updateExpressionList, expressionAttributeNames, valuesToAddOrRemoveList, onSuccessFn, onFailureFn) {
  let keyParams = {}
  keyParams[key] = {S: keyValue}  
  let expressionAttributeValues = undefined
  if (isAdd) {
    expressionAttributeValues = {}
    valuesToAddOrRemoveList.forEach((valuesToAddOrRemove, index) => {
      let fieldType = expressionAttributeListItemTypes[index]
      expressionAttributeValues[':valuesToTake' + index] = {L: valuesToAddOrRemove.map(valueToAddOrRemove => { let res = {}; res[fieldType] = valueToAddOrRemove; return res })}
    })
  }
  database_updateItem(table, keyParams, (isAdd ? 'set ': 'remove ') + updateExpressionList.join(', '), expressionAttributeNames, expressionAttributeValues, 'updating list in table ' + table, onSuccessFn, onFailureFn) 
}

// Updats fields (only those which have new values compared to current values) of an item's row in a table in database:
// if isDirectUpdate: tableToUpdate will be directly updated (caller chooses if its the original table or a different table). Otherwise, it's not a direct table update. Instead an 'update' entry will be put in the updates table, and a lambda trigger will push the update to the actual table upon approval....
// for updating teachers or curriculums tables: currently we do a direct update to submissions table if the entry is still there in submissions (not live/active yet). Otherwise if its already active in official table, instead of direct update a new update entry will be put in the "updates" table for teachers/curriculums
// if forceDbUpdate flag is true (set when fileserver files were updated as a result of teachers edits), the caller wants to force an update to the db entry (e.g. last-updated-date) even if no other fields are being updated
// if indirect update, optionalIndirectUpdateTypedExtraValues can have extra key/value pairs that will be included in the entry being put in the updates-queue tables (typed values ready for DynamoDB)...
function database_updateItemIfChanged(table, tableToUpdate, isDirectUpdate, keyParams, operationDescription, newValues, currentValues, updateLastUpdatedTimestampField, forceDbUpdate,  optionalIndirectUpdateTypedExtraValues, onSuccessFn, onFailureFn) {  
  let indirectUpdateItem = {}
  // check which fields actually need to be updated in database (as current values are different than new values given here):
  var updateExpressions = []
  var expressionAttributeNames = {}
  var expressionAttributeValues = {}
  let fieldTypes = dbConfig.tableFields[table]
  var anyFieldsUpdated = forceDbUpdate // force a db update (even if no db fields updated) in case fileserver changes were made...
  Object.keys(newValues).forEach(field => {
    let fieldType = fieldTypes[field]
    let isListType = Array.isArray(fieldType)
    let currValue = currentValues[field]
    let newValue = newValues[field] 
    let changed = isListType ? JSON.stringify(currValue) !== JSON.stringify(newValue) : currValue !== newValue
    if (changed) {
      //console.log(field, 'old', currValue, 'new', newValue)
      anyFieldsUpdated = true
      let typedUpdateValue = getTypedValueForDatabase(fieldType, newValue)
      if (isDirectUpdate) {
        addUpdateExpressionAndExpressionAttributeNameVarsForFieldUpdate(field, typedUpdateValue, updateExpressions, expressionAttributeNames, expressionAttributeValues)
      } else {
        indirectUpdateItem[field] = typedUpdateValue
      }
    }
  })
  if (!anyFieldsUpdated) {
    // nothing to update in db because all values there equal new values given here... just run the onSuccessFn here:
      //console.log('No database changes on operation', operationDescription + '...')
      if (onSuccessFn !== undefined)
        onSuccessFn()
  } else {
    // update last updated timestamp field also if caller wanted to:
    if (updateLastUpdatedTimestampField) {
      let field = updateLastUpdatedTimestampField
      let fieldType = 'N'
      let newValue = Utility.getEpochTime()
      currentValues[field] = newValue
      let typedUpdateValue = getTypedValueForDatabase(fieldType, newValue)
      if (isDirectUpdate) {
        addUpdateExpressionAndExpressionAttributeNameVarsForFieldUpdate(field, typedUpdateValue, updateExpressions, expressionAttributeNames, expressionAttributeValues)
      } else {
        indirectUpdateItem[field] = typedUpdateValue
      }
    }
    // compute the final set operation:
    let updateExpression = 'set ' + (updateExpressions.join(', '))
    expressionAttributeNames = (Object.keys(expressionAttributeNames).length > 0) ?
      expressionAttributeNames : undefined
    if (isDirectUpdate) {
      database_updateItem(tableToUpdate, keyParams, updateExpression, expressionAttributeNames, expressionAttributeValues, operationDescription, onSuccessFn, onFailureFn)
    } else {      
      Object.keys(keyParams).forEach(k => indirectUpdateItem[k] = keyParams[k])
      if (optionalIndirectUpdateTypedExtraValues !== undefined) {
        Object.keys(optionalIndirectUpdateTypedExtraValues).forEach(k => {
          indirectUpdateItem[k] = optionalIndirectUpdateTypedExtraValues[k]
        })
      }
      database_putItem(tableToUpdate, indirectUpdateItem, operationDescription, onSuccessFn, onFailureFn)
    }
  }
}

// Scan a table in database:
// (keyConditionExpression is not really a scan param. It was organized for convenience to match query parameters. If provided it will be just conjuncted with filter expression...)
function database_scanTable(table, expressionAttributeNames, expressionAttributeValues, keyConditionExpression, filterExpression, otherParams, operationDescription, onSuccessFn, onFailureFn) {
  let params = {
    TableName: table,
    //IndexName: dbConfig.sortIndexKey
  }
  if (expressionAttributeNames !== undefined)
    params.ExpressionAttributeNames = expressionAttributeNames
  if (expressionAttributeValues !== undefined)
    params.ExpressionAttributeValues = expressionAttributeValues
  if (keyConditionExpression !== undefined)
    params.FilterExpression = keyConditionExpression
  if (filterExpression !== undefined)
    params.FilterExpression = (keyConditionExpression ? (keyConditionExpression + ' and ') : '') + filterExpression
  // if table has 'active' flag to deactivate items, add filter to get only items whose 'active' flag is set to 'true' to make sure we don't show disabled items:
  if (dbConfig.tablesWithDeactivableItems.indexOf(table) !== -1) {
    if (params.ExpressionAttributeValues === undefined)
      params.ExpressionAttributeValues = {}
    params.ExpressionAttributeValues[':true'] = {BOOL: true}
    let activeCheck = 'active = :true'
    if (params.FilterExpression === undefined)
      params.FilterExpression = activeCheck
    else 
      params.FilterExpression = '(' + params.FilterExpression + ') and ' + activeCheck
  }
  if (otherParams !== undefined)
    Object.keys(otherParams).forEach(p => { params[p] = otherParams[p]})
  //console.log('scanTable', params)
  DynamoDB.scan(params, getDatabaseOperationResultHandler(operationDescription, onSuccessFn, onFailureFn))
}

// Scan a table in database:
// run synchronously (wait for it to finish and return the result):
export async function database_async_scanTable(table) {
  let params = {
    TableName: table,
    //IndexName: dbConfig.sortIndexKey
  }  
  //console.log('scanTable', params)
  return await DynamoDB.scan(params).promise()
}

// Query a table in database
function database_queryTable(table, expressionAttributeNames, expressionAttributeValues, keyConditionExpression, filterExpression, otherParams, operationDescription, onSuccessFn, onFailureFn) {
  let params = {
    TableName: table,
    //IndexName: dbConfig.sortIndexKey
  }
  if (expressionAttributeNames !== undefined)
    params.ExpressionAttributeNames = expressionAttributeNames
  if (expressionAttributeValues !== undefined)
    params.ExpressionAttributeValues = expressionAttributeValues
  if (keyConditionExpression !== undefined)
    params.KeyConditionExpression = keyConditionExpression
  if (filterExpression !== undefined)
    params.FilterExpression = filterExpression
  // if table has 'active' flag to deactivate items, add filter to get only items whose 'active' flag is set to 'true' to make sure we don't show disabled items:
  if (dbConfig.tablesWithDeactivableItems.indexOf(table) !== -1) {
    if (params.ExpressionAttributeValues === undefined)
      params.ExpressionAttributeValues = {}
    params.ExpressionAttributeValues[':true'] = {BOOL: true}
    let activeCheck = 'active = :true'
    if (params.FilterExpression === undefined)
      params.FilterExpression = activeCheck
    else 
      params.FilterExpression += ' and ' + activeCheck
  }  
  if (otherParams !== undefined)
    Object.keys(otherParams.keys(otherParams)).forEach(p => { params[p] = otherParams[p]})
  //console.log('queryTable', params)
  DynamoDB.query(params, getDatabaseOperationResultHandler(operationDescription, onSuccessFn, onFailureFn))
}

// Get a row of data from a table in database
function database_getItem(table, keyParams, operationDescription, onSuccessFn, onFailureFn) {
  let params = {
    TableName: table,
    Key: keyParams
  }
  //console.log('getItem', params)
  DynamoDB.getItem(params, getDatabaseOperationResultHandler(operationDescription, onSuccessFn, onFailureFn))
}

// Updata fields of an item's row in a table in database
function database_updateItem(table, keyParams, updateExpression, expressionAttributeNames, expressionAttributeValues, operationDescription, onSuccessFn, onFailureFn) {
  let params = {
    TableName: table,
    Key: keyParams,
    UpdateExpression: updateExpression,
    ReturnValues: 'UPDATED_NEW'
  }
  if (expressionAttributeNames !== undefined)
    params.ExpressionAttributeNames = expressionAttributeNames
  if (expressionAttributeValues !== undefined)
    params.ExpressionAttributeValues = expressionAttributeValues  
  //console.log('updateItem', params)
  DynamoDB.updateItem(params, getDatabaseOperationResultHandler(operationDescription, onSuccessFn, onFailureFn))
}

// To run synchronously (wait for it to finish and return the result):
async function database_sync_updateItem(table, keyParams, updateExpression, expressionAttributeNames, expressionAttributeValues) {
  let params = {
    TableName: table,
    Key: keyParams,
    UpdateExpression: updateExpression,
    ReturnValues: 'UPDATED_NEW'
  }
  if (expressionAttributeNames !== undefined)
    params.ExpressionAttributeNames = expressionAttributeNames
  if (expressionAttributeValues !== undefined)
    params.ExpressionAttributeValues = expressionAttributeValues
  return await DynamoDB.updateItem(params).promise()
}

// Insert a new row of data to a table in database
function database_putItem(table, item, operationDescription, onSuccessFn, onFailureFn) {
  let params = {
    TableName: table,
    ReturnConsumedCapacity: 'TOTAL',
    Item: item
  }
  ///console.log('putItem', params)
  DynamoDB.putItem(params, getDatabaseOperationResultHandler(operationDescription, onSuccessFn, onFailureFn))
}

// Remove an item's row from a table in database
function database_deleteItem(table, keyParams, operationDescription, onSuccessFn, onFailureFn) {
  let params = {
    TableName: table,
    Key: keyParams
  }  
  //console.log('deleteItem', params)
  DynamoDB.deleteItem(params, getDatabaseOperationResultHandler(operationDescription, onSuccessFn, onFailureFn))
}

// Get rows of data from a table in database
// (if there's more than limit (100) we'll do this in pieces...)
function database_batchGetItem(table, keys, operationDescription, onSuccessFn, onFailureFn) {
  let accumulatedData = undefined
  database_batchGetItem_oneRound(table, keys, operationDescription, onSuccessFn, onFailureFn, accumulatedData)
}

// helper for database_batchGetItem above:
function database_batchGetItem_oneRound(table, keys, operationDescription, onSuccessFn, onFailureFn, accumulatedData) {
  let limit = dbConfig.batchGetLimit
  let count = keys.length
  // nothing to do if count is zero:
  if (count === 0) {
    if (onSuccessFn !== undefined)
      onSuccessFn({Items: []})
    return
  }
  let withinLimit = count <= limit
  // pick the list of keys to be maxed out at the limit:
  let keysThisRound = withinLimit ? keys : keys.slice(0, limit)  
  let params = {
    RequestItems: {}
  }
  params.RequestItems[table] = {Keys: keysThisRound}
  //console.log('batchGetItems (batch of ' + keysThisRound.length + ')', params)
  let thisRoundOnSuccessFn = (data) => {
    let newData = data.Responses[table]
    if (accumulatedData === undefined)
      accumulatedData = newData
    else
      accumulatedData.push(...newData)
    if (withinLimit) {
      if (onSuccessFn !== undefined)
        onSuccessFn({Items: accumulatedData})
    } else {
      let keysNextRound = keys.slice(limit)  
      database_batchGetItem_oneRound(table, keysNextRound, operationDescription, onSuccessFn, onFailureFn, accumulatedData)
    }
  }
  DynamoDB.batchGetItem(params, getDatabaseOperationResultHandler(operationDescription, thisRoundOnSuccessFn, onFailureFn))
}

// Inserts/Delete rows of data to/from a table in database
// for Put set isPut = true, for Delete set isPut = false
// (if there's more than limit (25) we'll do this in pieces...)
function database_batchWriteItem(isPut, table, items, operationDescription, onSuccessFn, onFailureFn) {
  database_batchWriteItem_oneRound(isPut, table, items, operationDescription, onSuccessFn, onFailureFn)
}

// helper for database_batchWriteItem above:
function database_batchWriteItem_oneRound(isPut, table, items, operationDescription, onSuccessFn, onFailureFn) {
  let limit = dbConfig.batchWriteLimit
  let count = items.length
  // nothing to do if count is zero:
  if (count === 0) {
    if (onSuccessFn !== undefined)
        onSuccessFn()
    return
  }
  let withinLimit = count <= limit
  // pick the list of items to be maxed out at the limit:
  let itemsThisRound = withinLimit ? items : items.slice(0, limit)  
  let params = {
    RequestItems: {}
  }
  params.RequestItems[table] = isPut ?
    itemsThisRound.map(i => { return {PutRequest: {Item: i}} }) :
    itemsThisRound.map(i => { return {DeleteRequest: {Key: i}} })
  //console.log('batchWriteItems (batch of ' + itemsThisRound.length + ')', params)
  let thisRoundOnSuccessFn = (data) => {    
    if (withinLimit) {
      if (onSuccessFn !== undefined)
        onSuccessFn()
    } else {
      let itemsNextRound = items.slice(limit)     
      database_batchWriteItem_oneRound(isPut, table, itemsNextRound, operationDescription, onSuccessFn, onFailureFn)
    }
  }
  DynamoDB.batchWriteItem(params, getDatabaseOperationResultHandler(operationDescription, thisRoundOnSuccessFn, onFailureFn))
}

// Insert a new row clone (with new key params) of an existing row of data in a table in database
function database_duplicateItem(sourceTable, targetTable, sourceKeyParams, targetKeyParams, doUpdateDates, doAddIsFromCopyFlag = false, fieldsToAdd = undefined, operationDescription, onSuccessFn, onFailureFn) {
  targetTable = targetTable || sourceTable
  // 1. get the source item data in the table:
  database_getItem(sourceTable, sourceKeyParams, operationDescription + ': fetching source data', function(data) {
    let items = data.Item
    if (items) {      
      // update the key params for the duplicate:
      Object.keys(targetKeyParams).forEach(k => items[k] = targetKeyParams[k])
      // if the data has creation/update dates, make those up-to-date:
      if (doUpdateDates) {
        let duplicateDate = {N: '' + Utility.getEpochTime()}
        if (items['date-created'])  
          items['date-created'] = duplicateDate
        if (items['date-last-updated'])  
          items['date-last-updated'] = duplicateDate
      }
      // sets a flag so the lambda function knows this is a new entry caused by copy/paste action:
      if (doAddIsFromCopyFlag)
        items['is-from-copy'] = {BOOL: true}
      // add any other optional fields given:
      if (fieldsToAdd)
        Object.keys(fieldsToAdd).forEach(k => items[k] = fieldsToAdd[k])      
      // 2. put the duplicate item in the table:      
      database_putItem(targetTable, items, operationDescription + ': inserting duplicate data', onSuccessFn, onFailureFn)
    } else {      
        console.log('No item found with key', sourceKeyParams)
        if (onFailureFn !== undefined)
          onFailureFn()
    }    
  })  
}

