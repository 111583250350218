/*
    Creator site - Creator Curriculum View Page
    Views the listing for a given previously created curriculum by the creator teacher
*/

import React from 'react'

import * as Config from './config'
import * as Database from './database-api'
import CurriculumViewPage from './view'
import * as CreatorUtility from './creator-utility'
import * as Utility from './utility'

const globals = Config.globals

class CreatorCurriculumViewPage extends CurriculumViewPage {
    constructor(props) {
      super(props)
      this.pageId = 'creator-view'
      this.pageDir = 'creator/'
      // flag true for user site pages and false for creator site pages:
      this.isUserSite = false
      this.isCreatorSite = true
      this.fetchMissingSubmittedCurriculumsDataTried = false
      this.fetchMissingDraftedCurriculumsDataTried = false
      if (globals.haveLocalStorage) 
        this.lastTabOpen = localStorage.getItem('termeric-page-' + this.pageId + '-' + this.id + '-last-tab-open')
    }

    // override the superclass's userDataFetched which checks if user has bought the curriculum (not relevant here):
    userDataFetched() {
      this.updateTopMenuUserIconImage()
    }

    // Database API calls this when curriculum info for the given id is fetched. Here first we need to fetch the teacher's data:
    curriculumsDataFetched() { CreatorUtility.curriculumViewPageCurriculumsDataFetched(this) }
    
    // if this returns true the page proceeds to rendering
    // for this page we just need to make sure the user is the teacher for the curriculum being viewed (subclasses may want to redefine this...):
    curriculumTeacherCheckForPage(teacher) { return CreatorUtility.curriculumTeacherCheckForPage(teacher) }

    // which page to navigate to if curriculum with id on url params not found:
    accessNotAllowedNavigationPage() { return '/creator/library' }
  
    renderBody() {   
        return (
          <CreatorUtility.CreatorCurriculumViewPageBodyComponent page={this}/>
        )
    }

    // default render of a landing page without search bar in header...:
    render() { return Utility.renderNoSearchBarPage(this) }
}

export default CreatorCurriculumViewPage
