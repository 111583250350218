/*
    LessonPlan page
*/

import React from 'react'
import { Tabs, Tab, TabPanel, TabList } from 'react-web-tabs'
import 'react-web-tabs/dist/react-web-tabs.css'
import Collapsible from 'react-collapsible'

import * as Config from './config'
import * as Database from './database-api'
import * as Fileserver from './fileserver-api'
import * as Utility from './utility'

const globals = Config.globals

// ------------------------------------------------------------------------
/*
    LessonPlan Page
*/
class LessonPlanViewPage extends Utility.RestrictedAccessSessionLandingPage {
    constructor(props) {
        super(props)
        this.state = {
            dataFetched: false
        }
        this.pageId = 'lesson'
        this.curriculum = globals.urlParams.id
        this.lesson = globals.urlParams.lesson
        this.weekNumber = parseInt(globals.urlParams.week, 10)
        this.dayNumber = parseInt(globals.urlParams.day, 10)
        this.sectionNumber = parseInt(globals.urlParams.section, 10)
        this.lessonNumber = Math.max(1, parseInt(globals.urlParams.number, 10))        
        this.descriptionsDivContents = {}
        this.setDescriptionsDivContent = []
        this.activityDescriptionRefs = {}
        this.assessmentsRef = React.createRef()
        this.teacherNotesRef = React.createRef()
        this.slidesRef = React.createRef()
        this.otherResourcesRef = React.createRef()
        if (globals.haveLocalStorage)
            this.lastTabOpen = localStorage.getItem('termeric-page-' + this.pageId + '-' + this.lesson + '-last-tab-open')
    }

    componentDidMount() {            
        super.componentDidMount()    
        // if this page was loaded as a landing page, the superclass already initiates calls to the database to get the curriculum and teacher data. However, if we're here just by navigating from a previous page, the curriculums data was already fetched, so manually run the callback function to trigger getting teacher's data and render the page once the data is in:
        // if user has already bought the curr id we're currently viewing, then redirect to view page instead where they have full access to it:
        if (Database.isUserDataFetched())
            this.userDataFetched()
        if (Database.isCurriculumDataFetchedForId(this.curriculum)) 
            this.curriculumsDataFetched()
    }
    
    componentDidUpdate(prevProps, prevState, snapshot) {
        // make sure we need to proceed with rendering this page (not navigating already):
        if (!super.componentDidUpdate(prevProps, prevState, snapshot))
            return
        // if have received the description content from the external iframe, but we haven't yet set the content for the description div, set it here: 
        if (this.setDescriptionsDivContent.length > 0) {      
          this.insertContentFromExternalIframeIntoDOM(this.setDescriptionDivContent.slice())
        }  
        /*
        // bypass any browser cache on the iframe's src page to make sure we show up-to-date:
        // COMMENTED OUT: not needed??
        let iframeElement = document.getElementById('description-text-iframe-' + this.id)    
        if (iframeElement)
          iframeElement.contentWindow.location.href = iframeElement.src  
        */        
    }
    
    // Database API calls this when curriculum info for the given id is fetched. Here first we need to fetch the teacher's data:
    curriculumsDataFetched() {  
        super.curriculumsDataFetched()
        if (this.curriculumData) {            
            // make sure number is not out of range: 
            this.lessonNumber = Math.min(this.lessonNumber, this.curriculumData['num-lessons'])
            // check if user had previously marked this curriculum as wishlisted. Update wishlist button if so:        
            Database.database_fetchLessonData(this.curriculum, this.lesson)
        } else { // if there's no curriculum with the given id, it's an error. Redirect back to search page:            
            this.safeSetState({navigateTo: '/search'})
        }
    }

    // Database API calls this when teacher info for the given id is fetched. We'll trigger components that needed curriculum and teacher info to render now...
    lessonDataFetched() {
        this.lessonData = Database.getLessonData(this.curriculum, this.lesson)        
        if (this.lessonData) {
            // if this page is loaded as a landing page without having navigated from a curriculum view page, the cache below isn't set yet. do it now:        
            if (this.curriculumData['lessons-prev-next-info'] == null)
                Utility.renderListingContentCoursePlanAndStoreLessonsPrevNextLinksCache(this.curriculumData)
            this.safeSetState({dataFetched: true})
        } else { // if there's no lesson with the given id, it's an error. Redirect back to curriculum page:
            this.safeSetState({navigateTo: '/view?subject=' + this.subject + '&id=' + this.curriculum + '&random-key=' + this.curriculumData['random-key']}) 
        }
    }

    renderCTAs(curriculumData, lessonData) {
        let slidesIsExternal = lessonData['slides-is-external']
        return (
            <div id="top-lesson-listing-ctas" className="top-listing-image-and-ctas">
                {Utility.renderLink(<span><i className="fas fa-print"></i>&nbsp;&nbsp;&nbsp;Print lesson plan</span>, 'lesson-print-lesson-plan-button', 'wide-cta-text-button cta-text-button shadowed-text-button bordered-text-button text-button button', 'lesson-print?subject=' + this.subject + '&id=' + this.curriculum + '&random-key=' + curriculumData['random-key'] + '&section=' + this.sectionNumber + '&week=' + this.weekNumber + '&day=' + this.dayNumber + '&lesson=' + this.lesson + '&number=' + this.lessonNumber)}
                {Utility.renderButtonFromButton(<button><i className="fas fa-file-powerpoint"></i>&nbsp;&nbsp;&nbsp;{slidesIsExternal ? 'View' : 'Download'} slides</button>, 'lesson-download-slides-button', 'wide-cta-text-button cta-text-button shadowed-text-button bordered-text-button text-button button', slidesIsExternal ? e => {Utility.openExternalLink(lessonData.slidesUrl)} : e => { Fileserver.downloadContent(false, lessonData.slidesUrl) })}
            </div>
        )
    }

    renderLessonTopInfo() {
        let curriculumData = this.curriculumData            
        let lessonData = this.lessonData    
        let lessonNumber = this.lessonNumber        
        let gradeTitle = Database.getCurriculumGradeTitle(curriculumData)
        let lessonPrevNextLessonLinksInfo = curriculumData['lessons-prev-next-info'][lessonNumber - 1]
        let lessonPrevLinkInfo = lessonPrevNextLessonLinksInfo.prev
        let lessonNextLinkInfo = lessonPrevNextLessonLinksInfo.next
        return (
            <div id="lesson-body-top-listing" className="page-body-top-listing section">
                <div id="top-lesson-listing-info">
                    <div id="top-lesson-listing-classification" className="row-div">
                        {Utility.renderLink(curriculumData['topic-title'], 'top-curriculum-listing-topic', 'h5size', 'search?subject=' + curriculumData.subject + '&topic=' + curriculumData.topic)}
                        <i className="fas fa-angle-right row-padding-10"></i>
                        {Utility.renderLink(curriculumData.title + ' (' + gradeTitle + ')' , 'top-lesson-listing-curriculum-title', 'h5size', 'view?subject=' + curriculumData.subject + '&id=' + curriculumData.id + '&random-key=' + curriculumData['random-key'])}
                    </div><br/><br/>                    
                    <div id="lesson-number-and-prev-next-buttons" className="row-div bottom-margin25">
                        {this.lessonNumber === 1 ? null : Utility.renderReloadingLink(<span><i className="fas fa-angle-left"></i></span>, 'lesson-prev', 'size22 brown-color toggle-button toggle-link round-border image-button', this.pageDir + 'lesson?subject=' + curriculumData.subject + '&id=' + curriculumData.id + '&random-key=' + curriculumData['random-key'] + '&section=' + lessonPrevLinkInfo.section + '&week=' + lessonPrevLinkInfo.week+ '&day=' + lessonPrevLinkInfo.day + '&lesson=' + lessonPrevLinkInfo.lesson + '&number=' + (this.lessonNumber - 1))}
                        <div className='col-div'>
                            <span className="size20">Week {this.weekNumber}{curriculumData['num-lessons-per-week'] > 1 ? (' - Day ' + this.dayNumber) : ''}</span>
                            <span className="size25">Lesson {lessonNumber}</span>
                        </div>
                        {this.lessonNumber === curriculumData['num-lessons'] ? null : Utility.renderReloadingLink(<span><i className="fas fa-angle-right"></i></span>, 'lesson-next', 'size22 brown-color toggle-button toggle-link round-border image-button', this.pageDir + 'lesson?subject=' + curriculumData.subject + '&id=' + curriculumData.id + '&random-key=' + curriculumData['random-key'] + '&section=' + lessonNextLinkInfo.section + '&week=' + lessonNextLinkInfo.week + '&day=' + lessonNextLinkInfo.day + '&lesson=' + lessonNextLinkInfo.lesson + '&number=' + (this.lessonNumber + 1))}
                    </div>
                    <h3 id="top-lesson-listing-section-title" className="logo-font size22">{curriculumData['section-titles'][this.sectionNumber - 1]}</h3>
                    <h2 id="top-lesson-listing-title" className="logo-font size31">{curriculumData['lesson-titles'][this.lessonNumber - 1]}</h2>
                </div>  
                {this.renderCTAs(curriculumData, lessonData)}
            </div>
        )
    }

    renderDescription() {
        let lesson = this.lesson
        let descriptionDivRef = React.createRef()
        this.descriptionsDivs = {}
        this.descriptionsDivs[lesson] = descriptionDivRef
        return (
            <div id={'view-description-text-' + lesson} className="curriculum-about-item boxed-div" ref={descriptionDivRef}><em className="opac">description not available...</em></div>
        )
    }

    renderLessonContentAbout() {        
        let lessonData = this.lessonData
        let teacherRequirements = lessonData['teacher-requirements']
        let teacherRequirementsOptionals = lessonData['teacher-requirements-optionals']
        let studentRequirements = lessonData['student-requirements']
        let studentRequirementsOptionals = lessonData['student-requirements-optionals']
        return (
          <div id="lesson-body-listing-content-info" className="tab">
            <h4>Key Concepts &amp; Keywords</h4>
            {Utility.renderBulletListFromStringList('lesson-key-concepts', lessonData['key-concepts'])}
            <h4>Objectives</h4>
            {this.renderLessonContentObjectives()}
            <h4 id="lesson-description-title">Description</h4>        
            {this.renderDescription()}
            <h4>Requirements for teacher</h4>
            {Utility.renderBulletListFromStringList('lesson-requirements-teacher-text', [...teacherRequirements, ...teacherRequirementsOptionals], [...teacherRequirements.map(i => undefined), ...teacherRequirementsOptionals.map(i => 'optional')], true)}
            <h4 id="lesson-requirements-students-title">Requirements for students</h4>
            {Utility.renderBulletListFromStringList('lesson-requirements-student-text', [...studentRequirements, ...studentRequirementsOptionals], [...studentRequirements.map(i => undefined), ...studentRequirementsOptionals.map(i => 'optional')], true)}
          </div>
        )
    }

    renderLessonContentObjectives() {
        let lessonData = this.lessonData        
        return (
          <div id="lesson-body-listing-content-objectives" className="">            
            <h6>Students will be able to...</h6>
            {Utility.renderBulletListFromStringList('lesson-objectives', lessonData.objectives)}
          </div>
        )
    }

    // label for download resource button (undefined means using utility fns default...)
    getResourceDownloadLabel() {
        return undefined
    }

    renderLessonContentResources() {
        let lessonData = this.lessonData        
        return (            
          <div id="lesson-body-listing-content-resources" className="tab">
            <h4>Slides</h4>
            <h6>Lesson Slides</h6>
            {Utility.renderBulletListFromStringList('slides', ['Slides'], [lessonData.slidesUrl], false, this.slidesRef, [lessonData['slides-is-external']], this.getResourceDownloadLabel())}
            <h4>Other Resources</h4>        
            <h6>Activities involve the following resources:</h6>
            {Utility.renderBulletListFromStringList('other-resources', lessonData['other-resource-descriptions'], lessonData['other-resource-urls'], false, this.otherResourcesRef, lessonData['other-resource-is-externals'], this.getResourceDownloadLabel(), lessonData['other-resource-is-tests'], lessonData['other-resource-is-assignments'])}
          </div>
        )
    }

    renderLessonContentActivitiesHelper(idxs) {
        let lessonData = this.lessonData
        let activityTitles = lessonData['activity-titles']
        let activityDescriptions = lessonData['activity-descriptions']
        let activityDurations = lessonData['activity-durations']        
        return idxs.map(idx => {
            let activityTitle =  activityTitles[idx]
            let activityDescription =  activityDescriptions[idx] || ''
            let activityDuration = activityDurations[idx]
            let descriptionElementsRef = React.createRef()
            this.activityDescriptionRefs[idx] = descriptionElementsRef
            return (
                <Collapsible key={'activity-title-' + idx} trigger={<div className="margin20 lesson-activity-title-div"><span className="underlined">Activity {' ' + (idx + 1)}</span><span>: {activityTitle}</span><span className="lesson-activity-title-div-duration">{activityDuration} min.</span></div>} open={true} transitionTime={200} classParentString="lesson-activity-title-collapsible Collapsible" >
                    <div id={'activity-description-text-' + idx} className="description-bg boxed-div" ref={descriptionElementsRef}>{Utility.parseFormattedURLsFromText(activityDescription)}</div>
                </Collapsible>
            )
        })
    }

    // renders the lesson activities
    renderLessonContentActivities() {
        let lessonData = this.lessonData
        let activityTitles = lessonData['activity-titles']
        let idxs = [...Array(activityTitles.length).keys()]
        return (
            <div id="lesson-body-listing-content-activities" className="tab">
            <h4>Warmup Activity</h4>
            <div id="lesson-body-listing-content-warmup-activity-table" className="listing-table boxed-div">                  
                {this.renderLessonContentActivitiesHelper([0])}
            </div>
            <h4>Next Activities</h4>        
            <div id="lesson-body-listing-content-next-activities-table" className="listing-table boxed-div">                  
                {this.renderLessonContentActivitiesHelper(idxs.slice(1))}
            </div>
          </div>
        )
    }

    renderLessonContentAssessments() {
        let lessonData = this.lessonData
        return (
          <div id="lesson-body-listing-content-assessments" className="tab">
            <h4>Assessment Strategies</h4>
            <h6>Use these to assess student learning...</h6>
            {Utility.renderBulletListFromStringList('lesson-assessments', lessonData.assessments, undefined, false, this.assessmentsRef)}
          </div>
        )
    }

    renderLessonContentTeacherNotes() {
        let lessonData = this.lessonData
        return (
          <div id="lesson-body-listing-content-teacher-notes" className="tab">
            <h4>Teacher Notes</h4>
            <h6>Some notes and tips on how to deliver this lesson...</h6>
            <span className="form-input-description"><em className="opac">Also look for any notes for the teacher in the <strong>speaker notes</strong> in the lesson <strong>slides</strong></em>.</span>
            {Utility.renderBulletListFromStringList('lesson-teacher-notes', lessonData['teacher-notes'], undefined, false, this.teacherNotesRef)}
          </div>
        )
    }

    renderLessonContent() {
        return (
          <Tabs id="lesson-body-listing-content" className="page-body-listing-content section"
            defaultTab={this.lastTabOpen || 'about'}
            onChange={(tabId) => { if (globals.haveLocalStorage) localStorage.setItem('termeric-page-' + this.pageId + '-' + this.lesson + '-last-tab-open', tabId) }} >
            <TabList className="many-tabs-list">
              <Tab tabFor='about'><span className="many-tabs-header-lesson tab-header">About</span></Tab>
              <Tab tabFor='resources'><span className="many-tabs-header-lesson tab-header">Resources</span></Tab>
              <Tab tabFor='activities'><span className="many-tabs-header-lesson tab-header">Activities</span></Tab>
              <Tab tabFor='assessments'><span className="many-tabs-header-lesson tab-header">Assessment Strategies</span></Tab>
              <Tab tabFor='teacher-notes'><span className="many-tabs-header-lesson tab-header">Teacher Notes</span></Tab>
            </TabList>
            <TabPanel tabId='about'>
                {this.renderLessonContentAbout()}
            </TabPanel>
            <TabPanel tabId='resources'>
                {this.renderLessonContentResources()}
            </TabPanel>
            <TabPanel tabId='activities'>
                {this.renderLessonContentActivities()}
            </TabPanel>
            <TabPanel tabId='assessments'>
                {this.renderLessonContentAssessments()}
            </TabPanel>
            <TabPanel tabId='teacher-notes'>
                {this.renderLessonContentTeacherNotes()}
            </TabPanel>
          </Tabs>
        )
    }

    renderBody() {
        if (this.state.dataFetched) {
            return (
                <div>
                    {this.renderExternalIframes()}
                    <div id="lesson-body-main" className={'page-body' + (this.pageBodyClass ? (' ' + this.pageBodyClass) : '')}>
                        {this.renderLessonTopInfo()}
                        {this.renderLessonContent()}
                    </div> 
                </div>
            )
        } else return Utility.renderPageLoading()
    }

    // *** A *** Some of curriculum info (course and each lesson descriptions) comes from external HTML files sitting in the fileserver. We get those content by having invisible iframes to those html files, whose scripts will asyncronously send (postMessage) to this page their content DOM as a string. 
    // Here we create those iframes to initiate this process, and in another place we will receive the sent messages and convert the string into DOM elements to place in the right positions in this page...:
    renderExternalIframes() {
        return Utility.renderExternalIframes(this, this, this.lessonData, false)
    }

    // *** B *** here we will handle/receive the sent messages and convert the string into DOM elements to place in the right positions in this page...:    
    handleReceivingContentFromExternalIframes() {
        Utility.handleReceivingContentFromExternalIframes(this)    
    }

    // *** C **** Here we place the received content in the right position in this page:
    insertContentFromExternalIframeIntoDOM(ids) {    
        Utility.insertContentFromExternalIframeIntoDOM(this, ids)
    }

    // document header title for page:
    getPageTitle() { return this.getPageTitleForCurriculumListingPage() }

}

export default LessonPlanViewPage
