/*
    Creator site - New/Edit Teacher Profile Submission Page
*/

import React from 'react'
import { Tabs, Tab, TabPanel, TabList } from 'react-web-tabs'
import 'react-web-tabs/dist/react-web-tabs.css'

import * as Config from './config'
import * as Database from './database-api'
import * as Fileserver from './fileserver-api'
import * as Payout from './payout-api'
import * as Utility from './utility'
import * as CreatorUtility from './creator-utility'

const globals = Config.globals
const generalConfig = Config.pages.general
const payoutConfig = Config.payoutConfig

class CreatorEditTeacherPage extends CreatorUtility.CreatorLandingPage {
    constructor(props) {
        super(props)      
        this.pageId = 'creator-edit-teacher'
        this.isNew = true
        this.photoUploaded = false
        this.uploadDropZoneNewMessage = 'Drop an image (aspect ratio 1:1) file here, or click to select...'
        this.uploadDropZoneEditMessage = '✓ Image uploaded. To change it, drop an image (aspect ratio 1:1) file here or click to select...'
        this.uploadDropZoneRef = React.createRef()
        this.formNameRef = React.createRef()
        this.formWebpageRef = React.createRef()
        this.teacherFormAboutTextEditor = React.createRef()
        this.formTermsCheckboxRef = React.createRef()
        this.creatorData = undefined
        this.payoutIsLinked = false
    }
  
    componentDidMount() {
        super.componentDidMount()
        // show a wait dialog if payout account linking is in progress:
        let urlParams = globals.urlParams
        let isLinkPayoutResult = urlParams['link-payout-result']
        if ((isLinkPayoutResult !== undefined) && !urlParams.error)
            Utility.showWaitingDialog(0, 'Just a moment. Your payout account is linking...')
    }

    userAndTeacherDataFetched() {
        super.userAndTeacherDataFetched()
        if (this.teacherData) {             
            // there's already teacher with the given id. This is editing an existing profile. Get the creator data before rendering the page:
            this.isNew = false
            Database.database_fetchCreatorUserData()
        } else {
            // if there's not a teacher with the given id. This is a new teacher:
            // ready to render the form:
            this.setState({dataFetched: true})
        }
    }

    creatorUserDataFetched() {
        this.creatorData = Database.getCreatorUserData()
        this.payoutIsLinked = !!this.creatorData['payout-email'] 
        // if this page is loading after an external payout account linking session, process it here:
        let isLinkPayoutResult = globals.urlParams['link-payout-result']
        if (isLinkPayoutResult !== undefined)
            Payout.payout_requestUserPayoutLinking(this)
        // ready to render the form:
        this.setState({dataFetched: true})
    }

    // backend server calls this once user links payout account and user payout info is received:
    payoutLinkingSuccessful(userId, payerId, name, email) {                        
        let creatorData = {'payout-user-id': userId, 'payout-payer-id': payerId, 'payout-name': name, 'payout-email': email}
        // update the database creator entry
        let onSuccessFn = () => {            
            //this.payoutIsLinked = true
            //this.creatorData = Database.getCreatorUserData()
            let onCloseFn = () => {
                // refresh render the form:
                //this.setState({dataFetched: true})
                // navigate to creator library page:                        
                this.setState({navigateTo: '/creator/library'})
            }
            Utility.showTextDialog(0, 'Your payout account is now linked.', generalConfig.dialogBoxOKIconClassName, onCloseFn)      
        }
        if (this.isNew)
            Database.database_addNewCreator(creatorData, onSuccessFn)
        else
            Database.database_updateCreator(creatorData, onSuccessFn)
    }

    submitForm() {
        let isNew = this.isNew
        let name = this.formNameRef.current.value.trim()
        let webpage = this.formWebpageRef.current.value.trim()
        let about = this.teacherFormAboutTextEditor.current.getStylizedTextAsHtml()
        let termsCheckboxChecked = Utility.isCheckboxChecked(this.formTermsCheckboxRef.current)        
        var err
        if (!Utility.validateName(name)) {
            err = 'Enter your full name.'
        } else if (webpage && !Utility.validateURL(webpage)) {
            err = 'Enter a valid webpage URL.'
        } else if (!about) {
            err = 'Write a short bio about yourself.'
        } else if (isNew && !this.photoUploaded) {
            err = 'Please upload a profile picture.'
        } else if (!termsCheckboxChecked) {
            err = 'Please agree to the terms of use first.'
        }
        if (err) {
            Utility.showTextDialog(0, err, generalConfig.dialogBoxErrorIconClassName)
        } else {
            let onSuccessFn = () => {
                let onCloseFn = () => {
                    if (isNew) {
                        // if this was the first save, stay on this page so user can link payout account now:
                        this.isNew = false
                        this.teacherData = Database.getTeacherData(this.teacher)
                        this.setState({dataFetched: true})
                    } else // otherwise navigate to creator library page:                        
                        this.setState({navigateTo: '/creator/library'})                    
                }
                Utility.showTextDialog(0, 'Your teacher profile is saved.' + (isNew ? '\nYou may link your payout account now.' : ''), generalConfig.dialogBoxOKIconClassName, onCloseFn)
            }
            let teacherData = {name: name, webpage: webpage, about: about, image: isNew ? '' : (this.teacherData.image || '')}
            let fileserverUpdated = false, fileserverCleanupNeeded = false
            if (this.profileImageFileUploadedName) {
                fileserverUpdated = true
                fileserverCleanupNeeded = true
                teacherData.image = this.profileImageFileUploadedName
                // remove it so we don't force update unnecessarily next time profile is saved/submitted:
                delete this.profileImageFileUploadedName
            }
            Utility.showWaitingDialog()
            if (isNew)
                Database.database_addNewTeacher(teacherData, this.payoutIsLinked, onSuccessFn)
            else
                Database.database_updateTeacher(teacherData, fileserverUpdated, fileserverCleanupNeeded, onSuccessFn)
        }
    }

    profileImageOnSuccessfulDropFn(file, fileName, origFileName) {
        let onUploadSuccessFn = () => {            
            this.photoUploaded = true
            this.profileImageFileUploadedName = fileName
            let url = Fileserver.getTeacherImageUrl(this.teacher, !this.isNew && this.teacherData.active, true, fileName)
            this.uploadDropZoneRef.current.onUploadSuccessFn(url)
        }
        let onUploadFailureFn = () => {
            this.uploadDropZoneRef.current.onUploadFailureFn()
        }        
        Fileserver.uploadTeacherImage(this.teacher, !this.isNew && this.teacherData.active, fileName, file, onUploadSuccessFn, onUploadFailureFn)
    }

    renderForm() {
        return (
          <Tabs id="creator-edit-teacher-body-content" key={Utility.getEpochTime()} className="form-container page-body-listing-content section"
            defaultTab='about'
            onChange={(tabId) => {  }} >
            <TabList>
              <Tab tabFor='about'><span className="tab-header">About</span></Tab>
              <Tab tabFor='terms'><span className="tab-header">Terms of Use</span></Tab>              
            </TabList>
            <TabPanel tabId='about'>
              {this.renderFormAbout()}
            </TabPanel>
            <TabPanel tabId='terms'>
              {this.renderFormTerms()}
            </TabPanel>            
          </Tabs>
        )
    }

    renderFormAbout() {
        let teacherData = this.teacherData
        let isNew = this.isNew
        return (
            <div id="creator-edit-teacher-form-about" className="form flex-col">
                <div className="form-item flex-col">
                    <label>Name</label>
                    <input id="teacher-form-name" type="text" placeholder="Full name" defaultValue={isNew ? undefined : teacherData.name } ref={this.formNameRef} />
                </div>
                <div className="form-item flex-col">
                    <label>Personal Website</label>
                    <span className="form-input-description"><em className="opac">URL for your personal web page, LinkedIn or Facebook profile page, etc.</em></span>
                    <input id="teacher-form-webpage" type="text" placeholder="Personal website (e.g. LinkedIn page)" defaultValue={isNew ? undefined : teacherData.webpage} ref={this.formWebpageRef} />
                </div>
                <div className="form-item flex-col">
                    <label>About You</label>                            
                    <CreatorUtility.EditorWithHtmlImportExport id="teacher-form-about" ref={this.teacherFormAboutTextEditor} placeholder="Write a short bio..." defaultContentHtml={isNew ? undefined : teacherData.about} />
                </div>
                <div className="form-item flex-col">
                    <label>Profile Picture</label>
                    <CreatorUtility.FileUploadDropZone accept={'image/*'} hasImagePreview={true} extraClasses={'teacher-dropzone'} isNew={isNew} previewImagePlaceHolder={generalConfig.teacherImagePlaceHolder} previewFile={isNew ? undefined : teacherData.imageUrl} newMessage={this.uploadDropZoneNewMessage} editMessage={this.uploadDropZoneEditMessage} onSuccessfulDropFn={this.profileImageOnSuccessfulDropFn.bind(this)} ref={this.uploadDropZoneRef} />
                </div>
                <div className="form-item flex-col">
                    <label>Payout Settings</label>
                    <span className="form-input-description"><em className="opac">{this.payoutIsLinked ? '✓ Your payout account is already linked.' : (isNew ? 'Once you have saved your teacher profile you can link your payout account.' : 'Your payout account is not yet linked.')}</em></span>
                    <span className="form-input-description"><em className="opac">{this.payoutIsLinked ? '' : 'You can link your payout account later when you are submitting a curriculum to the Termeric store.'}</em></span>
                    {isNew ? null : Utility.renderExternalImageLink(payoutConfig.linkButtonUrl, 'teacher-form-payout-link-account-button', 'top-margin20 image-button', payoutConfig.payoutLinkAccountUrl, false)}                    
                </div>
            </div>
        )
    }

    renderFormTerms() {
        return (
            <div id="creator-edit-teacher-form-terms" className="form flex-col">
                <div className="form-item flex-col">                    
                    <h3>
                    Terms of User as a Creator Teacher
                    </h3>
                    <p>
                    To ensure quality, Termeric Education allows submitting only curriculums that have been tested in a real classroom. As a Creator Teacher, as part of creating your curriculum, you will be asked to provide proofs to demonstrate that you have taught the curriculum in a previous term as a teacher in the school you designate.
                    </p>
                    <p>
                    As a Creator Teacher, you may only submit <strong>curriculums created and taught by yourself</strong>. You may not submit someone else's curriculum. You may not submit a curriculum on someone's behalf, unless you provide a document proving you have been granted permission to do so by the author of the curriculum.
                    </p>                    
                    <p>
                    As a Creator Teacher, when you publish curriculum content on Termeric Education, you remain the sole owner of the curriculum. You simply grant Termeric Education a license to offer a <strong>license</strong> to User Teachers to use and teach your content to their own students only. Content is licensed, and not sold, to them. This license does not give them any right to share, redistribute, or resell the content.
                    </p>
                    <p>                    
                    If you are an Creator Teacher, you can submit content for publication on the platform and you can also communicate with the user teachers who have purchased your course curriculum or other content. In both cases, you must abide by the law and respect the rights of others: you cannot post any course, question, answer, review or other content that violates applicable local or national laws or regulations of your country. You are solely responsible for any courses, content, and actions you post or take via the platform and Services and their consequences. Make sure you understand all the copyright restrictions set forth in the <a href="/terms#section4" target="_blank">Creator Teacher Terms</a> before you submit any content for publication on Termeric Education.
                    </p>
                    <h4>
                    Lifetime Access Policy
                    </h4>
                    <p>
                    Once a Creator Teacher publishes a curriculum on the Termeric platform and the it becomes available in the Termeric store, Termeric Education’s Lifetime Access Policy guarantees that the curriculum will always remain available to access to the User Teachers who have purchased it.                    
                    However, the Creator Teacher may choose at anytime to remove the curriculum from the Termeric store, which will prevent any new User Teacher to search for or purchase it.
                    </p>
                    <h4>
                    Revenue Share Model
                    </h4>
                    <p>
                    When a User Teacher purchases a curriculum created by a Creator Teacher on the Termeric platform, Termeric Education keeps a percentage (no more than <strong>30%</strong>) of the sale and pays the rest of the sale amount (no less than <strong>70%</strong>) out to Creator Teacher. The monthly sum total of the Creator Teacher share of the sales are paid out to the Creator Teacher on a monthly basis.
                    </p>
                    <p>The revenue share model is subject to change. Please visit this section inside the <a className="resource-link" href="/terms#section4.1.a" target="_blank">Terms of Use</a> page for updates. Termeric Education will notify Teacher Creators any time the Revenue Share Model is updated.
                    </p>
                    <p>
                    Please review our full <a className="resource-link" href="/terms"  target="_blank">Terms of Use</a> page for more information.
                    </p>
                    {Utility.renderCheckbox('teacher-form-terms', !this.isNew && this.teacherData.active, 'I agree to these terms.', this.formTermsCheckboxRef)}
                </div>
            </div>
        )
    }

    renderBody() {
        if (this.state.dataFetched) {
            return (
                <div id="creator-edit-teacher-body" className="flex-col section page-body">
                    <script src='https://www.paypalobjects.com/js/external/connect/api.js'></script>      
                    <div id="creator-new-top-section" className="page-body-top-listing section">
                        <div className="flex-col">
                            {this.isNew ? null :
                                (<div className="bottom-margin25">
                                    <i className="fas fa-angle-left row-padding-10"></i>
                                    {Utility.renderLink('Library', 'creator-edit-teacher-back-button', 'h5size', 'creator/library')}
                                </div>)}                      
                            <h2 className="header-text size31">Save your teacher profile</h2>
                            <div id="creator-edit-teacher-submit" className="flex-col form-side-panel curriculum-listings-side-panel opac-background">
                                {Utility.renderButtonFromButton(<span><i className="fas fa-save"></i>&nbsp;&nbsp;&nbsp;Save teacher profile</span>, 'creator-edit-teacher-submit-button', 'wide-cta-text-button cta-text-button shadowed-text-button bordered-text-button text-button button', this.submitForm.bind(this))}
                            </div>
                        </div>
                    </div>
                    {this.renderForm()}
                </div>
            )
        } else return Utility.renderPageLoading()
    }
}

export default CreatorEditTeacherPage
