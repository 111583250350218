/*
    Library page
*/

import React from 'react'

import * as Config from './config'
import * as Database from './database-api'
import * as Utility from './utility'

const globals = Config.globals

const generalConfig = Config.pages.general
const libraryConfig = Config.pages.library

// ------------------------------------------------------------------------
/*
    Library Page
*/
class LibraryPage extends Utility.SessionLandingPageWithDialogBox {
  constructor(props) {
      super(props)
      var lastTabOpenIndex = globals.haveLocalStorage ?
        localStorage.getItem('termeric-page-library-last-tab-open') : undefined      
      lastTabOpenIndex = lastTabOpenIndex ? (parseInt(lastTabOpenIndex, 10) || 0) : 0      
      this.state = {
        // index for which tab is currently on:
        tabIndex: lastTabOpenIndex        
      }
      this.pageId = 'library'
      this.fetchMissingCurriculumsDataTried = false
      this.tabTitles = ['Purchased Curriculums', 'Wishlisted Curriculums']
      this.tabTitleIcons = ['fas fa-briefcase', 'fas fa-heart']
      this.libraryPanel = React.createRef()      
  }
  
  componentDidMount() {
    super.componentDidMount()
    // If database has already finished its fetching curriculum data for default query at this time, call the callback manually (otherwise, it will call it for us itself once fetched):
    if (Database.isUserDataFetched())
      this.userDataFetched()
  }

  userDataFetched() {
    super.userDataFetched()
    if (Database.isCurriculumsDataFetched())
      this.curriculumsDataFetched()
    else
      this.checkForMissingCurriculumsData()
  }

  curriculumsDataFetched() {
    if (Database.isUserDataFetched())    
      this.checkForMissingCurriculumsData()
  }

  checkForMissingCurriculumsData() {
    // list of curriculms ids for each tab (0: bought curriculums 1: wishlisted curriculums)
    this.curriculumsByTab = [Database.getUserBoughtCurriculums(), Database.getUserWishlistCurriculums()]
    let curriculums = Database.getUserLibraryCurriculums()
    let curriculumsWithoutFetchedData = Database.getCurriculumIdsWithoutFetchedData(curriculums)
    // if there are curriculums to show for which we have not fetched data from db, fetch here (cap number of tries to 2):
    if (curriculumsWithoutFetchedData.length > 0 && !this.fetchMissingCurriculumsDataTried) { 
        this.fetchMissingCurriculumsDataTried = true
        Database.fetchCurriculumDataForIds(curriculumsWithoutFetchedData)
    } else {
      this.allDataFetched()
    }
  }

  allDataFetched() {
    // filter out wishlisted curriculums which have been deactivated (removed from store) or set to private by the creator:
    this.curriculumsByTab[1] = Database.filterOutNonPublicCurriculums(this.curriculumsByTab[1])
    // switch tab if nothing in current tab but something in other tab:
    let boughts = this.curriculumsByTab[0].length
    let wishlists =  this.curriculumsByTab[1].length
    let tabIndexChoice = this.state.tabIndex
    if (boughts === 0 && wishlists > 0)
      tabIndexChoice = 1
    if (tabIndexChoice !== this.state.tabIndex) {
      if (globals.haveLocalStorage)
        localStorage.setItem('termeric-page-library-last-tab-open', tabIndexChoice)
      this.state.tabIndex = tabIndexChoice
    }
    this.state.curriculumAndUserDataFetched = true
    this.libraryPanel.current.setState({dataFetched: true})    
  }

  isShowingBoughtCurriculums() {
    return this.state.tabIndex === 0
  }

  getCurriculumsForTab() {
    return this.curriculumsByTab[this.state.tabIndex]
  }

  lastTabOpenLocalStorageKey() { return 'termeric-page-library-last-tab-open' }
  
  renderBody() {
    return (
      <LibraryPanel dataFetched={this.state.curriculumAndUserDataFetched} page={this} ref={this.libraryPanel} />
    )
  }
  
}

// section containing actual library items listing:
class LibraryListings extends Utility.LibraryListings  {

  // send an email to team to validate/process this refund:
  requestRefundForCurriculum(curriculumData) {
    // ask user confirm download first:
    Utility.showTextDialog(0, 'This will take you to the "Contact Us" form, where you can submit a request for a refund for your purchase of this curriculum. Do you want to proceed?', generalConfig.dialogBoxInfoIconClassName, undefined, ['Cancel', 'Proceed'], [undefined, (e) => this.parent.page.setState({navigateTo: '/contact?refund-request=true&subject=' + curriculumData.subject + '&id=' + curriculumData.id})])
  }

  // renders a Library curriculum item view:
  renderCurriculumItemDiv(id, curriculumData) {
    let page = this.parent.page    
    if (page.isShowingBoughtCurriculums() && Database.isPurchasedCurriculumMoneyBackGuaranteeValid(curriculumData.id)) {
      // add the buttons specific to bought items:
      let pageId = globals.currentPage.pageId
      let libraryItemImageAndInfoDivLink = this.renderCurriculumItemDivContent(id, curriculumData)
      let idPrefix = pageId + '-curriculum-item-' + id
      return (
        <div key={idPrefix} id={idPrefix} className={'curriculum-item-view' + (this.itemExtraClasses ? (' ' + this.itemExtraClasses) : '')}>
          {libraryItemImageAndInfoDivLink} 
          <div className="curriculum-item-view-buttons">
            {Utility.renderButton('Request refund', idPrefix + '-request-refund-button', 'text-button button', (e) => { this.requestRefundForCurriculum(curriculumData)})}          
        </div>
        </div>
      )
    } else
      return super.renderCurriculumItemDiv(id, curriculumData)
    
  }

  renderOptionalCurriculumInfo(id, curriculumData) {
    let page = this.parent.page
    let pageId = page.pageId
    let gradeTitle = Database.getCurriculumGradeTitle(curriculumData)
    return (
      <div>
        <span id={pageId + '-curriculum-item-topic-' + id} className="block h8size">{curriculumData['topic-title']}</span>        
        <span className="curriculum-view-card-grade curriculum-view-grade h8size block">{gradeTitle}</span>
        <span id={pageId + '-curriculum-item-teacher-' + id} className="curriculum-item-view-teacher curriculum-view-teacher h8size">By {curriculumData['teacher-name']}</span>
        {page.isShowingBoughtCurriculums() ? null : Utility.renderCurriculumPrice(page, curriculumData, 'block curriculum-item-view-price curriculum-view-price h4size', true, true)}
      </div>
    )
  }

}
  
class LibraryPanel extends React.Component {
  constructor(props) {
      super(props)
      this.state = { 
        dataFetched: props.dataFetched || false
      }
      this.page = props.page
      this.dependantComponentsOnToggledPagesComponent = []      
  }
  
  render() {
    if (this.state.dataFetched) {      
      return (
        <div id="library-listings-and-summary-section" className="flex-col section page-body">
          <Utility.LibraryTabSelection page={this.page} />
          <LibraryListings dataFetched={Database.isCurriculumsDataFetched()} numberOfResultsShown={libraryConfig.numberOfResultsShown} itemExtraClasses={'curriculum-item-view-single-col'} parent={this} />
          <Utility.CurriculumsListingPageToggleButtons numberOfResultsShown={libraryConfig.numberOfResultsShown} parent={this} />
        </div>
      )
    } else return Utility.renderPageLoading()
  }
}



export default LibraryPage
