/*
    User site - News/Announcements page
*/

import React from 'react'

import * as Config from './config'
import LandingPage from './landing'
import * as Utility from './utility'

const generalConfig = Config.pages.general
const fsConfig = Config.fsConfig

class NewsPage extends LandingPage {
    constructor(props) {
        super(props)
        this.state = {
            news: [
                {
                    date: 1691009686, 
                    title: `Teachers, Sell Your School Curriculums on Termeric!`, 
                    content: <p>We are actively looking to partner with school teachers who have created and taught their own school subject curriculums. Sign in and create your <a className="resource-link" href="/creator/edit-teacher">teacher profile</a>. You're then set to create and sell your curriculums to other teachers on Termeric. Our <a className="resource-link" href="/creator/library">Curriculum Creator</a> tool guides you to create and submit your first curriculum.</p>
                }
                /*
                {
                    date: 1628873040, 
                    title: 'Promotion: Get a $50 Amazon gift card!', 
                    content: <p>Great news! First 100 teachers who publish their first course curriculum on Termeric will receive a USD $50 Amazon gift card. <a className="resource-link" href="/creator/library">Submit</a> your curriculum by December 31, 2021 to receive this offer.</p>
                },
                {
                    date: 1627598361, 
                    title: 'Promotion: No commission on your curriculum sales on Termeric', 
                    content: <p>Rockstar Teachers! <a className="resource-link" href="/register">Sign up</a>, create your <a className="resource-link" href="/creator/library">teacher profile</a>, and create your first course curriculum on Termeric. You will receive 100% of the proceedings from your first year sales. Create your <a className="resource-link" href="/creator/library">teacher profile</a> on Termeric by December 31, 2021 to receive this offer.</p>
                }
                */
            ]
        }
        this.pageId = 'news'
    }

    renderNews() {
        let benefitsIcon = generalConfig.ctaItemIconImageUrl
        let newsComponents = this.state.news.map((n, idx) => {            
            return (
                <div key={'news-body-news-item-' + idx} className="curriculum-item-view flex-col flex-direction-column">
                    {Utility.renderImage(benefitsIcon, 'news-icon-' + idx + '-img', 'top-benefit-img round-border place-self-center')}
                    <span className="opac place-self-end">{Utility.getFormattedTime(n.date)}</span>
                    <div className="place-self-start">
                        <h4>{n.title}</h4>
                        {n.content}
                    </div>
                </div>
                )
        })
        return (
            <div className='curriculum-listings-panel curriculum-listings-panel-no-sides'>
                {newsComponents}
            </div>
        )
    }

    renderBody() {
        return <div id="news-body" className="flex-col section page-body">
            <h1 className="logo-font logo-color">News</h1>
            {this.renderNews()}
            {Utility.renderImage(fsConfig.rootBucketUrl + '/' + fsConfig.publicRootBucket + '/images/classroom-logo-1.png', 'news-image', 'place-self-center width300 top-margin25 opac')}
        </div>
    }

    // default render of a landing page without search bar in header...:
    render() {
        return Utility.renderNoSearchBarPage(this)
    }   
}

export default NewsPage
