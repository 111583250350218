import React from 'react'
import { Navigate } from 'react-router-dom'

// ------------------------------------------------------------------------
// Error Page element shown when navigating to a wrong subdomain name
class ErrorPage extends React.Component {

  constructor(props) {
    super(props)
    this.state = {        
        // will be set when we want to navigate to a new page: 
        navigateTo: undefined
    }
  }

  componentDidMount() {
    // go to home page:
    this.setState({navigateTo: '/'})
  }

  render() {    
    if (this.state.navigateTo !== undefined)
      return <Navigate to={this.state.navigateTo} />
    else
      return (
        <div>
          Uh-Oh, wrong URL! Redirecting back to home page... 
        </div>
      )
  }
}

export default ErrorPage;