/*
    Cart page
*/

import React from 'react'

import * as Config from './config'
import * as Database from './database-api'
import * as Pay from './pay-api'
import * as Utility from './utility'

const globals = Config.globals
const generalConfig = Config.pages.general
const cartConfig = Config.pages.cart

class CartPage extends Utility.LandingPageWithDialogBox {
  constructor(props) {
      super(props)
      this.state = {
        curriculumDataFetched: false
      }
      // 1 dialog box for the usual messages, 1 dialogbox for checkout form
      this.numberOfDialogBoxes = 2
      this.pageId = 'cart'      
      Database.getCart()      
      this.cartPanelRef = React.createRef()
  }
  
  componentDidMount() {
    super.componentDidMount()    
    // If database has already finished its fetching curriculum data for default query at this time, call the callback manually (otherwise, it will call it for us itself once fetched):
    if (Database.isSubjectsDataFetched())
      this.subjectsDataFetched()
  }

  subjectsDataFetched() {
    super.subjectsDataFetched()
    let curriculumsWithoutFetchedData = Database.getCurriculumIdsWithoutFetchedData(globals.cart)    
    if (curriculumsWithoutFetchedData.length > 0)
      Database.fetchCurriculumDataForIds(curriculumsWithoutFetchedData)
    else
      this.allDataFetched()
  }

  curriculumsDataFetched() {
    this.allDataFetched()
  }
  
  // database API calls this when curriculums info for all search results are fetched. It'll trigger components that need that info to render now...
  // if at this point there are curriculum ids in the shopping cart whose data have not yet been fetched from the database, call the database to do so
  // otherwise if we have all data we need go ahead and trigger the cart panel to render:
  allDataFetched() {
    // filter out wishlisted curriculums which have been deactivated (removed from store) by the creator:
    Database.filterOutDeactivatedCurriculumsFromCart()
    // if this page is loading after an external payment checkout session, process it here:
    let checkoutResult = globals.urlParams['checkout-result']
    if (checkoutResult !== undefined)
      Pay.pay_handleReturnFromCheckoutSession(checkoutResult, this)
    // proceed to render page:
    this.state.curriculumDataFetched = true
    this.cartPanelRef.current.setState({dataFetched: true})
    
  }

  async proceedWithCheckout() {
    let cartPanel = this.cartPanelRef.current
    let checkoutItems = cartPanel.checkoutItems
    let total = cartPanel.total
    // if it's a free checkout, skip to payment success:
    if (cartPanel.total.local === 0) {
      let checkoutData = {transactionId: 'free_transaction', curriculums: checkoutItems.map(i => i.id), curriculumSubjects: checkoutItems.map(i => i.subject), promoCodes: checkoutItems.map(i => i.promoCode), pricesLocal: checkoutItems.map(i => i.priceLocal), pricesUSD: checkoutItems.map(i => i.priceUSD), total: total}
      this.paymentProcessed({error: false, data: checkoutData})
    } else {
      // close the buttons and show waiting dialog:
      Utility.closeDialog(1)
      Utility.showWaitingDialog()      
      await Pay.pay_requestCheckoutSession(checkoutItems, total, this)
    }
  }

  checkoutButtonClicked() {
    if (Config.globals.sessionInfo) {
      // ask user confirm not to share first:
      Utility.showTextDialog(1, 'To continue with the purchase of the curriculums, you need to confirm:\nYou will not own the curriculums or contents within them. Rather, you will have a license to use them for teaching your own classroom only. You may not share or redistribute this content to others.\nTo confirm, click Proceed.', generalConfig.dialogBoxInfoIconClassName, undefined, ['Cancel', 'Proceed'], [undefined, async (e) => { await this.proceedWithCheckout() }])
    } else 
      globals.currentPage.setState({navigateTo: '/signin' + Utility.getSigninRelatedPageNavigationLink()})    
  }  

  // called once the payment is processed in the backend (stripe & our serverless server) and result returned back:
  paymentProcessed(response) {    
    if (response.error) // payment error:
      // response.error .type / .code / .message:
      // FIXME: Case handle differnt errors here, for example unsupported currency, etc. ...:
      Utility.showTextDialog(0, 'Payment failed:\n"' + response.error.message + '"', generalConfig.dialogBoxErrorIconClassName)
    else {
      // payment successful:
      let onCheckoutDatabaseFailureFn = (err) => {
        Utility.showTextDialog(0, err, generalConfig.dialogBoxErrorIconClassName)
      }
      let onCheckoutDatabaseSuccessFn = (e) => {
        // navigate to my purchased curriculms page:
        Utility.showTextDialog(1, 'The curriculums you purchased are added to your library.', generalConfig.dialogBoxInfoIconClassName, () => globals.currentPage.setState({navigateTo: '/library'}))   
      }
      let checkoutData = response.data
      Database.database_checkoutCart(checkoutData, onCheckoutDatabaseSuccessFn, onCheckoutDatabaseFailureFn)
    }

  }

  // Locale API calls this if it's just updated locale pay config to non-default (non USD currency) and it noticed that the current page has already rendered price of an item (so that page can force update of its price component so that it shows in the right local currency). 
  localePayConfigUpdated() {
    let cartPanelComponent = this.cartPanelRef.current
    if (cartPanelComponent != null)
      cartPanelComponent.forceUpdate()
  }
  
  renderBody() {    
    return (
      <CartPanel dataFetched={this.state.curriculumDataFetched} page={this} ref={this.cartPanelRef} />
    )
  }
}

// Outer box containing cart listings
class CartPanel extends React.Component {
  constructor(props) {
      super(props)
      this.state = { 
        dataFetched: props.dataFetched || false
      }
      this.page = props.page
      this.dependantComponentsOnToggledPagesComponent = []      
  }  

  render() {        
    if (this.state.dataFetched) {      
      return (
          <div id="cart-listings-and-summary-section" className="flex-col section page-body">
            <CartSummary />            
            <CartListingsAndCheckoutPanel cartPanel={this} />
            <Utility.CurriculumsListingPageToggleButtons numberOfResultsShown={cartConfig.numberOfResultsShown} dataFetched={true} numResults={globals.cart.length} parent={this} />
          </div>
      )
    } else {
      return (
        <div id="cart-listings-and-summary-section" className="flex-col section page-body"></div>
      )
    }
  }
}

class CartListingsAndCheckoutPanel extends React.Component {
  constructor(props) {
    super(props)
    this.cartPanel = props.cartPanel
    this.page = this.cartPanel.page
  }

  render() {
    if (globals.cart.length > 0) {
      return (
        <div id="cart-listings-and-checkout-panel" className="panel">
          <CartListings numberOfResultsShown={cartConfig.numberOfResultsShown} dataFetched={true} parent={this} cartPanel={this.cartPanel} />
          <CheckoutPanel cartPanel={this.cartPanel} />
        </div>
      )
    } else {
      return (
        <div id="cart-listings-and-checkout-panel" className="flex-col center-content panel">    
          {Utility.renderNothingToShow('cart-listings')}
        </div>
      )
    }
  }  
}

// section containing actual shopping cart items listing:
class CartListings extends Utility.LibraryListings  {
  
  constructor(props) {
    super(props)
    this.cartPanel = props.cartPanel
    this.page = this.cartPanel.page
    this.username = Utility.isUserSignedIn() ? globals.sessionInfo.username : undefined
    this.extraClasses = 'page-top-listings-panel'
  } 
  
  // removes the curriculum currently in the cart from cart, optionally adds to wishlist, and rerenders the cart component in this page:
  removeFromCart(curriculum, subject, addToWishlist) {
    if (addToWishlist) {
      // if signed in update wish list, otherwise go to signin without proceeding further:
      if (Config.globals.sessionInfo)
        Database.updateWishlist(curriculum, subject, true)
      else {
        globals.currentPage.setState({navigateTo: '/signin' + Utility.getSigninRelatedPageNavigationLink()})
        return
      }
    }
    Database.removeFromCart(curriculum, subject)    
    // rerender the cart:
    this.cartPanel.setState({dataFetched: true})    
  }

  getCurriculumViewerPage() {
    return 'preview'
  }

  getCurriculumListInCurrentPage() {    
    return globals.cart
  }

  // renders a cart curriculum item view:
  renderCurriculumItemDiv(id, curriculumData) {
    let cartItemImageAndInfoDivLink = this.renderCurriculumItemDivContent(id, curriculumData)
    return (
      <div key={'cart-curriculum-item-' + id} id={'cart-curriculum-item-' + id} className="curriculum-item-view">              
        {cartItemImageAndInfoDivLink}
        <div className="curriculum-item-view-buttons">
          {Utility.renderButton('Remove', 'cart-item-' + id + '-remove-button', 'text-button button', (e) => { this.removeFromCart(curriculumData.id, curriculumData.subject, false)})} 
          {Utility.renderButton('Save for later', 'cart-item-' + id + '-remove-button', 'text-button button', (e) => { this.removeFromCart(curriculumData.id, curriculumData.subject, true)})} 
        </div>
        {Utility.renderCurriculumPrice(this.page, curriculumData, 'curriculum-item-view-price curriculum-view-price h3size', true, true)}
      </div>
    )
  }

  // function to run before mapping over each item in list, during render
  itemsPreMapFn() {    
    this.cartPanel.total = {local: 0, USD: 0}    
    this.cartPanel.checkoutItems = []    
  }

  // function to run for each item in list, during render
  itemsInMapFn(curriculumData) {         
    let priceUSD = 0, priceLocal = 0, promoCode = ''
    // make it free if the user is the creator teacher:
    if (this.username !== curriculumData.teacher) {
      let priceInfo = Database.getCurriculumPriceInfo(curriculumData)
      priceUSD = priceInfo.priceUSD
      priceLocal = priceInfo.price
      promoCode = priceInfo.promoCode ? priceInfo.promoCode : '' 
    }
    this.cartPanel.total.USD += priceUSD
    this.cartPanel.total.local += priceLocal
    this.cartPanel.checkoutItems.push({id: curriculumData.id, subject: curriculumData.subject, name: curriculumData.title, description: curriculumData.subtitle, priceLocal: priceLocal, priceUSD: priceUSD, promoCode: promoCode, image: curriculumData.imageUrl})
  }

}

// section containing actual shopping cart items listing:
class CheckoutPanel extends React.Component {
  
  constructor(props) {
    super(props)
    this.cartPanel = props.cartPanel
    this.page = this.cartPanel.page
  }

  render() {
    return (
      <div id="cart-checkout" className="curriculum-listings-side-panel opac-background panel">
        <h5 id="cart-checkout-total-label">Total:</h5>
        <h1 id="cart-checkout-total">{Utility.priceToString(this.cartPanel.total.local)}</h1>
        {Utility.renderMoneyBackGuaranteeLabel(this.page, 'money-back-label top-margin20')}
        {Utility.renderApplyPromo('cart-apply-promo-button', this.page)}        
        {Utility.renderButton('Checkout', 'cart-checkout-button', 'cta-text-button shadowed-text-button bordered-text-button text-button button', (e) => this.page.checkoutButtonClicked())}
      </div>
    )
  }
}

// Cart results summary panel
class CartSummary extends React.Component {
  
  render() {     
      let cartSummaryMessage = Utility.plural('curriculum', globals.cart.length) + ' in Cart'
      return (
        <div id="cart-results-summary" className="panel">
          <h3 className="size25">{cartSummaryMessage}</h3>
        </div>
      )
  }
}

export default CartPage
