/*
    Creator site - Home page
*/

import React from 'react'

import * as Config from './config'
import * as Database from './database-api'
import * as Utility from './utility'
import * as CreatorUtility from './creator-utility'

const globals = Config.globals
const generalConfig = Config.pages.general
const creatorLibraryConfig = Config.pages.library

class CreatorLibraryPage extends CreatorUtility.CreatorRestrictedAccessLandingPage {
  constructor(props) {
      super(props)
      this.pageId = 'creator-libary'      
      var lastTabOpenIndex = globals.haveLocalStorage ?
        localStorage.getItem('termeric-page-creator-library-last-tab-open') : undefined      
      lastTabOpenIndex = lastTabOpenIndex ? (parseInt(lastTabOpenIndex, 10) || 0) : 0
      this.state = {
        // index for which tab is currently on:
        tabIndex: lastTabOpenIndex
      }
      this.tabTitles = ['Live Curriculums', 'In Review Curriculums', 'Draft Curriculums']
      this.tabTitleIcons = ['fas fa-store', 'fas fa-clock', 'fas fa-pencil-ruler']
      this.creatorLibraryPanel = React.createRef()      
  }
  
  componentDidMount() {
    super.componentDidMount()
    // if we're navigating back to this page, update the default tab and render again:     
    if (this.state.curriculumAndUserDataFetched)
      this.allDataAndCurriculumDataFetched() 
  }

  userAndTeacherDataFetched() {
    super.userAndTeacherDataFetched()
    if (!this.teacherData) {
      this.setState({navigateTo: '/creator/edit-teacher'})
    } else {
      // list of curriculms ids for each tab (0: live (approved) curriculums 1: submitted curriculums, 2: saved drafts)
      let teacher = this.teacher
      this.curriculumsByTab = [Database.getTeacherCurriculums(teacher), Database.getTeacherCurriculumsSubmitted(teacher), Database.getTeacherCurriculumsDrafted(teacher)]
      this.checkForMissingCurriculumsData()
    }
  }

  // sets the default tab to show based on previous activity and recent updates:
  setTabIndex() {
    let teacher = this.teacher
    this.curriculumsByTab = [Database.getTeacherCurriculums(teacher), Database.getTeacherCurriculumsSubmitted(teacher), Database.getTeacherCurriculumsDrafted(teacher)]
    let submittedCount = this.curriculumsByTab[1].length
    let draftedCount = this.curriculumsByTab[2].length
    let tabIndexChoice = this.state.tabIndex    
    if (tabIndexChoice === 0 && this.curriculumsByTab[0].length === 0 && (submittedCount + draftedCount > 0))
      tabIndexChoice = submittedCount > 0 ? 1 : 2    
    if (tabIndexChoice !== this.state.tabIndex) {
      if (globals.haveLocalStorage)
        localStorage.setItem('termeric-page-creator-library-last-tab-open', tabIndexChoice)      
      this.state.tabIndex = tabIndexChoice
    }
  }

  // what curriculum ids we need to fetch data for for this page:
  getCurriculumIdsToCheck(isSubmissionsTable, isDraftsTable) {
    return this.curriculumsByTab[isSubmissionsTable ? 1 : (isDraftsTable ? 2 : 0)]
  }

  allDataAndCurriculumDataFetched() {    
    this.setTabIndex()    
    this.curriculums = this.curriculumsByTab[0].concat(this.curriculumsByTab[1]).concat(this.curriculumsByTab[2])
    this.state.curriculumAndUserDataFetched = true
    this.creatorLibraryPanel.current.setState({dataFetched: true})
  }

  isProductionsTab() { return this.state.tabIndex === 0 }
  isSubmissionsTab() { return this.state.tabIndex === 1 }
  isDraftsTab() { return this.state.tabIndex === 2 }

  getCurriculumsForTab() {    
    return this.curriculumsByTab[this.state.tabIndex]
  }

  lastTabOpenLocalStorageKey() { return 'termeric-page-creator-library-last-tab-open' }

  renderBody() {
    return (
      <CreatorLibraryPanel dataFetched={this.state.curriculumAndUserDataFetched} page={this} ref={this.creatorLibraryPanel} />
    )
  }
  
}

// Outer box containing creator-libary listings
class CreatorLibraryPanel extends React.Component {
  constructor(props) {
      super(props)
      this.state = { 
        dataFetched: props.dataFetched || false
      }
      this.page = props.page
      this.dependantComponentsOnToggledPagesComponent = []      
  }
  
  render() {        
    if (this.state.dataFetched) {      
      return (        
        <div className="flex-col">
          <AddNewCurriculumPanel page={this.page} />        
          <div id="creator-libary-listings-and-add-new-panel" className="flex-col section page-body">   
              <CreatorLibraryTabSelection page={this.page} />
              <CreatorLibraryListings dataFetched={Database.isCurriculumsDataFetched()} numberOfResultsShown={creatorLibraryConfig.numberOfResultsShown} parent={this.page} />
                          
              <Utility.CurriculumsListingPageToggleButtons numberOfResultsShown={creatorLibraryConfig.numberOfResultsShown} parent={this} />
          </div>
        </div>
      )
    } else return Utility.renderPageLoading()
  }
}

class AddNewCurriculumPanel extends React.Component {
  
  constructor(props) {
    super(props)
    this.page = props.page    
  } 

  // ask user to confirm they will be required to upload proofs of teaching the curriculum:
  newCurriculumButtonClicked() {
    Utility.showTextDialog(0, 'To ensure quality, Termeric allows only curriculums that have been tested in a real classroom. As part of creating your curriculum, you will be asked to provide proofs to demonstrate that you have taught the curriculum in a previous term as a teacher in the school you designate.\nTo confirm, click Proceed.', generalConfig.dialogBoxInfoIconClassName, undefined, ['Cancel', 'Proceed'], [undefined, (e) => this.page.setState({navigateTo: '/creator/edit'})])
  }

  render() {
    return (
        <div id="creator-libary-add-new" className="curriculum-listings-side-panel opac-background panel">
        {Utility.renderLink(<span><i className="fas fa-chalkboard-teacher"></i>&nbsp;&nbsp;&nbsp;Edit teacher profile</span>, 'creator-libary-edit-teacher-button', 'wide-cta-text-button cta-text-button clear-shadowed-text-button bordered-text-button text-button button', 'creator/edit-teacher')}
        {Utility.renderButtonFromButton(<span><i className="fas fa-plus-square"></i>&nbsp;&nbsp;&nbsp;Create new curriculum</span>, 'creator-libary-add-new-button', 'wide-cta-text-button cta-text-button shadowed-text-button bordered-text-button text-button button', (e) => this.newCurriculumButtonClicked())}
        </div>
    )
  }
}

// section containing actual shopping creator home items listing:
class CreatorLibraryListings extends Utility.LibraryListings {  

  constructor(props) {
    super(props)
    this.parent = props.parent
    this.extraClasses = 'page-top-listings-panel curriculum-listings-panel-next-to-wide-cta'
  }

  getCurriculumListInCurrentPage() {
    return this.parent.getCurriculumsForTab()
  }
  
  getCurriculumViewerPage() {
    return 'creator/view'
  }

  renderOptionalCurriculumInfo(id, curriculumData) {
    let pageId = globals.currentPage.pageId
    let gradeTitle = Database.getCurriculumGradeTitle(curriculumData)
    return (
      <div>
        <span id={pageId + '-curriculum-item-topic-' + id} className="block h8size">{curriculumData['topic-title']}</span>        
        <span className="curriculum-view-card-grade curriculum-view-grade h8size block">{gradeTitle}</span>        
      </div>
    )
  }

  // renders a Library curriculum item view:
  renderCurriculumItemDiv(id, curriculumData) {
    let page = this.parent
    let pageId = globals.currentPage.pageId
    let isActive = curriculumData.active
    let teacher = page.teacher
    let teacherData = page.teacherData
    let libraryItemImageAndInfoDivLink = this.renderCurriculumItemDivContent(id, curriculumData)
    let inDrafts = page.isDraftsTab()
    let inProductions = page.isProductionsTab()
    let inSubmissions = !(inDrafts || inProductions)
    let buttonsComponents = inDrafts ?
      (
        <div className="curriculum-item-view-buttons">
          {Utility.renderButton('Delete', pageId + '-item-' + id + '-delete-button', 'text-button button', (e) => { CreatorUtility.showButtonDialog(curriculumData.id, curriculumData, 'This will permanently delete your curriculum.\nAre you sure?', 'Delete', (curriculum, curriculumData) => CreatorUtility.deleteCurriculum(curriculum, curriculumData, teacher, teacherData, () => { globals.currentPage.resultsComponent.setState({dataFetched: true}) }), true)})}
        </div>
      ) :
      (
        <div className="curriculum-item-view-buttons">
          {inProductions ? Utility.renderButton(isActive ? 'Remove from store' : 'Put back in store', pageId + '-item-' + id + '-deactivate-button', 'text-button button', (e) => { CreatorUtility.showButtonDialog(curriculumData.id, curriculumData, (isActive ? 'This will remove your curriculum from the store. Users who have already purchased it will continue to have access to it. Other users will not be able to search for or purchase it anymore. You can put it back in the store at any time.' : 'This will put the curriculum back in the store. Users will be able to find and purchase it.') + '\nAre you sure?', isActive ? 'Remove' : 'Put back', (curriculum, curriculumData) => CreatorUtility.toggleCurriculumActive(curriculum, curriculumData, teacher, teacherData, () => { globals.currentPage.resultsComponent.setState({dataFetched: true}) }), false)}) : null}
          {inSubmissions ? Utility.renderButton('Remove submission', pageId + '-item-' + id + '-remove-from-store-button', 'text-button button', (e) => { CreatorUtility.showButtonDialog(curriculumData.id, curriculumData, 'This will remove your curriculum submission, but keeps it in your drafts.\nAre you sure?', 'Remove', (curriculum, curriculumData) => CreatorUtility.removeCurriculumFromStore(curriculum, curriculumData, teacher, teacherData, () => { globals.currentPage.resultsComponent.setState({dataFetched: true}) }), true)}) : null}
        </div>
      )
    return (
      <div key={pageId + '-curriculum-item-' + id} id={pageId + '-curriculum-item-' + id} className="curriculum-item-view">              
        {libraryItemImageAndInfoDivLink}  
        {buttonsComponents}
      </div>
    )
  }

}

// Library tab selection component:
class CreatorLibraryTabSelection extends Utility.LibraryTabSelection {

  render() {
    let page = this.page
    // giving a unique key to be sure next time parent is re-render this component will re-render as well:
    return (
      <div id="creator-library-tab-select-panel" className="panel">        
        <Utility.OptionsSelect label={page.tabTitles[page.state.tabIndex]} id={'creator-library-tab-select-panel-options'} key={Utility.getEpochTime()} optionsDivExtraClasses={'library-tab-select-panel-options-div-options'} options={[0, 1, 2]} optionKeyToTitleFn={(idx, s) => { return page.tabTitles[s] }} optionKeyToTitleIconFn={(idx, s) => { return page.tabTitleIcons[s] }} onChangeFn={this.tabChangeFn.bind(this)} defaultValue={page.state.tabIndex} addDefault={false} />
      </div>
    )
  }
}

export default CreatorLibraryPage
