/*
    User site - Down Page for showing that site is down for maintenance
*/
import * as Config from './config'
import * as Utility from './utility'

const generalConfig = Config.pages.general
const downMessage = `We're doing some housekeeping. Please check back soon!`

class DownPage extends Utility.LandingPageWithDialogBox {
    constructor(props) {
        super(props)
        this.pageId = 'down'        
    }
    
    componentDidMount() {
        Utility.showTextDialog(0, downMessage, generalConfig.dialogBoxErrorIconClassName)
    }

    renderBodyAndFooter() {
        return Utility.renderBodyAndFooterForPageWithPopupDialogbox(this, 1, false)
    }

    // default render of a landing page without search bar in header...:
    render() {
        return Utility.renderNoSearchBarPage(this)        
    }
}

export default DownPage
