/*
    Lesson Plan Print page: print-friendly lesson view
*/

import React from 'react'

import * as Database from './database-api'
import * as Utility from './utility'
import LessonPlanViewPage from './lesson'

class LessonPlanPrintViewPage extends LessonPlanViewPage {
    constructor(props) {
        super(props)
        this.pageId = 'lesson-print'
        this.isPrintPage = true // flag for print pages
        this.bodyClass= 'no-margin-body'        
        this.pageBodyClass = 'no-padding-page-body'
        
    }

    componentDidUpdate(prevProps, prevState, snapshot) {        
    }
    
    render() {
        return this.noHeadersNoFooterRender()
    }

    renderLessonTopInfo() {
        return null
    }

    renderLessonHeaderInfo() {
        let curriculumData = this.curriculumData
        let lessonNumber = this.lessonNumber
        let lessonTitle = curriculumData['lesson-titles'][this.lessonNumber - 1]
        let lessonLink = 'lesson?subject=' + curriculumData.subject + '&id=' + curriculumData.id + '&random-key=' + curriculumData['random-key'] + '&section=' + this.sectionNumber + '&week=' + this.weekNumber + '&day=' + this.dayNumber + '&lesson=' + this.lesson + '&number=' + this.lessonNumber
        let gradeTitle = Database.getCurriculumGradeTitle(curriculumData)
        return (
            <div>
                <div className="margin20 section white-bg boxed-div">
                    {Utility.renderLink(curriculumData.title + ' (' + gradeTitle + ')', 'top-lesson-listing-curriculum-title', 'h5size', 'view?subject=' + curriculumData.subject + '&id=' + curriculumData.id + '&random-key=' + curriculumData['random-key'])}
                    <br/><br/>
                    <span  className="size20">Week {this.weekNumber}{curriculumData['num-lessons-per-week'] > 1 ? (' - Day ' + this.dayNumber) : ''}</span><br/><br/>
                    {Utility.renderLink('Lesson ' + lessonNumber ,  'top-lesson-listing-lesson-link', 'size20', lessonLink)}
                    <h3 id="top-lesson-listing-section-title" className="logo-font size22">{curriculumData['section-titles'][this.sectionNumber - 1]}</h3>
                    {Utility.renderLink(lessonTitle, 'top-lesson-listing-title', 'logo-font size31', lessonLink)}
                </div>
            </div>
        )
    }
  
    renderLessonContentActivitiesHelper(idxs) {
        let lessonData = this.lessonData
        let activityTitles = lessonData['activity-titles']
        let activityDescriptions = lessonData['activity-descriptions']
        let activityDurations = lessonData['activity-durations']
        return idxs.map(idx => {    
            let activityTitle =  activityTitles[idx] || ''
            let activityDescription =  activityDescriptions[idx] || ''
            let activityDuration = activityDurations[idx] || ''
            return (
                <div key={'activity-title-' + idx} className="margin20 avoid-print-break-inside lesson-activity-title-div">
                    <span className="underlined">Activity {' ' + (idx + 1)}</span>
                    <span className="size18 weight600">: {activityTitle}</span><span className="lesson-activity-title-div-duration">{activityDuration} min.</span>
                    <div id={'activity-description-text-' + idx} className="white-bg boxed-div">{Utility.parseFormattedURLsFromText(activityDescription, true)}</div>
                </div>
            )
        })
    }

    // renders the lesson activities
    renderLessonContentActivities() {
        let lessonData = this.lessonData
        let activityTitles = lessonData['activity-titles']
        let idxs = [...Array(activityTitles.length).keys()]
        return (
            <div id="lesson-body-listing-content-activities">
                <h4>Warmup Activity</h4>
                <div id="lesson-body-listing-content-warmup-activity-table" className="listing-table white-bg boxed-div">
                    {this.renderLessonContentActivitiesHelper([0])}
                </div>
                <h4>Next Activities</h4>        
                <div id="lesson-body-listing-content-next-activities-table" className="listing-table white-bg boxed-div">
                    {this.renderLessonContentActivitiesHelper(idxs.slice(1))}
                </div>
          </div>
        )
    }

    renderLessonContent() {
        return (
            <div>
                <div id="lesson-body-listing-content-info-1" className="A4-print-page">
                    {Utility.renderLogo(this)}
                    <h2 id="top-curriculum-listing-title" className="logo-color size24 top-margin20">Lesson Plan</h2>                    
                    {this.renderLessonHeaderInfo()} 
                    {this.renderLessonContentAbout()}                    
                    {this.renderLessonContentResources()}
                </div>                
                <div id="lesson-body-listing-content-info-2" className="A4-print-page">
                    <h4>Activities</h4> 
                    {this.renderLessonContentActivities()}
                    {this.renderLessonContentAssessments()}
                    {this.renderLessonContentTeacherNotes()}
                </div>
            </div>
        )
    }

    renderDescription() {
        let lesson = this.lesson
        let descriptionDivRef = React.createRef()
        this.descriptionsDivs = {}
        this.descriptionsDivs[lesson] = descriptionDivRef
        return (
            <div id={'view-description-text-' + lesson} className="" ref={descriptionDivRef}></div>
        )
    }

    // label for download resource button (undefined means using utility fns default...)
    getResourceDownloadLabel() {
        return 'Download'
    }

}

export default LessonPlanPrintViewPage
