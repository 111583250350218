/*
  termeric.education website code
*/

import React from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import './index.css'
// User site:
import HomePage from './home'
import RegisterPage from './register'
import SignInPage from './signin'
import SignInCallbackRoute from './signin-callback'
import SignOutRoute from './signout'
import ContactPage from './contact'
import DownPage from './down'
import AboutPage from './about'
import NewsPage from './news'
import PrivacyPage from './privacy'
import TermsPage from './terms'
import SearchPage from './search'
import CurriculumPreviewPage from './preview'
import CurriculumViewPage from './view'
import CurriculumPrintViewPage from './curriculum-print'
import CartPage from './cart'
import LibraryPage from './library'
import TeacherPage from './teacher'
import LessonPlanViewPage from './lesson'
import LessonPlanPrintViewPage from './lesson-print'
// Creator site:
import CreatorLibraryPage from './creator-library'
import CreatorCurriculumViewPage from './creator-view'
import CreatorEditCurriculumPage from './creator-edit'
import CreatorEditTeacherPage from './creator-edit-teacher'
import CreatorLessonPlanViewPage from './creator-lesson'
import CreatorEditLessonPlanPage from './creator-edit-lesson'
import CreatorCurriculumPrintViewPage from './creator-curriculum-print'
import CreatorLessonPlanPrintViewPage from './creator-lesson-print'
import CreatorSalesPage from './creator-sales'
// Admin site:
import AdminLibraryPage from './admin-library'
import AdminCurriculumViewPage from './admin-view'
import AdminLessonPlanViewPage from './admin-lesson'
import ErrorPage from './error'

// ------------------------------------------------------------------------
// App element which routes to different pages: HomePage, SigninPage, etc.
function App() {  
  return (
      <main>
          <Routes>
              <Route path="/" element={<HomePage />} />              
              <Route path="/signin" element={<SignInPage />} />
              <Route path="/register" element={<RegisterPage />} />
              <Route path="/signin-callback" element={<SignInCallbackRoute />} />
              <Route path="/signout" element={<SignOutRoute />} />
              <Route path="/about" element={<AboutPage />} />
              <Route path="/news" element={<NewsPage />} />
              <Route path="/privacy" element={<PrivacyPage />} />
              <Route path="/terms" element={<TermsPage />} />
              <Route path="/contact" element={<ContactPage />} />
              <Route path="/down" element={<DownPage />} />
              <Route path="/search" element={<SearchPage />} />
              <Route path="/preview" element={<CurriculumPreviewPage />} />
              <Route path="/cart" element={<CartPage />} />
              <Route path="/library" element={<LibraryPage />} />
              <Route path="/view" element={<CurriculumViewPage />} />
              <Route path="/curriculum-print" element={<CurriculumPrintViewPage />} />
              <Route path="/teacher" element={<TeacherPage />} />
              <Route path="/lesson" element={<LessonPlanViewPage />} />
              <Route path="/lesson-print" element={<LessonPlanPrintViewPage />} />
              <Route path="/creator/library" element={<CreatorLibraryPage />} />
              <Route path="/creator/view" element={<CreatorCurriculumViewPage />} />
              <Route path="/creator/curriculum-print" element={<CreatorCurriculumPrintViewPage />} />
              <Route path="/creator/lesson-print" element={<CreatorLessonPlanPrintViewPage />} />
              <Route path="/creator/lesson" element={<CreatorLessonPlanViewPage />} />
              <Route path="/creator/edit-teacher" element={<CreatorEditTeacherPage />} />
              <Route path="/creator/edit" element={<CreatorEditCurriculumPage />} />
              <Route path="/creator/edit-lesson" element={<CreatorEditLessonPlanPage />} />
              <Route path="/creator/sales" element={<CreatorSalesPage />} />
              <Route path="/admin/library" element={<AdminLibraryPage />} />
              <Route path="/admin/view" element={<AdminCurriculumViewPage />} />
              <Route path="/admin/lesson" element={<AdminLessonPlanViewPage />} />
              <Route path="/*" element={<ErrorPage />} />
          </Routes>
      </main>
  )
}

// ========================================

const container = document.getElementById('root')
const root = createRoot(container)
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>)
