/*
    Creator site - Sale report page
*/

import React from 'react'

import * as Config from './config'
import * as Database from './database-api'
import * as Utility from './utility'
import * as CreatorUtility from './creator-utility'

const globals = Config.globals

class CreatorSalesPage extends CreatorUtility.CreatorRestrictedAccessLandingPage {
  constructor(props) {
    super(props)
    this.pageId = 'creator-libary'
    this.salesMonth = Utility.getCurrentMonthName()
    this.salesYear = new Date().getUTCFullYear()
    this.monthSaleTotal = 0
    this.formMonthRef = React.createRef()
    this.formYearRef = React.createRef()
    this.monthSales = undefined
  }
  
  userAndTeacherDataFetched() {
    super.userAndTeacherDataFetched()
    if (!this.teacherData) {
      this.setState({navigateTo: '/creator/edit-teacher'})
    } else {
      // get the creator data before rendering the page:      
      Database.database_fetchCreatorUserData()        
    }
  }

  creatorUserDataFetched() {
    this.creatorData = Database.getCreatorUserData()    
    // fetch sales data now
    Database.database_fetchSalesData(this.teacher, this.salesMonth, this.salesYear)
  }

  saleDataFetched(items) {
    this.monthSales = items
    this.monthSales.reverse()
    this.monthSaleTotal = 0
    items.forEach((i) => {this.monthSaleTotal += parseInt(i['price-usd'].N, 10)})
    this.setState({dataFetched: true})
  }

  monthAndYearOnChangeFn(e, val) {    
    if (val) {
      this.salesMonth = this.formMonthRef.current.getValue()
      this.salesYear = parseInt(this.formYearRef.current.getValue(), 10)
      this.setState({dataFetched: false})
      // fetch sales data again:
      Database.database_fetchSalesData(this.teacher, this.salesMonth, this.salesYear)
    }
  }

  renderMonthSaleItems() {
    let saleComponents = this.monthSales.map((sale, idx) => { 
      let curriculum = sale.curriculum.S
      let subject = sale.subject.S       
      return (
          <div key={'creator-sales-body-monthly-item-' + idx} className="sales-item curriculum-item-view flex-row flex-direction-row">
            <span className='sales-item-date'>{Utility.getFormattedTime2(sale['date-purchased'].N)}</span>
            <a className='sales-item-curriculum resource-link' href={Utility.getACurriculumsDirectShareUrl(curriculum, subject)}>{curriculum}</a>
            <span className='sales-item-buyer'>{sale['buyer-name'].S}</span>
            <span className='sales-item-amount'>${sale['price-usd'].N}</span>
          </div>
      )
    })
  return (
      <div className='creator-sales-table curriculum-listings-panel curriculum-listings-panel-no-sides'>
        <div key={'creator-sales-body-monthly-item-headers'} className="sales-table-header sales-item curriculum-item-view flex-row flex-direction-row">
            <span className='sales-item-date'>Date</span>
            <span className='sales-item-curriculum'>Curriculum</span>
            <span className='sales-item-buyer'>Buyer</span>
            <span className='sales-item-amount'>Amount</span>
          </div>
        {saleComponents}
      </div>
    )
  }

  renderBody() {
    if (this.state.dataFetched) {
      return (
        <div id="creator-sales-page" className="flex-col section page-body">
          <h1 className="logo-font logo-color">My Sales</h1>
          <div key={'sales-body-total'} className="curriculum-item-view flex-col flex-direction-column">
            <h2>Totol Sales</h2>
            <h3 className="logo-font boxed-text">USD ${Utility.priceWithCommas(this.creatorData['sales-total-usd'])}</h3>
          </div>
          <div key={'creator-sales-body-monthly'} className="curriculum-item-view flex-col flex-direction-column">
            <div id="creator-sales-monthly-section" className="flex-col center-content panel">
              <h2>Monthly Sales</h2>
              <div id="creator-sales-form-about" className="form flex-col">
                <div className="form-item flex-col">
                  <label>Month</label>
                  <div className="flex-row flex-center">
                      <Utility.OptionsSelect label={this.salesMonth} id={'sales-table-form-month'} containerExtraClasses={'form-select-div'} options={CreatorUtility.getMonthsReverseFromCurrentMonth()} optionKeyToTitleFn={(idx, p) => { return p }} onChangeFn={this.monthAndYearOnChangeFn.bind(this)} defaultValue={this.salesMonth} addDefault={false} ref={this.formMonthRef} />
                  </div>
                </div>
                <div className="form-item flex-col">
                  <label>Year</label>
                  <div className="flex-row flex-center">
                      <Utility.OptionsSelect label={this.salesYear} id={'sales-table-form-term-year'} containerExtraClasses={'form-select-div'} options={CreatorUtility.getPastNYears(10)} optionKeyToTitleFn={(idx, p) => { return p }} onChangeFn={this.monthAndYearOnChangeFn.bind(this)} defaultValue={this.salesYear} addDefault={false} ref={this.formYearRef} />                      
                  </div>
                </div>
              </div>
              <h3 className="logo-font boxed-text">USD ${Utility.priceWithCommas(this.monthSaleTotal)}</h3>
                {(this.monthSales.length > 0) ?
                  this.renderMonthSaleItems() :
                  Utility.renderNothingToShow('sales-listings')}
            </div>
          </div>
        </div>
      )
    } else {
      return Utility.renderPageLoading()
    }
  }

}

export default CreatorSalesPage
