/*
    Search page
*/

import React from 'react'
import Collapsible from 'react-collapsible'

import * as Config from './config'
import * as Database from './database-api'
import * as Utility from './utility'
import LandingPage from './landing'

const globals = Config.globals
const searchConfig = Config.pages.search

class SearchPage extends LandingPage {
  constructor(props) {
      super(props)
      this.pageId = 'search'
      this.isSearchPage = true
      this.ranSearch = false
      this.searchResultsSection = React.createRef()
      this.defaultSortType = searchConfig.defaultResultsSortType
      this.sortTypeKeyToTitle = {relevant: 'Most Relevant', popular: 'Most Popular', rating: 'Highest Rated', 'price-lowest': 'Lowest Price', 'price-highest': 'Highest Price', recent: 'Most Recent'}
      this.sortType = globals.haveLocalStorage ? (localStorage.getItem('termeric-page-' + this.pageId + '-last-sort-type') || this.defaultSortType) : this.defaultSortType
  }

  componentDidMount() {
    super.componentDidMount()    
    // If database has already finished its fetching curriculum data for default query at this time, call the callback manually (otherwise, it will call it for us itself once fetched):
    if (Database.isSubjectsDataFetched())
      this.subjectsDataFetched()    
  }

  // database API calls this when subjects info is fetched. It'll trigger components that need that info to render now...
  subjectsDataFetched() {
    super.subjectsDataFetched()
    if (this.didMount)        
      this.searchResultsSection.current.setState({dataFetched: true})  
    if (Database.isCurriculumsDataFetched())
      this.curriculumsDataFetched()
    else if (!this.ranSearch)
      // rerun search and refresh page:
      this.runSearch(false) 
  }

  // database API calls this when curriculums info for all search results are fetched. It'll trigger components that need that info to render now...
  curriculumsDataFetched() {
    if (this.ranSearch) { 
      // if current sort type setting isn't default, apply it here:
      if (this.sortType !== this.defaultSortType) {        
        Database.applySortTypeToSearchResults(this.sortType)     
      }
      this.resultsComponent.setState({dataFetched: true})
    } else {
      // perform database search for curriculums based on subject/query: 
      this.runSearch(false) 
    }
  }

  // refreshes search page showing current subject's all curriculums
  refreshPage() {
    // delete any url param query values first:
    delete globals.urlParams.q
    // clear search bar:
    let inputElement = this.headerSearchBarRef.current
    inputElement.value = ''
    // rerun search and refresh page:
    this.runSearch(false) 
  }

  // Perform database search for curriculums based on subject/query: 
  runSearch(resetSortOption) {
    this.ranSearch = true
    let searchResultsSection = this.searchResultsSection.current
    if (resetSortOption && searchResultsSection.sortPanelOptionsRef && searchResultsSection.sortPanelOptionsRef.current) {
      // reset search options:
      searchResultsSection.sortPanelOptionsRef.current.setValue(this.sortType)
    }
    // What initial search query to run to get curriculum search results list for this page:
    let searchQuery = Database.getCurriculumResultsQueryForSearch()    
    if (searchQuery)
      Database.database_fetchCurriculumsData(searchQuery, false)
  }

  renderBody() {
    return (      
        <SearchResultsPanel ref={this.searchResultsSection} page={this} />
    )
  }

}

// Outer box containing results, filter and sort panels:
class SearchResultsPanel extends React.Component {
  constructor(props) {
      super(props)
      this.state = {
        dataFetched: Database.isSubjectsDataFetched()
      }
      this.page = props.page
      this.sortPanelOptionsRef = React.createRef()
      this.dependantComponentsOnToggledPagesComponent = []      
  }
  
  componentDidMount() {
    // set a flag so others know this is ready:
    this.didMount = true    
  }

  sortTypeChangeFn(e, sortType) {
    this.page.sortType = sortType
    if (globals.haveLocalStorage) localStorage.setItem('termeric-page-' + this.page.pageId + '-last-sort-type', sortType)    
    // notify the database API to apply a new sorting function to search results list:
    Database.applySortTypeToSearchResults(sortType, globals.currentPage.resultsComponent)
  }

  renderSortPanel() {
    // if the search subject pulldown menu is changed, store the current subject in the globals:    
    let sortType = this.page.sortType
    let sortTypeKeyToTitle = this.page.sortTypeKeyToTitle
    return (
      <div id="search-sort-panel" className="panel">
        <span className="size20">Sort by</span>
        <Utility.OptionsSelect label={sortTypeKeyToTitle[sortType]} id={'search-sort-panel-options'} options={['relevant', 'popular', 'rating', 'price-lowest', 'price-highest', 'recent']} optionKeyToTitleFn={(idx, s) => { return sortTypeKeyToTitle[s] }} onChangeFn={this.sortTypeChangeFn.bind(this)} defaultValue={sortType} addDefault={false} ref={this.sortPanelOptionsRef} />
      </div>
    )
  }

  renderFilterPanel() {
    const componentFn = (idx, id, item, valueFn, onChangeFn) => {
      return (
        Utility.renderCheckbox(id + '-div', false, item,  undefined, undefined, '', valueFn(idx, item), onChangeFn)
      )
    }
    let curriculumTypes = ['Curriculum', 'Curriculum Unit']
    let subjects = Database.getSubjects()
    let languages = Database.getLanguages()
    // type filter:
    let valueFn = (idx, name) => { return idx === 0 ? true : false }
    // define on-change function for (un)checking a subject results filter:    
    const typeOptions = Utility.generateComponentList(2, 'filter-option-type-', (i) => { return curriculumTypes[i] }, componentFn, valueFn, Utility.genOnChangeFnForFilters('type'))
    // subjects filter:
    valueFn = (idx, name) => { return subjects[idx] }
    // define on-change function for (un)checking a subject results filter:    
    const subjectOptions = Utility.generateComponentList(subjects.length, 'filter-option-subject-', (i) => { return Database.getSubjectTitle(subjects[i])}, componentFn, valueFn, Utility.genOnChangeFnForFilters('subject'))
    // grade filter:
    valueFn = (idx, name) => { return idx }
    // define on-change function for (un)checking a grade results filter:    
    const gradeOptions = Utility.generateComponentList(13, 'filter-option-grade-', (i) => { return i === 0 ? 'Pre/K' : ('Grade ' + i) }, componentFn, valueFn, Utility.genOnChangeFnForFilters('grade'))
    // lesson duration filter:
    valueFn = (idx, name) => { return (idx + 1) * 30 }
    // define on-change function for (un)checking a rating results filter:    
    const lessonDurationOptions = Utility.generateComponentList(4, 'filter-option-lesson-duration-', (i) => { return ((i + 1) * 30) + ' minutes or less' }, componentFn, valueFn, Utility.genOnChangeFnForFilters('lesson-duration'))
    // number of lessons filter:
    valueFn = (idx, name) => { return (10 - idx) * 10 }
    // define on-change function for (un)checking a rating results filter:    
    const numLessonsOptions = Utility.generateComponentList(9, 'filter-option-num-lessons-', (i) => { return ((10 - i) * 10) + ' lessons or more' }, componentFn, valueFn, Utility.genOnChangeFnForFilters('num-lessons'))
    // rating filter:
    valueFn = (idx, name) => { return 4 - idx }    
    // define on-change function for (un)checking a rating results filter:    
    const ratingOptions = Utility.generateComponentList(3, 'filter-option-rating-', (i) => { return 'Rating ' + (4 - i) + ' & higher' }, componentFn, valueFn, Utility.genOnChangeFnForFilters('rating'))
    // grade filter:
    valueFn = (idx, name) => { return languages[idx] }
    // define on-change function for (un)checking a language results filter:    
    const languageOptions = Utility.generateComponentList(languages.length, 'filter-option-language-', (i) => { return Database.getLanguageTitle(languages[i]) }, componentFn, valueFn, Utility.genOnChangeFnForFilters('language'))
    let triggerTransitionTime = 200, collapsibleClassName = 'search-filter-type-collapsible Collapsible'
    return (
      <div id="search-filter-panel-container">
        <Collapsible trigger="Filter by:" transitionTime={triggerTransitionTime} classParentString="search-filter-panel-title-collapsible Collapsible">
          <div id="search-filter-panel">
            <Collapsible trigger="Type" transitionTime={triggerTransitionTime} classParentString={collapsibleClassName}>
              {typeOptions}
            </Collapsible>
            <Collapsible trigger="Subjects" transitionTime={triggerTransitionTime} classParentString={collapsibleClassName}>
              {subjectOptions}
            </Collapsible>
            <Collapsible trigger="Grade" transitionTime={triggerTransitionTime} classParentString={collapsibleClassName}>
              {gradeOptions}
            </Collapsible>
            <Collapsible trigger="Lesson Duration" transitionTime={triggerTransitionTime} classParentString={collapsibleClassName}>
              {lessonDurationOptions}
            </Collapsible>
            <Collapsible trigger="Number of Lessons" transitionTime={triggerTransitionTime} classParentString={collapsibleClassName}>
              {numLessonsOptions}
            </Collapsible>
            <Collapsible trigger="Rating" transitionTime={triggerTransitionTime} classParentString={collapsibleClassName}>
              {ratingOptions}
            </Collapsible>
            <Collapsible trigger="Language" transitionTime={triggerTransitionTime} classParentString={collapsibleClassName}>
              {languageOptions}
            </Collapsible>
          </div>   
        </Collapsible>
      </div>      
    )
  }
 
  render() {
    if (this.state.dataFetched) {
      let numberOfResultsShown = searchConfig.numberOfResultsShown      
      return (
        <div id="search-results-section" className="flex-col section page-body">          
            <SearchResultsSummary parent={this} />
            <div id="search-results-and-sort-and-filter-panels" className="flex-col">      
            {this.renderSortPanel()}
            <div id="search-results-and-filter-panel">
              {this.renderFilterPanel()}
              <SearchResults dataFetched={Database.isCurriculumsDataFetched()} numberOfResultsShown={numberOfResultsShown} parent={this} />
            </div>
            </div>
            <Utility.CurriculumsListingPageToggleButtons numberOfResultsShown={numberOfResultsShown} parent={this} />            
        </div>
      )
    } else
      return Utility.renderPageLoading()
  }
}

// section containing actual search results listing:
class SearchResults extends Utility.ToggledPagesComponent {

  getCurriculumListInCurrentPage() {
    return super.getCurriculumListInCurrentPage(Database.getSearchResultCurriculumIds())
  }

  // renders a search result curriculum view:
  renderCurriculumItemDiv(id, curriculumData) {
    let ratingImageWidth = curriculumData.rating * 15
    let gradeTitle = Database.getCurriculumGradeTitle(curriculumData)
    let itemDiv = (
      <div key={'subject-curriculum-result-' + id} id={'subject-curriculum-result-' + id} className="curriculum-result-view button">              
        <img id={'subject-curriculum-result-img-' + id} className="curriculum-view-image  curriculum-result-view-image round-corners" src={curriculumData.imageUrl} alt="" />
        <div key={'subject-curriculum-result-info-and-price-' + id} id={'subject-curriculum-result-info-and-price-' + id} className="curriculum-result-info-and-price-view">
          <div key={'subject-curriculum-result-info-' + id} id={'subject-curriculum-result-info-' + id} className="curriculum-result-info-view">
            <div className="flex-row">
              <span id={'subject-curriculum-item-type-' + id} className="logo-color boxed-text curriculum-type-flag h8size">{curriculumData['single-unit'] ?  'Curriculum Unit' : 'Curriculum'}</span>
              {curriculumData['has-instruction-video'] ? <span className="logo-color boxed-text curriculum-type-flag left-margin5or0 right-margin0 h8size">&nbsp;<i className="fas fa-video" aria-hidden="true"></i>&nbsp;&nbsp;Instruction Video</span> : null}
            </div>
            <span id={'subject-curriculum-result-title-' + id} className="curriculum-result-view-title curriculum-view-title h3size weight500">{curriculumData.title}</span>
            <span id={'subject-curriculum-result-subtitle-' + id} className="curriculum-result-view-subtitle curriculum-view-subtitle h6size">{curriculumData.subtitle}</span>
            <span id={'subject-curriculum-result-grade-' + id} className="curriculum-result-view-grade curriculum-view-grade h8size">{gradeTitle}</span>
            <span id="subject-curriculum-result-topic" className="h8size">{curriculumData['topic-title']}</span>
            <span id={'subject-curriculum-result-teacher-' + id} className="curriculum-result-view-teacher curriculum-view-teacher h8size">{curriculumData['teacher-name']}</span>
            {Utility.renderRatingStars('curriculum-result', 'curriculum-listing', ratingImageWidth)}
          </div>
          {Utility.renderCurriculumPrice(this.parent.page, curriculumData, 'curriculum-result-view-price curriculum-view-price h3size weight500', true)}
        </div>
      </div>         
    )
    return Utility.renderDivLink('subject-curriculum-result-link-' + id, itemDiv, 'preview?subject=' + curriculumData.subject + '&id=' + curriculumData.id)
  }

  render() {
    if (this.state.dataFetched)
      return super.render('search', 'search-results-view-span-container panel')      
    else 
      return (
        <div id="search-results" className="section"></div>
      )
  }
}

// Search results summary panel
class SearchResultsSummary extends React.Component {
  constructor(props) {
      super(props)
      this.state = {
        dataFetched: false,
        numResults: 0
      }
      this.parent = props.parent
      this.parent.dependantComponentsOnToggledPagesComponent.push(this)
  }
 
  renderSubjectIconIfAny() {
    let s = globals.urlParams.subject || ''
    let topic = globals.urlParams.topic || ''
    if (s) {    
      return (
        <div id="search-subject-header" key={'subject-button-div-' + s} className="flex-row flex-center">
            <div key={'subject-button-img-div-' + s} className="subject-button-img-div-big subject-button-img-div-big-in-row subject-button-img-div round-border button image-button">
              {Utility.renderImage(Database.getSubjectIcon(s), 'subject-button-' + s,   'subject-button-img', (e) => { globals.currentPage.refreshPage() })}
            </div>
            <span className="logo-font size25">{Database.getSubjectTitle(s)}</span>
            {topic ? 
              <div>
                <i className="fas fa-angle-right row-padding-10"></i>
                <span className="logo-font size25">{Database.getTopicTitle(topic)}</span>
              </div>
              : null}
          </div>
      )
    } else {
      return (
        <div key={'subject-button-div-' + s} className="subject-button-div"></div>
      )
    }
  }

  render() {    
    if (this.state.dataFetched) {      
      let resultsSummaryMessage = Utility.plural('result', this.state.numResults) + ' ' + (globals.urlParams.q ? (' for "' + globals.urlParams.q + '"') : '') + (globals.urlParams.subject ? ' in' : '')
      return (
        <div id="search-results-summary" className="panel">
          <h3 className="size25">{resultsSummaryMessage}</h3>
          {this.renderSubjectIconIfAny()}
        </div>
      )
    } else {
      return (
        <div id="search-results-summary" className="panel"></div> 
      )
    }
  }
}

export default SearchPage
