/*
  Handles user session (sign in/out) from Cognito authentication system
*/

/*
from: https://gist.github.com/gabeweaver/d1be9f0d41069437f576c375c30e134c

Providers currently configured to use /callback:
    * AWS Cognito User Pool
    
  How it works:
    When successfully authenticating with a login UI, the Cognito User Pool redirects the user to this
    route with temporary tokens in the url hash. Since it is a fresh page reload upon redirect, once the component
    mounts, we call `parsedUrl` from the auth API to parse the hash and initiate starting a session with CognitoId.
    
    After successfully initiating a session with CognitoId, `session` is set in the redux store, which triggers
    the route components to receive updated props, which triggers a redirect to the routes index. 
    
    When a user initiates logging out, the Cognito User Pool redirects the user to this route upon successfully
    closing the current session and removing the tokens from storage. When this callback happens, there is no hash
    in the url, which triggers a redirect to the route index. If a user navigates to /callback manually, they will
    also be redirected to the route index. 
    
    The `withAuth` enhancer provides `parsedUrl` and `session` to this route. 
*/

import { CognitoAuth } from 'amazon-cognito-auth-js'
import { CognitoUserPool } from 'amazon-cognito-identity-js'

import * as Config from './config'
import * as Database from './database-api'
import * as Utility from './utility'

const globals = Config.globals
const authConfig = Config.authConfig
const dbConfig = Config.dbConfig
const generalConfig = Config.pages.general

// API functions for talking to the backend database:
let AWS = require('aws-sdk')

// Initalize config for backend AWS:
export function init(callerComponent) {    
    if (globals.AWSInit) { return }
    globals.AWSInit = true
    // Need to set the default region to get credentials by Cognito
    AWS.config.update({region: authConfig.region})
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        AccountId: dbConfig.accountId,        
        IdentityPoolId: authConfig.identityPoolId, //dbConfig.poolId,
        RoleArn: authConfig.cognitoUnauthRole
    })
    console.log('aws region/credentials init set')
}

export function initAndGetSessionInfoThenInitDatabase(callerComponent) {
    init(callerComponent)    
    getSessionInfoThenInitDatabase(callerComponent)
}

// Check if user session active, and initalize database connection and fetch landing page data:
// after logged in, cognito calls the callback script signin-callback.js with tokens in the url
// This function starts the CognitoId authentication process, and gets the user session info,
// and stores in the global session variable too:
export function getSession(callerComponent) {
    AWS.config.update({region: authConfig.region})
    const authData = {
        ClientId: authConfig.clientId,
        AppWebDomain: authConfig.loginDomain, 
        TokenScopesArray: authConfig.scope,            
        RedirectUriSignIn: authConfig.redirectTargetUrl,
        RedirectUriSignOut: authConfig.redirectTargetUrl
    }
    const auth = new CognitoAuth(authData)

    auth.userhandler = {
        onSuccess: function(result) {
            console.log("Cognito signin success...")            
            // redirect to home page:
            callerComponent.setState({redirect: true})            
        },
        onFailure: function(err) {
            console.log("Cognito signin fail!")
            console.log(err)
        }
    }

    // Parse the signin token gotten in the url calledback here from the cognito signin process, to init the session:
    auth.parseCognitoWebResponse(window.location.href)
}

// Check if user session active
// Gets cognito signin session info (user's name and email) (if any) and stores in globals
// Once done (either with success/fail) initiates database:
export function getSessionInfoThenInitDatabase(callerComponent) {
    if (globals.authInit) { return }
    globals.authInit = true    
    console.log('checking if have existing auth session...')
    // Gets a User Pool instance
    const userPool = new CognitoUserPool({
        UserPoolId: authConfig.userPoolId,
        ClientId: authConfig.clientId
      })
    let cognitoUser = userPool.getCurrentUser()    
    if (!cognitoUser) {
        console.log('aws no cognito users...')        
        return getUnAuthRoleCredentials(callerComponent)
    }
    // Get current session info and set it in the AWS config:
    cognitoUser.getSession((err, result) => {
        //console.log(err, result)
        if (!result) {            
            console.log('aws no cognito users in session...')            
            return getUnAuthRoleCredentials(callerComponent)
        }
        //console.log('user session', result)
        let payload = result.idToken.payload 
        console.log('have existing auth session...')//, payload)       
        globals.sessionInfo = {session: result, signedIn: true, username: authConfig.region + ':' + payload.sub, userName: payload.name, userEmail: payload.email}
        //console.log(globals.sessionInfo)
        const poolUrl = `cognito-idp.${authConfig.region}.amazonaws.com/${authConfig.userPoolId}`        
        // authenticate a user via Identity Pools as an Identity Provider with adminInitiateAuth(), and then turn around and grab an IdentityID by validating a User Pool IdToken against Amazon Cognito Federated Identities via getId(), and finally requesting AWS credentials from Amazon Cognito Federated Identities via getCredentialsForIdentity().        
        // set credentials for authenticated role:
        let cognitoParamsForCredentials = {            
            IdentityPoolId: authConfig.identityPoolId,
            Logins: {
                [poolUrl]: result.getIdToken().getJwtToken()
            }
        }
        //console.log(cognitoParamsForCredentials)
        // update the credentials to use the authenticated params:
        AWS.config.credentials = new AWS.CognitoIdentityCredentials(cognitoParamsForCredentials)        
        // refresh session if necessary:
        if (AWS.config.credentials.needsRefresh()) {            
            //refreshes credentials using AWS.CognitoIdentity.getCredentialsForIdentity()
		    AWS.config.credentials.refresh(error => {
                if (error) {
                    console.log('had problem with refreshing session!', error)
                }
		    })
        }
        // Add the User's Id Token to the Cognito credentials login map:
        let cognitoParamsForGetId = {
            IdentityPoolId: authConfig.identityPoolId,
            Logins: cognitoParamsForCredentials.Logins
        }
        const identity = new AWS.CognitoIdentity()
        globals.sessionInfo.identity = identity
        identity.getId(cognitoParamsForGetId, function (err, identityData) {
            if (err) {
                initDatabaseAfterAuthDone(callerComponent)
                return console.error('Cognito signin to aws auth role (get id) failed!', err)
            }
            const identityParams = {
                IdentityId: identityData.IdentityId,
                Logins: cognitoParamsForGetId.Logins
            }            
            globals.sessionInfo.identityParams = identityParams            
            identity.getCredentialsForIdentity(identityParams, function (err, data) {
                if (err) {
                    console.error('Cognito signin to aws auth role (get credentials) failed!', err)
                } else {                    
                    console.log('Successfully signed into aws auth role...')
                    globals.sessionInfo.username = identityData.IdentityId
                }
                initDatabaseAfterAuthDone(callerComponent)
            })
        })
    })
}

// Getting proper AWS credentials with Unauth role to access AWS backend while not being signed-in:
function getUnAuthRoleCredentials(callerComponent) {
    // Added this line below to fix the intermitten bug: 'CredentialsError: Missing credentials in config':
    AWS.config.credentials.clearCachedId()
    // set credentials for unauthenticated role:            
    let cognitoParamsForCredentials = {            
        IdentityPoolId: authConfig.identityPoolId
    }            
    AWS.config.credentials = new AWS.CognitoIdentityCredentials(cognitoParamsForCredentials)
    const identity = new AWS.CognitoIdentity()
    let cognitoParamsForGetId = {            
        IdentityPoolId: authConfig.identityPoolId
    }
    identity.getId(cognitoParamsForGetId, function (err, identityData) {                
        if (err) {                  
            console.error('Cognito signin to aws unauth role (get id) failed!', err)
        }                
        AWS.config.credentials = new AWS.CognitoIdentityCredentials(cognitoParamsForGetId)        
        // Initiliaze connection to database abd fetch curriculum data:
        initDatabaseAfterAuthDone(callerComponent)
    })    
}

// Initiliaze connection to database abd fetch curriculum data:
// (for non landing pages, start the redirect process back to landing page: home)
function initDatabaseAfterAuthDone(callerComponent) {
    // load AWS credentials so backend could be accessed:
    AWS.config.getCredentials(function(err) {
        if (err) {
            // credentials not loaded
            console.log(err, err.stack)
            // cannot access AWS! ask to force refresh page for a retry:            
            Utility.showTextDialog(0, 'Problem connecting to the server! Please refresh your browser page to retry.', generalConfig.dialogBoxErrorIconClassName)
        } else {
            // if at this point we're not in session (signed in), store unauth session info:
            if (!(globals.sessionInfo && globals.sessionInfo.signedIn)) {
                // store un auth session username:                
                globals.unAuthSessionInfo = {username: AWS.config.credentials.data.IdentityId}
            }
            // reset the aws region according to db region:
            AWS.config.update({region: dbConfig.region})
            console.log('backend AWS accessed...')
            // Check if user session active, and initalize database connection and fetch landing page data:
            Database.init()
        }
    })
}

// Sign out:
export function signOut(callerComponent) {
    const authData = {
        ClientId: authConfig.clientId,
        AppWebDomain: authConfig.loginJustDomain, 
        TokenScopesArray: authConfig.scope,
        RedirectUriSignIn: authConfig.redirectTargetUrl,
        RedirectUriSignOut: authConfig.redirectTargetUrl
    }
    const auth = new CognitoAuth(authData)
    globals.sessionInfo = undefined
    AWS.config.credentials.clearCachedId()
    // sign out
    auth.signOut()
}
