/*
    Teacher Page
    Shows the curriculum listings for a given teacher
*/

import React from 'react'

import * as Config from './config'
import * as Database from './database-api'
import * as Fileserver from './fileserver-api'
import * as Utility from './utility'

const reactAppEnv = Config.reactAppEnv
const globals = Config.globals
let teacherConfig = Config.pages.teacher

class TeacherPage extends Utility.LandingPageWithDialogBox {
  constructor(props) {
    super(props)
    this.pageId = 'teacher'
    let id = globals.urlParams.teacher 
    this.state = {
        dataFetched: false
    }
    this.fetchMissingCurriculumsDataTried = false
    this.id = id
    this.dependantComponentsOnToggledPagesComponent = []
  }

  componentDidMount() {    
    super.componentDidMount()       
    // if this page was loaded as a landing page, the superclass already initiates calls to the database to get the curriculum and teacher data. However, if we're here just by navigating from a previous page, the curriculums data was already fetched, so manually run the callback function to trigger getting teacher's data and render the page once the data is in:        
    if (Database.isSubjectsDataFetched())
      this.subjectsDataFetched()    
  }

  componentDidUpdate(prevProps, prevState, snapshot) { 
    // make sure we need to proceed with rendering this page (not navigating already):
    if (!super.componentDidUpdate(prevProps, prevState, snapshot))
      return
    // add html description of teacher about text:    
    Utility.insertHtmlAsChild('teacher-about-content', this.teacherData.about)        
  }

  subjectsDataFetched() {
    if (!this.id) 
        this.setState({navigateTo: '/'})
    else {
      super.subjectsDataFetched()
      if (Database.isTeacherDataFetched(this.id))
        this.teacherDataFetched()
      else
        Database.database_fetchTeacherData(this.id)
    }
  }
  
  // Database API calls this when teacher info for the given id is fetched. We'll trigger components that needed curriculum and teacher info to render now...
  teacherDataFetched() {
    this.teacherData = Database.getTeacherData(this.id)
    // if there's no teacher with the given id, it's an error. Redirect back to home page:
    if (!this.teacherData) 
      this.setState({navigateTo: '/'})
    else {
      if (!this.state.dataFetched)
        this.setState({dataFetched: true})
      this.curriculums = this.teacherData.curriculums
      let curriculumsWithoutFetchedData = Database.getCurriculumIdsWithoutFetchedData(this.curriculums)
      // if there are curriculums to show for which we have not fetched data from db, fetch here (cap number of tries to 2):    
      if (curriculumsWithoutFetchedData.length > 0 && !this.fetchMissingCurriculumsDataTried) {
        this.fetchMissingCurriculumsDataTried = true
        Database.fetchCurriculumDataForIds(curriculumsWithoutFetchedData)
      } else
        this.curriculumsDataFetched() 
    }
  }
  
  curriculumsDataFetched() {
    // filter out curriculums which have been deactivated (removed from store) or set to private by the teacher:
    this.curriculums = Database.filterOutNonPublicCurriculums(this.curriculums)
    this.state.curriculumDataFetched = true
    if (this.didMount && this.curriculumListingComponent)
      this.curriculumListingComponent.setState({dataFetched: true})
  }

  // get url for teacher's public (SEO-friendly) page:
  getTeacherShareUrl() {
    return Fileserver.getTeacherPublicPageUrl(this.id)
  }

  // renders share button to copy link to clipboard
  renderShareButton() {
    return Utility.renderButtonFromButton(<button>Share&nbsp;&nbsp;<i className="fas fa-share-alt"></i></button>, 'top-teacher-listing-share-button', 'wide-text-button colored-bordered-text-button bordered-text-button text-button button', (e) => {Utility.copyToClipboard(this.getTeacherShareUrl())})
  }

  renderListingImageAndLinks(teacherData) {     
    return (
      <div id="top-teacher-listing-image-and-links" className="top-listing-image-and-ctas">
        <img id="top-teacher-listing-image" className="teacher-listing-image round-border" src={teacherData.imageUrl} alt="" />
        {Utility.renderExternalLink(<span>Website <i className="fas fa-external-link-alt"></i></span>, 'top-teacher-listing-webpage-button', 'cta-text-button clear-shadowed-text-button bordered-text-button text-button button', teacherData.webpage)}
      </div>
    )
  }

  renderListingTopInfo() {
    let teacherData = this.teacherData
    let numberOfRatings = teacherData['num-ratings']
    let numberOfDownloads = teacherData['num-downloads']     
    return (
      <div id="teacher-body-top-listing" className="page-body-top-listing section">
        <div id="top-teacher-listing-info">        
          <h3>Teacher</h3>  
          <h2 id="top-teacher-listing-name" className="logo-font">{teacherData.name}</h2>          
          <div id="top-teacher-listing-rating-container" className="top-listing-rating-container row-div">            
            <h6 id="top-teacher-listing-rating-num-ratings" className="h7size weight200">{Utility.plural('rating', numberOfRatings)}</h6>
            <h6 id="top-teacher-listing-rating-num-downloads" className="h7size weight200">{Utility.plural('teacher', numberOfDownloads) + " used this teacher's curriculums"}</h6>
          </div>          
          <div id="top-teacher-listing-ctas" className="top-listing-ctas row-div">            
            {this.renderShareButton()}
        </div>
        </div>        
        {this.renderListingImageAndLinks(teacherData)}
      </div>
    )
  }  

  renderListingContent() {        
    return (
      <div id="teacher-listing-content-info" className="flex-col">        
        <h3 id="teacher-about-title">About teacher</h3>
          <div id="teacher-about-content"></div>        
        <h3 id="teacher-curriculums-title">Curriculums</h3>
        <CurriculumsListing numberOfResultsShown={teacherConfig.numberOfResultsShown} dataFetched={this.state.curriculumDataFetched} parent={this} />
        <Utility.CurriculumsListingPageToggleButtons numberOfResultsShown={teacherConfig.numberOfResultsShown} dataFetched={this.state.curriculumDataFetched} parent={this} />
      </div>
    )
  }

  renderBody() {
    if (this.state.dataFetched) {
      return (
        <div id="teacher-body-main" className="page-body">
          {this.renderListingTopInfo()}
          {this.renderListingContent()}          
        </div> 
      )
    } else return Utility.renderPageLoading()
  }

  // document header title for page:
  getPageTitle() {
    let teacherData = this.teacherData
    return teacherData ? (teacherData.name + ' — Curriculum Developer | Teacher') : reactAppEnv.REACT_APP_SITE_TITLE
  }

}

export default TeacherPage

// section containing actual shopping Library items listing:
class CurriculumsListing extends Utility.ToggledPagesComponent {

  constructor(props) {
    super(props)    
    this.parent.curriculumListingComponent = this    
  }

  getCurriculumListInCurrentPage() {
    return super.getCurriculumListInCurrentPage(this.parent.curriculums)
  }

  // renders a teacher curriculum item view:
  renderCurriculumItemDiv(id, curriculumData) {
    let ratingImageWidth = curriculumData.rating * 15
    let gradeTitle = Database.getCurriculumGradeTitle(curriculumData)
    let teacherItemImageAndInfoDiv = (
      <div className="curriculum-item-link">
        <img id={'teacher-curriculum-item-img-' + id} className="curriculum-view-image  curriculum-item-view-image round-corners" src={curriculumData.imageUrl} alt="" />
        <div key={'teacher-curriculum-item-info-' + id} id={'teacher-curriculum-item-info-' + id} className="curriculum-item-info-view">
          <div className="flex-row-mobile-portrait-col">
            <span id={'teacher-curriculum-item-type-' + id} className="logo-color boxed-text curriculum-type-flag h8size">{curriculumData['single-unit'] ? 'Curriculum Unit' : 'Curriculum'}</span>            
            {curriculumData['has-instruction-video'] ? <span className="logo-color boxed-text curriculum-type-flag left-margin5or0 right-margin0 h8size">&nbsp;<i className="fas fa-video" aria-hidden="true"></i>&nbsp;&nbsp;Instruction Video</span> : null}
          </div>     
          <span id={'teacher-curriculum-item-title-' + id} className="curriculum-item-view-title curriculum-view-title h5size weight600">{curriculumData.title}</span>
          <span id={'teacher-curriculum-item-subtitle-' + id} className="curriculum-item-view-subtitle curriculum-view-subtitle h8size">{curriculumData.subtitle}</span>
          <span id={'teacher-curriculum-item-topic'} className="block h8size">{curriculumData['topic-title']}</span>
          <span className="curriculum-view-card-grade curriculum-view-grade h8size block">{gradeTitle}</span>
          {Utility.renderRatingStars('curriculum-result', 'curriculum-listing', ratingImageWidth)}
        </div>        
      </div>
    )
    let teacherItemImageAndInfoDivLink = Utility.renderDivLink('teacher-curriculum-item-link-' + id, teacherItemImageAndInfoDiv, 'preview?subject=' + curriculumData.subject + '&id=' + curriculumData.id)
    return (
      <div key={'teacher-curriculum-item-' + id} id={'teacher-curriculum-item-' + id} className="curriculum-item-view curriculum-item-view-single-col">
        {teacherItemImageAndInfoDivLink}
      </div>
    )
  }

  render() {
    if (this.state.dataFetched)
      return super.render('teacher', 'curriculum-listings-panel panel')      
    else
      return (
        <div id="library-listings" className="curriculum-listings-panel panel">                
        </div>
      )
  }
}