/*
  Handles opations on cloud stoage (currently Google Drive)
*/

import fetch from 'isomorphic-unfetch'

import * as Config from './config'
import * as Utility from './utility'

const generalConfig = Config.pages.general
const serverConfig = Config.serverConfig

// make a copy of external cloud storage resource and return the share link of the copy:
export async function makeCloudStorageResourceCopy(curriculum, lesson, platform, optionalTitle, lock, url) {  
    if (!isCloudStorageURL(url)) {
      Utility.showTextDialog(0, 'Enter a valid cloud storage (Google Drive) document URL.', generalConfig.dialogBoxErrorIconClassName)
      return false
    } 
    Utility.showWaitingDialog()
    let callResult = {data: url, error: false}
      try {
        const res = await fetch(serverConfig.apiUrl, { // Backend API url
            method: 'POST',
            body: JSON.stringify({
                type: 'cloud-storage-make-file-copy',
                params: {curriculum: curriculum, lesson: lesson, platform: platform, title: optionalTitle, lock: lock, url: url}
            }),
        })
        callResult = await res.json()
    } catch (error) {
      callResult.error = error.toString()
    }  
    if (callResult.error) {
      Utility.showTextDialog(0, 'There was a problem creating the copy:\n"' + callResult.error + '"', generalConfig.dialogBoxErrorIconClassName)
      return false
    }
    Utility.showTextDialog(0, 'The new URL for your document copy is inserted into the form.', generalConfig.dialogBoxOKIconClassName)
    return callResult.data
}

// COMMENTED OUT: no longer creating individual lesson folders. all resources under curriculum folder to avoid having to copy documents when copy/pasting lessons...
/*
// makes a copy of a lesson folder by creating a new folder and adding that folder in the parent list of each file in the original folder (done when UI copy/paste a lesson which has cloud storage files) 
export async function makeCloudStorageLessonFolderCopy(curriculum, fromLesson, toLesson, platform) {  
  let callResult = {error: false}
    try {
      const res = await Fetch(serverConfig.apiUrl, { // Backend API url
          method: 'POST',
          body: JSON.stringify({
              type: 'cloud-storage-copy-lesson-folder',
              params: {curriculum: curriculum, fromLesson: fromLesson, toLesson: toLesson, platform: platform}
          }),
      })
      callResult = await res.json()
  } catch (error) {
    callResult.error = error.toString()
  }  
  return callResult.error
}
*/

// checks if any of the external resource files of a lesson (slides/other resources) are cloud storage (google drive) types:
export function doesLessonHaveCloudStorageResources(lessonData) {  
  return (lessonData['slides-is-external'] && isCloudStorageURL(lessonData.slides))
    || lessonData['other-resource-is-externals'].filter((is, index) => is && isCloudStorageURL(lessonData['other-resources'][index])).length > 0
}

// returns platform for external resource files on the cloud storage (currently only google drive)
export function getCloudStoragePlatformForResources(lessonData) {
  //FIXME
  return 'google'
}

// is input string a url for a cloud storage (currently only Google Drive) document:
export function isCloudStorageURL(url) {
    let pattern = new RegExp('^\s*(https?:\\/\\/docs.google.com)')
    return pattern.test(url)
}

// is input string the url for a cloud storage resource already copied using the UI:
export function isCloudStorageCopyURL(url) {
  return url.endsWith('usp=drivesdk') && isCloudStorageURL(url)   
}
