/*
 API functions for talking to the backend AWS S3 fileserver
*/

import * as Config from './config'
import * as Utility from './utility'

const AWS = require('aws-sdk')
const FS = require('fs')

const globals = Config.globals
const fsConfig = Config.fsConfig
const pagesConfig = Config.pages

// S3 reference:
let S3

// init connection to fileserver:
export function fileserver_initConnection() {  
    if (!globals.fileserverInit) {
        S3 = new AWS.S3()
        globals.fileserverInit = true
    }
}

// returns the fs's private or public bucket url:
function getBucketDir(isPublicBucket) {
    return fsConfig.rootBucketUrl + '/' + (isPublicBucket ? fsConfig.publicRootBucket : fsConfig.privateRootBucket) + '/'
}

// relative location of public images dir:
function getPublicImagesRelativeDir() {
    return fsConfig.publicImagesBucketDir
}

// relative location of public pages dir:
export function getWebpageRootUrl() {
    //return fsConfig.rootBucketUrl + '/' + fsConfig.webpageBucket + '/'
    return fsConfig.webpageBucketUrl
}

// get url for curriculum directory static page:
export function getWebpageCurriculumDirectoryPageUrl() {
    return getWebpageRootUrl() + fsConfig.curriculumDirectoryPublicPageFile
}

// get url for teacher directory static page:
export function getWebpageTeacherDirectoryPageUrl() {
    return getWebpageRootUrl() + fsConfig.teacherDirectoryPublicPageFile
}

// given a subject key and its icon image file name, returns the full url to access the image from the fileserver or a placeholder image:
export function getSubjectIconUrl(subject, fileName) {
    return fileName ?
        (getBucketDir(true) + getPublicImagesRelativeDir() + fileName) : pagesConfig.general.subjectIconPlaceHolder
}

// given a teacher id and its boolean flag (is published or not) returns the relative url (without url prefix for the bucket) to access teachers files from the fileserver:
function getTeacherDataRelativeDir(teacher, inProductions, isUpdate, isForUrl) {
    return fsConfig.dataBucketDir + (inProductions ? (isUpdate ? fsConfig.updatesDataBucketDir : fsConfig.liveDataBucketDir) : fsConfig.submissionsDataBucketDir) + fsConfig.teachersBucketDir + (isForUrl ? encodeURIComponent(teacher) : teacher) + '/'
}

// given a teacher id and its boolean flag (is published or not) returns the relative url to access teachers files from the fileserver:
function getTeacherDir(teacher, inProductions, isPublicBucket, isUpdate, isForUrl) {
    return getBucketDir(isPublicBucket) + getTeacherDataRelativeDir(teacher, inProductions, isUpdate, isForUrl)
}

// given a teacher id and its boolean flag (is published or not) and its image file name, returns the full url to access the image from the fileserver:
export function getTeacherImageUrl(teacher, inProductions, isUpdate, fileName) {
    return fileName ?
        (getTeacherDir(teacher, inProductions, true, isUpdate, true) + fsConfig.imageBucketDir + fileName) : pagesConfig.general.teacherImagePlaceHolder
}

// given a live teacher id returns the full share url to access its public (SEO-friendly) page:
export function getTeacherPublicPageUrl(teacher) {
    return getWebpageRootUrl() + fsConfig.teachersBucketDir + encodeURIComponent(teacher) + '/' + fsConfig.teacherPublicPageFile
}

// given a curriculum/teacher id and curriculum's random key, and its boolean flag (curriculum is published or not) returns the relative url (without url prefix for the bucket) to access curriculum's data files from the fileserver:
function getCurriculumDataRelativeDir(isPublicBucket, randomKey, curriculum, teacher, inProductions, isUpdate, isForUrl) {
    return fsConfig.dataBucketDir + (inProductions ? (isUpdate ? fsConfig.updatesDataBucketDir : fsConfig.liveDataBucketDir) : fsConfig.submissionsDataBucketDir) + fsConfig.curriculumsBucketDir + (isForUrl ? encodeURIComponent(teacher) : teacher) + '/' + (isPublicBucket ? '' : ((isForUrl ? encodeURIComponent(randomKey) : randomKey) + '/')) + (isForUrl ? encodeURIComponent(curriculum) : curriculum) + '/'
}

// given a curriculum/teacher id and its boolean flag (curriculum is published or not) returns the relative url to access curriculum's files from the fileserver:
function getCurriculumDir(isPublicBucket, randomKey, curriculum, teacher, inProductions, isUpdate, isForUrl) {
    return getBucketDir(isPublicBucket) + getCurriculumDataRelativeDir(isPublicBucket, randomKey, curriculum, teacher, inProductions, isUpdate, isForUrl)
}

// given a curriculum and teacher id and its inProduction boolean flag (is published or not) and its image file name, returns the full url to access the image from the fileserver:
export function getCurriculumImageUrl(curriculum, teacher, inProductions, isUpdate, fileName) {
    return fileName ?
        (getCurriculumDir(true, undefined, curriculum, teacher, inProductions, isUpdate, true) + fsConfig.imageBucketDir + fileName) :
        pagesConfig.general.curriculumImagePlaceHolder
}

// given a curriculum and teacher id and boolean flag (is published or not) and its bundle zip file name, returns the full url to access the file from the fileserver:
export function getCurriculumBundleUrl(randomKey, curriculum, teacher, inProductions, fileName) {    
    return getCurriculumDir(false, randomKey, curriculum, teacher, inProductions, false, true) + fsConfig.bundleBucketDir + fileName
}

// given a live curriculum id returns the full share url to access its public (SEO-friendly) page:
export function getCurriculumPublicPageUrl(curriculum) {
    return getWebpageRootUrl() + fsConfig.curriculumsBucketDir + encodeURIComponent(curriculum) + '/' + fsConfig.curriculumPublicPageFile
}

// sets for url path for curriculum data's description, image, and bundle fields (as organized by teacher/curriculum ids in the S3 fileserver):
export function setCurriculumDataFullUrls(curriculum, curriculumData) {
    let teacher = curriculumData.teacher
    let inProductions = curriculumData['in-productions']
    let curriculumRelativePublicDir = getCurriculumDataRelativeDir(true, curriculumData['random-key'], curriculum, teacher, inProductions, false, true)
    let curriculumRelativePrivateDir = getCurriculumDataRelativeDir(false, curriculumData['random-key'], curriculum, teacher, inProductions, false, false) // files on private bucket will be downloaded using S3.getSignedUrl so don't need urls be encoded here...
    let curriculumPublicDir = getBucketDir(true) + curriculumRelativePublicDir
    // for resources, check if file name is actually a url. if so just return it. Otherwise its a filename sitting on our fileserver. return its full url:    
    let resources = curriculumData['resources']
    let resourceUrls = []
    let resourceIsExternals = curriculumData['resource-is-externals']
    resources.forEach((resourceUrl, index) => {
        resourceUrls.push(resourceIsExternals[index] ? resourceUrl : curriculumRelativePrivateDir + fsConfig.resourcesBucketDir + resourceUrl)
    })
    curriculumData['resource-urls'] = resourceUrls
    curriculumData.description = curriculumPublicDir  + fsConfig.aboutBucketDir + fsConfig.curriculumDescriptionFile
    curriculumData.previewsUrl = curriculumPublicDir  + fsConfig.previewsBucketDir + curriculumData.previews
    curriculumData.imageUrl = curriculumData.image ?
        (curriculumPublicDir + fsConfig.imageBucketDir + curriculumData.image)
        : pagesConfig.general.curriculumImagePlaceHolder
    curriculumData.bundle = curriculumRelativePrivateDir + fsConfig.bundleBucketDir + curriculumData.bundle    
    curriculumData.lessonDescriptionUrlTemplate = getLessonDescriptionUrlTemplate(curriculum, teacher, inProductions)
}

// sets for url path for curriculum data's various fileserver url related fields (as organized by teacher/curriculum ids in the S3 fileserver), only for those updated (keys of the curriculumDataChanges)
export function setCurriculumDataFullUrlsForUpdatedFields(curriculum, curriculumData, curriculumDataChanges) {    
    let teacher = curriculumData.teacher
    let inProductions = curriculumData['in-productions']
    let curriculumRelativePublicDir = getCurriculumDataRelativeDir(true, curriculumData['random-key'], curriculum, teacher, inProductions, false, true)
    let curriculumRelativePrivateDir = getCurriculumDataRelativeDir(false, curriculumData['random-key'], curriculum, teacher, inProductions, false, false) // files on private bucket will be downloaded using S3.getSignedUrl so don't need urls be encoded here...
    let curriculumPublicDir = getBucketDir(true) + curriculumRelativePublicDir
    if (curriculumDataChanges.image)
        curriculumData.imageUrl = curriculumPublicDir + fsConfig.imageBucketDir + curriculumDataChanges.image
    if (curriculumDataChanges.previews)
        curriculumData.previewsUrl = curriculumPublicDir + fsConfig.previewsBucketDir + curriculumDataChanges.previews
    // for resources, check if file name is actually a url. if so just return it. Otherwise its a filename sitting on our fileserver. return its full url:    
    let resourceChanges = curriculumDataChanges['resources']
    if (resourceChanges) {
        let resourceUrls = []
        let resourceIsExternals = curriculumDataChanges['resource-is-externals']
        resourceChanges.forEach((resourceUrl, index) => {
            resourceUrls.push(resourceIsExternals[index] ? resourceUrl : curriculumRelativePrivateDir + fsConfig.resourcesBucketDir + resourceUrl)
        })
        curriculumData['resource-urls'] = resourceUrls
    }
}

// given a curriculum/teacher id and its boolean flag (curriculum is published or not) returns the relative url to access curriculum's files from the fileserver:
function getLessonDataRelativeDir(isPublicBucket, randomKey, curriculum, lesson, teacher, inProductions, isUpdate, isForUrl) {
    return getCurriculumDataRelativeDir(isPublicBucket, randomKey, curriculum, teacher, inProductions, isUpdate, isForUrl) + fsConfig.lessonsBucketDir + (isForUrl ? encodeURIComponent(lesson) : lesson) + '/'
}

// given a curriculum/teacher/lesson id and its boolean flag (curriculum is published or not) returns the relative url to access curriculum's files from the fileserver:
function getLessonDir(isPublicBucket, randomKey, curriculum, lesson, teacher, inProductions, isUpdate, isForUrl) {
    return getBucketDir(isPublicBucket) + getLessonDataRelativeDir(isPublicBucket, randomKey, curriculum, lesson, teacher, inProductions, isUpdate, isForUrl)
}

// given a curriculum and teacher id and its boolean flag (is published or not) and a particular lesson's slides file name returns the full url to access the file from the fileserver
// NOTE: if the fileName it's actually an external url (not on our fileserver), then just returns it unchanged:
export function getLessonSlidesUrl(randomKey, curriculum, lesson, teacher, inProductions, fileName) {     
    // check if file name is actually a url. if so just return it:
    if (Utility.isURL(fileName))
        return fileName
    // we have a file name, which means the file is residing on our fileserver:
    let lessonDir = getLessonDir(false, randomKey, curriculum, lesson, teacher, inProductions, false, false) // files on private bucket will be downloaded using S3.getSignedUrl so don't need urls be encoded here...
    return lessonDir + fsConfig.slidesBucketDir + fileName
}

// url for a lesson's description file on fileserver, except id of lesson replaced by @, so that it can be replaced by any id:
export function getLessonDescriptionUrlTemplate(curriculum, teacher, inProductions) {       
    return getBucketDir(true) + getCurriculumDataRelativeDir(true, undefined, curriculum, teacher, inProductions, false, true) + fsConfig.lessonsBucketDir  + '@/' + fsConfig.aboutBucketDir + fsConfig.curriculumDescriptionFile
}

// sets for url path for lesson data's description, slides, and other resources fields (as organized by teacher/curriculum/lesson ids in the S3 fileserver):
export function setLessonDataFullUrls(randomKey, curriculum, lesson, teacher, lessonData, inProductions) {
    let lessonRelativePublicDir = getLessonDataRelativeDir(true, randomKey, curriculum, lesson, teacher, inProductions, false, true)
    let lessonRelativePrivateDir = getLessonDataRelativeDir(false, randomKey, curriculum, lesson, teacher, inProductions, false, false) // files on private bucket will be downloaded using S3.getSignedUrl so don't need urls be encoded here...
    let lessonPublicDir = getBucketDir(true) + lessonRelativePublicDir
    let lessonPrivateDir = lessonRelativePrivateDir
    // for slides/resources, check if file name is actually a url. if so just return it. Otherwise its a filename sitting on our fileserver. return its full url:    
    lessonData.slidesUrl = lessonData['slides-is-external'] ? lessonData.slides : lessonPrivateDir + fsConfig.slidesBucketDir + lessonData.slides
    let otherResources = lessonData['other-resources']
    let otherResourceUrls = []
    let otherResourceIsExternals = lessonData['other-resource-is-externals']
    otherResources.forEach((resourceUrl, index) => {
        otherResourceUrls.push(otherResourceIsExternals[index] ? resourceUrl : lessonPrivateDir + fsConfig.resourcesBucketDir + resourceUrl)
    })
    lessonData['other-resource-urls'] = otherResourceUrls
    lessonData.description = lessonPublicDir + fsConfig.aboutBucketDir + fsConfig.curriculumDescriptionFile
}

// sets for url path for lesson data's description, slides, and other resources fields (as organized by teacher/curriculum/lesson ids in the S3 fileserver):
export function setLessonDataFullUrlsForUpdatedFields(randomKey, curriculum, lesson, teacher, lessonData, lessonDataChanges, inProductions) {
    let lessonRelativePrivateDir = getLessonDataRelativeDir(false, randomKey, curriculum, lesson, teacher, inProductions, false, false) // files on private bucket will be downloaded using S3.getSignedUrl so don't need urls be encoded here...
    let lessonPrivateDir = lessonRelativePrivateDir
    // for slides/resources, check if file name is actually a url. if so just return it. Otherwise its a filename sitting on our fileserver. return its full url:
    if (lessonDataChanges.slides) {
        lessonData.slidesUrl = lessonData['slides-is-external'] ? lessonDataChanges.slides : lessonPrivateDir + fsConfig.slidesBucketDir + lessonDataChanges.slides
    }
    let otherResourceChanges = lessonDataChanges['other-resources']
    if (otherResourceChanges) {
        let otherResourceUrls = []
        let otherResourceIsExternals = lessonDataChanges['other-resource-is-externals']
        otherResourceChanges.forEach((resourceUrl, index) => {
            otherResourceUrls.push(otherResourceIsExternals[index] ? resourceUrl : lessonPrivateDir + fsConfig.resourcesBucketDir + resourceUrl)
        })
        lessonData['other-resource-urls'] = otherResourceUrls
    }
}

// sets for url path for lesson data's only description file (as organized by teacher/curriculum/lesson ids in the S3 fileserver):
export function setLessonDataDescriptionFullUrl(randomKey, curriculum, lesson, teacher, lessonData, inProductions) {
    let lessonRelativePublicDir = getLessonDataRelativeDir(true, randomKey, curriculum, lesson, teacher, inProductions, false, true)    
    let lessonPublicDir = getBucketDir(true) + lessonRelativePublicDir    
    lessonData.description = lessonPublicDir + fsConfig.aboutBucketDir + fsConfig.curriculumDescriptionFile
}

// given a selected teacher profile image, teacher id, and its boolean flag (is published or not) and its image file name, uploads the image to the fileserver:
export function uploadTeacherImage(teacher, inProductions, fileName, file, onSuccessFn, onFailureFn) {
    let bucketTargetRelativeDir = getTeacherDataRelativeDir(teacher, inProductions, true, false) + fsConfig.imageBucketDir
    uploadFile(file, true, bucketTargetRelativeDir, fileName, onSuccessFn, onFailureFn)
}

// given a selected image file, teacher id, and its boolean flag (is published or not) and its image file name, uploads the image to the fileserver:
export function uploadCurriculumImageFile(curriculum, teacher, inProductions, fileName, file, onSuccessFn, onFailureFn) {
    let bucketTargetRelativeDir = getCurriculumDataRelativeDir(true, undefined, curriculum, teacher, inProductions, true, false) + fsConfig.imageBucketDir
    uploadFile(file, true, bucketTargetRelativeDir, fileName, onSuccessFn, onFailureFn)
}

// given a selected previews file, teacher id, and its boolean flag (is published or not) and its image file name, uploads the image to the fileserver:
export function uploadCurriculumPreviewsFile(curriculum, teacher, inProductions, fileName, file, onSuccessFn, onFailureFn) {
    let bucketTargetRelativeDir = getCurriculumDataRelativeDir(true, undefined, curriculum, teacher, inProductions, true, false) + fsConfig.previewsBucketDir
    uploadFile(file, true, bucketTargetRelativeDir, fileName, onSuccessFn, onFailureFn)
}

// given a selected proof file, teacher id, and its boolean flag (is published or not) and its image file name, uploads the image to the fileserver:
export function uploadCurriculumResourceOrProofFile(isResource, randomKey, curriculum, teacher, inProductions, fileName, file, onSuccessFn, onFailureFn) {
    let bucketTargetRelativeDir = getCurriculumDataRelativeDir(false, randomKey, curriculum, teacher, inProductions, true, false) + (isResource ? fsConfig.resourcesBucketDir : fsConfig.teachingProofsBucketDir)
    uploadFile(file, false, bucketTargetRelativeDir, fileName, onSuccessFn, onFailureFn)
}

// given a curriculum and teacher id, and its boolean flag (is published or not) and its description text, uploads the description text (in html) as a text file on the fileserver:
export function uploadCurriculumDescriptionFile(curriculum, teacher, inProductions, fileName, descriptionHtmlText, onSuccessFn, onFailureFn) {
    let bucketTargetRelativeDir = getCurriculumDataRelativeDir(true, undefined, curriculum, teacher, inProductions, true, false) + fsConfig.aboutBucketDir
    uploadTextAsFile(descriptionHtmlText, true, bucketTargetRelativeDir, fileName, onSuccessFn, onFailureFn)
}

/*
// NOTE: Commented out - Operations below are done by lambda functions instead...

// deletes curriculum files for in 'submissions' (not live) curriculums (from both public/private buckets in the fileserve)
export function deleteCurriculumFiles(randomKey, curriculum, teacher, inProductions, onSuccessFn, onFailureFn) {
    let bucketRelativePublicDir = getCurriculumDataRelativeDir(true, randomKey, curriculum, teacher, inProductions, false, false)
    let bucketRelativePrivateDir = getCurriculumDataRelativeDir(false, randomKey, curriculum, teacher, inProductions, false, false)
    let finalOnSuccessFn = (data) => {
        // delete curriculum files under private bucket:
        emptyBucket(false, bucketRelativePrivateDir, onSuccessFn, onFailureFn)
    }
    // delete curriculum files under public bucket:
    emptyBucket(true, bucketRelativePublicDir, finalOnSuccessFn, onFailureFn)
}
*/

// copies (instead of deletes) a curriculum files from 'live' bucket to 'submissions' (deleting the live bucket will be done by the lambda):
export function copyCurriculumFilesOutOfProductions(randomKey, curriculum, teacher, onSuccessFn, onFailureFn) {
    let bucketSourceRelativePublicDir = getCurriculumDataRelativeDir(true, randomKey, curriculum, teacher, true, false, false)
    let bucketTargetRelativePublicDir = getCurriculumDataRelativeDir(true, randomKey, curriculum, teacher, false, false, false)
    let bucketSourceRelativePrivateDir = getCurriculumDataRelativeDir(false, randomKey, curriculum, teacher, true, false, false)
    let bucketTargetRelativePrivateDir = getCurriculumDataRelativeDir(false, randomKey, curriculum, teacher, false, false, false)    
    let finalOnSuccessFn = (data) => {
        // copy curriculum files under private bucket:
        copyBucket(false, bucketSourceRelativePrivateDir, bucketTargetRelativePrivateDir, undefined, onSuccessFn, onFailureFn)
    }
    // copy curriculum files under public bucket:
    copyBucket(true, bucketSourceRelativePublicDir, bucketTargetRelativePublicDir, undefined, finalOnSuccessFn, onFailureFn)
}

// given a curriculum/lesson/teacher id, and its boolean flag (is published or not) and its description text, uploads the description text (in html) as a text file on the fileserver:
export function uploadLessonDescriptionFile(curriculum, lesson, teacher, inProductions, fileName, descriptionHtmlText, onSuccessFn, onFailureFn) {
    let bucketTargetRelativeDir = getLessonDataRelativeDir(true, undefined, curriculum, lesson, teacher, inProductions, true, false) + fsConfig.aboutBucketDir
    uploadTextAsFile(descriptionHtmlText, true, bucketTargetRelativeDir, fileName, onSuccessFn, onFailureFn)
}

// given a selected lesson resource (slides or other file), teacher curr and lesson id, and its boolean flag (is published or not) and the file name, uploads the resource to the fileserver:
export function uploadLessonResourceFile(isSlides, randomKey, curriculum, lesson, teacher, inProductions, fileName, file, onSuccessFn, onFailureFn) {
    let bucketTargetRelativeDir = getLessonDataRelativeDir(false, randomKey, curriculum, lesson, teacher, inProductions, true, false) + (isSlides ? fsConfig.slidesBucketDir : fsConfig.resourcesBucketDir)
    uploadFile(file, false, bucketTargetRelativeDir, fileName, onSuccessFn, onFailureFn)
}

// duplicates a lesson files folder to another lesson folder (copy/pasting a lesson from UI)
// NOTE: for live curriculums the dupliacte files will fo under /updates folder and then lambdas will handle moving them to live...:
// copyResourcesToo = true -> copies of uploaded lesson files in slides/ and resources/ folders will also be made
export function duplicateLessonFiles(randomKey, curriculum, fromLesson, toLesson, teacher, toLessonData, inProductions, copyResourcesToo, onSuccessFn, onFailureFn) {
    let bucketSourcePublicRelativeDir = getLessonDataRelativeDir(true, undefined, curriculum, fromLesson, teacher, inProductions, false, false)
    let bucketSourcePrivateRelativeDir = getLessonDataRelativeDir(false, randomKey, curriculum, fromLesson, teacher, inProductions, false, false)
    // isUpdate = inProductions because we want to force the duplicate files target be updates/ rather than live/ for live curriculums...:
    let bucketTargetPublicRelativeDir = getLessonDataRelativeDir(true, undefined, curriculum, toLesson, teacher, inProductions, inProductions, false)
    let bucketTargetPrivateRelativeDir = getLessonDataRelativeDir(false, randomKey, curriculum, toLesson, teacher, inProductions, inProductions, false)
    let finalOnSuccessFn = async () => {
        // update the cached link to description file:
        setLessonDataDescriptionFullUrl(randomKey, curriculum, toLesson, teacher, toLessonData, inProductions)
        // 3. modify lesson description for the pasted lesson to replace the source lesson id to target id...
        let sourceDescriptionBucketRelativeDir = getCurriculumDataRelativeDir(true, undefined, curriculum, teacher, inProductions, false, false) + fsConfig.lessonsBucketDir + fromLesson + '/' + fsConfig.aboutBucketDir
        let targetDescriptionBucketRelativeDir = getCurriculumDataRelativeDir(true, undefined, curriculum, teacher, inProductions, inProductions, false) + fsConfig.lessonsBucketDir + toLesson + '/' + fsConfig.aboutBucketDir
        let targetDescriptionFile = targetDescriptionBucketRelativeDir + fsConfig.curriculumDescriptionFile
        let callResult = await readTextFile(true, sourceDescriptionBucketRelativeDir, fsConfig.curriculumDescriptionFile)
        let sourceDescriptionHtml = callResult.error ? '' : callResult.data
        let targetDescriptionHtml = sourceDescriptionHtml.replaceAll(fromLesson, toLesson)
        let putParams = {Bucket: fsConfig.publicRootBucket, Key: targetDescriptionFile, ContentType: 'text/html', Body: targetDescriptionHtml}
        S3.putObject(putParams, getFileserverOperationResultHandler('updating pasted lesson description file' + (inProductions ? ' in updates area' : ''), onSuccessFn, onFailureFn))       
    }
    let publicCopyOnSuccessFn = () => {
        if (copyResourcesToo) {
            // 2. copy lesson files under private bucket:
            copyBucket(false, bucketSourcePrivateRelativeDir, bucketTargetPrivateRelativeDir, undefined, finalOnSuccessFn, onFailureFn)
        } else
            finalOnSuccessFn()
    }
    // 1. copy lesson files under public bucket:
    copyBucket(true, bucketSourcePublicRelativeDir, bucketTargetPublicRelativeDir, undefined, publicCopyOnSuccessFn, onFailureFn)
}

// upload a file to an S3 bucket specified:
export function uploadFile(file, isPublicBucket, bucketTargetRelativeDir, targetFileName, onSuccessFn, onFailureFn) {    
    uploadContent(file, file.type, isPublicBucket, bucketTargetRelativeDir, targetFileName, onSuccessFn, onFailureFn)
}

// upload text as a text file an S3 bucket specified:
export function uploadTextAsFile(text, isPublicBucket, bucketTargetRelativeDir, targetFileName, onSuccessFn, onFailureFn) {    
    uploadContent(text, 'text/html', isPublicBucket, bucketTargetRelativeDir, targetFileName, onSuccessFn, onFailureFn)
}

/*
  Admin Fileserver functions
*/

// run admin only fileserver functions with a check first if admin role is granted:
export function admin_runFunction(database, fnName, fnArgs) {
    let err = false
    if (!database.isAdminUserDataFetched())
      err = 'Error: Only admins can run function ' + fnName  
    let fn = admin_functions[fnName]
    if (fn === undefined)
      err = 'Error: No such admin function ' + fnName    
    if (err) {    
      console.log(err)
      return err
    }
    return fn(...fnArgs)
  }
  
// list of admin only functions:
const admin_functions = {  
    // given a subject key and its icon image file name, uploads the image to the right fileserver location:
    uploadSubjectIcon: (subject, fileName, file, onSuccessFn, onFailureFn) => {
        let bucketTargetRelativeDir = getPublicImagesRelativeDir()
        uploadFile(file, true, bucketTargetRelativeDir, fileName, onSuccessFn, onFailureFn)
    },

    // sets for url path for curriculum data's admin related fields (currently: teaching proof docs):
    setCurriculumDataAdminUrls: (curriculum, curriculumData) => {
        let teacher = curriculumData.teacher
        let inProductions = curriculumData['in-productions']    
        let curriculumRelativePrivateDir = getCurriculumDataRelativeDir(false, curriculumData['random-key'], curriculum, teacher, inProductions, false, false) // files on private bucket will be downloaded using S3.getSignedUrl so don't need urls be encoded here...    
        let proofsDir = curriculumRelativePrivateDir + fsConfig.teachingProofsBucketDir
        curriculumData.schoolProofUrl = proofsDir + curriculumData['school-proof']
        curriculumData.teachingProofUrl = proofsDir + curriculumData['teaching-proof']
        let otherProofs = curriculumData['other-proofs']
        let otherProofUrls = []
        let otherProofIsExternals = curriculumData['other-proof-is-externals']
        otherProofs.forEach((proofUrl, index) => {
            otherProofUrls.push(otherProofIsExternals[index] ? proofUrl : proofsDir + proofUrl)
        })
        curriculumData['other-proof-urls'] = otherProofUrls
    }
}

/*
  S3 Fileserver Primitive Operations & any helpers:
*/

// returns a handler function for all database operations (get, put, update) to handler success/failure
// used as a helper function for operation functions below...:
function getFileserverOperationResultHandler(operationDescription, onSuccessFn, onFailureFn) {
    operationDescription = operationDescription || 'performing fileserver opertion'
    return (err, data) => {
      if (err) {
        let errorMessage = 'There was a problem ' + operationDescription + '!' + '\n"' + err.toString() + '"'
        console.log('Error', errorMessage, err.stack)
        if (onFailureFn !== undefined)
          onFailureFn(errorMessage)
      } else {
        //console.log('Success', operationDescription + '...')
        if (onSuccessFn !== undefined)
          onSuccessFn(data)
      }
    }
}

// upload content (text, file) to an S3 bucket specified:
export function uploadContent(content, contentType, isPublicBucket, bucketTargetRelativeDir, targetFileName, onSuccessFn, onFailureFn) {
    // Setting up S3 upload parameters
    const params = {
      Bucket: isPublicBucket ? fsConfig.publicRootBucket : fsConfig.privateRootBucket,
      Key: bucketTargetRelativeDir + targetFileName, // File name you want to save as in S3
      ContentType: contentType,
      Body: content
    }
    //console.log('uploadContent', params)
    // Uploading files to the bucket    
    S3.upload(params, getFileserverOperationResultHandler('uploading file', onSuccessFn, onFailureFn))
}

// downloads to the browser a content (files related to a curriculum like slides, PDFs, etc.) from an S3 bucket specified 
export function downloadContent(isPublicBucket, bucketFileNameRelativePath, onSuccessFn, onFailureFn) {
    let finalOnSuccessFn = (contentSignedUrl) => {
        //window.location = contentSignedUrl
        // open in a new tab (or to download bar if browser cannot view it):    
        window.open(contentSignedUrl, '_blank')        
        if (onSuccessFn !== undefined)
            onSuccessFn(contentSignedUrl)        
    }
    // Setting up S3 get object parameters
    const params = {
      Bucket: isPublicBucket ? fsConfig.publicRootBucket : fsConfig.privateRootBucket,
      Key: bucketFileNameRelativePath     
    }
    //console.log('downloadContent', params)
    // Downloading file from the bucket
    S3.getSignedUrl('getObject', params, getFileserverOperationResultHandler('downloading file', finalOnSuccessFn, onFailureFn))
}

// lists and copies all files under a bucket prefix (relative dir) from source to target dir:
// continuationToken = undefined by default. if it is set we're recalling the function because listed files were more than max count allowed (1000) and we're requesting the next batch...
export function copyBucket(isPublicBucket, bucketSourceRelativeDir, bucketTargetRelativeDir, continuationToken, onSuccessFn, onFailureFn) {
    let bucket = isPublicBucket ? fsConfig.publicRootBucket : fsConfig.privateRootBucket
    if (onSuccessFn === undefined)
        onSuccessFn = (data) => { }
    if (onFailureFn === undefined)
        onFailureFn = (err) => { console.log(err) }    
    // what to do when we list the files under the bucket:
    let listHandlerFn = (data) => {        
        let isTruncated = data.IsTruncated
        let nextContinuationToken = data.NextContinuationToken
        let count = data.Contents.length
        if (count === 0) { 
            return onSuccessFn()
        }
        var filesProcessed = 0
        let paramsC = {
            CopySource: '',        
            Bucket: bucket,
            Key: ''
        }        
        data.Contents.forEach(content => {
                paramsC.CopySource = bucket + '/' + content.Key
                paramsC.Key = content.Key.replace(bucketSourceRelativeDir, bucketTargetRelativeDir)                
                S3.copyObject(paramsC, function(err, data) {
                    filesProcessed++
                    if (err) return onFailureFn(err)
                    if (filesProcessed === count) {
                        if (isTruncated) // list objects only returns max maxCount=1000...
                            copyBucket(isPublicBucket, bucketSourceRelativeDir, bucketTargetRelativeDir, nextContinuationToken, onSuccessFn, onFailureFn)
                        else 
                            onSuccessFn()
                    }
                })
            }
        )
    }
    // get list of files under the bucket
    listFiles(isPublicBucket, bucketSourceRelativeDir, continuationToken, listHandlerFn, onFailureFn)
}

// NOTE: Removing empty/move bucket operations because client code doesn't handle them. Lambda triggers on the backend will handle moves and deletes...
/*

// lists and deletes all files under a bucket prefix (relative dir):
export function emptyBucket(isPublicBucket, bucketRelativeDir, onSuccessFn, onFailureFn) {
    let bucket = isPublicBucket ? fsConfig.publicRootBucket : fsConfig.privateRootBucket
    if (onSuccessFn === undefined)
        onSuccessFn = (data) => { console.log(data) }
    if (onFailureFn === undefined)
        onFailureFn = (err) => { console.log(err) }
    // what to do when we list the files under the bucket:
    let listHandlerFn = (data) => {
        let isTruncated = data.IsTruncated
        let count = data.Contents.length
        if (count === 0) {
            return onSuccessFn()            
        }
        let paramsD = {
            Bucket: bucket, 
            Delete: {Objects: data.Contents.map(content => { return {Key: content.Key} })}
        }
        S3.deleteObjects(paramsD, function(err, data) {
            if (err) return onFailureFn(err)
            if (isTruncated) // list objects only returns max maxCount=1000...
                emptyBucket(isPublicBucket, bucketRelativeDir, onSuccessFn, onFailureFn)
            else 
                onSuccessFn()
        })
    }
    // get list of files under the bucket
    listFiles(isPublicBucket, bucketRelativeDir, undefined, listHandlerFn, onFailureFn)
}

// lists and copies all files under a bucket prefix (relative dir) from source to target dir, then deletes the source bucket dir:
export function moveBucket(isPublicBucket, bucketSourceRelativeDir, bucketTargetRelativeDir, onSuccessFn, onFailureFn) {
    let copyOnSuccessFn = () => {
        let emptyOnSuccessFn = () => {
            if (onSuccessFn !== undefined)
                onSuccessFn()
        }
        // empty the source bucket dir:
        emptyBucket(isPublicBucket, bucketSourceRelativeDir, emptyOnSuccessFn, onFailureFn)        
    }
    // copy the course to target dir:
    copyBucket(isPublicBucket, bucketSourceRelativeDir, bucketTargetRelativeDir, undefined, copyOnSuccessFn, onFailureFn)
}
*/

// move a file object from a bucket to another (copy + delete)
export function moveFile(isPublicBucket, bucketSourceRelativeDir, bucketTargetRelativeDir, fileName, onSuccessFn, onFailureFn) {
    let bucket = isPublicBucket ? fsConfig.publicRootBucket : fsConfig.privateRootBucket 
    // Setting up S3 upload parameters    
    const params = {
      CopySource: bucket + '/' + bucketSourceRelativeDir + fileName,
      Bucket: bucket,
      Key: bucketTargetRelativeDir + fileName // File name you want to save as in S3
    }
    // Uploading files to the bucket
    let finalOnSuccessFn = (data) => {        
        deleteFile(isPublicBucket, bucketSourceRelativeDir, fileName, onSuccessFn)    
    }
    S3.copyObject(params, getFileserverOperationResultHandler('copying file', finalOnSuccessFn, onFailureFn))
}

// delete a file object from a bucket
export function deleteFile(isPublicBucket, bucketRelativeDir, fileName, onSuccessFn, onFailureFn) {
    // Setting up S3 upload parameters    
    const params = {      
      Bucket: isPublicBucket ? fsConfig.publicRootBucket : fsConfig.privateRootBucket ,
      Key: bucketRelativeDir + fileName
    }
    // Delete file from the bucket    
    S3.deleteObject(params, getFileserverOperationResultHandler('deleting file', onSuccessFn, onFailureFn))
}

// get files (objects) inside a bucket given a relative dir
// then runs the listHandlerFn on the retried file list
// continuationToken = undefined by default. if it is set we're recalling the function because listed files were more than max count allowed (1000) and we're requesting the next batch...
export function listFiles(isPublicBucket, bucketRelativeDir, continuationToken, listHandlerFn, onFailureFn) {
    // Setting up S3 upload parameters    
    const params = {
      Bucket: isPublicBucket ? fsConfig.publicRootBucket : fsConfig.privateRootBucket,
      Prefix: bucketRelativeDir
    }
    if (continuationToken)
        params.ContinuationToken = continuationToken
    // Listing files in the bucket    
    S3.listObjectsV2(params, getFileserverOperationResultHandler('listing files', listHandlerFn, onFailureFn))
}

// read contents of a text file on fileserver:
export async function readTextFile(isPublicBucket, bucketRelativeDir, fileName) {  
    const params = {
      Bucket: isPublicBucket ? fsConfig.publicRootBucket : fsConfig.privateRootBucket,
      Key: bucketRelativeDir + fileName
    }
    let callResult
    // Read file in the bucket
    try {
        callResult = await S3.getObject(params).promise()
    } catch (error) {
        callResult = {error: error}
    }    
    if (!callResult.error)
      callResult.data = callResult.Body.toString('ascii')  
    return callResult  
  }