/*
    Admin site - Admin Curriculum View Page
    Allows the admin viewer to view the listing for a given previously created curriculum by any teacher, whether its productions (live), in submissions, or in drafts curriculums table
*/

import React from 'react'
import { Tabs, Tab, TabPanel, TabList } from 'react-web-tabs'
import 'react-web-tabs/dist/react-web-tabs.css'

import CreatorCurriculumViewPage from './creator-view'
import * as Database from './database-api'
import * as Fileserver from './fileserver-api'
import * as Config from './config'
import * as CreatorUtility from './creator-utility'
import * as Utility from './utility'

const globals = Config.globals
const generalConfig = Config.pages.general

class AdminCurriculumViewPage extends CreatorCurriculumViewPage {
    constructor(props) {
        super(props)
        this.pageId = 'admin-view'
        this.pageDir = 'admin/'
        // flag true for user site pages and false for creator site pages:      
        this.isCreatorSite = false
        this.isAdminSite = true    
        if (globals.haveLocalStorage) 
            this.lastTabOpen = localStorage.getItem('termeric-page-' + this.pageId + '-' + this.id + '-last-tab-open')
        if (Database.isAdminUserDataFetched())
            this.adminUserDataFetched()
    }

    // override the superclass's userDataFetched which checks if user has bought the curriculum (not relevant here):
    userDataFetched() {
        this.updateTopMenuUserIconImage()
        // Fetch the data for the admin user in session (if any) from the database:
        Database.database_fetchAdminUserData()
    }

    // database API calls this when data for admin user in session's info is fetched
    // if the user is indeed admin, Database.cachedAdminUserInfo will be defined...
    adminUserDataFetched() {
        // if user is not admin, boot the user out of this page:        
        if (!Database.isAdminUserDataFetched())
            this.setState({navigateTo: this.accessNotAllowedNavigationPage()})
        else if (Database.isCurriculumsDataFetched())
            this.curriculumsDataFetched()
        else
            Database.fetchCurriculumDataForIds([this.id])
    }

    // Database API calls this when curriculum info for the given id is fetched. Here first we need to fetch the teacher's data:
    curriculumsDataFetched() {        
        // wait to see if user is not admin, boot the user out of this page:
        if (!Database.isAdminUserDataFetched())
            return undefined
        super.curriculumsDataFetched()        
        // set fs full url path for admin related files for this curriculum:
        if (this.curriculumData !== undefined && !this.setUrlFullPaths) {
            this.setUrlFullPaths = true
            Fileserver.admin_runFunction(Database, 'setCurriculumDataAdminUrls', [this.id, this.curriculumData])
        }
    }

    // for admins we don't need to require the user is the teacher for the curriculum being viewed...
    curriculumTeacherCheckForPage(teacher) {
        return true
    }

    // which page to navigate to if curriculum with id on url params not found:
    accessNotAllowedNavigationPage() {
        return '/admin/library'
    }

    // doApprove = true : put an entry on curriculums-apporve-queue-table for lambda to handle making the curriculum live and do the db/fs housekeeping work...
    // doApprove = false : put an entry on email-queue-table for lambda to send an email to creator with message of not approved...
    submitCurriculumApproval(doApprove, curriculumData, rejectionMessage = undefined) {       
        let onSuccessFn = () => {
            let onCloseFn = () => {
                this.setState({navigateTo: this.accessNotAllowedNavigationPage()})
            }
            Utility.showTextDialog(0, 'Curriculum ' + (doApprove ? 'approval' : 'rejection') + ' is sent.', generalConfig.dialogBoxOKIconClassName, onCloseFn)
            return true
        }
        // if the teacher creator has uploaded a zip file containing the preview snapshots, admin needs to manually create the "<id>-previews.pdf" (containing all the pages) from the zip file contents and then upload to the correct location on fileserver, as well as update the "previews" field for the curriculum entry in db to set to "<id>-previews.pdf"....:
        if (doApprove && (curriculumData.previews.toLowerCase().endsWith('.zip')))
            Utility.showTextDialog(0, 'This curriculum has a ZIP file containing the preview snapshots. Before being allowed to approve this, please manually create the "<id>-previews.pdf" (containing all the pages) from the zip file contents and then upload to the correct location on fileserver, as well as update the "previews" field for the curriculum entry in db to set to "<id>-previews.pdf".', generalConfig.dialogBoxErrorIconClassName)
        else
            Database.admin_runFunction('database_submitCurriculumApprovalOutcome', [this.id, curriculumData, doApprove, rejectionMessage, onSuccessFn])
    }

    openCurriculumRejectionDialog(curriculumData) {        
        Utility.showDialog(1, this.renderRejectionForm(), undefined, ['Cancel', 'Send'], [undefined, (e) => {
            let rejectionMessage = this.rejectionFormMessageRef.current.value.trim()
            if (rejectionMessage) {
                this.submitCurriculumApproval(false, curriculumData, rejectionMessage)
                return true
            } else
                Utility.showTextDialog(0, 'Write a rejection message first.', generalConfig.dialogBoxErrorIconClassName)
        }])
    }

    // if it's a live curriculum, it will deactivate it (keeping it in productions table) if it's live, or activate it if it's currently deactivated:
    toggleCurriculumActive(curriculum, curriculumData) {
        let isActive = curriculumData['in-productions'] && curriculumData.active       
        let onSuccessFn = () => {
            let onCloseFn = () => {
                this.setState({navigateTo: this.accessNotAllowedNavigationPage()})
            }
            Utility.showTextDialog(0, 'Curriculum is now ' + (isActive ? 'deactivated.' : 'live.'), generalConfig.dialogBoxOKIconClassName, onCloseFn)
            return true
        }
        Database.database_toggleCurriculumActive(!isActive, curriculum, curriculumData, onSuccessFn)    
    }


    // removes curriculum from store and move it into drafts just in case the teacher wants to submit again:
    // also if it was a live curriculum, it will place a copy of the curriculum and lessons and their fileserver files into the archive deleted tables/fileserver areas...:
    removeCurriculumFromStore(curriculum, curriculumData) {
        let teacher = curriculumData.teacher
        let onTeacherDataFn = () => {
            Utility.showWaitingDialog()
            // 2. remove the curriculum from store:
            CreatorUtility.removeCurriculumFromStore(curriculum, curriculumData, teacher, Database.getTeacherData(teacher))
        }
        // 1. get teacher data first:        
        Database.database_fetchTeacherData(teacher, true, undefined, onTeacherDataFn)        
    }
    
    // renders rejection form to write a message for the teacher:
    renderRejectionForm() {
        this.rejectionFormMessageRef = React.createRef()        
        return (
          <div id="admin-view-rejection-form" className="form flex-col">
            <div className="form-item flex-col">
              <label>Write a message for the teacher explaining the reason for not approving and how to fix it</label>                   
              <textarea id="admin-view-rejection-form-message" className="textarea top-margin15" placeholder="Write a message for the teacher..." ref={this.rejectionFormMessageRef} />
            </div>            
          </div>
        )
    }
  
    renderBody() {
        return (
            <AdminCurriculumViewPageBodyComponent page={this}/>
        )
    }

}

export default AdminCurriculumViewPage

class AdminCurriculumViewPageBodyComponent extends CreatorUtility.CreatorCurriculumViewPageBodyComponent {    

    renderListingImageAndCTAs(curriculumData) {
        return (
          <div id="top-curriculum-listing-image-and-ctas" className="top-listing-image-and-ctas">
            <img id="top-curriculum-listing-image" className="curriculum-listing-image boxed-div" src={curriculumData.imageUrl} alt="" />
            {Utility.renderButtonFromButton(<span><i className="fas fa-eye"></i>&nbsp;&nbsp;&nbsp;Preview samples</span>, 'admin-view-preview-content-button', 'wide-cta-text-button cta-text-button clear-shadowed-text-button bordered-text-button text-button button', (e) => this.previewContent(curriculumData.previewsUrl))}
            {curriculumData['in-submissions'] ?
                (<div className="flex-col">
                    {Utility.renderButtonFromButton(<span><i className="fas fa-thumbs-up"></i>&nbsp;&nbsp;&nbsp;Approve</span>, 'admin-view-edit-curriculum-button', 'wide-cta-text-button cta-text-button shadowed-text-button bordered-text-button text-button button', (e) => { this.page.submitCurriculumApproval(true, curriculumData)} )}
                    {Utility.renderButtonFromButton(<span><i className="fas fa-thumbs-down"></i>&nbsp;&nbsp;&nbsp;Reject</span>, 'admin-view-edit-curriculum-button', 'wide-cta-text-button cta-text-button red-shadowed-text-button clear-shadowed-text-button bordered-text-button text-button button', (e) => { this.page.openCurriculumRejectionDialog(curriculumData)} )} 
                </div>) : null}
            {(curriculumData['in-productions']) ?
                    Utility.renderButtonFromButton(<span><i className={curriculumData.active ? 'fas fa-toggle-off' : 'fas fa-toggle-on'}></i>&nbsp;&nbsp;&nbsp;{curriculumData.active ? 'Deactivate' : 'Activate'}</span>, 'admin-view-edit-curriculum-button-1', 'wide-cta-text-button cta-text-button shadowed-text-button bordered-text-button text-button button', (e) => { this.page.toggleCurriculumActive(curriculumData.id, curriculumData)} ) : null}
            {(curriculumData['in-productions']) ?
                    Utility.renderButtonFromButton(<span><i className="fas fa-eject"></i>&nbsp;&nbsp;&nbsp;Remove from store</span>, 'admin-view-edit-curriculum-button-2', 'wide-cta-text-button cta-text-button shadowed-text-button bordered-text-button text-button button', (e) => { this.page.removeCurriculumFromStore(curriculumData.id, curriculumData)} ) : null}
            <span className="no-box-shadow wide-cta-text-button cta-text-button bordered-text-button text-button">{curriculumData.active ? '🟢' : (curriculumData['in-submissions'] ? '🟡': (curriculumData['in-drafts'] ? '🔴' : '🟡'))}&nbsp;&nbsp;&nbsp;{curriculumData.active ? '  Live' : (curriculumData['in-submissions'] ? 'Submitted for review' : (curriculumData['in-drafts'] ? 'Working draft' : 'Deactived'))}</span>
          </div>
        )
    }

    renderListingContent() {
        return (
          <AdminCurriculumViewListingContentComponent parent={this} page={this.page} ref={this.listingContentComponentRef} />
        )    
    }

}

class AdminCurriculumViewListingContentComponent extends Utility.CurriculumViewListingContentComponent {

    render() {
        let parent = this.parent
        return (
          <Tabs id="admin-view-body-listing-content" className="page-body-listing-content section"
            defaultTab={this.page.lastTabOpen || 'info'}
            onChange={(tabId) => { parent.setLastTabOpen(tabId, false) }} >
            <TabList>
              <Tab tabFor='info'><span className="tab-header">About</span></Tab>
              <Tab tabFor='plan'><span className="tab-header">Course Plan</span></Tab>
              <Tab tabFor='resources'><span className="tab-header">Resources</span></Tab>
              <Tab tabFor='teacher-notes'><span className="tab-header">Teacher Notes</span></Tab>
              <Tab tabFor='proof'><span className="tab-header">Proof of Teaching</span></Tab>
            </TabList>
            <TabPanel tabId='info'>
                {this.renderListingContentInfo()}
            </TabPanel>
            <TabPanel tabId='plan'>
                {this.renderListingContentCoursePlan()}
            </TabPanel>
            <TabPanel tabId='resources'>
                {this.renderCurriculumContentResources()}
          </TabPanel>
            <TabPanel tabId='teacher-notes'>
                {this.renderCurriculumContentTeacherNotes()}
            </TabPanel>
            <TabPanel tabId='proof'>
                {this.renderListingContentTeachingProof()}
            </TabPanel>
          </Tabs>
        )
    }

    renderListingContentTeachingProof() {
        let curriculumData = this.page.curriculumData
        return (
          <div id="admin-view-body-listing-content-resources" className="tab">
            <h4>School Teacher Position Proof</h4>  
            <h6>Document as a proof that teacher held a teaching position, during the time teacher taught this course curriculum, at the school teacher designated: such as a school contract or document, etc.</h6>
            {Utility.renderBulletListFromStringList('school-proof', ['School Proof'], [curriculumData.schoolProofUrl], false, undefined, [false])}
            <h4>Teaching Proof</h4>
            <h6>Document as a proof that teacher previously taught this curriculum at the school teacher designated: such as a school document listing the course with teacher name, an official syllabus, etc.</h6>
            {Utility.renderBulletListFromStringList('teaching-proof', ['Teaching Proof'], [curriculumData.teachingProofUrl], false, undefined, [false])}
            <h4>Other Proofs</h4>        
            <h6>Lists any other documents that support establishing the creator teaching this curriculum. If someone is submitting the curriculum on behalf of another teacher, here they need to add a document that states they have permission from the original teacher author.</h6>
            {Utility.renderBulletListFromStringList('other-proofs', curriculumData['other-proof-descriptions'], curriculumData['other-proof-urls'], false, undefined, curriculumData['other-proof-is-externals'])}
          </div>
        )
    }
}
