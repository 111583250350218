/*
  Handles fetching client locale information such as ip, country, currency, local exchange rate to USD
*/

import fetch from 'isomorphic-unfetch'
import {publicIpv4} from 'public-ip';

import * as Config from './config'
import * as Utility from './utility'

const globals = Config.globals
const serverConfig = Config.serverConfig
const payConfig = Config.payConfig

// called by the frontend UI to (if cache is expired) send an async request to backend serverless server to send the locale info, including user's country, currency, and exchange rate:
export async function locale_fetchUserInfo() {
    let wasCached = false
    let ip, country, currency, currencySymbol, currencyExchangeRateToUSD, localeDataCacheDate = 0, currencyExchangeRateCacheDate = 0, requestParams, requestTime
    let haveLocalStorage = globals.haveLocalStorage
    // if locale info of user is already cached in the local store, add to the params so that the srever doesn't fetch it again (only the current exchange rate now):
    if (haveLocalStorage) {
        requestTime = Utility.getEpochTime()
        localeDataCacheDate = localStorage.getItem('termeric-locale-cache-date')
        localeDataCacheDate = localeDataCacheDate ? parseInt(localeDataCacheDate, 10) : 0
        // if locale info was cached more recently than 'maxNumberOfDaysBeforeLocaleCacheExpires' days old, then skip getting the latest and use the cached data to save server request bandwidth/quota...:
        if (Utility.numberOfDaysBetweenEpochTimes(requestTime, localeDataCacheDate) < payConfig.maxNumberOfDaysBeforeLocaleCacheExpires) {
            currency = localStorage.getItem('termeric-locale-currency')
            // skip if locale currency is the default=USD:
            if (currency === 'USD')
                return fetchUserInfoCompleted()
            ip = localStorage.getItem('termeric-locale-ip')
            country = localStorage.getItem('termeric-locale-country')            
            currencySymbol = localStorage.getItem('termeric-locale-currency-symbol')            
            let cachedExchangeRate = localStorage.getItem('termeric-locale-currency-exchange-rate')
            currencyExchangeRateCacheDate = localStorage.getItem('termeric-locale-currency-exchange-rate-date')
            currencyExchangeRateCacheDate = currencyExchangeRateCacheDate ? parseInt(currencyExchangeRateCacheDate, 10) : 0
            currencyExchangeRateToUSD = cachedExchangeRate ? parseFloat(cachedExchangeRate, 10) : undefined
            //console.log('locale cached is valid', ip, country, currency, currencySymbol, currencyExchangeRateToUSD, currencyExchangeRateCacheDate)
        }
    }
    if (ip) {
        wasCached = true        
        requestParams = {country: country, currency: currency, currencySymbol: currencySymbol, currencyExchangeRateToUSD: currencyExchangeRateToUSD}
        // apply the cached locale data right away (but still make the request to get more up-to-date excange rate...):
        updateLocalPayConfig(requestParams)
        // if locale exchange rate info was cached and exchange rate is newer than 'maxNumberOfDaysBeforeExchangeRateCacheExpires' days old, then skip getting the latest and use the cached rate to save server request bandwidth/quota...:
        if (Utility.numberOfDaysBetweenEpochTimes(requestTime, currencyExchangeRateCacheDate) < payConfig.maxNumberOfDaysBeforeExchangeRateCacheExpires) {
            return fetchUserInfoCompleted()
        }
        // remove the one that was not part of request params:
        delete requestParams.currencyExchangeRateToUSD
    } else {
        ip = await publicIpv4()
        //console.log('ip was fetched', ip)
        requestParams = {}
    }
    requestParams.ip = ip
    try {                
        //console.log(requestParams)     
        const res = await fetch(serverConfig.apiUrl, { // Backend API url
            method: 'POST',
            body: JSON.stringify({
                type: 'locale-data',
                params: requestParams
            }),
        })
        // Make the request and wait for response:
        const data = await res.json()  
        if (data.error) {
            console.log('locale_fetchUserInfo - Error receiving locale data: ', data.error)
            return fetchUserInfoCompleted(data.error)
        }
        if (wasCached) {
            data.country = country
            data.currency = currency
            data.currencySymbol = currencySymbol
        } else {
            // cache the values
            if (haveLocalStorage) {
                localStorage.setItem('termeric-locale-ip', ip)
                localStorage.setItem('termeric-locale-country', data.country)
                localStorage.setItem('termeric-locale-currency', data.currency)
                localStorage.setItem('termeric-locale-currency-symbol', data.currencySymbol)
                localStorage.setItem('termeric-locale-cache-date', requestTime)
            }
        }
        if (haveLocalStorage) {
            localStorage.setItem('termeric-locale-currency-exchange-rate', data.currencyExchangeRateToUSD)
            localStorage.setItem('termeric-locale-currency-exchange-rate-date', requestTime)
        }
        updateLocalPayConfig(data)
    } catch (error) {
        console.log('locale_fetchUserInfo - Failed to connect to server: ', error)
        return fetchUserInfoCompleted(error)
    }
    return fetchUserInfoCompleted()
}

// sets a global flag so we know locale data fetch is complete (curriculum current prices won't be cached if not done yet):
function fetchUserInfoCompleted(error = false) {
    globals.localeDataFetched = true
    return error
}

// updates local prices based on exchange rate to USD:
function updateLocalPayConfig(data) {    
    // skip if locale currency is the default=USD:
    if (data.currency !== 'USD') {        
        ['country', 'currency', 'currencySymbol', 'currencyExchangeRateToUSD'].forEach(f => payConfig[f] = data[f])
        // update local prices based on exchange rate to USD:
        let exchangeRate = payConfig.currencyExchangeRateToUSD
        let pricesUSD = payConfig.priceTierPrices.USD
        let pricesLocal = payConfig.priceTierPrices.local
        payConfig.priceTiers.forEach(i => pricesLocal[i] = Math.round((pricesUSD[i] * exchangeRate) / 5) * 5)
        //console.log('locale data updated', data, payConfig)
        // now that we have just updated locale pay config to non-default (non USD currency), check if current page has already rendered price of an item and if so notify it, so that page can force update of its price component so that it shows in the right local currency...:        
        let page = globals.currentPage        
        if (page !== undefined && page.priceRendered)
            page.localePayConfigUpdated()
    }    
}
