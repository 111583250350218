/*
    Called when sign out
    We sign out of cognito session and then redirect to home page.
*/

import React, { Component } from 'react'
import { Navigate } from 'react-router-dom'
import * as Auth from './auth-api'

class SignOutRoute extends Component {
    constructor(props) {
        super(props)
        this.state = {          
        }
    }

    /** If a hash is in the URL, start the CognitoId auth process */
    componentDidMount() {
        //console.log('did mount', this.props.location.hash, window.location.href)                
        // sign out:
        Auth.signOut()
    }
  
    // If sign out was success, redirect to home page...:
    render() {
        //console.log("redirect", this.props.location.hash, this.state.redirect)
        if (this.state.redirect)
            return <Navigate to="/" />
        return <div />
    }
}
  
export default SignOutRoute;
