/*
    Called once sign in operation is done by Cognito. 
    We parse the session token and get session info, then redirect to home page.
*/

import React, { Component } from 'react'
import { Navigate } from 'react-router-dom'

import * as Config from './config'
import * as Auth from './auth-api'

const globals = Config.globals

class SignInCallbackRoute extends Component {
    constructor(props) {
        super(props)
        this.state = {}
        this.redirectTo = '/'
        // checked if we have stored a refering page before signin/register redirect to external cognito auth page, so that now we can auto redirect back to that page, instead of home page...:
        if (globals.haveLocalStorage) {
            let referingPageUrlParams = localStorage.getItem('termeric-page-home-last-page-open-before-signin')
            if (referingPageUrlParams) {
                localStorage.removeItem('termeric-page-home-last-page-open-before-signin')
                this.redirectTo = decodeURIComponent(referingPageUrlParams)
            }
        }
    }

    /** If a hash is in the URL, start the CognitoId auth process */
    componentDidMount() {
        // set a flag so others know this is ready:
        this.didMount = true
        //console.log('did mount', this.props.location.hash, window.location.href)
        Auth.getSession(this)
    }
  
    // If sign in/out was success, redirect to home page...:
    render() {        
        //if (!this.props.location.hash || this.state.redirect)
            return <Navigate to={this.redirectTo} />
        //return <div />
    }
}
  
export default SignInCallbackRoute;
