/*
    Curriculum View Page
    Opens the listing for a given already-purchased curriculum
*/

import React from 'react'

import * as Config from './config'
import * as Database from './database-api'
import * as Utility from './utility'

const globals = Config.globals
const generalConfig = Config.pages.general

class CurriculumViewPage extends Utility.RestrictedAccessSessionLandingPage {
  constructor(props) {
    super(props)
    this.state = {
      dataFetched: false
    }
    // 1 dialog box for the usual messages, 1 dialogbox for leaving/updating a curriculum review:
    this.numberOfDialogBoxes = 2
    this.pageId = 'view'
    this.pageBody = React.createRef()
    if (globals.haveLocalStorage)
      this.lastTabOpen = localStorage.getItem('termeric-page-' + this.pageId + '-' + this.id + '-last-tab-open')
  }

  componentDidMount() {
    super.componentDidMount()
    // if this page was loaded as a landing page, the superclass already initiates calls to the database to get the curriculum and teacher and user data. However, if we're here just by navigating from a previous page, these callbacks need to be called manually here:
    // if user has already bought the curr id we're currently viewing, then redirect to view page instead where they have full access to it:
    if (Database.isUserDataFetched())
      this.userDataFetched()
    // manually run the callback function to trigger getting teacher's data and render the page once the data is in:
    if (Database.isCurriculumDataFetchedForId(this.id))
      this.curriculumsDataFetched()
  }        

  // Database API calls this when curriculum info for the given id is fetched. Here first we need to fetch the teacher's data:
  curriculumsDataFetched() {   
    super.curriculumsDataFetched()
    if (this.curriculumData) {
      // check if user had previously marked this curriculum as wishlisted. Update wishlist button if so:        
      Database.database_fetchTeacherData(this.curriculumData.teacher)
    } else { // If there's no curriculum with the given id, it's an error. Redirect back to search page:
      this.setState({navigateTo: '/search'})
    }
  }

  // Database API calls this when teacher info for the given id is fetched. We'll trigger components that needed curriculum and teacher info to render now...
  teacherDataFetched() {
    this.teacherData = Database.getTeacherData(this.curriculumData.teacher)        
    if (this.teacherData !== undefined) {
      this.setState({dataFetched: true})
    }
  }

  // Database API calls this when teacher info for the given id is fetched. We'll trigger components that needed review info to render now...
  reviewsDataFetched() {        
    this.reviewsComponent.setState({dataFetched: true})
  }

  // Database API calls this when user tried to leave a new review (or update an old one) and any data is fetched
  userReviewForCurriculumFetched() {
    this.userReviewData = Database.getUserReviewForCurriculum(this.id)
    this.isNewReview = this.userReviewData === undefined   
    Utility.showDialog(1, this.renderReviewForm(), undefined, [this.isNewReview ? 'Submit' : 'Update'], [this.submitReview.bind(this)])
  }
  
  // fetches any already submitted review for the curriculum by the user and opens a dialog box to leave a new review (or update an old one if previously submitted):
  openReviewDialog() { 
    Database.database_fetchUserReviewDataForCurriculum(this.id, true)
  }

  // submits the new review data by the user about the curriculum (or update an old one):
  submitReview() {
    let rating = parseInt(this.reviewFormRatingRef.current.getAttribute('value'), 10)
    let review = this.reviewFormReviewRef.current.value.trim().replaceAll('\n', '<br>')
    var err
    if (!rating) {
        err = "Choose a rating between 1-5 stars"
    }
    var success = true
    if (err) {
        Utility.showTextDialog(0, err, generalConfig.dialogBoxErrorIconClassName)
        success = false
    } else {
      let isAnonymousReview = Utility.isCheckboxChecked(this.reviewFormCheckboxRef.current)
      let onSuccessFn = () => {            
          Utility.showTextDialog(0, 'Your review is saved.', generalConfig.dialogBoxOKIconClassName)
      }
      let reviewData = {rating: rating, review: review, 'reviewer-name': isAnonymousReview ? '' : globals.sessionInfo.userName}  
      let teacher = this.teacherData.id
      this.isNewReview ?
        Database.database_addNewReview(reviewData, this.id, teacher, this.curriculumData, onSuccessFn) :
        Database.database_updateReview(reviewData, this.id, teacher, this.curriculumData, onSuccessFn)
    }
    return success
  }

  // renders review form:
  renderReviewForm() {
    this.reviewFormCheckboxRef = React.createRef()
    this.reviewFormRatingRef = React.createRef()
    this.reviewFormReviewRef = React.createRef()
    return (
      <div id="view-review-form" className="form flex-col">
        <div className="form-rating-and-review-container">
          <div className="form-item flex-col">
            <label>Rate the course</label>
            {Utility.renderSelectableRatingStars(this, 'view-review-form-rating', 'rating-select', 200, this.isNewReview ? 0 : this.userReviewData.rating, this.reviewFormRatingRef)}
          </div>
          <div className="form-item flex-col">
            <label>Review this course</label>          
            <textarea id="view-review-form-review" className="textarea" placeholder="Write a review for this course..." defaultValue={this.isNewReview ? undefined : this.userReviewData.review.replace(/\<br\>/g, '\n') } ref={this.reviewFormReviewRef} />
          </div>
        </div>
        <div className="form-item flex-col">
          {Utility.renderCheckbox('view-review-form-anonymous-checkbox', !(this.isNewReview || this.userReviewData['reviewer-name']), 'Leave this review anonymously', this.reviewFormCheckboxRef)} 
        </div>
      </div>
    )
  }

  // forces update of the page body:
  reRenderBody() {
    let pageBodyComponent = this.pageBody.current
    if (pageBodyComponent != null)
      pageBodyComponent.forceUpdate()
  }
 
  renderBody() {
    return (
      <Utility.CurriculumViewPageBodyComponent page={this} ref={this.pageBody} />
    )      
  }

  // document header title for page:
  getPageTitle() { return this.getPageTitleForCurriculumListingPage() }

}

export default CurriculumViewPage


