/*
    User site - Contact Page for User to Send Messge to Us the Termeric Team or to a Teacher Curriculum Creator
*/

import React from 'react'

import * as Config from './config'
import * as Database from './database-api'
import * as Utility from './utility'

const globals = Config.globals
const generalConfig = Config.pages.general
const fsConfig = Config.fsConfig

class ContactPage extends Utility.LandingPageWithDialogBox {
    constructor(props) {
        super(props)
        this.pageId = 'contact'
        this.teacher = globals.urlParams['recipient-teacher'] || undefined
        this.isTeacherContact = this.teacher !== undefined
        let id = globals.urlParams.id, subject = globals.urlParams.subject
        this.isRefundRequest = globals.urlParams['refund-request'] === 'true' && subject && id 
        this.defaultTitle = this.isRefundRequest ? ('Requesting refund for curriculum: ' + subject + ' ' + id) : ''
        let dataFetched = Database.isSubjectsDataFetched() && (!this.isTeacherContact || Database.isTeacherDataFetched(this.teacher))  // ready to render... 
        this.state = {
            dataFetched: dataFetched
        }
        if (dataFetched) {
            if (this.isTeacherContact)
                this.teacherData = Database.getTeacherData(this.teacher)
        }
        this.formNameRef = React.createRef()
        this.formEmailRef = React.createRef()
        this.formTitleRef = React.createRef()
        this.formMessageRef = React.createRef()
    }
  
    subjectsDataFetched() {
        if (this.isTeacherContact && !Database.isTeacherDataFetched(this.teacher))
            Database.database_fetchTeacherData(this.teacher)
        else {
            if (this.isTeacherContact)
                this.teacherData = Database.getTeacherData(this.teacher)
            // initializations are done and ready to render:
            this.setState({dataFetched: true})
        }
    }
    
    // Database API calls this when teacher info for the given id is fetched. We'll trigger components that needed curriculum and teacher info to render now...
    teacherDataFetched() {
        this.teacherData = Database.getTeacherData(this.teacher)
        // if there's no teacher with the given id, it's an error. Redirect back to home page:
        if (this.isTeacherContact && !this.teacherData) 
            this.setState({navigateTo: '/'})
        else {
            // initializations are done and ready to render:
            this.setState({dataFetched: true})
        }
    }

    submitForm() {
        let isTeacherContact = this.isTeacherContact
        let name = this.formNameRef.current.value.trim()
        let email = this.formEmailRef.current.value.trim()
        let title = this.formTitleRef.current.value.trim()
        let message = this.formMessageRef.current.value.trim()
        var err
        if (!Utility.validateName(name)) {
            err = 'Enter your name.'
        } else if (!Utility.validateEmail(email)) {
            err = 'Enter a valid email.'
        } else if (!message) {
            err = this.isRefundRequest ? 'Please explain the reason for this refund request.' : 'Write a message.'
        }
        if (err) {
            Utility.showTextDialog(0, err, generalConfig.dialogBoxErrorIconClassName)
        } else {
            let onSuccessFn = () => {
                let onCloseFn = () => {
                    this.setState({navigateTo: '/'})
                }
                Utility.showTextDialog(0, (this.isRefundRequest ? 'Your request for refund is received. Our team will get back to you soon' : 'Your message is sent') + (isTeacherContact ? (' to teacher ' + (this.teacherData.name) + '. They will be replying directly to your email address.') : '. Thank you!'), generalConfig.dialogBoxOKIconClassName, onCloseFn)
            }
            let messageData = {name: name, email: email, title: title, message: message}
            if (isTeacherContact) {
                messageData['recipient-teacher'] = this.teacher
                messageData['recipient-teacher-name'] = this.teacherData.name
            }
            Database.database_sendEmailMessage(messageData, onSuccessFn)
        }
    }

    renderBody() {
        if (this.state.dataFetched) {
            let name = undefined, email = undefined
            let sessionInfo = globals.sessionInfo
            if (Utility.isUserSignedIn()) {
                name = sessionInfo.userName
                email = sessionInfo.userEmail
            }
            return (
                <div id="contact-body" className="flex-col section page-body">
                    <div id="contact-top-section" className="page-body-top-listing page-body-top-with-top-padding section">
                        <div>
                            <h2 className="header-text size31">{(this.isRefundRequest ? 'Send a money refund request to' : 'Write a message to') + ' ' + (this.isTeacherContact ? ('teacher ' + this.teacherData.name) : 'the Termeric Team')}</h2>
                            <h4 className="header-text size25 weight200 top-margin30">{this.isTeacherContact ? 'Your email address will be visible to them.' : (this.isRefundRequest ? 'Sorry to hear you are not satisfied with a curriculum... No worries! We will take care of your refund.' : 'We like to hear from you!')}</h4>
                        </div>
                        <div id="contact-submit" className="flex-col form-side-panel curriculum-listings-side-panel">
                            {Utility.renderButton(this.isRefundRequest ? 'Send refund request' : 'Send message', 'contact-submit-button', 'wide-cta-text-button cta-text-button shadowed-text-button bordered-text-button text-button button', this.submitForm.bind(this))}
                        </div>
                    </div>                
                    <div id="contact-form" className="form-container form flex-col">
                        <div className="form-item flex-col">
                            <label>Name</label>
                            <input id="contact-form-name" type="text" placeholder="Your name" defaultValue={name} ref={this.formNameRef} />
                        </div>
                        <div className="form-item flex-col">
                            <label>Email Address</label>
                            <input id="contact-form-email" type="text" placeholder="Your email address" defaultValue={email} ref={this.formEmailRef} />
                        </div>
                        <div className="form-item flex-col">
                            <label>Title</label>
                            <input id="contact-form-title" type="text" placeholder="A title for your message" defaultValue={globals.urlParams.title || this.defaultTitle} ref={this.formTitleRef} />
                        </div>
                        <div className="form-item flex-col">
                            <label>Message</label> 
                            <textarea id="contact-form-message" className="textarea" placeholder={this.isRefundRequest ? 'Please explain why you are not satisfied with this curriculum...' : 'Write a message...'} ref={this.formMessageRef} />
                        </div>
                    </div>
                    {Utility.renderImage(fsConfig.rootBucketUrl + '/' + fsConfig.publicRootBucket + '/images/classroom-logo-1.png', 'contact-image', 'place-self-center width300 top-margin25 opac5')}
                </div>
            )
        } else return Utility.renderPageLoading()
    }

    // default render of a landing page without search bar in header...:
    render() {
        return Utility.renderNoSearchBarPage(this)
    }   
}

export default ContactPage
